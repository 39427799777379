<template>
<div class="d-flex flex-column flex-root">
	<div class="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep" id="kt_create_account_stepper" data-kt-stepper="true">
		<div class="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
			<div class="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center" style="background-image: url(/assets/theme/media/logos/auth-bg.png)">
				<div class="d-flex flex-center py-10 py-lg-20 mt-lg-20">
					<a href="#">
						<img alt="Logo" src="assets/theme/media/logos/logo.png" class="h-100px">
					</a>
				</div>
				<div class="d-flex flex-row-fluid justify-content-center p-10">
					<div class="stepper-nav">
						<div class="stepper-item" data-kt-stepper-element="nav" v-if="this.activeStep > 0 && this.activeStep !== 5"> <!-- current -->
							<div class="stepper-wrapper">
								<div class="stepper-icon rounded-3">
									<i class="stepper-check fas fa-check"></i>
									<span class="stepper-number">
										<span class="svg-icon svg-icon-primary svg-icon-2hx" :class="{ 'svg-icon-success': this.activeStep == 1 }"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="currentColor"/>
										<rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="currentColor"/>
										</svg>
										</span>
									</span>
								</div>
								<div class="stepper-label text-white">
									<h3 class="stepper-title text-white fs-2">Account Info</h3>
									<div class="stepper-desc fw-normal text-gray-200">Setup your account settings</div>
								</div>
							</div>
							<div class="stepper-line"></div>
						</div>
						<div class="stepper-item" data-kt-stepper-element="nav" v-if="this.activeStep > 1 && this.activeStep !== 5 && userInfo.login_method == 2 && userInfo.authentication_type == 2">
							<div class="stepper-wrapper">
								<div class="stepper-icon rounded-3">
									<i class="stepper-check fas fa-check"></i>
									<span class="stepper-number">
										<span class="svg-icon svg-icon-primary svg-icon-2hx" :class="{ 'svg-icon-success': this.activeStep == 2 }"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
										<path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="currentColor"/>
										<path d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"/>
										</svg></span>
									</span>
								</div>
								<div class="stepper-label text-white">
									<h3 class="stepper-title text-white fs-2">Verification</h3>
									<div class="stepper-desc fw-normal text-gray-200">Verification</div>
								</div>
							</div>
							<div class="stepper-line"></div>
						</div>
						<div class="stepper-item" data-kt-stepper-element="nav" v-if="this.activeStep > 2 && this.activeStep !== 5">
							<div class="stepper-wrapper">
								<div class="stepper-icon">
									<i class="stepper-check fas fa-check"></i>
									<span class="stepper-number">
										<span class="svg-icon svg-icon-primary svg-icon-2hx" :class="{ 'svg-icon-success': this.activeStep == 3 }"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M1.34375 3.9463V15.2178C1.34375 16.119 2.08105 16.8563 2.98219 16.8563H8.65093V19.4594H6.15702C5.38853 19.4594 4.75981 19.9617 4.75981 20.5757V21.6921H19.2403V20.5757C19.2403 19.9617 18.6116 19.4594 17.8431 19.4594H15.3492V16.8563H21.0179C21.919 16.8563 22.6562 16.119 22.6562 15.2178V3.9463C22.6562 3.04516 21.9189 2.30786 21.0179 2.30786H2.98219C2.08105 2.30786 1.34375 3.04516 1.34375 3.9463ZM12.9034 9.9016C13.241 9.98792 13.5597 10.1216 13.852 10.2949L15.0393 9.4353L15.9893 10.3853L15.1297 11.5727C15.303 11.865 15.4366 12.1837 15.523 12.5212L16.97 12.7528V13.4089H13.9851C13.9766 12.3198 13.0912 11.4394 12 11.4394C10.9089 11.4394 10.0235 12.3198 10.015 13.4089H7.03006V12.7528L8.47712 12.5211C8.56345 12.1836 8.69703 11.8649 8.87037 11.5727L8.0107 10.3853L8.96078 9.4353L10.148 10.2949C10.4404 10.1215 10.759 9.98788 11.0966 9.9016L11.3282 8.45467H12.6718L12.9034 9.9016ZM16.1353 7.93758C15.6779 7.93758 15.3071 7.56681 15.3071 7.1094C15.3071 6.652 15.6779 6.28122 16.1353 6.28122C16.5926 6.28122 16.9634 6.652 16.9634 7.1094C16.9634 7.56681 16.5926 7.93758 16.1353 7.93758ZM2.71385 14.0964V3.90518C2.71385 3.78023 2.81612 3.67796 2.94107 3.67796H21.0589C21.1839 3.67796 21.2861 3.78023 21.2861 3.90518V14.0964C15.0954 14.0964 8.90462 14.0964 2.71385 14.0964Z" fill="currentColor"/>
										</svg>
										</span>
									</span>
								</div>
								<div class="stepper-label text-white">
									<h3 class="stepper-title text-white fs-2">Account Type</h3>
									<div class="stepper-desc fw-normal text-gray-200">Select your account type</div>
								</div>
							</div>
							<div class="stepper-line"></div>
						</div>
						<div class="stepper-item" data-kt-stepper-element="nav" v-if="this.activeStep > 3 && this.activeStep !== 5">
							<div class="stepper-wrapper">
								<div class="stepper-icon">
									<i class="stepper-check fas fa-check"></i>
									<span class="stepper-number">
										<span class="svg-icon svg-icon-primary svg-icon-2hx" :class="{ 'svg-icon-success': this.activeStep == 4 }"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"/>
										<path d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z" fill="currentColor"/>
										<path d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z" fill="currentColor"/>
										</svg>
										</span>
									</span>
								</div>
								<div class="stepper-label text-white">
									<h3 class="stepper-title text-white fs-2">Login</h3>
									<div class="stepper-desc fw-normal text-gray-200">Your are ready to Login</div>
								</div>
							</div>
							<div class="stepper-line"></div>
						</div>
						<div class="stepper-item" data-kt-stepper-element="nav" v-if="this.activeStep == 5">
							<div class="stepper-wrapper">
								<div class="stepper-icon">
									<i class="stepper-check fas fa-check"></i>
									<span class="stepper-number">
										<span class="svg-icon svg-icon-primary svg-icon-2hx" :class="{ 'svg-icon-success': this.activeStep == 5 }"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"/>
										<path d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z" fill="currentColor"/>
										<path d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z" fill="currentColor"/>
										</svg>
										</span>
									</span>
								</div>
								<div class="stepper-label text-white">
									<h3 class="stepper-title text-white fs-2">Password Expiration</h3>
									<div class="stepper-desc fw-normal text-gray-200">Reset password or continue.</div>
								</div>
							</div>
							<div class="stepper-line"></div>
						</div>
					</div>
				</div> 
			</div>
		</div>
		<div class="d-flex flex-column flex-lg-row-fluid py-10">
			<div class="d-flex flex-center flex-column flex-column-fluid">
				<div class="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
					<div class="current" data-kt-stepper-element="content" v-if="this.activeStep == 1">
						<form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_sign_in_form" @submit.prevent="onBeforeSubmit()">
							<div class="text-center mb-10">
								<h1 class="text-dark mb-3">Sign In to CreatorCUBE</h1> 
								<div class="text-gray-400 fw-bold fs-4">
								New Here?
								<a href="/" class="link-primary fw-bolder">
									Create an Account
								</a>
								</div>
							</div>
							<div class="separator separator-content my-14">
								<span class="w-125px text-gray-900 fw-semibold fs-7">Controller</span>
							</div>
							<div class="fv-row mb-10 fv-plugins-icon-container">
								<label class="form-label fs-6 fw-bolder text-dark">Email</label>
								<input class="form-control bg-white form-control-lg" type="text" name="email" autocomplete="off" v-model="form.username" placeholder="Email" :class="{'border border-danger': isValidEmail == false}"/>
								<small class="text-danger" v-if="!isValidEmail && form.username !== null && form.username !== '' ">Email is not valid</small>
							</div>

							<div class="fv-row mb-5 fv-plugins-icon-container">
							<div class="d-flex flex-stack mb-2">
								<label class="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
								</div>
								<div class="position-relative">
								<input id="ps_password" class="form-control bg-white form-control-lg" type="password" name="password" maxlength="16" v-model="form.password" autocomplete="off" placeholder="Password"/>
								<span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility()">
									<i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
									<i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
								</span>
								</div>
								<div class="fv-plugins-message-container invalid-feedback">
								<p v-if="showError" id="error">
									Email or Password is incorrect
								</p>
								</div>
							</div>
							
							<div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10">
								<div></div>
								<router-link to="/ForgotPassword">
								<a href="#" class="link-primary fw-bold">
									Forgot Password ?
								</a>
								</router-link>
							</div>

							<div class="text-center"> 
								<button type="submit" class="btn btn-lg btn-primary w-100 mb-5" data-kt-indicator="on" :disabled="isValidEmail !== true">
									<strong v-if="!this.isProgressing"> Continue </strong> 
									<span class="indicator-progress" v-if="this.isProgressing">
										Signing In. Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span> 
								</button>
							</div> 
							<div></div>
						</form>
					</div>
					<div class="current" data-kt-stepper-element="content"  v-if="this.activeStep == 2">
						<form class="form w-100 mb-13" id="kt_sing_in_two_steps_form" @submit.prevent="onVerifyTFA()">
							<div class="text-center mb-10">
								<img alt="Logo" class="mh-125px" src="assets/theme/media/logos/smartphone-2.svg">
							</div>
							<div class="text-center mb-10">
								<h1 class="text-dark mb-5">Two Step Verification</h1>
								<h3 class="text-gray-700 mb-5"> Welcome {{ userInfo.name_surname }}.</h3>
								<div class="text-muted fw-semibold fs-5 mb-2">Please enter the Authentication Code:</div>
							</div>
							<div class="mb-10">
								<div class="fw-bold text-start text-dark fs-6 mb-1 ms-1">Type your 6 digit security code</div>
								<div class="d-flex flex-wrap flex-stack">
									<input type="text" v-model="authCode" class="form-control text-center mt-3 form-control-lg fw-bolder" inputmode="text" maxlength="6">
								</div>
							</div>
							<div class="d-flex flex-center">											
								<button type="button" class="btn btn-lg btn-primary me-5" @click="this.activeStep = 1">
									<span class="indicator-label">Previous</span>
									<span class="indicator-progress">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
								</button>
								<button type="button" class="btn btn-lg btn-primary me-5" data-kt-indicator="on" @click="onVerifyTFA()">
									<strong v-if="!this.isVerifyProgressing"> Verify </strong> 
									<span class="indicator-progress" v-if="this.isVerifyProgressing">
										Verifying. Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span> 
								</button> 
							</div>
						</form>
					</div>
					<div class="current" data-kt-stepper-element="content"  v-if="this.activeStep == 3">
						<div class="w-100">
							<div class="pb-10">
								<h2 class="fw-bold d-flex align-items-center text-dark">Choose Account Type 
								<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Billing is issued based on your selected account type" data-bs-original-title="Billing is issued based on your selected account type" data-kt-initialized="1"></i></h2>
								<div class="text-muted fw-semibold fs-6">If you need more info, please check out 
								<a href="#" class="link-primary fw-bold">Help Page</a>.</div>
							</div>
							<div class="pb-10 pb-lg-15 mt-1 mb-1"> 
								<h4 class="fw-bold d-flex align-items-center text-dark">Current Permissions:</h4>
								<span class="badge badge-primary" v-if="userInfo.account_type == 1">System User</span>
								<span class="badge badge-success" v-if="userInfo.account_type == 2">Controller User</span>
								<span class="badge badge-secondary ms-4" v-if="userInfo.permission_type == 1">Admin</span> 
								<span class="badge badge-secondary ms-4" v-if="userInfo.permission_type == 2">Client</span> 
							</div>
							<div class="fv-row fv-plugins-icon-container">
								<div class="row">
									<div class="col-lg-12" v-if="userInfo.account_type == 1 && userInfo.permission_type == 1">
										<input type="radio" class="btn-check" name="account_type" value="system" checked="checked" id="kt_create_account_form_account_type_personal" v-model="form.typeSelection">
										<label class="btn btn-outline btn-outline-dashed btn-active-light-primary shadow btn-outline-default p-7 d-flex align-items-center mb-10" for="kt_create_account_form_account_type_personal"
											:class="{'shadow-lg': form.typeSelection == 'system', 'shadow-sm': form.typeSelection == 'controller'}">
											<span class="svg-icon svg-icon-3x me-5"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M1.34375 3.9463V15.2178C1.34375 16.119 2.08105 16.8563 2.98219 16.8563H8.65093V19.4594H6.15702C5.38853 19.4594 4.75981 19.9617 4.75981 20.5757V21.6921H19.2403V20.5757C19.2403 19.9617 18.6116 19.4594 17.8431 19.4594H15.3492V16.8563H21.0179C21.919 16.8563 22.6562 16.119 22.6562 15.2178V3.9463C22.6562 3.04516 21.9189 2.30786 21.0179 2.30786H2.98219C2.08105 2.30786 1.34375 3.04516 1.34375 3.9463ZM12.9034 9.9016C13.241 9.98792 13.5597 10.1216 13.852 10.2949L15.0393 9.4353L15.9893 10.3853L15.1297 11.5727C15.303 11.865 15.4366 12.1837 15.523 12.5212L16.97 12.7528V13.4089H13.9851C13.9766 12.3198 13.0912 11.4394 12 11.4394C10.9089 11.4394 10.0235 12.3198 10.015 13.4089H7.03006V12.7528L8.47712 12.5211C8.56345 12.1836 8.69703 11.8649 8.87037 11.5727L8.0107 10.3853L8.96078 9.4353L10.148 10.2949C10.4404 10.1215 10.759 9.98788 11.0966 9.9016L11.3282 8.45467H12.6718L12.9034 9.9016ZM16.1353 7.93758C15.6779 7.93758 15.3071 7.56681 15.3071 7.1094C15.3071 6.652 15.6779 6.28122 16.1353 6.28122C16.5926 6.28122 16.9634 6.652 16.9634 7.1094C16.9634 7.56681 16.5926 7.93758 16.1353 7.93758ZM2.71385 14.0964V3.90518C2.71385 3.78023 2.81612 3.67796 2.94107 3.67796H21.0589C21.1839 3.67796 21.2861 3.78023 21.2861 3.90518V14.0964C15.0954 14.0964 8.90462 14.0964 2.71385 14.0964Z" fill="currentColor"/>
											</svg>
											</span>
											<span class="d-block fw-semibold text-start">
												<span class="text-dark fw-bold d-block fs-4 mb-2">System Account</span>
												<span class="text-muted fw-semibold fs-6">Get Access with System Account permissions</span>
											</span>
										</label>
										<div class="fv-plugins-message-container invalid-feedback"></div>
									</div>
									<div class="col-lg-12" v-if="userInfo.account_type == 1 || userInfo.account_type == 2">
										<input type="radio" class="btn-check" name="account_type" value="controller" id="kt_create_account_form_account_type_corporate" v-model="form.typeSelection"> 
										<label class="btn btn-outline btn-outline-dashed btn-active-light-primary shadow btn-outline-default p-7 d-flex align-items-center" for="kt_create_account_form_account_type_corporate"
											:class="{'shadow-lg': form.typeSelection == 'controller', 'shadow-sm': form.typeSelection == 'system'}">
											<span class="svg-icon svg-icon-3x me-5"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M6.5 11C8.98528 11 11 8.98528 11 6.5C11 4.01472 8.98528 2 6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11Z" fill="currentColor"/>
											<path opacity="0.3" d="M13 6.5C13 4 15 2 17.5 2C20 2 22 4 22 6.5C22 9 20 11 17.5 11C15 11 13 9 13 6.5ZM6.5 22C9 22 11 20 11 17.5C11 15 9 13 6.5 13C4 13 2 15 2 17.5C2 20 4 22 6.5 22ZM17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22Z" fill="currentColor"/>
											</svg>
											</span>
											<span class="d-block fw-semibold text-start">
												<span class="text-dark fw-bold d-block fs-4 mb-2">Controller Account</span>
												<span class="text-muted fw-semibold fs-6">Get Access with Selected Controller Account's permissions</span>
											</span>
										</label>
									</div> 
									<div class="col-lg-12 mt-5" v-if="form.typeSelection == 'controller' && userInfo.account_type != 2"> 
										<Select2 v-model="form.selected_controller_id" :options="controllerList" :settings="{ width: '100%' }"/>  
									</div> 
								</div>
								<div class="d-flex flex-center mt-5">												
									<button type="button" class="btn btn-lg btn-primary me-5" @click="onClickPrevious()">
										<span class="indicator-label">Previous</span>
										<span class="indicator-progress">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
									<button type="button" 
											id="kt_sing_in_two_steps_submit" 
											class="btn btn-lg btn-success fw-bold" 
											@click="onVerifyLogin()" 
											:disabled="form.typeSelection == 'controller' && form.selected_controller_id == -1 && userInfo.account_type != 2">
										<span class="indicator-label">Login</span>
										<span class="indicator-progress">Please wait... 
										<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
								</div>
							</div>
						</div>									
					</div>
					<div class="current" data-kt-stepper-element="content"  v-if="this.activeStep == 4">
						<div class="w-100">
							<div class="pb-8 pb-lg-10">
								<h2 class="fw-bold text-dark">Your Are Done!</h2> 
							</div>
							<div class="mb-0">
								<div class="fs-6 text-gray-600 mb-5">Writing headlines for blog posts is as much an art as it is a science and probably warrants its own post, but for all advise is with what works for your great &amp; amazing audience.</div>
								<div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
									<span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
											<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
											<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
										</svg>
									</span>
									<div class="d-flex flex-stack flex-grow-1">
										<div class="fw-semibold">
											<h4 class="text-gray-900 fw-bold">We need your attention!</h4>
											<div class="fs-6 text-gray-700">To start using great tools, please, 
											<a href="/metronic8/demo16/../demo16/utilities/wizards/vertical.html" class="fw-bold">Create Team Platform</a></div>
										</div>
									</div>
								</div>
							</div>
							<div class="d-flex flex-center mt-5">												
								<button type="button" class="btn btn-lg btn-primary me-5 mt-5" @click="this.activeStep = 3">
									<span class="indicator-label">Previous</span>
									<span class="indicator-progress">Please wait...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
								</button>
								<button type="button" class="btn btn-lg btn-success me-5 mt-5" @click="onVerifyLogin()">
									<span class="indicator-label">Continue to Dashboard</span>
								</button>
							</div>
						</div>
					</div>

					<div class="current" data-kt-stepper-element="content" v-if="this.activeStep == 5">
						<div class="card-body py-15 py-lg-20">
							<div class="text-center mb-10">
								<h1 class="text-dark mb-3">
									Your password will expire in {{passwordChangeRemindDay}} days.
								</h1>
								<div class="text-muted fw-semibold fs-5 mb-5">
									You can change your password or continue using current password.
									<br>
									<a href="#" class="link-primary fw-bold" @click="onBackLogin()">
										Back to Login
									</a>
								</div>
							</div>
							
							<div class="text-center">
								<img src="/assets/theme/media/illustrations/unitedpalms-1/9.png" class="mw-100 mh-200px theme-light-show" alt="">
							</div>
							<div class="d-flex flex-stack pt-15 justify-content-center"> 
								<div class="me-5">
								<button type="button" class="btn btn-lg btn-light-success" @click="submit()">
									Continue Login
								</button>
								</div>
								<div>
								<button type="button" class="btn btn-lg btn-primary me-3" @click="goToResetPassword()"> 
									Change Password
								</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import axios from 'axios' 
import SecureLS from "secure-ls";  
const ls = new SecureLS({ isCompression: false });
import { load } from 'recaptcha-v3';

export default {
	name: "Login",
	components: {},
	data() {
		return {
			activeStep: 1,
			authCode: null,
			isProgressing: false,
			userInfo: null,
			isHide: true,
			accountList: [{id: null, text: 'Select controller account'}],
			form: {
				username: "",
				password: "",
				selected_controller_id: -1,
				typeSelection: 'system'
			},
			controllerList: [],
			showError: false,
			isVerifyProgressing: false,
			passwordChangeRemindDay: 0,
			isVerifyRecaptcha: false,
			rc_sk: ''
		};
	},
	computed: {
		isValidEmail() {
			return (this.form.username == null || this.form.username == '' || this.form.username == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.username);
		}
	},
	methods: {
	// ...mapActions(["LogIn"]),
	async submit() {
		this.isProgressing = true;
		const params = {
			username: this.form.username,
			password: this.form.password
		};
		await axios.post('/api/Auth/AuthUser', params, {'Content-Type': 'application/json'}).then((response) => {
           if(response.data.id != null && response.data.id.length == 36 && (response.data.error == null || response.data.error == 'undefined' || response.data.error == '')){
				response.data.company_account_id = this.$unlock(response.data.company_account_id);
				response.data.controller_account_id = this.$unlock(response.data.controller_account_id);
				response.data.system_account_id = this.$unlock(response.data.system_account_id);
				response.data.user_mail = this.$unlock(response.data.user_mail);
				response.data.name_surname = this.$unlock(response.data.name_surname);
				response.data.phone = this.$unlock(response.data.phone);
				response.data.controller_account_name = this.$unlock(response.data.controller_account_name);
				response.data.company_url = this.$unlock(response.data.company_url);
				response.data.currency_code = this.$unlock(response.data.currency_code);
				response.data.system_account_country_code = this.$unlock(response.data.system_account_country_code);
				
				ls.set('user_' + response.data.session_id, response.data); 
				sessionStorage.setItem('sid', response.data.session_id);
				setTimeout(() => { 
					this.isProgressing = false;
					this.userInfo = response.data;
					// Eğer 2FA açıksa Verify ekranına yönlendirilir. Değilse bu step pas geçilir.
					if(this.userInfo.login_method == 2 && this.userInfo.authentication_type == 2){
						this.authCode = null;
						this.activeStep = 2;
					}
					else{
						this.onPassTFA();
					} 
					if (this.userInfo.account_type == '1' && this.userInfo.permission_type == '2') {
						this.form.typeSelection = 'controller';
					}
				}, 500);
			}
			else{ 
				this.isProgressing = false;
				sessionStorage.clear();
				return;
			}
			this.showError = false;
      })
      .catch(function (error) { 
          console.log(error);
          this.showError = true;
          this.$swal("Login failed.", "Please make sure you entered the correct information and try again..!", 'error');
      });  
	},
    async loadReCaptcha() {
		if(this.rc_sk !== null && this.rc_sk !== '' && this.rc_sk !== undefined) {
			const recaptcha = await load(this.rc_sk);
			if(!this.isVerifyRecaptcha) recaptcha.hideBadge();
			else recaptcha.showBadge();
		}
    },
	async onBeforeSubmit() {
      if(this.isVerifyRecaptcha) {
        const recaptcha = await load(this.rc_sk);
        const token = await recaptcha.execute('login');
        const parameters = {
          token: token,
          sk: this.rc_sk
        };
        const that = this;
        axios.post("/api/Auth/CheckRC", parameters, { "Content-Type": "application/json" })
        .then((response) => {
          if (response.data.success == true && response.data.score > 0.5) {
            this.checkAuthPassword();
          } else {            
            that.$swal("Failed", "ReCaptcha error..!", 'error'); 
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      } else {
        this.checkAuthPassword();
      }
    },
	getData() {
		document.body.classList.add("app-blank");
		document.body.style.backgroundImage = "";	  
		let element = document.getElementsByClassName("modal-backdrop");
		if(element.length > 0) { element[0].className = ""; }
		this.form = {
			username: "",
			password: "",
			selected_controller_id: -1,
			typeSelection: "system",
		};
		
		axios.get('/api/GeneralUserParameters/GetRc').then((response) => {
			if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
				this.isVerifyRecaptcha = response.data.is_view_rc_lg == 1 ? true : false;
				this.rc_sk = response.data.sk;
				this.loadReCaptcha();
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	},
	onPassTFA(){
		const params = {
			session_id: this.$getUser().session_id,
			user_id: this.$getUser().id
		}
		axios.post("/api/Access/GetConfirmed", params, {'Content-Type': 'application/json'}).then((response) => {
			if(this.userInfo.account_type == 2 || (this.userInfo.account_type == 1 && this.userInfo.permission_type == 1 && response.data.length == 0) ) {
				if(this.userInfo.account_type) { this.selected_controller_id = this.userInfo.id; }
				this.onVerifyLogin();
			} else {
				this.controllerList = response.data; 
				this.controllerList.unshift( { id: -1, text: 'Select a Controller Account' } );
				setTimeout(() => { 
					this.isVerifyProgressing = false;
					this.activeStep = 3;
				}, 200);
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	},
	onVerifyTFA(){ 
		this.isVerifyProgressing = true;
		const parameters = {
			code: this.authCode
		}; 
		axios.post('/api/Users/CheckAuthenticationCode', parameters, {'Content-Type': 'application/json'})
		.then((response) => {
			if(response.data == true){
				this.lists = [];
				const params = {
					session_id: this.$getUser().session_id,
					user_id: this.$getUser().id
				}
				axios.post("/api/Access/GetConfirmed", params, {'Content-Type': 'application/json'}).then((response) => {
					if(this.userInfo.account_type == 2 || (this.userInfo.account_type == 1 && this.userInfo.permission_type == 1 && response.data.length == 0) ) {
						if(this.userInfo.account_type) { this.selected_controller_id = this.userInfo.id; }
						this.onVerifyLogin();
					} else {
						this.controllerList = response.data; 
						this.controllerList.unshift( { id: -1, text: 'Select a Controller Account' } );
						setTimeout(() => { 
							this.isVerifyProgressing = false;
							this.authCode = null;
							this.activeStep = 3;
						}, 200);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			}
			else{
				this.isVerifyProgressing = false;
				this.$swal("Error", "Authentication failed..!", 'warning');
			} 
		}) 
		.catch(function (error) {
			console.log(error);
			this.Progress.finish();
		});   
	},
	onVerifyLogin(){ 
		if(this.form.selected_controller_id != null && this.form.selected_controller_id != '-1' && this.form.selected_controller_id != ''){
			const params = {
				session_id: this.$getUser().session_id,
				controller_id: this.form.selected_controller_id
			}
			axios.post("/api/Users/SetControllerToSession", params, {'Content-Type': 'application/json'}).then((response) => { 
				if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
				var userData = ls.get('user_' + sessionStorage.getItem('sid'));
				userData['controller_account_id'] = this.form.selected_controller_id;
				this.controllerList.forEach(element => {
					if (element.id == this.form.selected_controller_id) {
						userData['controller_account_name'] = element.text;
					}
				});
				userData['permission_type'] = 2;
				ls.set('user_' + sessionStorage.getItem('sid'), userData);
				window.location.href= "/";
				}
			})
			.catch(function (error) {
				console.log(error); 
			});
		} 
		else{
			window.location.href= "/";
		}
	},
	onClickPrevious(){
		if(this.$getUser().login_method == 2 && this.$getUser().authentication_type == 2){
			this.authCode = null;
			this.activeStep = 2;
			this.form.selected_controller_id = -1;
		}
		else{
			this.activeStep = 1;
			this.form.selected_controller_id = -1;
		}  
	},
	goToResetPassword() {
		this.$router.push({ name: 'ResetPassword', params: { user_mail: this.form.username } });
	},
	checkAuthPassword() {
		const parameters = {
			username: this.form.username,
			password: this.form.password,
		};
		axios.post("/api/Auth/CheckAuthPassword", parameters, {'Content-Type': 'application/json'}).then((response) => { 
			if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){				
				if (response.data.is_password_change_required == 1) {
					this.$router.push({ name: "ResetPassword", params: { user_mail: this.form.username }});
				} else if (response.data.is_remind_password_change == 1) {
					this.passwordChangeRemindDay = response.data.password_change_remind_day;
					this.activeStep = 5;
				} else {
					this.submit();
				}
			}
		})
		.catch(function (error) {
		console.log(error);
		});
	},	
	onBackLogin() {
		this.form = {
			username: "",
			password: "",
			selected_controller_id: -1,
			typeSelection: 'system'
		};
		this.activeStep = 1;
		this.authCode = null;
		this.passwordChangeRemindDay = 0;
	},
	togglePasswordVisibility() {
		this.isHide = !this.isHide;
		var x = document.getElementById("ps_password");
		if (x.type === "password") {
			x.type = "text";
		} else {
			x.type = "password";
		}
	},
  },
  mounted() {
    sessionStorage.clear();
  },
  created() {
    window.document.title = "Login | CreatorCUBE";
    this.getData();
  }
};
</script>
<style scoped>
	body{
	height: 100% !important;
	}

	@media (min-width: 992px){
	.flex-lg-start {
		justify-content: start;
		align-items: start;
	}}
</style>
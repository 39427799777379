<template>
  <div>
    <div
      id="kt_activities"
      class="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
    >
      <div class="card shadow-none rounded-0">
        <div class="card-header" id="kt_activities_header">
          <h3 class="card-title fw-bolder text-dark">Activity Logs</h3>
          <div class="card-toolbar">
            <button
              type="button"
              class="btn btn-sm btn-icon btn-active-light-primary me-n5"
              id="kt_activities_close"
            >
              <span class="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>

        <div class="card-body position-relative" id="kt_activities_body">
          <div
            id="kt_activities_scroll"
            class="position-relative scroll-y me-n5 pe-5"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_activities_body"
            data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
            data-kt-scroll-offset="5px"
          >
            <div class="timeline">
              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>

                <div
                  class="timeline-icon symbol symbol-circle symbol-40px me-4"
                >
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                          fill="currentColor"
                        />
                        <path
                          d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="timeline-content mb-10 mt-n1">
                  <div class="pe-3 mb-5">
                    <div class="fs-5 fw-bold mb-2">
                      There are 2 new tasks for you in “AirPlus Mobile App”
                      project:
                    </div>

                    <div class="d-flex align-items-center mt-1 fs-6">
                      <div class="text-muted me-2 fs-7">
                        Added at 4:23 PM by
                      </div>

                      <div
                        class="symbol symbol-circle symbol-25px"
                        data-bs-toggle="tooltip"
                        data-bs-boundary="window"
                        data-bs-placement="top"
                        title="Nina Nilson"
                      >
                        <img
                          src="assets/theme/media/avatars/300-14.jpg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="overflow-auto pb-5">
                    <div
                      class="
                        d-flex
                        align-items-center
                        border border-dashed border-gray-300
                        rounded
                        min-w-750px
                        px-7
                        py-3
                        mb-5
                      "
                    >
                      <a
                        href="../../demo16/dist/apps/projects/project.html"
                        class="
                          fs-5
                          text-dark text-hover-primary
                          fw-bold
                          w-375px
                          min-w-200px
                        "
                        >Meeting with customer</a
                      >

                      <div class="min-w-175px pe-2">
                        <span class="badge badge-light text-muted"
                          >Application Design</span
                        >
                      </div>

                      <div
                        class="
                          symbol-group symbol-hover
                          flex-nowrap flex-grow-1
                          min-w-100px
                          pe-2
                        "
                      >
                        <div class="symbol symbol-circle symbol-25px">
                          <img
                            src="assets/theme/media/avatars/300-2.jpg"
                            alt="img"
                          />
                        </div>

                        <div class="symbol symbol-circle symbol-25px">
                          <img
                            src="assets/theme/media/avatars/300-14.jpg"
                            alt="img"
                          />
                        </div>

                        <div class="symbol symbol-circle symbol-25px">
                          <div
                            class="
                              symbol-label
                              fs-8
                              fw-bold
                              bg-primary
                              text-inverse-primary
                            "
                          >
                            A
                          </div>
                        </div>
                      </div>

                      <div class="min-w-125px pe-2">
                        <span class="badge badge-light-primary"
                          >In Progress</span
                        >
                      </div>

                      <a
                        href="../../demo16/dist/apps/projects/project.html"
                        class="btn btn-sm btn-light btn-active-light-primary"
                        >View</a
                      >
                    </div>

                    <div
                      class="
                        d-flex
                        align-items-center
                        border border-dashed border-gray-300
                        rounded
                        min-w-750px
                        px-7
                        py-3
                        mb-0
                      "
                    >
                      <a
                        href="../../demo16/dist/apps/projects/project.html"
                        class="
                          fs-5
                          text-dark text-hover-primary
                          fw-bold
                          w-375px
                          min-w-200px
                        "
                        >Project Delivery Preparation</a
                      >

                      <div class="min-w-175px">
                        <span class="badge badge-light text-muted"
                          >CRM System Development</span
                        >
                      </div>

                      <div
                        class="
                          symbol-group symbol-hover
                          flex-nowrap flex-grow-1
                          min-w-100px
                        "
                      >
                        <div class="symbol symbol-circle symbol-25px">
                          <img
                            src="assets/theme/media/avatars/300-20.jpg"
                            alt="img"
                          />
                        </div>

                        <div class="symbol symbol-circle symbol-25px">
                          <div
                            class="
                              symbol-label
                              fs-8
                              fw-bold
                              bg-success
                              text-inverse-primary
                            "
                          >
                            B
                          </div>
                        </div>
                      </div>

                      <div class="min-w-125px">
                        <span class="badge badge-light-success">Completed</span>
                      </div>

                      <a
                        href="../../demo16/dist/apps/projects/project.html"
                        class="btn btn-sm btn-light btn-active-light-primary"
                        >View</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>

                <div class="timeline-icon symbol symbol-circle symbol-40px">
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M5.78001 21.115L3.28001 21.949C3.10897 22.0059 2.92548 22.0141 2.75004 21.9727C2.57461 21.9312 2.41416 21.8418 2.28669 21.7144C2.15923 21.5869 2.06975 21.4264 2.0283 21.251C1.98685 21.0755 1.99507 20.892 2.05201 20.7209L2.886 18.2209L7.22801 13.879L10.128 16.774L5.78001 21.115Z"
                          fill="currentColor"
                        />
                        <path
                          d="M21.7 8.08899L15.911 2.30005C15.8161 2.2049 15.7033 2.12939 15.5792 2.07788C15.455 2.02637 15.3219 1.99988 15.1875 1.99988C15.0531 1.99988 14.92 2.02637 14.7958 2.07788C14.6717 2.12939 14.5589 2.2049 14.464 2.30005L13.74 3.02295C13.548 3.21498 13.4402 3.4754 13.4402 3.74695C13.4402 4.01849 13.548 4.27892 13.74 4.47095L14.464 5.19397L11.303 8.35498C10.1615 7.80702 8.87825 7.62639 7.62985 7.83789C6.38145 8.04939 5.2293 8.64265 4.332 9.53601C4.14026 9.72817 4.03256 9.98855 4.03256 10.26C4.03256 10.5315 4.14026 10.7918 4.332 10.984L13.016 19.667C13.208 19.859 13.4684 19.9668 13.74 19.9668C14.0115 19.9668 14.272 19.859 14.464 19.667C15.3575 18.77 15.9509 17.618 16.1624 16.3698C16.374 15.1215 16.1932 13.8383 15.645 12.697L18.806 9.53601L19.529 10.26C19.721 10.452 19.9814 10.5598 20.253 10.5598C20.5245 10.5598 20.785 10.452 20.977 10.26L21.7 9.53601C21.7952 9.44108 21.8706 9.32825 21.9221 9.2041C21.9737 9.07995 22.0002 8.94691 22.0002 8.8125C22.0002 8.67809 21.9737 8.54505 21.9221 8.4209C21.8706 8.29675 21.7952 8.18392 21.7 8.08899Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="timeline-content mb-10 mt-n2">
                  <div class="overflow-auto pe-3">
                    <div class="fs-5 fw-bold mb-2">
                      Invitation for crafting engaging designs that speak human
                      workshop
                    </div>

                    <div class="d-flex align-items-center mt-1 fs-6">
                      <div class="text-muted me-2 fs-7">Sent at 4:23 PM by</div>

                      <div
                        class="symbol symbol-circle symbol-25px"
                        data-bs-toggle="tooltip"
                        data-bs-boundary="window"
                        data-bs-placement="top"
                        title="Alan Nilson"
                      >
                        <img
                          src="assets/theme/media/avatars/300-1.jpg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>

                <div class="timeline-icon symbol symbol-circle symbol-40px">
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11.2166 8.50002L10.5166 7.80007C10.1166 7.40007 10.1166 6.80005 10.5166 6.40005L13.4166 3.50002C15.5166 1.40002 18.9166 1.50005 20.8166 3.90005C22.5166 5.90005 22.2166 8.90007 20.3166 10.8001L17.5166 13.6C17.1166 14 16.5166 14 16.1166 13.6L15.4166 12.9C15.0166 12.5 15.0166 11.9 15.4166 11.5L18.3166 8.6C19.2166 7.7 19.1166 6.30002 18.0166 5.50002C17.2166 4.90002 16.0166 5.10007 15.3166 5.80007L12.4166 8.69997C12.2166 8.89997 11.6166 8.90002 11.2166 8.50002ZM11.2166 15.6L8.51659 18.3001C7.81659 19.0001 6.71658 19.2 5.81658 18.6C4.81658 17.9 4.71659 16.4 5.51659 15.5L8.31658 12.7C8.71658 12.3 8.71658 11.7001 8.31658 11.3001L7.6166 10.6C7.2166 10.2 6.6166 10.2 6.2166 10.6L3.6166 13.2C1.7166 15.1 1.4166 18.1 3.1166 20.1C5.0166 22.4 8.51659 22.5 10.5166 20.5L13.3166 17.7C13.7166 17.3 13.7166 16.7001 13.3166 16.3001L12.6166 15.6C12.3166 15.2 11.6166 15.2 11.2166 15.6Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M5.0166 9L2.81659 8.40002C2.31659 8.30002 2.0166 7.79995 2.1166 7.19995L2.31659 5.90002C2.41659 5.20002 3.21659 4.89995 3.81659 5.19995L6.0166 6.40002C6.4166 6.60002 6.6166 7.09998 6.5166 7.59998L6.31659 8.30005C6.11659 8.80005 5.5166 9.1 5.0166 9ZM8.41659 5.69995H8.6166C9.1166 5.69995 9.5166 5.30005 9.5166 4.80005L9.6166 3.09998C9.6166 2.49998 9.2166 2 8.5166 2H7.81659C7.21659 2 6.71659 2.59995 6.91659 3.19995L7.31659 4.90002C7.41659 5.40002 7.91659 5.69995 8.41659 5.69995ZM14.6166 18.2L15.1166 21.3C15.2166 21.8 15.7166 22.2 16.2166 22L17.6166 21.6C18.1166 21.4 18.4166 20.8 18.1166 20.3L16.7166 17.5C16.5166 17.1 16.1166 16.9 15.7166 17L15.2166 17.1C14.8166 17.3 14.5166 17.7 14.6166 18.2ZM18.4166 16.3L19.8166 17.2C20.2166 17.5 20.8166 17.3 21.0166 16.8L21.3166 15.9C21.5166 15.4 21.1166 14.8 20.5166 14.8H18.8166C18.0166 14.8 17.7166 15.9 18.4166 16.3Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="timeline-content mb-10 mt-n1">
                  <div class="mb-5 pe-3">
                    <a
                      href="#"
                      class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                      >3 New Incoming Project Files:</a
                    >

                    <div class="d-flex align-items-center mt-1 fs-6">
                      <div class="text-muted me-2 fs-7">
                        Sent at 10:30 PM by
                      </div>

                      <div
                        class="symbol symbol-circle symbol-25px"
                        data-bs-toggle="tooltip"
                        data-bs-boundary="window"
                        data-bs-placement="top"
                        title="Jan Hummer"
                      >
                        <img
                          src="assets/theme/media/avatars/300-23.jpg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="overflow-auto pb-5">
                    <div
                      class="
                        d-flex
                        align-items-center
                        border border-dashed border-gray-300
                        rounded
                        min-w-700px
                        p-5
                      "
                    >
                      <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                        <img
                          alt=""
                          class="w-30px me-3"
                          src="assets/theme/media/svg/files/pdf.svg"
                        />

                        <div class="ms-1 fw-bold">
                          <a
                            href="../../demo16/dist/apps/projects/project.html"
                            class="fs-6 text-hover-primary fw-bolder"
                            >Finance KPI App Guidelines</a
                          >

                          <div class="text-gray-400">1.9mb</div>
                        </div>
                      </div>

                      <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                        <img
                          alt="../../demo16/dist/apps/projects/project.html"
                          class="w-30px me-3"
                          src="assets/theme/media/svg/files/doc.svg"
                        />

                        <div class="ms-1 fw-bold">
                          <a href="#" class="fs-6 text-hover-primary fw-bolder"
                            >Client UAT Testing Results</a
                          >

                          <div class="text-gray-400">18kb</div>
                        </div>
                      </div>

                      <div class="d-flex flex-aligns-center">
                        <img
                          alt="../../demo16/dist/apps/projects/project.html"
                          class="w-30px me-3"
                          src="assets/theme/media/svg/files/css.svg"
                        />

                        <div class="ms-1 fw-bold">
                          <a href="#" class="fs-6 text-hover-primary fw-bolder"
                            >Finance Reports</a
                          >

                          <div class="text-gray-400">20mb</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>

                <div class="timeline-icon symbol symbol-circle symbol-40px">
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                          fill="currentColor"
                        />
                        <path
                          d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="timeline-content mb-10 mt-n1">
                  <div class="pe-3 mb-5">
                    <div class="fs-5 fw-bold mb-2">
                      Task
                      <a href="#" class="text-primary fw-bolder me-1">#45890</a
                      >merged with
                      <a href="#" class="text-primary fw-bolder me-1">#45890</a
                      >in “Ads Pro Admin Dashboard project:
                    </div>

                    <div class="d-flex align-items-center mt-1 fs-6">
                      <div class="text-muted me-2 fs-7">
                        Initiated at 4:23 PM by
                      </div>

                      <div
                        class="symbol symbol-circle symbol-25px"
                        data-bs-toggle="tooltip"
                        data-bs-boundary="window"
                        data-bs-placement="top"
                        title="Nina Nilson"
                      >
                        <img
                          src="assets/theme/media/avatars/300-14.jpg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>

                <div class="timeline-icon symbol symbol-circle symbol-40px">
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                          fill="currentColor"
                        />
                        <path
                          d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="timeline-content mb-10 mt-n1">
                  <div class="pe-3 mb-5">
                    <div class="fs-5 fw-bold mb-2">
                      3 new application design concepts added:
                    </div>

                    <div class="d-flex align-items-center mt-1 fs-6">
                      <div class="text-muted me-2 fs-7">
                        Created at 4:23 PM by
                      </div>

                      <div
                        class="symbol symbol-circle symbol-25px"
                        data-bs-toggle="tooltip"
                        data-bs-boundary="window"
                        data-bs-placement="top"
                        title="Marcus Dotson"
                      >
                        <img
                          src="assets/theme/media/avatars/300-2.jpg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="overflow-auto pb-5">
                    <div
                      class="
                        d-flex
                        align-items-center
                        border border-dashed border-gray-300
                        rounded
                        min-w-700px
                        p-7
                      "
                    >
                      <div class="overlay me-10">
                        <div class="overlay-wrapper">
                          <img
                            alt="img"
                            class="rounded w-150px"
                            src="assets/theme/media/stock/600x400/img-29.jpg"
                          />
                        </div>

                        <div
                          class="overlay-layer bg-dark bg-opacity-10 rounded"
                        >
                          <a href="#" class="btn btn-sm btn-primary btn-shadow"
                            >Explore</a
                          >
                        </div>
                      </div>

                      <div class="overlay me-10">
                        <div class="overlay-wrapper">
                          <img
                            alt="img"
                            class="rounded w-150px"
                            src="assets/theme/media/stock/600x400/img-31.jpg"
                          />
                        </div>

                        <div
                          class="overlay-layer bg-dark bg-opacity-10 rounded"
                        >
                          <a href="#" class="btn btn-sm btn-primary btn-shadow"
                            >Explore</a
                          >
                        </div>
                      </div>

                      <div class="overlay">
                        <div class="overlay-wrapper">
                          <img
                            alt="img"
                            class="rounded w-150px"
                            src="assets/theme/media/stock/600x400/img-40.jpg"
                          />
                        </div>

                        <div
                          class="overlay-layer bg-dark bg-opacity-10 rounded"
                        >
                          <a href="#" class="btn btn-sm btn-primary btn-shadow"
                            >Explore</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>

                <div class="timeline-icon symbol symbol-circle symbol-40px">
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="timeline-content mb-10 mt-n1">
                  <div class="pe-3 mb-5">
                    <div class="fs-5 fw-bold mb-2">
                      New case
                      <a href="#" class="text-primary fw-bolder me-1">#67890</a
                      >is assigned to you in Multi-platform Database Design
                      project
                    </div>

                    <div class="overflow-auto pb-5">
                      <div class="d-flex align-items-center mt-1 fs-6">
                        <div class="text-muted me-2 fs-7">
                          Added at 4:23 PM by
                        </div>

                        <a href="#" class="text-primary fw-bolder me-1"
                          >Alice Tan</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>

                <div class="timeline-icon symbol symbol-circle symbol-40px">
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                          fill="currentColor"
                        />
                        <path
                          d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="timeline-content mb-10 mt-n1">
                  <div class="pe-3 mb-5">
                    <div class="fs-5 fw-bold mb-2">
                      You have received a new order:
                    </div>

                    <div class="d-flex align-items-center mt-1 fs-6">
                      <div class="text-muted me-2 fs-7">
                        Placed at 5:05 AM by
                      </div>

                      <div
                        class="symbol symbol-circle symbol-25px"
                        data-bs-toggle="tooltip"
                        data-bs-boundary="window"
                        data-bs-placement="top"
                        title="Robert Rich"
                      >
                        <img
                          src="assets/theme/media/avatars/300-4.jpg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="overflow-auto pb-5">
                    <div
                      class="
                        notice
                        d-flex
                        bg-light-primary
                        rounded
                        border-primary border border-dashed
                        min-w-lg-600px
                        flex-shrink-0
                        p-6
                      "
                    >
                      <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            opacity="0.3"
                            d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z"
                            fill="currentColor"
                          />
                          <path
                            d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>

                      <div
                        class="
                          d-flex
                          flex-stack flex-grow-1 flex-wrap flex-md-nowrap
                        "
                      >
                        <div class="mb-3 mb-md-0 fw-bold">
                          <h4 class="text-gray-900 fw-bolder">
                            Database Backup Process Completed!
                          </h4>
                          <div class="fs-6 text-gray-700 pe-7">
                            Login into Admin Dashboard to make sure the data
                            integrity is OK
                          </div>
                        </div>

                        <a
                          href="#"
                          class="
                            btn btn-primary
                            px-6
                            align-self-center
                            text-nowrap
                          "
                          >Proceed</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-line w-40px"></div>

                <div class="timeline-icon symbol symbol-circle symbol-40px">
                  <div class="symbol-label bg-light">
                    <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="timeline-content mt-n1">
                  <div class="pe-3 mb-5">
                    <div class="fs-5 fw-bold mb-2">
                      New order
                      <a href="#" class="text-primary fw-bolder me-1">#67890</a
                      >is placed for Workshow Planning &amp; Budget Estimation
                    </div>

                    <div class="d-flex align-items-center mt-1 fs-6">
                      <div class="text-muted me-2 fs-7">
                        Placed at 4:23 PM by
                      </div>

                      <a href="#" class="text-primary fw-bolder me-1"
                        >Jimmy Bold</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer py-5 text-center" id="kt_activities_footer">
          <a
            href="../../demo16/dist/pages/user-profile/activity.html"
            class="btn btn-bg-body text-primary"
            >View All Activities

            <span class="svg-icon svg-icon-3 svg-icon-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="18"
                  y="13"
                  width="13"
                  height="2"
                  rx="1"
                  transform="rotate(-180 18 13)"
                  fill="currentColor"
                />
                <path
                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div
      id="kt_drawer_chat"
      class="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="chat"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_drawer_chat_toggle"
      data-kt-drawer-close="#kt_drawer_chat_close"
    >
      <div class="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
        <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">
          <div class="card-title">
            <div class="d-flex justify-content-center flex-column me-3">
              <a
                href="#"
                class="
                  fs-4
                  fw-bolder
                  text-gray-900 text-hover-primary
                  me-1
                  mb-2
                  lh-1
                "
                >Brian Cox</a
              >

              <div class="mb-0 lh-1">
                <span
                  class="badge badge-success badge-circle w-10px h-10px me-1"
                ></span>
                <span class="fs-7 fw-bold text-muted">Active</span>
              </div>
            </div>
          </div>

          <div class="card-toolbar">
            <div class="me-2">
              <button
                class="btn btn-sm btn-icon btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                <i class="bi bi-three-dots fs-3"></i>
              </button>

              <div
                class="
                  menu
                  menu-sub
                  menu-sub-dropdown
                  menu-column
                  menu-rounded
                  menu-gray-800
                  menu-state-bg-light-primary
                  fw-bold
                  w-200px
                  py-3
                "
                data-kt-menu="true"
              >
                <div class="menu-item px-3">
                  <div
                    class="
                      menu-content
                      text-muted
                      pb-2
                      px-3
                      fs-7
                      text-uppercase
                    "
                  >
                    Contacts
                  </div>
                </div>

                <div class="menu-item px-3">
                  <a
                    href="#"
                    class="menu-link px-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_users_search"
                    >Add Contact</a
                  >
                </div>

                <div class="menu-item px-3">
                  <a
                    href="#"
                    class="menu-link flex-stack px-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_invite_friends"
                    >Invite Contacts
                    <i
                      class="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      title="Specify a contact email to send an invitation"
                    ></i
                  ></a>
                </div>

                <div
                  class="menu-item px-3"
                  data-kt-menu-trigger="hover"
                  data-kt-menu-placement="right-start"
                >
                  <a href="#" class="menu-link px-3">
                    <span class="menu-title">Groups</span>
                    <span class="menu-arrow"></span>
                  </a>

                  <div class="menu-sub menu-sub-dropdown w-175px py-4">
                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class="menu-link px-3"
                        data-bs-toggle="tooltip"
                        title="Coming soon"
                        >Create Group</a
                      >
                    </div>

                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class="menu-link px-3"
                        data-bs-toggle="tooltip"
                        title="Coming soon"
                        >Invite Members</a
                      >
                    </div>

                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class="menu-link px-3"
                        data-bs-toggle="tooltip"
                        title="Coming soon"
                        >Settings</a
                      >
                    </div>
                  </div>
                </div>

                <div class="menu-item px-3 my-1">
                  <a
                    href="#"
                    class="menu-link px-3"
                    data-bs-toggle="tooltip"
                    title="Coming soon"
                    >Settings</a
                  >
                </div>
              </div>
            </div>

            <div
              class="btn btn-sm btn-icon btn-active-light-primary"
              id="kt_drawer_chat_close"
            >
              <span class="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div class="card-body" id="kt_drawer_chat_messenger_body">
          <div
            class="scroll-y me-n5 pe-5"
            data-kt-element="messages"
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
            data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
            data-kt-scroll-offset="0px"
          >
            <div class="d-flex justify-content-start mb-10">
              <div class="d-flex flex-column align-items-start">
                <div class="d-flex align-items-center mb-2">
                  <div class="symbol symbol-35px symbol-circle">
                    <img
                      alt="Pic"
                      src="assets/theme/media/avatars/300-25.jpg"
                    />
                  </div>

                  <div class="ms-3">
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        me-1
                      "
                      >Brian Cox</a
                    >
                    <span class="text-muted fs-7 mb-1">2 mins</span>
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-info
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-start
                  "
                  data-kt-element="message-text"
                >
                  How likely are you to recommend our company to your friends
                  and family ?
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-10">
              <div class="d-flex flex-column align-items-end">
                <div class="d-flex align-items-center mb-2">
                  <div class="me-3">
                    <span class="text-muted fs-7 mb-1">5 mins</span>
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        ms-1
                      "
                      >You</a
                    >
                  </div>

                  <div class="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/theme/media/avatars/300-1.jpg" />
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-primary
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-end
                  "
                  data-kt-element="message-text"
                >
                  Hey there, we’re just writing to let you know that you’ve been
                  subscribed to a repository on GitHub.
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-start mb-10">
              <div class="d-flex flex-column align-items-start">
                <div class="d-flex align-items-center mb-2">
                  <div class="symbol symbol-35px symbol-circle">
                    <img
                      alt="Pic"
                      src="assets/theme/media/avatars/300-25.jpg"
                    />
                  </div>

                  <div class="ms-3">
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        me-1
                      "
                      >Brian Cox</a
                    >
                    <span class="text-muted fs-7 mb-1">1 Hour</span>
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-info
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-start
                  "
                  data-kt-element="message-text"
                >
                  Ok, Understood!
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-10">
              <div class="d-flex flex-column align-items-end">
                <div class="d-flex align-items-center mb-2">
                  <div class="me-3">
                    <span class="text-muted fs-7 mb-1">2 Hours</span>
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        ms-1
                      "
                      >You</a
                    >
                  </div>

                  <div class="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/theme/media/avatars/300-1.jpg" />
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-primary
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-end
                  "
                  data-kt-element="message-text"
                >
                  You’ll receive notifications for all issues, pull requests!
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-start mb-10">
              <div class="d-flex flex-column align-items-start">
                <div class="d-flex align-items-center mb-2">
                  <div class="symbol symbol-35px symbol-circle">
                    <img
                      alt="Pic"
                      src="assets/theme/media/avatars/300-25.jpg"
                    />
                  </div>

                  <div class="ms-3">
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        me-1
                      "
                      >Brian Cox</a
                    >
                    <span class="text-muted fs-7 mb-1">3 Hours</span>
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-info
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-start
                  "
                  data-kt-element="message-text"
                >
                  You can unwatch this repository immediately by clicking here:
                  <a href="https://keenthemes.com">Keenthemes.com</a>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-10">
              <div class="d-flex flex-column align-items-end">
                <div class="d-flex align-items-center mb-2">
                  <div class="me-3">
                    <span class="text-muted fs-7 mb-1">4 Hours</span>
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        ms-1
                      "
                      >You</a
                    >
                  </div>

                  <div class="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/theme/media/avatars/300-1.jpg" />
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-primary
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-end
                  "
                  data-kt-element="message-text"
                >
                  Most purchased Business courses during this sale!
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-start mb-10">
              <div class="d-flex flex-column align-items-start">
                <div class="d-flex align-items-center mb-2">
                  <div class="symbol symbol-35px symbol-circle">
                    <img
                      alt="Pic"
                      src="assets/theme/media/avatars/300-25.jpg"
                    />
                  </div>

                  <div class="ms-3">
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        me-1
                      "
                      >Brian Cox</a
                    >
                    <span class="text-muted fs-7 mb-1">5 Hours</span>
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-info
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-start
                  "
                  data-kt-element="message-text"
                >
                  Company BBQ to celebrate the last quater achievements and
                  goals. Food and drinks provided
                </div>
              </div>
            </div>

            <div
              class="d-flex justify-content-end mb-10 d-none"
              data-kt-element="template-out"
            >
              <div class="d-flex flex-column align-items-end">
                <div class="d-flex align-items-center mb-2">
                  <div class="me-3">
                    <span class="text-muted fs-7 mb-1">Just now</span>
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        ms-1
                      "
                      >You</a
                    >
                  </div>

                  <div class="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/theme/media/avatars/300-1.jpg" />
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-primary
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-end
                  "
                  data-kt-element="message-text"
                ></div>
              </div>
            </div>

            <div
              class="d-flex justify-content-start mb-10 d-none"
              data-kt-element="template-in"
            >
              <div class="d-flex flex-column align-items-start">
                <div class="d-flex align-items-center mb-2">
                  <div class="symbol symbol-35px symbol-circle">
                    <img
                      alt="Pic"
                      src="assets/theme/media/avatars/300-25.jpg"
                    />
                  </div>

                  <div class="ms-3">
                    <a
                      href="#"
                      class="
                        fs-5
                        fw-bolder
                        text-gray-900 text-hover-primary
                        me-1
                      "
                      >Brian Cox</a
                    >
                    <span class="text-muted fs-7 mb-1">Just now</span>
                  </div>
                </div>

                <div
                  class="
                    p-5
                    rounded
                    bg-light-info
                    text-dark
                    fw-bold
                    mw-lg-400px
                    text-start
                  "
                  data-kt-element="message-text"
                >
                  Right before vacation season we have the next Big Deal for
                  you.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer pt-4" id="kt_drawer_chat_messenger_footer">
          <textarea
            class="form-control form-control-flush mb-3"
            rows="1"
            data-kt-element="input"
            placeholder="Type a message"
          ></textarea>

          <!--begin:Toolbar-->
          <div class="d-flex flex-stack">
            <div class="d-flex align-items-center me-2">
              <button
                class="btn btn-sm btn-icon btn-active-light-primary me-1"
                type="button"
                data-bs-toggle="tooltip"
                title="Coming soon"
              >
                <i class="bi bi-paperclip fs-3"></i>
              </button>
              <button
                class="btn btn-sm btn-icon btn-active-light-primary me-1"
                type="button"
                data-bs-toggle="tooltip"
                title="Coming soon"
              >
                <i class="bi bi-upload fs-3"></i>
              </button>
            </div>

            <button
              class="btn btn-primary"
              type="button"
              data-kt-element="send"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      id="kt_engage_demos"
      class="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="explore"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_engage_demos_toggle"
      data-kt-drawer-close="#kt_engage_demos_close"
    >
      <div class="card shadow-none rounded-0 w-100">
        <div class="card-header" id="kt_engage_demos_header">
          <h3 class="card-title fw-bolder text-gray-700">Explore</h3>
          <div class="card-toolbar">
            <button
              type="button"
              class="
                btn btn-sm btn-icon btn-active-color-primary
                h-40px
                w-40px
                me-n6
              "
              id="kt_engage_demos_close"
            >
              <span class="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>

        <div class="card-body" id="kt_engage_demos_body">
          <div
            id="kt_explore_scroll"
            class="scroll-y me-n5 pe-5"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_engage_demos_body"
            data-kt-scroll-dependencies="#kt_engage_demos_header"
            data-kt-scroll-offset="5px"
          >
            <div class="mb-0">
              <div class="mb-7">
                <div class="d-flex flex-stack">
                  <h3 class="mb-0">Metronic Licenses</h3>
                  <a
                    href="https://themeforest.net/licenses/standard"
                    class="fw-bold"
                    target="_blank"
                    >License FAQs</a
                  >
                </div>
              </div>

              <div
                class="
                  rounded
                  border border-dashed border-gray-300
                  py-4
                  px-6
                  mb-5
                "
              >
                <div class="d-flex flex-stack">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-1">
                      <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
                        Regular License
                      </div>
                      <i
                        class="
                          text-gray-400
                          fas
                          fa-exclamation-circle
                          ms-1
                          fs-7
                        "
                        data-bs-toggle="popover"
                        data-bs-custom-class="popover-dark"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        data-bs-content="Use, by you or one client in a single end product which end users are not charged for"
                      ></i>
                    </div>
                    <div class="fs-7 text-muted">
                      For single end product used by you or one client
                    </div>
                  </div>
                  <div class="text-nowrap">
                    <span class="text-muted fs-7 fw-bold me-n1">$</span>
                    <span class="text-dark fs-1 fw-bolder">39</span>
                  </div>
                </div>
              </div>

              <div
                class="
                  rounded
                  border border-dashed border-gray-300
                  py-4
                  px-6
                  mb-5
                "
              >
                <div class="d-flex flex-stack">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-1">
                      <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
                        Extended License
                      </div>
                      <i
                        class="
                          text-gray-400
                          fas
                          fa-exclamation-circle
                          ms-1
                          fs-7
                        "
                        data-bs-toggle="popover"
                        data-bs-custom-class="popover-dark"
                        data-bs-trigger="hover"
                        data-bs-placement="top"
                        data-bs-content="Use, by you or one client, in a single end product which end users can be charged for."
                      ></i>
                    </div>
                    <div class="fs-7 text-muted">
                      For single SaaS app with paying users
                    </div>
                  </div>
                  <div class="text-nowrap">
                    <span class="text-muted fs-7 fw-bold me-n1">$</span>
                    <span class="text-dark fs-1 fw-bolder">969</span>
                  </div>
                </div>
              </div>

              <div
                class="
                  rounded
                  border border-dashed border-gray-300
                  py-4
                  px-6
                  mb-5
                "
              >
                <div class="d-flex flex-stack">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-1">
                      <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
                        Custom License
                      </div>
                    </div>
                    <div class="fs-7 text-muted">
                      Reach us for custom license offers.
                    </div>
                  </div>
                  <div class="text-nowrap">
                    <a
                      href="https://keenthemes.com/contact/"
                      class="btn btn-sm btn-success"
                      target="_blank"
                      >Contact Us</a
                    >
                  </div>
                </div>
              </div>

              <a
                href="https://1.envato.market/EA4JP"
                class="btn btn-primary mb-15 w-100"
                >Buy Now</a
              > 
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="kt_help"
      class="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="help"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_help_toggle"
      data-kt-drawer-close="#kt_help_close"
    >
      <div class="card shadow-none rounded-0 w-100">
        <div class="card-header" id="kt_help_header">
          <h5 class="card-title fw-bold text-gray-600">
            Learn &amp; Get Inspired
          </h5>
          <div class="card-toolbar">
            <button
              type="button"
              class="btn btn-sm btn-icon explore-btn-dismiss me-n5"
              id="kt_help_close"
            >
              <span class="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>

        <div class="card-body" id="kt_help_body">
          <div
            id="kt_help_scroll"
            class="hover-scroll-overlay-y"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_help_body"
            data-kt-scroll-dependencies="#kt_help_header"
            data-kt-scroll-offset="5px"
          >
            <div
              class="
                rounded
                border border-dashed border-gray-300
                p-6 p-lg-8
                mb-10
              "
            >
              <h2 class="fw-bolder mb-5">
                Support at
                <a href="https://devs.keenthemes.com" class=""
                  >devs.keenthemes.com</a
                >
              </h2>

              <div class="fs-5 fw-bold mb-5">
                <span class="text-gray-500"
                  >Join our developers community to find answer to your question
                  and help others.</span
                >
                <a
                  class="explore-link d-none"
                  href="https://keenthemes.com/licensing"
                  >FAQs</a
                >
              </div>

              <a
                href="https://devs.keenthemes.com"
                class="btn btn-lg explore-btn-primary w-100"
                >Get Support</a
              >
            </div>

            <div class="d-flex align-items-center mb-7">
              <div
                class="
                  d-flex
                  flex-center
                  w-50px
                  h-50px
                  w-lg-75px
                  h-lg-75px
                  flex-shrink-0
                  rounded
                  bg-light-warning
                "
              >
                <span
                  class="svg-icon svg-icon-warning svg-icon-2x svg-icon-lg-3x"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>

              <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                <div class="d-flex flex-column me-2 me-lg-5">
                  <a
                    href="../../demo16/dist/documentation/getting-started.html"
                    class="
                      text-dark text-hover-primary
                      fw-bolder
                      fs-6 fs-lg-4
                      mb-1
                    "
                    >Documentation &amp; Videos</a
                  >

                  <div class="text-muted fw-bold fs-7 fs-lg-6">
                    From guides and video tutorials, to live demos and code
                    examples to get started.
                  </div>
                </div>

                <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="18"
                      y="13"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-180 18 13)"
                      fill="currentColor"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div class="d-flex align-items-center mb-7">
              <div
                class="
                  d-flex
                  flex-center
                  w-50px
                  h-50px
                  w-lg-75px
                  h-lg-75px
                  flex-shrink-0
                  rounded
                  bg-light-primary
                "
              >
                <span
                  class="svg-icon svg-icon-primary svg-icon-2x svg-icon-lg-3x"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z"
                      fill="currentColor"
                    />
                    <path
                      d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>

              <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                <div class="d-flex flex-column me-2 me-lg-5">
                  <a
                    href="../../demo16/dist/documentation/base/utilities.html"
                    class="
                      text-dark text-hover-primary
                      fw-bolder
                      fs-6 fs-lg-4
                      mb-1
                    "
                    >Plugins &amp; Components</a
                  >

                  <div class="text-muted fw-bold fs-7 fs-lg-6">
                    Check out our 300+ in-house components and customized
                    3rd-party plugins.
                  </div>
                </div>

                <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="18"
                      y="13"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-180 18 13)"
                      fill="currentColor"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div class="d-flex align-items-center mb-7">
              <div
                class="
                  d-flex
                  flex-center
                  w-50px
                  h-50px
                  w-lg-75px
                  h-lg-75px
                  flex-shrink-0
                  rounded
                  bg-light-info
                "
              >
                <span class="svg-icon svg-icon-info svg-icon-2x svg-icon-lg-3x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
                      fill="currentColor"
                    />
                    <path
                      d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>

              <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                <div class="d-flex flex-column me-2 me-lg-5">
                  <a
                    href="https://preview.keenthemes.com/metronic8/demo16/layout-builder.html"
                    class="
                      text-dark text-hover-primary
                      fw-bolder
                      fs-6 fs-lg-4
                      mb-1
                    "
                    >Layout Builder</a
                  >

                  <div class="text-muted fw-bold fs-7 fs-lg-6">
                    Build your layout, preview it and export the HTML for server
                    side integration.
                  </div>
                </div>

                <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="18"
                      y="13"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-180 18 13)"
                      fill="currentColor"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div class="d-flex align-items-center mb-7">
              <div
                class="
                  d-flex
                  flex-center
                  w-50px
                  h-50px
                  w-lg-75px
                  h-lg-75px
                  flex-shrink-0
                  rounded
                  bg-light-success
                "
              >
                <span
                  class="svg-icon svg-icon-success svg-icon-2x svg-icon-lg-3x"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z"
                      fill="currentColor"
                    />
                    <path
                      d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>

              <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                <div class="d-flex flex-column me-2 me-lg-5">
                  <a
                    href="https://devs.keenthemes.com/metronic"
                    class="
                      text-dark text-hover-primary
                      fw-bolder
                      fs-6 fs-lg-4
                      mb-1
                    "
                    >Metronic Downloads</a
                  >

                  <div class="text-muted fw-bold fs-7 fs-lg-6">
                    Download your prefered framework and demo with one click.
                  </div>
                </div>

                <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="18"
                      y="13"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-180 18 13)"
                      fill="currentColor"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div class="d-flex align-items-center mb-7">
              <div
                class="
                  d-flex
                  flex-center
                  w-50px
                  h-50px
                  w-lg-75px
                  h-lg-75px
                  flex-shrink-0
                  rounded
                  bg-light-danger
                "
              >
                <span
                  class="svg-icon svg-icon-danger svg-icon-2x svg-icon-lg-3x"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13 9V8C13 7.4 13.4 7 14 7H15C16.7 7 18 5.7 18 4V3C18 2.4 17.6 2 17 2C16.4 2 16 2.4 16 3V4C16 4.6 15.6 5 15 5H14C12.3 5 11 6.3 11 8V9H13Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M21 22H3C2.4 22 2 21.6 2 21V10C2 9.4 2.4 9 3 9H21C21.6 9 22 9.4 22 10V21C22 21.6 21.6 22 21 22ZM5 12C4.4 12 4 12.4 4 13C4 13.6 4.4 14 5 14C5.6 14 6 13.6 6 13C6 12.4 5.6 12 5 12ZM8 12C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14C8.6 14 9 13.6 9 13C9 12.4 8.6 12 8 12ZM11 12C10.4 12 10 12.4 10 13C10 13.6 10.4 14 11 14C11.6 14 12 13.6 12 13C12 12.4 11.6 12 11 12ZM14 12C13.4 12 13 12.4 13 13C13 13.6 13.4 14 14 14C14.6 14 15 13.6 15 13C15 12.4 14.6 12 14 12ZM9 15C8.4 15 8 15.4 8 16C8 16.6 8.4 17 9 17C9.6 17 10 16.6 10 16C10 15.4 9.6 15 9 15ZM12 15C11.4 15 11 15.4 11 16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16C13 15.4 12.6 15 12 15ZM15 15C14.4 15 14 15.4 14 16C14 16.6 14.4 17 15 17C15.6 17 16 16.6 16 16C16 15.4 15.6 15 15 15ZM19 18C18.4 18 18 18.4 18 19C18 19.6 18.4 20 19 20C19.6 20 20 19.6 20 19C20 18.4 19.6 18 19 18ZM7 19C7 18.4 6.6 18 6 18H5C4.4 18 4 18.4 4 19C4 19.6 4.4 20 5 20H6C6.6 20 7 19.6 7 19ZM7 16C7 15.4 6.6 15 6 15H5C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17H6C6.6 17 7 16.6 7 16ZM17 14H19C19.6 14 20 13.6 20 13C20 12.4 19.6 12 19 12H17C16.4 12 16 12.4 16 13C16 13.6 16.4 14 17 14ZM18 17H19C19.6 17 20 16.6 20 16C20 15.4 19.6 15 19 15H18C17.4 15 17 15.4 17 16C17 16.6 17.4 17 18 17ZM17 19C17 18.4 16.6 18 16 18H9C8.4 18 8 18.4 8 19C8 19.6 8.4 20 9 20H16C16.6 20 17 19.6 17 19Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>

              <div class="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                <div class="d-flex flex-column me-2 me-lg-5">
                  <a
                    href="../../demo16/dist/documentation/getting-started/changelog.html"
                    class="
                      text-dark text-hover-primary
                      fw-bolder
                      fs-6 fs-lg-4
                      mb-1
                    "
                    >What's New</a
                  >

                  <div class="text-muted fw-bold fs-7 fs-lg-6">
                    Latest features and improvements added with our users
                    feedback in mind.
                  </div>
                </div>

                <span class="svg-icon svg-icon-gray-400 svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="18"
                      y="13"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-180 18 13)"
                      fill="currentColor"
                    />
                    <path
                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        engage-toolbar
        d-flex
        position-fixed
        px-5
        fw-bolder
        zindex-2
        top-50
        end-0
        transform-90
        mt-20
        gap-2
      "
    >
      <button
        id="kt_engage_demos_toggle"
        class="
          engage-demos-toggle
          btn btn-flex
          h-35px
          bg-body
          btn-color-gray-700 btn-active-color-gray-900
          shadow-sm
          fs-6
          px-4
          rounded-top-0
        "
        title="Check out 22 more demos"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-dismiss="click"
        data-bs-trigger="hover"
      >
        <span id="kt_engage_demos_label">Feature</span>
      </button>

      <button
        id="kt_help_toggle"
        class="
          engage-help-toggle
          btn btn-flex
          h-35px
          bg-body
          btn-color-gray-700 btn-active-color-gray-900
          shadow-sm
          px-5
          rounded-top-0
        "
        title="Learn &amp; Get Inspired"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-dismiss="click"
        data-bs-trigger="hover"
      >
        Help
      </button>

    
    </div>

    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <span class="svg-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect
            opacity="0.5"
            x="13"
            y="6"
            width="13"
            height="2"
            rx="1"
            transform="rotate(90 13 6)"
            fill="currentColor"
          />
          <path
            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </div>

    <div
      class="modal fade"
      id="kt_modal_create_project"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header">
            <h2>Create Project</h2>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div class="modal-body scroll-y m-5">
            <div
              class="stepper stepper-links d-flex flex-column"
              id="kt_modal_create_project_stepper"
            >
              <div class="container">
                <div class="stepper-nav justify-content-center py-2">
                  <div
                    class="stepper-item me-5 me-md-15 current"
                    data-kt-stepper-element="nav"
                  >
                    <h3 class="stepper-title">Project Type</h3>
                  </div>

                  <div
                    class="stepper-item me-5 me-md-15"
                    data-kt-stepper-element="nav"
                  >
                    <h3 class="stepper-title">Project Settings</h3>
                  </div>

                  <div
                    class="stepper-item me-5 me-md-15"
                    data-kt-stepper-element="nav"
                  >
                    <h3 class="stepper-title">Budget</h3>
                  </div>

                  <div
                    class="stepper-item me-5 me-md-15"
                    data-kt-stepper-element="nav"
                  >
                    <h3 class="stepper-title">Build A Team</h3>
                  </div>

                  <div
                    class="stepper-item me-5 me-md-15"
                    data-kt-stepper-element="nav"
                  >
                    <h3 class="stepper-title">Set First Target</h3>
                  </div>

                  <div
                    class="stepper-item me-5 me-md-15"
                    data-kt-stepper-element="nav"
                  >
                    <h3 class="stepper-title">Upload Files</h3>
                  </div>

                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <h3 class="stepper-title">Completed</h3>
                  </div>
                </div>

                <form
                  class="mx-auto w-100 mw-600px pt-15 pb-10"
                  novalidate="novalidate"
                  id="kt_modal_create_project_form"
                  method="post"
                >
                  <div class="current" data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-7 pb-lg-12">
                        <h1 class="fw-bolder text-dark">Project Type</h1>

                        <div class="text-muted fw-bold fs-4">
                          If you need more info, please check out
                          <a href="#" class="link-primary fw-bolder"
                            >FAQ Page</a
                          >
                        </div>
                      </div>

                      <div class="fv-row mb-15" data-kt-buttons="true">
                        <label
                          class="
                            btn
                            btn-outline
                            btn-outline-dashed
                            btn-outline-default
                            d-flex
                            text-start
                            p-6
                            mb-6
                            active
                          "
                        >
                          <input
                            class="btn-check"
                            type="radio"
                            checked="checked"
                            name="project_type"
                            value="1"
                          />

                          <span class="d-flex">
                            <span class="svg-icon svg-icon-3hx">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>

                            <span class="ms-4">
                              <span
                                class="
                                  fs-3
                                  fw-bolder
                                  text-gray-900
                                  mb-2
                                  d-block
                                "
                                >Personal Project</span
                              >
                              <span class="fw-bold fs-4 text-muted"
                                >If you need more info, please check it
                                out</span
                              >
                            </span>
                          </span>
                        </label>

                        <label
                          class="
                            btn
                            btn-outline
                            btn-outline-dashed
                            btn-outline-default
                            d-flex
                            text-start
                            p-6
                          "
                        >
                          <input
                            class="btn-check"
                            type="radio"
                            name="project_type"
                            value="2"
                          />

                          <span class="d-flex">
                            <span class="svg-icon svg-icon-3hx">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>

                            <span class="ms-4">
                              <span
                                class="
                                  fs-3
                                  fw-bolder
                                  text-gray-900
                                  mb-2
                                  d-block
                                "
                                >Team Project</span
                              >
                              <span class="fw-bold fs-4 text-muted"
                                >Create corporate account to manage users</span
                              >
                            </span>
                          </span>
                        </label>
                      </div>

                      <div class="d-flex justify-content-end">
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          data-kt-element="type-next"
                        >
                          <span class="indicator-label">Project Settings</span>
                          <span class="indicator-progress"
                            >Please wait...
                            <span
                              class="
                                spinner-border spinner-border-sm
                                align-middle
                                ms-2
                              "
                            ></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-12">
                        <h1 class="fw-bolder text-dark">Project Settings</h1>

                        <div class="text-muted fw-bold fs-4">
                          If you need more info, please check
                          <a href="#" class="link-primary"
                            >Project Guidelines</a
                          >
                        </div>
                      </div>

                      <div class="fv-row mb-8">
                        <div
                          class="dropzone"
                          id="kt_modal_create_project_settings_logo"
                        >
                          <div class="dz-message needsclick">
                            <span
                              class="svg-icon svg-icon-3hx svg-icon-primary"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>

                            <div class="ms-4">
                              <h3 class="dfs-3 fw-bolder text-gray-900 mb-1">
                                Drop files here or click to upload.
                              </h3>
                              <span class="fw-bold fs-4 text-muted"
                                >Upload up to 10 files</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="fv-row mb-8">
                        <label class="required fs-6 fw-bold mb-2"
                          >Customer</label
                        >

                        <select
                          class="form-select form-select-solid"
                          data-control="select2"
                          data-hide-search="true"
                          data-placeholder="Select..."
                          name="settings_customer"
                        >
                          <option></option>
                          <option value="1">Keenthemes</option>
                          <option value="2">CRM App</option>
                        </select>
                      </div>

                      <div class="fv-row mb-8">
                        <label
                          class="
                            d-flex
                            align-items-center
                            fs-6
                            fw-bold
                            form-label
                            mb-2
                          "
                        >
                          <span class="required">Project Name</span>
                          <i
                            class="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Specify project name"
                          ></i>
                        </label>

                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder="Enter Project Name"
                          value="StockPro Mobile App"
                          name="settings_name"
                        />
                      </div>

                      <div class="fv-row mb-8">
                        <label class="required fs-6 fw-bold mb-2"
                          >Project Description</label
                        >

                        <textarea
                          class="form-control form-control-solid"
                          rows="3"
                          placeholder="Enter Project Description"
                          name="settings_description"
                        >
Experience share market at your fingertips with TICK PRO stock investment mobile trading app</textarea
                        >
                      </div>

                      <div class="fv-row mb-8">
                        <label class="required fs-6 fw-bold mb-2"
                          >Release Date</label
                        >

                        <div
                          class="position-relative d-flex align-items-center"
                        >
                          <span
                            class="svg-icon svg-icon-2 position-absolute mx-4"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                                fill="currentColor"
                              />
                              <path
                                d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                                fill="currentColor"
                              />
                              <path
                                d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>

                          <input
                            class="form-control form-control-solid ps-12"
                            placeholder="Pick date range"
                            name="settings_release_date"
                          />
                        </div>
                      </div>

                      <div class="fv-row mb-15">
                        <div class="d-flex flex-stack">
                          <div class="me-5">
                            <label class="required fs-6 fw-bold"
                              >Notifications</label
                            >
                            <div class="fs-7 fw-bold text-muted">
                              Allow Notifications by Phone or Email
                            </div>
                          </div>

                          <div class="d-flex">
                            <label
                              class="
                                form-check form-check-custom form-check-solid
                                me-10
                              "
                            >
                              <input
                                class="form-check-input h-20px w-20px"
                                type="checkbox"
                                value="email"
                                name="settings_notifications[]"
                              />

                              <span class="form-check-label fw-bold"
                                >Email</span
                              >
                            </label>

                            <label
                              class="
                                form-check form-check-custom form-check-solid
                              "
                            >
                              <input
                                class="form-check-input h-20px w-20px"
                                type="checkbox"
                                value="phone"
                                checked="checked"
                                name="settings_notifications[]"
                              />

                              <span class="form-check-label fw-bold"
                                >Phone</span
                              >
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex flex-stack">
                        <button
                          type="button"
                          class="btn btn-lg btn-light me-3"
                          data-kt-element="settings-previous"
                        >
                          Project Type
                        </button>
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          data-kt-element="settings-next"
                        >
                          <span class="indicator-label">Budget</span>
                          <span class="indicator-progress"
                            >Please wait...
                            <span
                              class="
                                spinner-border spinner-border-sm
                                align-middle
                                ms-2
                              "
                            ></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-10 pb-lg-12">
                        <h1 class="fw-bolder text-dark">Budget</h1>

                        <div class="text-muted fw-bold fs-4">
                          If you need more info, please check
                          <a href="#" class="link-primary"
                            >Project Guidelines</a
                          >
                        </div>
                      </div>

                      <div class="fv-row mb-8">
                        <label
                          class="d-flex align-items-center fs-6 fw-bold mb-2"
                        >
                          <span class="required">Setup Budget</span>
                          <i
                            class="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="&lt;div class='p-4 rounded bg-light'&gt; &lt;div class='d-flex flex-stack text-muted mb-4'&gt; &lt;i class='fas fa-university fs-3 me-3'&gt;&lt;/i&gt; &lt;div class='fw-bold'&gt;INCBANK **** 1245 STATEMENT&lt;/div&gt; &lt;/div&gt; &lt;div class='d-flex flex-stack fw-bold text-gray-600'&gt; &lt;div&gt;Amount&lt;/div&gt; &lt;div&gt;Transaction&lt;/div&gt; &lt;/div&gt; &lt;div class='separator separator-dashed my-2'&gt;&lt;/div&gt; &lt;div class='d-flex flex-stack text-dark fw-bolder mb-2'&gt; &lt;div&gt;USD345.00&lt;/div&gt; &lt;div&gt;KEENTHEMES*&lt;/div&gt; &lt;/div&gt; &lt;div class='d-flex flex-stack text-muted mb-2'&gt; &lt;div&gt;USD75.00&lt;/div&gt; &lt;div&gt;Hosting fee&lt;/div&gt; &lt;/div&gt; &lt;div class='d-flex flex-stack text-muted'&gt; &lt;div&gt;USD3,950.00&lt;/div&gt; &lt;div&gt;Payrol&lt;/div&gt; &lt;/div&gt; &lt;/div&gt;"
                          ></i>
                        </label>

                        <div
                          class="position-relative w-lg-250px"
                          id="kt_modal_create_project_budget_setup"
                          data-kt-dialer="true"
                          data-kt-dialer-min="50"
                          data-kt-dialer-max="50000"
                          data-kt-dialer-step="100"
                          data-kt-dialer-prefix="$"
                          data-kt-dialer-decimals="2"
                        >
                          <button
                            type="button"
                            class="
                              btn btn-icon btn-active-color-gray-700
                              position-absolute
                              translate-middle-y
                              top-50
                              start-0
                            "
                            data-kt-dialer-control="decrease"
                          >
                            <span class="svg-icon svg-icon-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x="2"
                                  y="2"
                                  width="20"
                                  height="20"
                                  rx="10"
                                  fill="currentColor"
                                />
                                <rect
                                  x="6.01041"
                                  y="10.9247"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>

                          <input
                            type="text"
                            class="
                              form-control form-control-solid
                              border-0
                              ps-12
                            "
                            data-kt-dialer-control="input"
                            placeholder="Amount"
                            name="budget_setup"
                            readonly="readonly"
                            value="$50"
                          />

                          <button
                            type="button"
                            class="
                              btn btn-icon btn-active-color-gray-700
                              position-absolute
                              translate-middle-y
                              top-50
                              end-0
                            "
                            data-kt-dialer-control="increase"
                          >
                            <span class="svg-icon svg-icon-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x="2"
                                  y="2"
                                  width="20"
                                  height="20"
                                  rx="10"
                                  fill="currentColor"
                                />
                                <rect
                                  x="10.8891"
                                  y="17.8033"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-90 10.8891 17.8033)"
                                  fill="currentColor"
                                />
                                <rect
                                  x="6.01041"
                                  y="10.9247"
                                  width="12"
                                  height="2"
                                  rx="1"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>

                      <div class="fv-row mb-8">
                        <label class="fs-6 fw-bold mb-2">Budget Usage</label>

                        <div
                          class="row g-9"
                          data-kt-buttons="true"
                          data-kt-buttons-target="[data-kt-button='true']"
                        >
                          <div class="col-md-6 col-lg-12 col-xxl-6">
                            <label
                              class="
                                btn
                                btn-outline
                                btn-outline-dashed
                                btn-outline-default
                                active
                                d-flex
                                text-start
                                p-6
                              "
                              data-kt-button="true"
                            >
                              <span
                                class="
                                  form-check
                                  form-check-custom
                                  form-check-solid
                                  form-check-sm
                                  align-items-start
                                  mt-1
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="budget_usage"
                                  value="1"
                                  checked="checked"
                                />
                              </span>

                              <span class="ms-5">
                                <span
                                  class="
                                    fs-4
                                    fw-bolder
                                    text-gray-800
                                    mb-2
                                    d-block
                                  "
                                  >Precise Usage</span
                                >
                                <span class="fw-bold fs-7 text-gray-600"
                                  >Withdraw money to your bank account per
                                  transaction under $50,000 budget</span
                                >
                              </span>
                            </label>
                          </div>

                          <div class="col-md-6 col-lg-12 col-xxl-6">
                            <label
                              class="
                                btn
                                btn-outline
                                btn-outline-dashed
                                btn-outline-default
                                d-flex
                                text-start
                                p-6
                              "
                              data-kt-button="true"
                            >
                              <span
                                class="
                                  form-check
                                  form-check-custom
                                  form-check-solid
                                  form-check-sm
                                  align-items-start
                                  mt-1
                                "
                              >
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="budget_usage"
                                  value="2"
                                />
                              </span>

                              <span class="ms-5">
                                <span
                                  class="
                                    fs-4
                                    fw-bolder
                                    text-gray-800
                                    mb-2
                                    d-block
                                  "
                                  >Extreme Usage</span
                                >
                                <span class="fw-bold fs-7 text-gray-600"
                                  >Withdraw money to your bank account per
                                  transaction under $50,000 budget</span
                                >
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="fv-row mb-15">
                        <div class="d-flex flex-stack">
                          <div class="me-5">
                            <label class="fs-6 fw-bold"
                              >Allow Changes in Budget</label
                            >
                            <div class="fs-7 fw-bold text-muted">
                              If you need more info, please check budget
                              planning
                            </div>
                          </div>

                          <label
                            class="
                              form-check
                              form-switch
                              form-check-custom
                              form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                              name="budget_allow"
                              checked="checked"
                            />
                            <span class="form-check-label fw-bold text-muted"
                              >Allowed</span
                            >
                          </label>
                        </div>
                      </div>

                      <div class="d-flex flex-stack">
                        <button
                          type="button"
                          class="btn btn-lg btn-light me-3"
                          data-kt-element="budget-previous"
                        >
                          Project Settings
                        </button>
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          data-kt-element="budget-next"
                        >
                          <span class="indicator-label">Build Team</span>
                          <span class="indicator-progress"
                            >Please wait...
                            <span
                              class="
                                spinner-border spinner-border-sm
                                align-middle
                                ms-2
                              "
                            ></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-12">
                        <h1 class="fw-bolder text-dark">Build a Team</h1>

                        <div class="text-muted fw-bold fs-4">
                          If you need more info, please check
                          <a href="#" class="link-primary"
                            >Project Guidelines</a
                          >
                        </div>
                      </div>

                      <div class="mb-8">
                        <label class="fs-6 fw-bold mb-2"
                          >Invite Teammates</label
                        >

                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder="Add project memnbers by name or email.."
                          name="invite_teammates"
                        />
                      </div>

                      <div class="mb-8">
                        <div class="fs-6 fw-bold mb-2">Team Members</div>

                        <div class="mh-300px scroll-y me-n7 pe-7">
                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-6.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Emma Smith</a
                                >
                                <div class="fw-bold text-muted">
                                  smith@kpmg.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2" selected="selected">
                                  Owner
                                </option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <span
                                  class="
                                    symbol-label
                                    bg-light-danger
                                    text-danger
                                    fw-bold
                                  "
                                  >M</span
                                >
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Melody Macy</a
                                >
                                <div class="fw-bold text-muted">
                                  melody@altbox.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1" selected="selected">
                                  Guest
                                </option>
                                <option value="2">Owner</option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-1.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Max Smith</a
                                >
                                <div class="fw-bold text-muted">max@kt.com</div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2">Owner</option>
                                <option value="3" selected="selected">
                                  Can Edit
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-5.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Sean Bean</a
                                >
                                <div class="fw-bold text-muted">
                                  sean@dellito.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2" selected="selected">
                                  Owner
                                </option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-25.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Brian Cox</a
                                >
                                <div class="fw-bold text-muted">
                                  brian@exchange.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2">Owner</option>
                                <option value="3" selected="selected">
                                  Can Edit
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <span
                                  class="
                                    symbol-label
                                    bg-light-warning
                                    text-warning
                                    fw-bold
                                  "
                                  >C</span
                                >
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Mikaela Collins</a
                                >
                                <div class="fw-bold text-muted">
                                  mik@pex.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2" selected="selected">
                                  Owner
                                </option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-9.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Francis Mitcham</a
                                >
                                <div class="fw-bold text-muted">
                                  f.mit@kpmg.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2">Owner</option>
                                <option value="3" selected="selected">
                                  Can Edit
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <span
                                  class="
                                    symbol-label
                                    bg-light-danger
                                    text-danger
                                    fw-bold
                                  "
                                  >O</span
                                >
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Olivia Wild</a
                                >
                                <div class="fw-bold text-muted">
                                  olivia@corpmail.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2" selected="selected">
                                  Owner
                                </option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <span
                                  class="
                                    symbol-label
                                    bg-light-primary
                                    text-primary
                                    fw-bold
                                  "
                                  >N</span
                                >
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Neil Owen</a
                                >
                                <div class="fw-bold text-muted">
                                  owen.neil@gmail.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1" selected="selected">
                                  Guest
                                </option>
                                <option value="2">Owner</option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-23.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Dan Wilson</a
                                >
                                <div class="fw-bold text-muted">
                                  dam@consilting.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2">Owner</option>
                                <option value="3" selected="selected">
                                  Can Edit
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <span
                                  class="
                                    symbol-label
                                    bg-light-danger
                                    text-danger
                                    fw-bold
                                  "
                                  >E</span
                                >
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Emma Bold</a
                                >
                                <div class="fw-bold text-muted">
                                  emma@intenso.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2" selected="selected">
                                  Owner
                                </option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-12.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Ana Crown</a
                                >
                                <div class="fw-bold text-muted">
                                  ana.cf@limtel.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1" selected="selected">
                                  Guest
                                </option>
                                <option value="2">Owner</option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <span
                                  class="
                                    symbol-label
                                    bg-light-info
                                    text-info
                                    fw-bold
                                  "
                                  >A</span
                                >
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Robert Doe</a
                                >
                                <div class="fw-bold text-muted">
                                  robert@benko.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2">Owner</option>
                                <option value="3" selected="selected">
                                  Can Edit
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-13.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >John Miller</a
                                >
                                <div class="fw-bold text-muted">
                                  miller@mapple.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2">Owner</option>
                                <option value="3" selected="selected">
                                  Can Edit
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <span
                                  class="
                                    symbol-label
                                    bg-light-success
                                    text-success
                                    fw-bold
                                  "
                                  >L</span
                                >
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Lucy Kunic</a
                                >
                                <div class="fw-bold text-muted">
                                  lucy.m@fentech.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2" selected="selected">
                                  Owner
                                </option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border-bottom border-gray-300 border-bottom-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src="assets/theme/media/avatars/300-21.jpg"
                                />
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Ethan Wilder</a
                                >
                                <div class="fw-bold text-muted">
                                  ethan@loop.com.au
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1" selected="selected">
                                  Guest
                                </option>
                                <option value="2">Owner</option>
                                <option value="3">Can Edit</option>
                              </select>
                            </div>
                          </div>

                          <div class="d-flex flex-stack py-4">
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px symbol-circle">
                                <span
                                  class="
                                    symbol-label
                                    bg-light-primary
                                    text-primary
                                    fw-bold
                                  "
                                  >N</span
                                >
                              </div>

                              <div class="ms-5">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Neil Owen</a
                                >
                                <div class="fw-bold text-muted">
                                  owen.neil@gmail.com
                                </div>
                              </div>
                            </div>

                            <div class="ms-2 w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                              >
                                <option value="1">Guest</option>
                                <option value="2">Owner</option>
                                <option value="3" selected="selected">
                                  Can Edit
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex flex-stack mb-15">
                        <div class="me-5 fw-bold">
                          <label class="fs-6"
                            >Adding Users by Team Members</label
                          >
                          <div class="fs-7 text-muted">
                            If you need more info, please check budget planning
                          </div>
                        </div>

                        <label
                          class="
                            form-check
                            form-switch
                            form-check-custom
                            form-check-solid
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            checked="checked"
                          />
                        </label>
                      </div>

                      <div class="d-flex flex-stack">
                        <button
                          type="button"
                          class="btn btn-lg btn-light me-3"
                          data-kt-element="team-previous"
                        >
                          Budget
                        </button>
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          data-kt-element="team-next"
                        >
                          <span class="indicator-label">Set Target</span>
                          <span class="indicator-progress"
                            >Please wait...
                            <span
                              class="
                                spinner-border spinner-border-sm
                                align-middle
                                ms-2
                              "
                            ></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-12">
                        <h1 class="fw-bolder text-dark">Set First Target</h1>

                        <div class="text-muted fw-bold fs-4">
                          If you need more info, please check
                          <a href="#" class="link-primary"
                            >Project Guidelines</a
                          >
                        </div>
                      </div>

                      <div class="fv-row mb-8">
                        <label class="fs-6 fw-bold mb-2">Target Title</label>
                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder="Enter Target Title"
                          name="Project Launch"
                        />
                      </div>

                      <div class="row g-9 mb-8">
                        <div class="col-md-6 fv-row">
                          <label class="required fs-6 fw-bold mb-2"
                            >Assign</label
                          >
                          <select
                            class="form-select form-select-solid"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="Select a Team Member"
                            name="target_assign"
                          >
                            <option></option>
                            <option value="1">Karina Clark</option>
                            <option value="2" selected="selected">
                              Robert Doe
                            </option>
                            <option value="3">Niel Owen</option>
                            <option value="4">Olivia Wild</option>
                            <option value="5">Sean Bean</option>
                          </select>
                        </div>

                        <div class="col-md-6 fv-row">
                          <label class="required fs-6 fw-bold mb-2"
                            >Due Date</label
                          >
                          <div
                            class="position-relative d-flex align-items-center"
                          >
                            <span
                              class="svg-icon svg-icon-2 position-absolute mx-4"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>

                            <input
                              class="form-control form-control-solid ps-12"
                              placeholder="Pick date range"
                              name="target_due_date"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="fv-row mb-8">
                        <label class="fs-6 fw-bold mb-2">Target Details</label>
                        <textarea
                          class="form-control form-control-solid"
                          rows="2"
                          name="target_details"
                          placeholder="Type Target Details"
                        >
Experience share market at your fingertips with TICK PRO stock investment mobile trading app</textarea
                        >
                      </div>

                      <div class="fv-row mb-8">
                        <label class="required fs-6 fw-bold mb-2">Tags</label>
                        <input
                          class="form-control form-control-solid"
                          value="Important, Urgent"
                          name="target_tags"
                        />
                      </div>

                      <div class="fv-row mb-8">
                        <div class="d-flex flex-stack">
                          <div class="me-5">
                            <label class="fs-6 fw-bold"
                              >Allow Changes in Budget</label
                            >
                            <div class="fs-7 fw-bold text-muted">
                              If you need more info, please check budget
                              planning
                            </div>
                          </div>

                          <label
                            class="
                              form-check
                              form-switch
                              form-check-custom
                              form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                              name="target_allow"
                              checked="checked"
                            />
                            <span class="form-check-label fw-bold text-muted"
                              >Allowed</span
                            >
                          </label>
                        </div>
                      </div>

                      <div class="fv-row mb-15">
                        <div class="d-flex flex-stack">
                          <div class="me-5">
                            <label class="fs-6 fw-bold">Notifications</label>
                            <div class="fs-7 fw-bold text-muted">
                              Allow Notifications by Phone or Email
                            </div>
                          </div>

                          <div class="d-flex">
                            <label
                              class="
                                form-check form-check-custom form-check-solid
                                me-10
                              "
                            >
                              <input
                                class="form-check-input h-20px w-20px"
                                type="checkbox"
                                value="email"
                                name="target_notifications[]"
                              />

                              <span class="form-check-label fw-bold"
                                >Email</span
                              >
                            </label>

                            <label
                              class="
                                form-check form-check-custom form-check-solid
                              "
                            >
                              <input
                                class="form-check-input h-20px w-20px"
                                type="checkbox"
                                value="phone"
                                checked="checked"
                                name="target_notifications[]"
                              />

                              <span class="form-check-label fw-bold"
                                >Phone</span
                              >
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex flex-stack">
                        <button
                          type="button"
                          class="btn btn-lg btn-light me-3"
                          data-kt-element="targets-previous"
                        >
                          Build a Team
                        </button>
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          data-kt-element="targets-next"
                        >
                          <span class="indicator-label">Upload Files</span>
                          <span class="indicator-progress"
                            >Please wait...
                            <span
                              class="
                                spinner-border spinner-border-sm
                                align-middle
                                ms-2
                              "
                            ></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-10 pb-lg-12">
                        <h1 class="fw-bolder text-dark">Upload Files</h1>

                        <div class="text-muted fw-bold fs-4">
                          If you need more info, please check
                          <a href="#" class="link-primary"
                            >Project Guidelines</a
                          >
                        </div>
                      </div>

                      <div class="fv-row mb-8">
                        <div
                          class="dropzone"
                          id="kt_modal_create_project_files_upload"
                        >
                          <div class="dz-message needsclick">
                            <span
                              class="svg-icon svg-icon-3hx svg-icon-primary"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>

                            <div class="ms-4">
                              <h3 class="dfs-3 fw-bolder text-gray-900 mb-1">
                                Drop files here or click to upload.
                              </h3>
                              <span class="fw-bold fs-4 text-muted"
                                >Upload up to 10 files</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mb-8">
                        <label class="fs-6 fw-bold mb-2">Uploaded File</label>

                        <div class="mh-300px scroll-y me-n7 pe-7">
                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border
                              border-top-0
                              border-left-0
                              border-right-0
                              border-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px">
                                <img
                                  src="assets/theme/media/svg/files/pdf.svg"
                                  alt="icon"
                                />
                              </div>

                              <div class="ms-6">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Avionica Technical Requirements</a
                                >
                                <div class="fw-bold text-muted">230kb</div>
                              </div>
                            </div>

                            <div class="min-w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Edit"
                              >
                                <option></option>
                                <option value="1">Remove</option>
                                <option value="2">Modify</option>
                                <option value="3">Select</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border
                              border-top-0
                              border-left-0
                              border-right-0
                              border-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px">
                                <img
                                  src="assets/theme/media/svg/files/doc.svg"
                                  alt="icon"
                                />
                              </div>

                              <div class="ms-6">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >9 Degree CURD draftplan</a
                                >
                                <div class="fw-bold text-muted">3.6mb</div>
                              </div>
                            </div>

                            <div class="min-w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Edit"
                              >
                                <option></option>
                                <option value="1">Remove</option>
                                <option value="2">Modify</option>
                                <option value="3">Select</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border
                              border-top-0
                              border-left-0
                              border-right-0
                              border-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px">
                                <img
                                  src="assets/theme/media/svg/files/css.svg"
                                  alt="icon"
                                />
                              </div>

                              <div class="ms-6">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >User CRUD Styles</a
                                >
                                <div class="fw-bold text-muted">85kb</div>
                              </div>
                            </div>

                            <div class="min-w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Edit"
                              >
                                <option></option>
                                <option value="1">Remove</option>
                                <option value="2">Modify</option>
                                <option value="3">Select</option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="
                              d-flex
                              flex-stack
                              py-4
                              border
                              border-top-0
                              border-left-0
                              border-right-0
                              border-dashed
                            "
                          >
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px">
                                <img
                                  src="assets/theme/media/svg/files/ai.svg"
                                  alt="icon"
                                />
                              </div>

                              <div class="ms-6">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Design Initial Logo</a
                                >
                                <div class="fw-bold text-muted">40kb</div>
                              </div>
                            </div>

                            <div class="min-w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Edit"
                              >
                                <option></option>
                                <option value="1">Remove</option>
                                <option value="2">Modify</option>
                                <option value="3">Select</option>
                              </select>
                            </div>
                          </div>

                          <div class="d-flex flex-stack py-4">
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-35px">
                                <img
                                  src="assets/theme/media/svg/files/tif.svg"
                                  alt="icon"
                                />
                              </div>

                              <div class="ms-6">
                                <a
                                  href="#"
                                  class="
                                    fs-5
                                    fw-bolder
                                    text-gray-900 text-hover-primary
                                    mb-2
                                  "
                                  >Tower Hill Bilboard</a
                                >
                                <div class="fw-bold text-muted">27mb</div>
                              </div>
                            </div>

                            <div class="min-w-100px">
                              <select
                                class="
                                  form-select form-select-solid form-select-sm
                                "
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Edit"
                              >
                                <option></option>
                                <option value="1">Remove</option>
                                <option value="2">Modify</option>
                                <option value="3">Select</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="fv-row mb-8">
                        <div class="d-flex flex-stack">
                          <div class="me-5">
                            <label class="fs-6 fw-bold"
                              >Allow Changes in Budget</label
                            >
                            <div class="fs-7 fw-bold text-muted">
                              If you need more info, please check budget
                              planning
                            </div>
                          </div>

                          <label
                            class="
                              form-check
                              form-switch
                              form-check-custom
                              form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="1"
                              name="target_allow"
                              checked="checked"
                            />
                            <span class="form-check-label fw-bold text-muted"
                              >Allowed</span
                            >
                          </label>
                        </div>
                      </div>

                      <div class="d-flex flex-stack">
                        <button
                          type="button"
                          class="btn btn-lg btn-light me-3"
                          data-kt-element="files-previous"
                        >
                          Set First Target
                        </button>
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          data-kt-element="files-next"
                        >
                          <span class="indicator-label">Complete</span>
                          <span class="indicator-progress"
                            >Please wait...
                            <span
                              class="
                                spinner-border spinner-border-sm
                                align-middle
                                ms-2
                              "
                            ></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="pb-12 text-center">
                        <h1 class="fw-bolder text-dark">Project Created!</h1>

                        <div class="text-muted fw-bold fs-4">
                          If you need more info, please check how to create
                          project
                        </div>
                      </div>

                      <div class="d-flex flex-center pb-20">
                        <button
                          type="button"
                          class="btn btn-lg btn-light me-3"
                          data-kt-element="complete-start"
                        >
                          Create New Project
                        </button>
                        <a
                          href=""
                          class="btn btn-lg btn-primary"
                          data-bs-toggle="tooltip"
                          title="Coming Soon"
                          >View Project</a
                        >
                      </div>

                      <div class="text-center px-4">
                        <img
                          src="assets/theme/media/illustrations/sketchy-1/9.png"
                          alt=""
                          class="mww-100 mh-350px"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="kt_modal_upgrade_plan"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content rounded">
          <div class="modal-header justify-content-end border-0 pb-0">
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div class="modal-body pt-0 pb-15 px-5 px-xl-20">
            <div class="mb-13 text-center">
              <h1 class="mb-3">Upgrade a Plan</h1>
              <div class="text-muted fw-bold fs-5">
                If you need more info, please check
                <a href="#" class="link-primary fw-bolder">Pricing Guidelines</a
                >.
              </div>
            </div>

            <div class="d-flex flex-column">
              <div
                class="nav-group nav-group-outline mx-auto"
                data-kt-buttons="true"
              >
                <button
                  class="
                    btn btn-color-gray-400 btn-active btn-active-secondary
                    px-6
                    py-3
                    me-2
                    active
                  "
                  data-kt-plan="month"
                >
                  Monthly
                </button>
                <button
                  class="
                    btn btn-color-gray-400 btn-active btn-active-secondary
                    px-6
                    py-3
                  "
                  data-kt-plan="annual"
                >
                  Annual
                </button>
              </div>

              <div class="row mt-10">
                <div class="col-lg-6 mb-10 mb-lg-0">
                  <div class="nav flex-column">
                    <div
                      class="
                        nav-link
                        btn
                        btn-outline
                        btn-outline-dashed
                        btn-color-dark
                        btn-active
                        btn-active-primary
                        d-flex
                        flex-stack
                        text-start
                        p-6
                        active
                        mb-6
                      "
                      data-bs-toggle="tab"
                      data-bs-target="#kt_upgrade_plan_startup"
                    >
                      <div class="d-flex align-items-center me-2">
                        <div
                          class="
                            form-check
                            form-check-custom
                            form-check-solid
                            form-check-success
                            me-6
                          "
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="plan"
                            checked="checked"
                            value="startup"
                          />
                        </div>

                        <div class="flex-grow-1">
                          <h2
                            class="
                              d-flex
                              align-items-center
                              fs-2
                              fw-bolder
                              flex-wrap
                            "
                          >
                            Startup
                          </h2>
                          <div class="fw-bold opacity-50">
                            Best for startups
                          </div>
                        </div>
                      </div>

                      <div class="ms-5">
                        <span class="mb-2">$</span>
                        <span
                          class="fs-3x fw-bolder"
                          data-kt-plan-price-month="39"
                          data-kt-plan-price-annual="399"
                          >39</span
                        >
                        <span class="fs-7 opacity-50"
                          >/ <span data-kt-element="period">Mon</span></span
                        >
                      </div>
                    </div>

                    <div
                      class="
                        nav-link
                        btn
                        btn-outline
                        btn-outline-dashed
                        btn-color-dark
                        btn-active
                        btn-active-primary
                        d-flex
                        flex-stack
                        text-start
                        p-6
                        mb-6
                      "
                      data-bs-toggle="tab"
                      data-bs-target="#kt_upgrade_plan_advanced"
                    >
                      <div class="d-flex align-items-center me-2">
                        <div
                          class="
                            form-check
                            form-check-custom
                            form-check-solid
                            form-check-success
                            me-6
                          "
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="plan"
                            value="advanced"
                          />
                        </div>

                        <div class="flex-grow-1">
                          <h2
                            class="
                              d-flex
                              align-items-center
                              fs-2
                              fw-bolder
                              flex-wrap
                            "
                          >
                            Advanced
                          </h2>
                          <div class="fw-bold opacity-50">
                            Best for 100+ team size
                          </div>
                        </div>
                      </div>

                      <div class="ms-5">
                        <span class="mb-2">$</span>
                        <span
                          class="fs-3x fw-bolder"
                          data-kt-plan-price-month="339"
                          data-kt-plan-price-annual="3399"
                          >339</span
                        >
                        <span class="fs-7 opacity-50"
                          >/ <span data-kt-element="period">Mon</span></span
                        >
                      </div>
                    </div>

                    <div
                      class="
                        nav-link
                        btn
                        btn-outline
                        btn-outline-dashed
                        btn-color-dark
                        btn-active
                        btn-active-primary
                        d-flex
                        flex-stack
                        text-start
                        p-6
                        mb-6
                      "
                      data-bs-toggle="tab"
                      data-bs-target="#kt_upgrade_plan_enterprise"
                    >
                      <div class="d-flex align-items-center me-2">
                        <div
                          class="
                            form-check
                            form-check-custom
                            form-check-solid
                            form-check-success
                            me-6
                          "
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="plan"
                            value="enterprise"
                          />
                        </div>

                        <div class="flex-grow-1">
                          <h2
                            class="
                              d-flex
                              align-items-center
                              fs-2
                              fw-bolder
                              flex-wrap
                            "
                          >
                            Enterprise
                            <span class="badge badge-light-success ms-2 fs-7"
                              >Most popular</span
                            >
                          </h2>
                          <div class="fw-bold opacity-50">
                            Best value for 1000+ team
                          </div>
                        </div>
                      </div>

                      <div class="ms-5">
                        <span class="mb-2">$</span>
                        <span
                          class="fs-3x fw-bolder"
                          data-kt-plan-price-month="999"
                          data-kt-plan-price-annual="9999"
                          >999</span
                        >
                        <span class="fs-7 opacity-50"
                          >/ <span data-kt-element="period">Mon</span></span
                        >
                      </div>
                    </div>

                    <div
                      class="
                        nav-link
                        btn btn-outline btn-outline-dashed btn-color-dark
                        d-flex
                        flex-stack
                        text-start
                        p-6
                      "
                    >
                      <div class="d-flex align-items-center me-2">
                        <div
                          class="
                            form-check
                            form-check-custom
                            form-check-solid
                            form-check-success
                            me-6
                          "
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="plan"
                            value="custom"
                          />
                        </div>

                        <div class="flex-grow-1">
                          <h2
                            class="
                              d-flex
                              align-items-center
                              fs-2
                              fw-bolder
                              flex-wrap
                            "
                          >
                            Custom
                          </h2>
                          <div class="fw-bold opacity-50">
                            Requet a custom license
                          </div>
                        </div>
                      </div>

                      <div class="ms-5">
                        <a href="#" class="btn btn-sm btn-primary"
                          >Contact Us</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="tab-content rounded h-100 bg-light p-10">
                    <div
                      class="tab-pane fade show active"
                      id="kt_upgrade_plan_startup"
                    >
                      <div class="pb-5">
                        <h2 class="fw-bolder text-dark">
                          What’s in Startup Plan?
                        </h2>
                        <div class="text-muted fw-bold">
                          Optimal for 10+ team size and new startup
                        </div>
                      </div>

                      <div class="pt-1">
                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Up to 10 Active Users</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Up to 30 Project Integrations</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Analytics Module</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-muted flex-grow-1"
                            >Finance Module</span
                          >

                          <span class="svg-icon svg-icon-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-muted flex-grow-1"
                            >Accounting Module</span
                          >

                          <span class="svg-icon svg-icon-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-muted flex-grow-1"
                            >Network Platform</span
                          >

                          <span class="svg-icon svg-icon-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center">
                          <span class="fw-bold fs-5 text-muted flex-grow-1"
                            >Unlimited Cloud Space</span
                          >

                          <span class="svg-icon svg-icon-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="kt_upgrade_plan_advanced">
                      <div class="pb-5">
                        <h2 class="fw-bolder text-dark">
                          What’s in Startup Plan?
                        </h2>
                        <div class="text-muted fw-bold">
                          Optimal for 100+ team size and grown company
                        </div>
                      </div>

                      <div class="pt-1">
                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Up to 10 Active Users</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Up to 30 Project Integrations</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Analytics Module</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Finance Module</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Accounting Module</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-muted flex-grow-1"
                            >Network Platform</span
                          >

                          <span class="svg-icon svg-icon-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center">
                          <span class="fw-bold fs-5 text-muted flex-grow-1"
                            >Unlimited Cloud Space</span
                          >

                          <span class="svg-icon svg-icon-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <rect
                                x="7"
                                y="15.3137"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7 15.3137)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.41422"
                                y="7"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.41422 7)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="kt_upgrade_plan_enterprise">
                      <div class="pb-5">
                        <h2 class="fw-bolder text-dark">
                          What’s in Startup Plan?
                        </h2>
                        <div class="text-muted fw-bold">
                          Optimal for 1000+ team and enterpise
                        </div>
                      </div>

                      <div class="pt-1">
                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Up to 10 Active Users</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Up to 30 Project Integrations</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Analytics Module</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Finance Module</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Accounting Module</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center mb-7">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Network Platform</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="d-flex align-items-center">
                          <span class="fw-bold fs-5 text-gray-700 flex-grow-1"
                            >Unlimited Cloud Space</span
                          >

                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                              />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-center flex-row-fluid pt-12">
              <button
                type="reset"
                class="btn btn-light me-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">
                Upgrade Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="kt_modal_create_campaign"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen p-9">
        <div class="modal-content modal-rounded">
          <div class="modal-header py-7 d-flex justify-content-between">
            <h2>Create Campaign</h2>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div class="modal-body scroll-y m-5">
            <div
              class="stepper stepper-links d-flex flex-column"
              id="kt_modal_create_campaign_stepper"
            >
              <div class="stepper-nav justify-content-center py-2">
                <div
                  class="stepper-item me-5 me-md-15 current"
                  data-kt-stepper-element="nav"
                >
                  <h3 class="stepper-title">Campaign Details</h3>
                </div>

                <div
                  class="stepper-item me-5 me-md-15"
                  data-kt-stepper-element="nav"
                >
                  <h3 class="stepper-title">Creative Uploads</h3>
                </div>

                <div
                  class="stepper-item me-5 me-md-15"
                  data-kt-stepper-element="nav"
                >
                  <h3 class="stepper-title">Audiences</h3>
                </div>

                <div
                  class="stepper-item me-5 me-md-15"
                  data-kt-stepper-element="nav"
                >
                  <h3 class="stepper-title">Budget Estimates</h3>
                </div>

                <div class="stepper-item" data-kt-stepper-element="nav">
                  <h3 class="stepper-title">Completed</h3>
                </div>
              </div>

              <form
                class="mx-auto w-100 mw-600px pt-15 pb-10"
                novalidate="novalidate"
                id="kt_modal_create_campaign_stepper_form"
              >
                <div class="current" data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="pb-10 pb-lg-15">
                      <h2 class="fw-bolder d-flex align-items-center text-dark">
                        Setup Campaign Details
                        <i
                          class="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title="Campaign name will be used as reference within your campaign reports"
                        ></i>
                      </h2>

                      <div class="text-muted fw-bold fs-6">
                        If you need more info, please check out
                        <a href="#" class="link-primary fw-bolder">Help Page</a
                        >.
                      </div>
                    </div>

                    <div class="mb-10 fv-row">
                      <label class="required form-label mb-3"
                        >Campaign Name</label
                      >

                      <input
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        name="campaign_name"
                        placeholder=""
                        value=""
                      />
                    </div>

                    <div class="fv-row mb-10">
                      <label class="d-block fw-bold fs-6 mb-5">
                        <span class="required">Company Logo</span>
                        <i
                          class="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title="E.g. Select a logo to represent the company that's running the campaign."
                        ></i>
                      </label>

                      <div
                        class="
                          image-input image-input-empty image-input-outline
                        "
                        data-kt-image-input="true"
                        style="
                          background-image: url(assets/theme/media/svg/files/blank-image.svg);
                        "
                      >
                        <div class="image-input-wrapper w-125px h-125px"></div>

                        <label
                          class="
                            btn btn-icon btn-circle btn-active-color-primary
                            w-25px
                            h-25px
                            bg-body
                            shadow
                          "
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          title="Change avatar"
                        >
                          <i class="bi bi-pencil-fill fs-7"></i>

                          <input
                            type="file"
                            name="avatar"
                            accept=".png, .jpg, .jpeg"
                          />
                          <input type="hidden" name="avatar_remove" />
                        </label>

                        <span
                          class="
                            btn btn-icon btn-circle btn-active-color-primary
                            w-25px
                            h-25px
                            bg-body
                            shadow
                          "
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          title="Cancel avatar"
                        >
                          <i class="bi bi-x fs-2"></i>
                        </span>

                        <span
                          class="
                            btn btn-icon btn-circle btn-active-color-primary
                            w-25px
                            h-25px
                            bg-body
                            shadow
                          "
                          data-kt-image-input-action="remove"
                          data-bs-toggle="tooltip"
                          title="Remove avatar"
                        >
                          <i class="bi bi-x fs-2"></i>
                        </span>
                      </div>

                      <div class="form-text">
                        Allowed file types: png, jpg, jpeg.
                      </div>
                    </div>

                    <div class="mb-10">
                      <label class="required fw-bold fs-6 mb-5"
                        >Campaign Goal</label
                      >

                      <div class="d-flex fv-row">
                        <div
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            value="0"
                            id="kt_modal_update_role_option_0"
                            checked="checked"
                          />

                          <label
                            class="form-check-label"
                            for="kt_modal_update_role_option_0"
                          >
                            <div class="fw-bolder text-gray-800">
                              Get more visitors
                            </div>
                            <div class="text-gray-600">
                              Increase impression traffic onto the platform
                            </div>
                          </label>
                        </div>
                      </div>

                      <div class="separator separator-dashed my-5"></div>

                      <div class="d-flex fv-row">
                        <div
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            value="1"
                            id="kt_modal_update_role_option_1"
                          />

                          <label
                            class="form-check-label"
                            for="kt_modal_update_role_option_1"
                          >
                            <div class="fw-bolder text-gray-800">
                              Get more messages on chat
                            </div>
                            <div class="text-gray-600">
                              Increase community interaction and communication
                            </div>
                          </label>
                        </div>
                      </div>

                      <div class="separator separator-dashed my-5"></div>

                      <div class="d-flex fv-row">
                        <div
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            value="2"
                            id="kt_modal_update_role_option_2"
                          />

                          <label
                            class="form-check-label"
                            for="kt_modal_update_role_option_2"
                          >
                            <div class="fw-bolder text-gray-800">
                              Get more calls
                            </div>
                            <div class="text-gray-600">
                              Boost telecommunication feedback to provide
                              precise and accurate information
                            </div>
                          </label>
                        </div>
                      </div>

                      <div class="separator separator-dashed my-5"></div>

                      <div class="d-flex fv-row">
                        <div
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            value="3"
                            id="kt_modal_update_role_option_3"
                          />

                          <label
                            class="form-check-label"
                            for="kt_modal_update_role_option_3"
                          >
                            <div class="fw-bolder text-gray-800">
                              Get more likes
                            </div>
                            <div class="text-gray-600">
                              Increase positive interactivity on social media
                              platforms
                            </div>
                          </label>
                        </div>
                      </div>

                      <div class="separator separator-dashed my-5"></div>

                      <div class="d-flex fv-row">
                        <div
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            value="4"
                            id="kt_modal_update_role_option_4"
                          />

                          <label
                            class="form-check-label"
                            for="kt_modal_update_role_option_4"
                          >
                            <div class="fw-bolder text-gray-800">
                              Lead generation
                            </div>
                            <div class="text-gray-600">
                              Collect contact information for potential
                              customers
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="pb-10 pb-lg-12">
                      <h1 class="fw-bolder text-dark">Upload Files</h1>

                      <div class="text-muted fw-bold fs-4">
                        If you need more info, please check
                        <a href="#" class="link-primary">Campaign Guidelines</a>
                      </div>
                    </div>

                    <div class="fv-row mb-10">
                      <div
                        class="dropzone"
                        id="kt_modal_create_campaign_files_upload"
                      >
                        <div class="dz-message needsclick">
                          <span class="svg-icon svg-icon-3hx svg-icon-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
                                fill="currentColor"
                              />
                              <path
                                d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
                                fill="currentColor"
                              />
                              <path
                                d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
                                fill="currentColor"
                              />
                              <path
                                d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>

                          <div class="ms-4">
                            <h3 class="dfs-3 fw-bolder text-gray-900 mb-1">
                              Drop campaign files here or click to upload.
                            </h3>
                            <span class="fw-bold fs-4 text-muted"
                              >Upload up to 10 files</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-10">
                      <label class="fs-6 fw-bold mb-2">Uploaded File</label>

                      <div class="mh-300px scroll-y me-n7 pe-7">
                        <div
                          class="
                            d-flex
                            flex-stack
                            py-4
                            border
                            border-top-0
                            border-left-0
                            border-right-0
                            border-dashed
                          "
                        >
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-35px">
                              <img
                                src="assets/theme/media/svg/files/pdf.svg"
                                alt="icon"
                              />
                            </div>

                            <div class="ms-6">
                              <a
                                href="#"
                                class="
                                  fs-5
                                  fw-bolder
                                  text-gray-900 text-hover-primary
                                  mb-2
                                "
                                >Product Specifications</a
                              >
                              <div class="fw-bold text-muted">230kb</div>
                            </div>
                          </div>

                          <div class="min-w-100px">
                            <select
                              class="
                                form-select form-select-solid form-select-sm
                              "
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option></option>
                              <option value="1">Remove</option>
                              <option value="2">Modify</option>
                              <option value="3">Select</option>
                            </select>
                          </div>
                        </div>

                        <div
                          class="
                            d-flex
                            flex-stack
                            py-4
                            border
                            border-top-0
                            border-left-0
                            border-right-0
                            border-dashed
                          "
                        >
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-35px">
                              <img
                                src="assets/theme/media/svg/files/tif.svg"
                                alt="icon"
                              />
                            </div>

                            <div class="ms-6">
                              <a
                                href="#"
                                class="
                                  fs-5
                                  fw-bolder
                                  text-gray-900 text-hover-primary
                                  mb-2
                                "
                                >Campaign Creative Poster</a
                              >
                              <div class="fw-bold text-muted">2.4mb</div>
                            </div>
                          </div>

                          <div class="min-w-100px">
                            <select
                              class="
                                form-select form-select-solid form-select-sm
                              "
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option></option>
                              <option value="1">Remove</option>
                              <option value="2">Modify</option>
                              <option value="3">Select</option>
                            </select>
                          </div>
                        </div>

                        <div
                          class="
                            d-flex
                            flex-stack
                            py-4
                            border
                            border-top-0
                            border-left-0
                            border-right-0
                            border-dashed
                          "
                        >
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-35px">
                              <img
                                src="assets/theme/media/svg/files/folder-document.svg"
                                alt="icon"
                              />
                            </div>

                            <div class="ms-6">
                              <a
                                href="#"
                                class="
                                  fs-5
                                  fw-bolder
                                  text-gray-900 text-hover-primary
                                  mb-2
                                "
                                >Campaign Landing Page Source</a
                              >
                              <div class="fw-bold text-muted">1.12mb</div>
                            </div>
                          </div>

                          <div class="min-w-100px">
                            <select
                              class="
                                form-select form-select-solid form-select-sm
                              "
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option></option>
                              <option value="1">Remove</option>
                              <option value="2">Modify</option>
                              <option value="3">Select</option>
                            </select>
                          </div>
                        </div>

                        <div
                          class="
                            d-flex
                            flex-stack
                            py-4
                            border
                            border-top-0
                            border-left-0
                            border-right-0
                            border-dashed
                          "
                        >
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-35px">
                              <img
                                src="assets/theme/media/svg/files/css.svg"
                                alt="icon"
                              />
                            </div>

                            <div class="ms-6">
                              <a
                                href="#"
                                class="
                                  fs-5
                                  fw-bolder
                                  text-gray-900 text-hover-primary
                                  mb-2
                                "
                                >Landing Page Styling</a
                              >
                              <div class="fw-bold text-muted">85kb</div>
                            </div>
                          </div>

                          <div class="min-w-100px">
                            <select
                              class="
                                form-select form-select-solid form-select-sm
                              "
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option></option>
                              <option value="1">Remove</option>
                              <option value="2">Modify</option>
                              <option value="3">Select</option>
                            </select>
                          </div>
                        </div>

                        <div
                          class="
                            d-flex
                            flex-stack
                            py-4
                            border
                            border-top-0
                            border-left-0
                            border-right-0
                            border-dashed
                          "
                        >
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-35px">
                              <img
                                src="assets/theme/media/svg/files/ai.svg"
                                alt="icon"
                              />
                            </div>

                            <div class="ms-6">
                              <a
                                href="#"
                                class="
                                  fs-5
                                  fw-bolder
                                  text-gray-900 text-hover-primary
                                  mb-2
                                "
                                >Design Source Files</a
                              >
                              <div class="fw-bold text-muted">48mb</div>
                            </div>
                          </div>

                          <div class="min-w-100px">
                            <select
                              class="
                                form-select form-select-solid form-select-sm
                              "
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option></option>
                              <option value="1">Remove</option>
                              <option value="2">Modify</option>
                              <option value="3">Select</option>
                            </select>
                          </div>
                        </div>

                        <div class="d-flex flex-stack py-4">
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-35px">
                              <img
                                src="assets/theme/media/svg/files/doc.svg"
                                alt="icon"
                              />
                            </div>

                            <div class="ms-6">
                              <a
                                href="#"
                                class="
                                  fs-5
                                  fw-bolder
                                  text-gray-900 text-hover-primary
                                  mb-2
                                "
                                >Campaign Plan Document</a
                              >
                              <div class="fw-bold text-muted">27kb</div>
                            </div>
                          </div>

                          <div class="min-w-100px">
                            <select
                              class="
                                form-select form-select-solid form-select-sm
                              "
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option></option>
                              <option value="1">Remove</option>
                              <option value="2">Modify</option>
                              <option value="3">Select</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="pb-10 pb-lg-12">
                      <h1 class="fw-bolder text-dark">Configure Audiences</h1>

                      <div class="text-muted fw-bold fs-4">
                        If you need more info, please check
                        <a href="#" class="link-primary">Campaign Guidelines</a>
                      </div>
                    </div>

                    <div class="fv-row mb-10">
                      <label class="fs-6 fw-bold mb-2"
                        >Gender
                        <i
                          class="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title="Show your ads to either men or women, or select 'All' for both"
                        ></i
                      ></label>

                      <div
                        class="row g-9"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button='true']"
                      >
                        <div class="col">
                          <label
                            class="
                              btn
                              btn-outline
                              btn-outline-dashed
                              btn-outline-default
                              active
                              d-flex
                              text-start
                              p-6
                            "
                            data-kt-button="true"
                          >
                            <span
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-sm
                                align-items-start
                                mt-1
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="campaign_gender"
                                value="1"
                                checked="checked"
                              />
                            </span>

                            <span class="ms-5">
                              <span class="fs-4 fw-bolder text-gray-800 d-block"
                                >All</span
                              >
                            </span>
                          </label>
                        </div>

                        <div class="col">
                          <label
                            class="
                              btn
                              btn-outline
                              btn-outline-dashed
                              btn-outline-default
                              d-flex
                              text-start
                              p-6
                            "
                            data-kt-button="true"
                          >
                            <span
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-sm
                                align-items-start
                                mt-1
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="campaign_gender"
                                value="2"
                              />
                            </span>

                            <span class="ms-5">
                              <span class="fs-4 fw-bolder text-gray-800 d-block"
                                >Male</span
                              >
                            </span>
                          </label>
                        </div>

                        <div class="col">
                          <label
                            class="
                              btn
                              btn-outline
                              btn-outline-dashed
                              btn-outline-default
                              d-flex
                              text-start
                              p-6
                            "
                            data-kt-button="true"
                          >
                            <span
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-sm
                                align-items-start
                                mt-1
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="campaign_gender"
                                value="3"
                              />
                            </span>

                            <span class="ms-5">
                              <span class="fs-4 fw-bolder text-gray-800 d-block"
                                >Female</span
                              >
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="fv-row mb-10">
                      <label class="fs-6 fw-bold mb-2"
                        >Age
                        <i
                          class="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title="Select the minimum and maximum age of the people who will find your ad relevant."
                        ></i
                      ></label>

                      <div class="d-flex flex-stack">
                        <div
                          id="kt_modal_create_campaign_age_min"
                          class="fs-7 fw-bold text-muted"
                        ></div>
                        <div
                          id="kt_modal_create_campaign_age_slider"
                          class="noUi-sm w-100 ms-5 me-8"
                        ></div>
                        <div
                          id="kt_modal_create_campaign_age_max"
                          class="fs-7 fw-bold text-muted"
                        ></div>
                      </div>
                    </div>

                    <div class="fv-row mb-10">
                      <label class="fs-6 fw-bold mb-2"
                        >Location
                        <i
                          class="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title="Enter one or more location points for more specific targeting."
                        ></i
                      ></label>

                      <input
                        class="form-control d-flex align-items-center"
                        value=""
                        id="kt_modal_create_campaign_location"
                      />
                    </div>
                  </div>
                </div>

                <div data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="pb-10 pb-lg-12">
                      <h1 class="fw-bolder text-dark">Budget Estimates</h1>

                      <div class="text-muted fw-bold fs-4">
                        If you need more info, please check
                        <a href="#" class="link-primary">Campaign Guidelines</a>
                      </div>
                    </div>

                    <div class="fv-row mb-10">
                      <label class="fs-6 fw-bold mb-2"
                        >Campaign Duration
                        <i
                          class="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title="Choose how long you want your ad to run for"
                        ></i
                      ></label>

                      <div class="d-flex gap-9 mb-7">
                        <button
                          type="button"
                          class="
                            btn
                            btn-outline
                            btn-outline-dashed
                            btn-outline-default
                            active
                          "
                          id="kt_modal_create_campaign_duration_all"
                        >
                          Continuous duration
                          <br />
                          <span class="fs-7"
                            >Your ad will run continuously for a daily
                            budget.</span
                          >
                        </button>

                        <button
                          type="button"
                          class="
                            btn
                            btn-outline
                            btn-outline-dashed
                            btn-outline-default
                          "
                          id="kt_modal_create_campaign_duration_fixed"
                        >
                          Fixed duration
                          <br />
                          <span class="fs-7"
                            >Your ad will run on the specified dates only.</span
                          >
                        </button>
                      </div>

                      <input
                        class="form-control form-control-solid d-none"
                        placeholder="Pick date &amp; time"
                        id="kt_modal_create_campaign_datepicker"
                      />
                    </div>

                    <div class="fv-row mb-10">
                      <label class="fs-6 fw-bold mb-2"
                        >Daily Budget
                        <i
                          class="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title="Choose the budget allocated for each day. Higher budget will generate better results"
                        ></i
                      ></label>

                      <div class="d-flex flex-column text-center">
                        <div
                          class="
                            d-flex
                            align-items-start
                            justify-content-center
                            mb-7
                          "
                        >
                          <span class="fw-bolder fs-4 mt-1 me-2">$</span>
                          <span
                            class="fw-bolder fs-3x"
                            id="kt_modal_create_campaign_budget_label"
                          ></span>
                          <span class="fw-bolder fs-3x">.00</span>
                        </div>
                        <div
                          id="kt_modal_create_campaign_budget_slider"
                          class="noUi-sm"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-kt-stepper-element="content">
                  <div class="w-100">
                    <div class="pb-12 text-center">
                      <h1 class="fw-bolder text-dark">Campaign Created!</h1>

                      <div class="fw-bold text-muted fs-4">
                        You will receive an email with with the summary of your
                        newly created campaign!
                      </div>
                    </div>

                    <div class="d-flex flex-center pb-20">
                      <button
                        id="kt_modal_create_campaign_create_new"
                        type="button"
                        class="btn btn-lg btn-light me-3"
                        data-kt-element="complete-start"
                      >
                        Create New Campaign
                      </button>
                      <a
                        href=""
                        class="btn btn-lg btn-primary"
                        data-bs-toggle="tooltip"
                        title="Coming Soon"
                        >View Campaign</a
                      >
                    </div>

                    <div class="text-center px-4">
                      <img
                        src="assets/theme/media/illustrations/sketchy-1/9.png"
                        alt=""
                        class="mww-100 mh-350px"
                      />
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-stack pt-10">
                  <div class="me-2">
                    <button
                      type="button"
                      class="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <span class="svg-icon svg-icon-3 me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="6"
                            y="11"
                            width="13"
                            height="2"
                            rx="1"
                            fill="currentColor"
                          />
                          <path
                            d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>

                  <div>
                    <button
                      type="button"
                      class="btn btn-lg btn-primary"
                      data-kt-stepper-action="submit"
                    >
                      <span class="indicator-label"
                        >Submit

                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="18"
                              y="13"
                              width="13"
                              height="2"
                              rx="1"
                              transform="rotate(-180 18 13)"
                              fill="currentColor"
                            />
                            <path
                              d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="
                            spinner-border spinner-border-sm
                            align-middle
                            ms-2
                          "
                        ></span
                      ></span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-lg btn-primary"
                      data-kt-stepper-action="next"
                    >
                      Continue

                      <span class="svg-icon svg-icon-3 ms-1 me-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.5"
                            x="18"
                            y="13"
                            width="13"
                            height="2"
                            rx="1"
                            transform="rotate(-180 18 13)"
                            fill="currentColor"
                          />
                          <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="kt_modal_create_app"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered mw-900px">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Create App</h2>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div class="modal-body py-lg-10 px-lg-10">
            <div
              class="
                stepper stepper-pills stepper-column
                d-flex
                flex-column flex-xl-row flex-row-fluid
              "
              id="kt_modal_create_app_stepper"
            >
              <div
                class="
                  d-flex
                  justify-content-center justify-content-xl-start
                  flex-row-auto
                  w-100 w-xl-300px
                "
              >
                <div class="stepper-nav ps-lg-10">
                  <div
                    class="stepper-item current"
                    data-kt-stepper-element="nav"
                  >
                    <div class="stepper-line w-40px"></div>

                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">1</span>
                    </div>

                    <div class="stepper-label">
                      <h3 class="stepper-title">Details</h3>
                      <div class="stepper-desc">Name your App</div>
                    </div>
                  </div>

                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-line w-40px"></div>

                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">2</span>
                    </div>

                    <div class="stepper-label">
                      <h3 class="stepper-title">Frameworks</h3>
                      <div class="stepper-desc">Define your app framework</div>
                    </div>
                  </div>

                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-line w-40px"></div>

                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">3</span>
                    </div>

                    <div class="stepper-label">
                      <h3 class="stepper-title">Database</h3>
                      <div class="stepper-desc">
                        Select the app database type
                      </div>
                    </div>
                  </div>

                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-line w-40px"></div>

                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">4</span>
                    </div>

                    <div class="stepper-label">
                      <h3 class="stepper-title">Billing</h3>
                      <div class="stepper-desc">Provide payment details</div>
                    </div>
                  </div>

                  <div class="stepper-item" data-kt-stepper-element="nav">
                    <div class="stepper-line w-40px"></div>

                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">5</span>
                    </div>

                    <div class="stepper-label">
                      <h3 class="stepper-title">Completed</h3>
                      <div class="stepper-desc">Review and Submit</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex-row-fluid py-lg-5 px-lg-15">
                <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_modal_create_app_form"
                >
                  <div class="current" data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="fv-row mb-10">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          <span class="required">App Name</span>
                          <i
                            class="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Specify your unique app name"
                          ></i>
                        </label>

                        <input
                          type="text"
                          class="
                            form-control form-control-lg form-control-solid
                          "
                          name="name"
                          placeholder=""
                          value=""
                        />
                      </div>

                      <div class="fv-row">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-4"
                        >
                          <span class="required">Category</span>
                          <i
                            class="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Select your app category"
                          ></i>
                        </label>

                        <!--begin:Options-->
                        <div class="fv-row">
                          <!--begin:Option-->
                          <label class="d-flex flex-stack mb-5 cursor-pointer">
                            <!--begin:Label-->
                            <span class="d-flex align-items-center me-2">
                              <!--begin:Icon-->
                              <span class="symbol symbol-50px me-6">
                                <span class="symbol-label bg-light-primary">
                                  <span
                                    class="svg-icon svg-icon-1 svg-icon-primary"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </span>
                              </span>

                              <!--begin:Info-->
                              <span class="d-flex flex-column">
                                <span class="fw-bolder fs-6"
                                  >Quick Online Courses</span
                                >
                                <span class="fs-7 text-muted"
                                  >Creating a clear text structure is just one
                                  SEO</span
                                >
                              </span>
                            </span>

                            <!--begin:Input-->
                            <span
                              class="
                                form-check form-check-custom form-check-solid
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="category"
                                value="1"
                              />
                            </span>
                          </label>

                          <!--begin:Option-->
                          <label class="d-flex flex-stack mb-5 cursor-pointer">
                            <!--begin:Label-->
                            <span class="d-flex align-items-center me-2">
                              <!--begin:Icon-->
                              <span class="symbol symbol-50px me-6">
                                <span class="symbol-label bg-light-danger">
                                  <span
                                    class="svg-icon svg-icon-1 svg-icon-danger"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <rect
                                        x="2"
                                        y="2"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="13"
                                        y="2"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="13"
                                        y="13"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="2"
                                        y="13"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </span>
                              </span>

                              <!--begin:Info-->
                              <span class="d-flex flex-column">
                                <span class="fw-bolder fs-6"
                                  >Face to Face Discussions</span
                                >
                                <span class="fs-7 text-muted"
                                  >Creating a clear text structure is just one
                                  aspect</span
                                >
                              </span>
                            </span>

                            <!--begin:Input-->
                            <span
                              class="
                                form-check form-check-custom form-check-solid
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="category"
                                value="2"
                              />
                            </span>
                          </label>

                          <!--begin:Option-->
                          <label class="d-flex flex-stack cursor-pointer">
                            <!--begin:Label-->
                            <span class="d-flex align-items-center me-2">
                              <!--begin:Icon-->
                              <span class="symbol symbol-50px me-6">
                                <span class="symbol-label bg-light-success">
                                  <span
                                    class="svg-icon svg-icon-1 svg-icon-success"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </span>
                              </span>

                              <!--begin:Info-->
                              <span class="d-flex flex-column">
                                <span class="fw-bolder fs-6"
                                  >Full Intro Training</span
                                >
                                <span class="fs-7 text-muted"
                                  >Creating a clear text structure
                                  copywriting</span
                                >
                              </span>
                            </span>

                            <!--begin:Input-->
                            <span
                              class="
                                form-check form-check-custom form-check-solid
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="category"
                                value="3"
                              />
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="fv-row">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-4"
                        >
                          <span class="required">Select Framework</span>
                          <i
                            class="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Specify your apps framework"
                          ></i>
                        </label>

                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-5">
                          <!--begin:Label-->
                          <span class="d-flex align-items-center me-2">
                            <!--begin:Icon-->
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label bg-light-warning">
                                <i class="fab fa-html5 text-warning fs-2x"></i>
                              </span>
                            </span>

                            <!--begin:Info-->
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">HTML5</span>
                              <span class="fs-7 text-muted"
                                >Base Web Projec</span
                              >
                            </span>
                          </span>

                          <!--begin:Input-->
                          <span
                            class="
                              form-check form-check-custom form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              checked="checked"
                              name="framework"
                              value="1"
                            />
                          </span>
                        </label>

                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-5">
                          <!--begin:Label-->
                          <span class="d-flex align-items-center me-2">
                            <!--begin:Icon-->
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label bg-light-success">
                                <i class="fab fa-react text-success fs-2x"></i>
                              </span>
                            </span>

                            <!--begin:Info-->
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">ReactJS</span>
                              <span class="fs-7 text-muted"
                                >Robust and flexible app framework</span
                              >
                            </span>
                          </span>

                          <!--begin:Input-->
                          <span
                            class="
                              form-check form-check-custom form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="framework"
                              value="2"
                            />
                          </span>
                        </label>

                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-5">
                          <!--begin:Label-->
                          <span class="d-flex align-items-center me-2">
                            <!--begin:Icon-->
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label bg-light-danger">
                                <i class="fab fa-angular text-danger fs-2x"></i>
                              </span>
                            </span>

                            <!--begin:Info-->
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">Angular</span>
                              <span class="fs-7 text-muted"
                                >Powerful data mangement</span
                              >
                            </span>
                          </span>

                          <!--begin:Input-->
                          <span
                            class="
                              form-check form-check-custom form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="framework"
                              value="3"
                            />
                          </span>
                        </label>

                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer">
                          <!--begin:Label-->
                          <span class="d-flex align-items-center me-2">
                            <!--begin:Icon-->
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label bg-light-primary">
                                <i class="fab fa-vuejs text-primary fs-2x"></i>
                              </span>
                            </span>

                            <!--begin:Info-->
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">Vue</span>
                              <span class="fs-7 text-muted"
                                >Lightweight and responsive framework</span
                              >
                            </span>
                          </span>

                          <!--begin:Input-->
                          <span
                            class="
                              form-check form-check-custom form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="framework"
                              value="4"
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="fv-row mb-10">
                        <label class="required fs-5 fw-bold mb-2"
                          >Database Name</label
                        >

                        <input
                          type="text"
                          class="
                            form-control form-control-lg form-control-solid
                          "
                          name="dbname"
                          placeholder=""
                          value="master_db"
                        />
                      </div>

                      <div class="fv-row">
                        <label
                          class="d-flex align-items-center fs-5 fw-bold mb-4"
                        >
                          <span class="required">Select Database Engine</span>
                          <i
                            class="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Select your app database engine"
                          ></i>
                        </label>

                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-5">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label bg-light-success">
                                <i
                                  class="fas fa-database text-success fs-2x"
                                ></i>
                              </span>
                            </span>

                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">MySQL</span>
                              <span class="fs-7 text-muted"
                                >Basic MySQL database</span
                              >
                            </span>
                          </span>

                          <span
                            class="
                              form-check form-check-custom form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="dbengine"
                              checked="checked"
                              value="1"
                            />
                          </span>
                        </label>

                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer mb-5">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label bg-light-danger">
                                <i class="fab fa-google text-danger fs-2x"></i>
                              </span>
                            </span>

                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">Firebase</span>
                              <span class="fs-7 text-muted"
                                >Google based app data management</span
                              >
                            </span>
                          </span>

                          <span
                            class="
                              form-check form-check-custom form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="dbengine"
                              value="2"
                            />
                          </span>
                        </label>

                        <!--begin:Option-->
                        <label class="d-flex flex-stack cursor-pointer">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label bg-light-warning">
                                <i class="fab fa-amazon text-warning fs-2x"></i>
                              </span>
                            </span>

                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">DynamoDB</span>
                              <span class="fs-7 text-muted"
                                >Amazon Fast NoSQL Database</span
                              >
                            </span>
                          </span>

                          <span
                            class="
                              form-check form-check-custom form-check-solid
                            "
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="dbengine"
                              value="3"
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100">
                      <div class="d-flex flex-column mb-7 fv-row">
                        <label
                          class="
                            d-flex
                            align-items-center
                            fs-6
                            fw-bold
                            form-label
                            mb-2
                          "
                        >
                          <span class="required">Name On Card</span>
                          <i
                            class="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Specify a card holder's name"
                          ></i>
                        </label>

                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder=""
                          name="card_name"
                          value="Max Doe"
                        />
                      </div>

                      <div class="d-flex flex-column mb-7 fv-row">
                        <label class="required fs-6 fw-bold form-label mb-2"
                          >Card Number</label
                        >

                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control form-control-solid"
                            placeholder="Enter card number"
                            name="card_number"
                            value="4111 1111 1111 1111"
                          />

                          <div
                            class="
                              position-absolute
                              translate-middle-y
                              top-50
                              end-0
                              me-5
                            "
                          >
                            <img
                              src="assets/theme/media/svg/card-logos/visa.svg"
                              alt=""
                              class="h-25px"
                            />
                            <img
                              src="assets/theme/media/svg/card-logos/mastercard.svg"
                              alt=""
                              class="h-25px"
                            />
                            <img
                              src="assets/theme/media/svg/card-logos/american-express.svg"
                              alt=""
                              class="h-25px"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row mb-10">
                        <div class="col-md-8 fv-row">
                          <label class="required fs-6 fw-bold form-label mb-2"
                            >Expiration Date</label
                          >

                          <div class="row fv-row">
                            <div class="col-6">
                              <select
                                name="card_expiry_month"
                                class="form-select form-select-solid"
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Month"
                              >
                                <option></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                            </div>

                            <div class="col-6">
                              <select
                                name="card_expiry_year"
                                class="form-select form-select-solid"
                                data-control="select2"
                                data-hide-search="true"
                                data-placeholder="Year"
                              >
                                <option></option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4 fv-row">
                          <label
                            class="
                              d-flex
                              align-items-center
                              fs-6
                              fw-bold
                              form-label
                              mb-2
                            "
                          >
                            <span class="required">CVV</span>
                            <i
                              class="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="tooltip"
                              title="Enter a card CVV code"
                            ></i>
                          </label>

                          <div class="position-relative">
                            <input
                              type="text"
                              class="form-control form-control-solid"
                              minlength="3"
                              maxlength="4"
                              placeholder="CVV"
                              name="card_cvv"
                            />

                            <div
                              class="
                                position-absolute
                                translate-middle-y
                                top-50
                                end-0
                                me-3
                              "
                            >
                              <span class="svg-icon svg-icon-2hx">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M22 7H2V11H22V7Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    opacity="0.3"
                                    d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex flex-stack">
                        <div class="me-5">
                          <label class="fs-6 fw-bold form-label"
                            >Save Card for further billing?</label
                          >
                          <div class="fs-7 fw-bold text-muted">
                            If you need more info, please check budget planning
                          </div>
                        </div>

                        <label
                          class="
                            form-check
                            form-switch
                            form-check-custom
                            form-check-solid
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                            checked="checked"
                          />
                          <span class="form-check-label fw-bold text-muted"
                            >Save Card</span
                          >
                        </label>
                      </div>
                    </div>
                  </div>

                  <div data-kt-stepper-element="content">
                    <div class="w-100 text-center">
                      <h1 class="fw-bolder text-dark mb-3">Release!</h1>

                      <div class="text-muted fw-bold fs-3">
                        Submit your app to kickstart your project.
                      </div>

                      <div class="text-center px-4 py-15">
                        <img
                          src="assets/theme/media/illustrations/sketchy-1/9.png"
                          alt=""
                          class="mw-100 mh-300px"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-stack pt-10">
                    <div class="me-2">
                      <button
                        type="button"
                        class="btn btn-lg btn-light-primary me-3"
                        data-kt-stepper-action="previous"
                      >
                        <span class="svg-icon svg-icon-3 me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="6"
                              y="11"
                              width="13"
                              height="2"
                              rx="1"
                              fill="currentColor"
                            />
                            <path
                              d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>

                    <div>
                      <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        data-kt-stepper-action="submit"
                      >
                        <span class="indicator-label"
                          >Submit

                          <span class="svg-icon svg-icon-3 ms-2 me-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x="18"
                                y="13"
                                width="13"
                                height="2"
                                rx="1"
                                transform="rotate(-180 18 13)"
                                fill="currentColor"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </span>
                        <span class="indicator-progress"
                          >Please wait...
                          <span
                            class="
                              spinner-border spinner-border-sm
                              align-middle
                              ms-2
                            "
                          ></span
                        ></span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        data-kt-stepper-action="next"
                      >
                        Continue

                        <span class="svg-icon svg-icon-3 ms-1 me-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="18"
                              y="13"
                              width="13"
                              height="2"
                              rx="1"
                              transform="rotate(-180 18 13)"
                              fill="currentColor"
                            />
                            <path
                              d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="kt_modal_users_search"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header pb-0 border-0 justify-content-end">
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div class="text-center mb-13">
              <h1 class="mb-3">Search Users</h1>
              <div class="text-muted fw-bold fs-5">
                Invite Collaborators To Your Project
              </div>
            </div>

            <div
              id="kt_modal_users_search_handler"
              data-kt-search-keypress="true"
              data-kt-search-min-length="2"
              data-kt-search-enter="enter"
              data-kt-search-layout="inline"
            >
              <form
                data-kt-search-element="form"
                class="w-100 position-relative mb-5"
                autocomplete="off"
              >
                <input type="hidden" />

                <span
                  class="
                    svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500
                    position-absolute
                    top-50
                    ms-5
                    translate-middle-y
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>

                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid px-15"
                  name="search"
                  value=""
                  placeholder="Search by username, full name or email..."
                  data-kt-search-element="input"
                />

                <span
                  class="
                    position-absolute
                    top-50
                    end-0
                    translate-middle-y
                    lh-0
                    d-none
                    me-5
                  "
                  data-kt-search-element="spinner"
                >
                  <span
                    class="spinner-border h-15px w-15px align-middle text-muted"
                  ></span>
                </span>

                <span
                  class="
                    btn btn-flush btn-active-color-primary
                    position-absolute
                    top-50
                    end-0
                    translate-middle-y
                    lh-0
                    me-5
                    d-none
                  "
                  data-kt-search-element="clear"
                >
                  <span class="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="6"
                        y="17.3137"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      />
                      <rect
                        x="7.41422"
                        y="6"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
              </form>

              <div class="py-5">
                <div data-kt-search-element="suggestions">
                  <h3 class="fw-bold mb-5">Recently searched:</h3>

                  <div class="mh-375px scroll-y me-n7 pe-7">
                    <a
                      href="#"
                      class="
                        d-flex
                        align-items-center
                        p-3
                        rounded
                        bg-state-light bg-state-opacity-50
                        mb-1
                      "
                    >
                      <div class="symbol symbol-35px symbol-circle me-5">
                        <img
                          alt="Pic"
                          src="assets/theme/media/avatars/300-6.jpg"
                        />
                      </div>

                      <div class="fw-bold">
                        <span class="fs-6 text-gray-800 me-2">Emma Smith</span>
                        <span class="badge badge-light">Art Director</span>
                      </div>
                    </a>

                    <a
                      href="#"
                      class="
                        d-flex
                        align-items-center
                        p-3
                        rounded
                        bg-state-light bg-state-opacity-50
                        mb-1
                      "
                    >
                      <div class="symbol symbol-35px symbol-circle me-5">
                        <span
                          class="
                            symbol-label
                            bg-light-danger
                            text-danger
                            fw-bold
                          "
                          >M</span
                        >
                      </div>

                      <div class="fw-bold">
                        <span class="fs-6 text-gray-800 me-2">Melody Macy</span>
                        <span class="badge badge-light"
                          >Marketing Analytic</span
                        >
                      </div>
                    </a>

                    <a
                      href="#"
                      class="
                        d-flex
                        align-items-center
                        p-3
                        rounded
                        bg-state-light bg-state-opacity-50
                        mb-1
                      "
                    >
                      <div class="symbol symbol-35px symbol-circle me-5">
                        <img
                          alt="Pic"
                          src="assets/theme/media/avatars/300-1.jpg"
                        />
                      </div>

                      <div class="fw-bold">
                        <span class="fs-6 text-gray-800 me-2">Max Smith</span>
                        <span class="badge badge-light">Software Enginer</span>
                      </div>
                    </a>

                    <a
                      href="#"
                      class="
                        d-flex
                        align-items-center
                        p-3
                        rounded
                        bg-state-light bg-state-opacity-50
                        mb-1
                      "
                    >
                      <div class="symbol symbol-35px symbol-circle me-5">
                        <img
                          alt="Pic"
                          src="assets/theme/media/avatars/300-5.jpg"
                        />
                      </div>

                      <div class="fw-bold">
                        <span class="fs-6 text-gray-800 me-2">Sean Bean</span>
                        <span class="badge badge-light">Web Developer</span>
                      </div>
                    </a>

                    <a
                      href="#"
                      class="
                        d-flex
                        align-items-center
                        p-3
                        rounded
                        bg-state-light bg-state-opacity-50
                        mb-1
                      "
                    >
                      <div class="symbol symbol-35px symbol-circle me-5">
                        <img
                          alt="Pic"
                          src="assets/theme/media/avatars/300-25.jpg"
                        />
                      </div>

                      <div class="fw-bold">
                        <span class="fs-6 text-gray-800 me-2">Brian Cox</span>
                        <span class="badge badge-light">UI/UX Designer</span>
                      </div>
                    </a>
                  </div>
                </div>

                <div data-kt-search-element="results" class="d-none">
                  <div class="mh-375px scroll-y me-n7 pe-7">
                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="0"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='0']"
                            value="0"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-6.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Emma Smith</a
                          >
                          <div class="fw-bold text-muted">smith@kpmg.com</div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2" selected="selected">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="1"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='1']"
                            value="1"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <span
                            class="
                              symbol-label
                              bg-light-danger
                              text-danger
                              fw-bold
                            "
                            >M</span
                          >
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Melody Macy</a
                          >
                          <div class="fw-bold text-muted">
                            melody@altbox.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1" selected="selected">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="2"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='2']"
                            value="2"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-1.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Max Smith</a
                          >
                          <div class="fw-bold text-muted">max@kt.com</div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3" selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="3"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='3']"
                            value="3"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-5.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Sean Bean</a
                          >
                          <div class="fw-bold text-muted">sean@dellito.com</div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2" selected="selected">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="4"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='4']"
                            value="4"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-25.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Brian Cox</a
                          >
                          <div class="fw-bold text-muted">
                            brian@exchange.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3" selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="5"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='5']"
                            value="5"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <span
                            class="
                              symbol-label
                              bg-light-warning
                              text-warning
                              fw-bold
                            "
                            >C</span
                          >
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Mikaela Collins</a
                          >
                          <div class="fw-bold text-muted">mik@pex.com</div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2" selected="selected">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="6"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='6']"
                            value="6"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-9.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Francis Mitcham</a
                          >
                          <div class="fw-bold text-muted">f.mit@kpmg.com</div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3" selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="7"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='7']"
                            value="7"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <span
                            class="
                              symbol-label
                              bg-light-danger
                              text-danger
                              fw-bold
                            "
                            >O</span
                          >
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Olivia Wild</a
                          >
                          <div class="fw-bold text-muted">
                            olivia@corpmail.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2" selected="selected">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="8"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='8']"
                            value="8"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <span
                            class="
                              symbol-label
                              bg-light-primary
                              text-primary
                              fw-bold
                            "
                            >N</span
                          >
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Neil Owen</a
                          >
                          <div class="fw-bold text-muted">
                            owen.neil@gmail.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1" selected="selected">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="9"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='9']"
                            value="9"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-23.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Dan Wilson</a
                          >
                          <div class="fw-bold text-muted">
                            dam@consilting.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3" selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="10"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='10']"
                            value="10"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <span
                            class="
                              symbol-label
                              bg-light-danger
                              text-danger
                              fw-bold
                            "
                            >E</span
                          >
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Emma Bold</a
                          >
                          <div class="fw-bold text-muted">emma@intenso.com</div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2" selected="selected">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="11"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='11']"
                            value="11"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-12.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Ana Crown</a
                          >
                          <div class="fw-bold text-muted">
                            ana.cf@limtel.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1" selected="selected">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="12"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='12']"
                            value="12"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <span
                            class="symbol-label bg-light-info text-info fw-bold"
                            >A</span
                          >
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Robert Doe</a
                          >
                          <div class="fw-bold text-muted">robert@benko.com</div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3" selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="13"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='13']"
                            value="13"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-13.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >John Miller</a
                          >
                          <div class="fw-bold text-muted">
                            miller@mapple.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3" selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="14"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='14']"
                            value="14"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <span
                            class="
                              symbol-label
                              bg-light-success
                              text-success
                              fw-bold
                            "
                            >L</span
                          >
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Lucy Kunic</a
                          >
                          <div class="fw-bold text-muted">
                            lucy.m@fentech.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2" selected="selected">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="15"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='15']"
                            value="15"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-21.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Ethan Wilder</a
                          >
                          <div class="fw-bold text-muted">
                            ethan@loop.com.au
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1" selected="selected">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3">Can Edit</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="border-bottom border-gray-300 border-bottom-dashed"
                    ></div>

                    <div
                      class="rounded d-flex flex-stack bg-active-lighten p-4"
                      data-user-id="16"
                    >
                      <div class="d-flex align-items-center">
                        <label
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="users"
                            data-kt-check="true"
                            data-kt-check-target="[data-user-id='16']"
                            value="16"
                          />
                        </label>

                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            src="assets/theme/media/avatars/300-25.jpg"
                          />
                        </div>

                        <div class="ms-5">
                          <a
                            href="#"
                            class="
                              fs-5
                              fw-bolder
                              text-gray-900 text-hover-primary
                              mb-2
                            "
                            >Brian Cox</a
                          >
                          <div class="fw-bold text-muted">
                            brian@exchange.com
                          </div>
                        </div>
                      </div>

                      <div class="ms-2 w-100px">
                        <select
                          class="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-hide-search="true"
                        >
                          <option value="1">Guest</option>
                          <option value="2">Owner</option>
                          <option value="3" selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-center mt-15">
                    <button
                      type="reset"
                      id="kt_modal_users_search_reset"
                      data-bs-dismiss="modal"
                      class="btn btn-active-light me-3"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_users_search_submit"
                      class="btn btn-primary"
                    >
                      Add Selected Users
                    </button>
                  </div>
                </div>

                <div data-kt-search-element="empty" class="text-center d-none">
                  <div class="fw-bold py-10">
                    <div class="text-gray-600 fs-3 mb-2">No users found</div>
                    <div class="text-muted fs-6">
                      Try to search by username, full name or email...
                    </div>
                  </div>

                  <div class="text-center px-5">
                    <img
                      src="assets/theme/media/illustrations/sketchy-1/1.png"
                      alt=""
                      class="w-100 h-200px h-sm-325px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="kt_modal_invite_friends"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog mw-650px">
        <div class="modal-content">
          <div class="modal-header pb-0 border-0 justify-content-end">
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div class="text-center mb-13">
              <h1 class="mb-3">Invite a Friend</h1>

              <div class="text-muted fw-bold fs-5">
                If you need more info, please check out
                <a href="#" class="link-primary fw-bolder">FAQ Page</a>.
              </div>
            </div>

            <div class="btn btn-light-primary fw-bolder w-100 mb-8">
              <img
                alt="Logo"
                src="assets/theme/media/svg/brand-logos/google-icon.svg"
                class="h-20px me-3"
              />Invite Gmail Contacts
            </div>

            <div class="separator d-flex flex-center mb-8">
              <span class="text-uppercase bg-body fs-7 fw-bold text-muted px-3"
                >or</span
              >
            </div>

            <textarea
              class="form-control form-control-solid mb-8"
              rows="3"
              placeholder="Type or paste emails here"
            ></textarea>

            <div class="mb-10">
              <div class="fs-6 fw-bold mb-2">Your Invitations</div>

              <div class="mh-300px scroll-y me-n7 pe-7">
                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-6.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Emma Smith</a
                      >
                      <div class="fw-bold text-muted">smith@kpmg.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2" selected="selected">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span
                        class="symbol-label bg-light-danger text-danger fw-bold"
                        >M</span
                      >
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Melody Macy</a
                      >
                      <div class="fw-bold text-muted">melody@altbox.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1" selected="selected">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-1.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Max Smith</a
                      >
                      <div class="fw-bold text-muted">max@kt.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3" selected="selected">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-5.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Sean Bean</a
                      >
                      <div class="fw-bold text-muted">sean@dellito.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2" selected="selected">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-25.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Brian Cox</a
                      >
                      <div class="fw-bold text-muted">brian@exchange.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3" selected="selected">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span
                        class="
                          symbol-label
                          bg-light-warning
                          text-warning
                          fw-bold
                        "
                        >C</span
                      >
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Mikaela Collins</a
                      >
                      <div class="fw-bold text-muted">mik@pex.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2" selected="selected">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-9.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Francis Mitcham</a
                      >
                      <div class="fw-bold text-muted">f.mit@kpmg.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3" selected="selected">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span
                        class="symbol-label bg-light-danger text-danger fw-bold"
                        >O</span
                      >
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Olivia Wild</a
                      >
                      <div class="fw-bold text-muted">olivia@corpmail.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2" selected="selected">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span
                        class="
                          symbol-label
                          bg-light-primary
                          text-primary
                          fw-bold
                        "
                        >N</span
                      >
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Neil Owen</a
                      >
                      <div class="fw-bold text-muted">owen.neil@gmail.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1" selected="selected">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-23.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Dan Wilson</a
                      >
                      <div class="fw-bold text-muted">dam@consilting.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3" selected="selected">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span
                        class="symbol-label bg-light-danger text-danger fw-bold"
                        >E</span
                      >
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Emma Bold</a
                      >
                      <div class="fw-bold text-muted">emma@intenso.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2" selected="selected">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-12.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Ana Crown</a
                      >
                      <div class="fw-bold text-muted">ana.cf@limtel.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1" selected="selected">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span class="symbol-label bg-light-info text-info fw-bold"
                        >A</span
                      >
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Robert Doe</a
                      >
                      <div class="fw-bold text-muted">robert@benko.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3" selected="selected">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-13.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >John Miller</a
                      >
                      <div class="fw-bold text-muted">miller@mapple.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3" selected="selected">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <span
                        class="
                          symbol-label
                          bg-light-success
                          text-success
                          fw-bold
                        "
                        >L</span
                      >
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Lucy Kunic</a
                      >
                      <div class="fw-bold text-muted">lucy.m@fentech.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2" selected="selected">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    flex-stack
                    py-4
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-21.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Ethan Wilder</a
                      >
                      <div class="fw-bold text-muted">ethan@loop.com.au</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1" selected="selected">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3">Can Edit</option>
                    </select>
                  </div>
                </div>

                <div class="d-flex flex-stack py-4">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        alt="Pic"
                        src="assets/theme/media/avatars/300-9.jpg"
                      />
                    </div>

                    <div class="ms-5">
                      <a
                        href="#"
                        class="
                          fs-5
                          fw-bolder
                          text-gray-900 text-hover-primary
                          mb-2
                        "
                        >Francis Mitcham</a
                      >
                      <div class="fw-bold text-muted">f.mit@kpmg.com</div>
                    </div>
                  </div>

                  <div class="ms-2 w-100px">
                    <select
                      class="form-select form-select-solid form-select-sm"
                      data-control="select2"
                      data-hide-search="true"
                    >
                      <option value="1">Guest</option>
                      <option value="2">Owner</option>
                      <option value="3" selected="selected">Can Edit</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-stack">
              <div class="me-5 fw-bold">
                <label class="fs-6">Adding Users by Team Members</label>
                <div class="fs-7 text-muted">
                  If you need more info, please check budget planning
                </div>
              </div>

              <label
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="1"
                  checked="checked"
                />
                <span class="form-check-label fw-bold text-muted">Allowed</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
</style>
<script>
</script>
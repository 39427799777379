import { createWebHistory, createRouter } from "vue-router"; 
import SecureLS from "secure-ls";  
const ls = new SecureLS({ isCompression: false });

import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";   
import Logout from "@/components/Logout.vue";
import ScreenLock from "@/components/ScreenLock.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import VerifyAccount from "@/components/VerifyAccount.vue";
import SystemAccount from "@/components/system-management/Accounts/System/Overview.vue";
import ControllerAccountList from "@/components/system-management/Accounts/Controller/List.vue";
import ControllerAccountCreate from "@/components/system-management/Accounts/Controller/Create.vue";
import ControllerAccountUpdate from "@/components/system-management/Accounts/Controller/Update.vue";
import CompanyAccountList from "@/components/system-management/Accounts/Company/List.vue";
// import CompanyAccountCreate from "@/components/system-management/Accounts/Company/Create";
import CompanyAccountUpdate from "@/components/system-management/Accounts/Company/Update";
import ControllerUsers from "@/components/system-management/Users/ControllerUsers.vue";
import SystemUserList from "@/components/system-management/Users/SystemUserList";
import GeneralUserParameters from "@/components/system-management/GeneralSystemParameters/GeneralUserParameters";
import RequiredFieldDefaults from "@/components/system-management/GeneralSystemParameters/RequiredFieldDefaults";
import ReportDesign from "@/components/system-management/GeneralSystemParameters/ReportDesign";
import DataImportTemplates from "@/components/system-management/GeneralSystemParameters/DataImportTemplates";
import UpdateImportTemplate from "@/components/system-management/Users/UpdateImportTemplate";
import EditImportTemplate from "@/components/system-management/Users/EditImportTemplate";
import CreateImportTemplate from "@/components/system-management/Users/CreateImportTemplate";
import DesignImportTemplate from "@/components/system-management/Users/DesignImportTemplate";
import ControllerUserList from "@/components/system-management/Users/ControllerUserList";
import CompanyUserList from "@/components/system-management/Users/CompanyUserList";
import SystemUserCreate from "@/components/system-management/Users/SystemUserCreate";
import CompanyUserCreate from "@/components/system-management/Users/CompanyUserCreate";
import ControllerUserCreate from "@/components/system-management/Users/ControllerUserCreate";
import CompanyUserUpdate from "@/components/system-management/Users/CompanyUserUpdate";
import ControllerUserUpdate from "@/components/system-management/Users/ControllerUserUpdate";
import UserAdminProfile from "@/components/system-management/Users/UserAdminProfile";
import SystemUserUpdate from "@/components/system-management/Users/SystemUserUpdate";
// import DeleteParameters from "@/components/system-management/DeleteParameters"; 
import CompanyTypesList from "@/components/system-management/CompanyTypes/CompanyTypesList.vue";
import CompanyTypesCreate from "@/components/system-management/CompanyTypes/CompanyTypesCreate.vue";
import CompanyTypesUpdate from "@/components/system-management/CompanyTypes/CompanyTypesUpdate.vue";
import CompanyBusinessTypeList from "@/components/system-management/CompanyBusinessTypes/CompanyBusinessTypeList.vue";
import CompanyBusinessTypeCreate from "@/components/system-management/CompanyBusinessTypes/CompanyBusinessTypeCreate.vue";
import CompanyBusinessTypeUpdate from "@/components/system-management/CompanyBusinessTypes/CompanyBusinessTypeUpdate.vue";
import CompanySectorList from "@/components/system-management/CompanySectors/CompanySectorList.vue";
import CompanySectorCreate from "@/components/system-management/CompanySectors/CompanySectorCreate.vue";
import CompanySectorUpdate from "@/components/system-management/CompanySectors/CompanySectorUpdate.vue";
import TaskGroupCategoriesList from "@/components/system-management/TaskGroupCategories/TaskGroupCategoriesList.vue";
import TaskGroupCategoriesCreate from "@/components/system-management/TaskGroupCategories/TaskGroupCategoriesCreate.vue";
import TaskGroupCategoriesUpdate from "@/components/system-management/TaskGroupCategories/TaskGroupCategoriesUpdate.vue";
import TaskGroupsList from "@/components/system-management/TaskGroups/TaskGroupsList.vue";
import TaskGroupsCreate from "@/components/system-management/TaskGroups/TaskGroupsCreate.vue";
import TaskGroupsUpdate from "@/components/system-management/TaskGroups/TaskGroupsUpdate.vue";
import TaskTypesList from "@/components/system-management/TaskTypes/TaskTypesList.vue";
import TaskTypesCreate from "@/components/system-management/TaskTypes/TaskTypesCreate.vue";
import TaskTypesUpdate from "@/components/system-management/TaskTypes/TaskTypesUpdate.vue";
import DefaultCompanyTasksList from "@/components/system-management/DefaultCompanyTasks/DefaultCompanyTasksList.vue";
import DefaultLiabilityTypesList from "@/components/system-management/DefaultLiabilityTypes/DefaultLiabilityTypesList.vue";
import HolidaysList from "@/components/system-management/Holidays/HolidaysList.vue";
import HolidaysCreate from "@/components/system-management/Holidays/HolidaysCreate.vue";
import HolidaysUpdate from "@/components/system-management/Holidays/HolidaysUpdate.vue";
import CompanyTypeDetailsList from "@/components/system-management/CompanyTypeDetails/CompanyTypeDetailsList.vue";
import CompanyTypeDetailsCreate from "@/components/system-management/CompanyTypeDetails/CompanyTypeDetailsCreate.vue";
import CompanyTypeDetailsUpdate from "@/components/system-management/CompanyTypeDetails/CompanyTypeDetailsUpdate.vue";
 
import PlanSettingCreate from "@/components/system-management/AccountingPlans/PlanSettingCreate.vue";
import PlanList from "@/components/system-management/AccountingPlans/PlanList.vue";
import PlanCreate from "@/components/system-management/AccountingPlans/PlanCreate.vue";
import PlanUpdate from "@/components/system-management/AccountingPlans/PlanUpdate.vue";

import BankAccountTypeList from "@/components/system-management/ListTypes/BankAccountType/BankAccountTypeList.vue";
import BankAccountTypeCreate from "@/components/system-management/ListTypes/BankAccountType/BankAccountTypeCreate.vue";
import BankAccountTypeUpdate from "@/components/system-management/ListTypes/BankAccountType/BankAccountTypeUpdate.vue";

import BankList from "@/components/system-management/ListTypes/BankList/BankList.vue";
import BankCreate from "@/components/system-management/ListTypes/BankList/BankCreate.vue";
import BankUpdate from "@/components/system-management/ListTypes/BankList/BankUpdate.vue";

import PersonnelPositionList from "@/components/system-management/ListTypes/PersonnelPositionList/PersonnelPositionList.vue";
import PersonnelPositionCreate from "@/components/system-management/ListTypes/PersonnelPositionList/PersonnelPositionCreate.vue";
import PersonnelPositionUpdate from "@/components/system-management/ListTypes/PersonnelPositionList/PersonnelPositionUpdate.vue";

import ExpenseGroupList from "@/components/system-management/ListTypes/ExpenseGroup/ExpenseGroupList.vue";
import ExpenseGroupCreate from "@/components/system-management/ListTypes/ExpenseGroup/ExpenseGroupCreate.vue";
import ExpenseGroupUpdate from "@/components/system-management/ListTypes/ExpenseGroup/ExpenseGroupUpdate.vue";

import ExpenseTypeList from "@/components/system-management/ListTypes/ExpenseType/ExpenseTypeList.vue";
import ExpenseTypeCreate from "@/components/system-management/ListTypes/ExpenseType/ExpenseTypeCreate.vue";
import ExpenseTypeUpdate from "@/components/system-management/ListTypes/ExpenseType/ExpenseTypeUpdate.vue";

import StockGroupList from "@/components/system-management/ListTypes/StockGroup/StockGroupList.vue";
import StockGroupCreate from "@/components/system-management/ListTypes/StockGroup/StockGroupCreate.vue";
import StockGroupUpdate from "@/components/system-management/ListTypes/StockGroup/StockGroupUpdate.vue";

import ProductGroupList from "@/components/system-management/ListTypes/ProductGroup/ProductGroupList.vue";
import ProductGroupCreate from "@/components/system-management/ListTypes/ProductGroup/ProductGroupCreate.vue";
import ProductGroupUpdate from "@/components/system-management/ListTypes/ProductGroup/ProductGroupUpdate.vue";

import AssetTypeList from "@/components/system-management/ListTypes/AssetType/AssetTypeList.vue";
import AssetTypeCreate from "@/components/system-management/ListTypes/AssetType/AssetTypeCreate.vue";
import AssetTypeUpdate from "@/components/system-management/ListTypes/AssetType/AssetTypeUpdate.vue";

import UnitTypeList from "@/components/system-management/ListTypes/UnitType/UnitTypeList.vue";
import UnitTypeCreate from "@/components/system-management/ListTypes/UnitType/UnitTypeCreate.vue";
import UnitTypeUpdate from "@/components/system-management/ListTypes/UnitType/UnitTypeUpdate.vue";

import UnitType2List from "@/components/system-management/ListTypes/UnitType2/UnitType2List.vue";
import UnitType2Create from "@/components/system-management/ListTypes/UnitType2/UnitType2Create.vue";
import UnitType2Update from "@/components/system-management/ListTypes/UnitType2/UnitType2Update.vue";

import AccountingTemplatesList from "@/components/system-management/AccountingTemplates/AccountingTemplatesList.vue";
import AccountingTemplatesCreate from "@/components/system-management/AccountingTemplates/AccountingTemplatesCreate.vue";
import AccountingTemplatesUpdate from "@/components/system-management/AccountingTemplates/AccountingTemplatesUpdate.vue";

import PaymentList from "@/components/system-management/ListTypes/Payment/PaymentList.vue";
import PaymentCreate from "@/components/system-management/ListTypes/Payment/PaymentCreate.vue";
import PaymentUpdate from "@/components/system-management/ListTypes/Payment/PaymentUpdate.vue";

import ShippingList from "@/components/system-management/ListTypes/Shipping/ShippingList.vue";
import ShippingCreate from "@/components/system-management/ListTypes/Shipping/ShippingCreate.vue";
import ShippingUpdate from "@/components/system-management/ListTypes/Shipping/ShippingUpdate.vue";

import RelationTypeList from "@/components/system-management/ListTypes/RelationType/List.vue";
import RelationTypeCreate from "@/components/system-management/ListTypes/RelationType/Create.vue";
import RelationTypeUpdate from "@/components/system-management/ListTypes/RelationType/Update.vue";

import PriceTypeList from "@/components/system-management/ListTypes/PriceType/PriceTypeList.vue";
import PriceTypeCreate from "@/components/system-management/ListTypes/PriceType/PriceTypeCreate.vue";
import PriceTypeUpdate from "@/components/system-management/ListTypes/PriceType/PriceTypeUpdate.vue";

import CustomerTypeList from "@/components/system-management/ListTypes/CustomerType/CustomerTypeList.vue";
import CustomerTypeCreate from "@/components/system-management/ListTypes/CustomerType/CustomerTypeCreate.vue";
import CustomerTypeUpdate from "@/components/system-management/ListTypes/CustomerType/CustomerTypeUpdate.vue";

import VatRateList from "@/components/system-management/ListTypes/VatRate/VatRateList.vue";
import VatRateCreate from "@/components/system-management/ListTypes/VatRate/VatRateCreate.vue";
import VatRateUpdate from "@/components/system-management/ListTypes/VatRate/VatRateUpdate.vue";

import UserAssignmentList from "@/components/system-management/ListTypes/UserAssignment/UserAssignmentList.vue";
import UserAssignmentCreate from "@/components/system-management/ListTypes/UserAssignment/UserAssignmentCreate.vue";
import UserAssignmentUpdate from "@/components/system-management/ListTypes/UserAssignment/UserAssignmentUpdate.vue";

import ProductTypeList from "@/components/system-management/ListTypes/ProductType/ProductTypeList.vue";
import ProductTypeCreate from "@/components/system-management/ListTypes/ProductType/ProductTypeCreate.vue";
import ProductTypeUpdate from "@/components/system-management/ListTypes/ProductType/ProductTypeUpdate.vue";

import ListingParameters from "@/components/system-management/GeneralSystemParameters/ListingParameters.vue";
import SEOSettings from "@/components/system-management/GeneralSystemParameters/SEOSettings.vue";
import ConsolidationSettings from "@/components/system-management/GeneralSystemParameters/ConsolidationSettings.vue";
 
import CurrencyList from "@/components/system-management/CurrencyList/List.vue";
import CurrencyCreate from "@/components/system-management/CurrencyList/Create.vue";
import CurrencyUpdate from "@/components/system-management/CurrencyList/Update.vue";

import CountryList from "@/components/system-management/CountryList/List.vue";
import CountryCreate from "@/components/system-management/CountryList/Create.vue";
import CountryUpdate from "@/components/system-management/CountryList/Update.vue";

import Settings from "@/components/Settings/Settings.vue";

import SystemSettings from "@/components/Settings/SystemSettings.vue";
import ControllerSettings from "@/components/Settings/ControllerSettings.vue";
import CompanySettings from "@/components/Settings/CompanySettings.vue";
import AccessAuthorization from "@/components/Settings/AccessAuthorization.vue";
import ControllerAuthorizationList from "@/components/Settings/ControllerAuthorizationList.vue";
import GeneralSettings from "@/components/Settings/GeneralSettings/GeneralSettings.vue";

import OtherAccountGroupList from "@/components/system-management/ListTypes/OtherAccountGroup/OtherAccountGroupList.vue";
import OtherAccountGroupCreate from "@/components/system-management/ListTypes/OtherAccountGroup/OtherAccountGroupCreate.vue";
import OtherAccountGroupUpdate from "@/components/system-management/ListTypes/OtherAccountGroup/OtherAccountGroupUpdate.vue";

import DescriptionTypeList from "@/components/system-management/ListTypes/DescriptionType/DescriptionTypeList.vue";
import DescriptionTypeCreate from "@/components/system-management/ListTypes/DescriptionType/DescriptionTypeCreate.vue";
import DescriptionTypeUpdate from "@/components/system-management/ListTypes/DescriptionType/DescriptionTypeUpdate.vue";

import ListTypes from "@/components/Settings/GeneralSettings/ListTypes.vue";
import PolicyListTypes from "@/components/Settings/GeneralSettings/PolicyListTypes.vue";
import AccountingPlans from "@/components/Settings/GeneralSettings/AccountingPlans.vue";
import CompanyManagement from "@/components/Settings/GeneralSettings/CompanyManagement.vue";
import TaskManagement from "@/components/Settings/GeneralSettings/TaskManagement.vue";

import StockTrackingGroupList from "@/components/system-management/ListTypes/StockTrackingGroup/StockTrackingGroupList.vue";
import StockTrackingGroupCreate from "@/components/system-management/ListTypes/StockTrackingGroup/StockTrackingGroupCreate.vue";
import StockTrackingGroupUpdate from "@/components/system-management/ListTypes/StockTrackingGroup/StockTrackingGroupUpdate.vue";

import LiabilityGroupList from "@/components/system-management/ListTypes/LiabilityGroup/LiabilityGroupList.vue";
import LiabilityGroupCreate from "@/components/system-management/ListTypes/LiabilityGroup/LiabilityGroupCreate.vue";
import LiabilityGroupUpdate from "@/components/system-management/ListTypes/LiabilityGroup/LiabilityGroupUpdate.vue";

import LiabilityTypeList from "@/components/system-management/ListTypes/LiabilityType/LiabilityTypeList.vue";
import LiabilityTypeCreate from "@/components/system-management/ListTypes/LiabilityType/LiabilityTypeCreate.vue";
import LiabilityTypeUpdate from "@/components/system-management/ListTypes/LiabilityType/LiabilityTypeUpdate.vue";

import PermissionDenied from "@/components/PermissionDenied.vue";
import YourCompanies from "@/components/company-management/YourCompanies.vue";
 
import DocumentLocationCreate from "@/components/Settings/DocumentLocations/DocumentLocationCreate.vue";  
import DocumentLocationUpdate from "@/components/Settings/DocumentLocations/DocumentLocationUpdate.vue";  
import DocumentLocationList from "@/components/Settings/DocumentLocations/DocumentLocationList.vue";  
import DocumentTypeCreate from "@/components/Settings/DocumentTypes/DocumentTypeCreate.vue";  
import DocumentTypeList from "@/components/Settings/DocumentTypes/DocumentTypeList.vue";  
import DocumentTypeUpdate from "@/components/Settings/DocumentTypes/DocumentTypeUpdate.vue"; 

import FixedPaymentValueList from "@/components/Settings/FixedPaymentValue/FixedPaymentValueList.vue";
import FixedPaymentValueCreate from "@/components/Settings/FixedPaymentValue/FixedPaymentValueCreate.vue";
import FixedPaymentValueUpdate from "@/components/Settings/FixedPaymentValue/FixedPaymentValueUpdate.vue";

import ControllerOperationGroupsList from "@/components/Settings/ControllerOperationGroups/ControllerOperationGroupsList.vue";
import ControllerOperationGroupsCreate from "@/components/Settings/ControllerOperationGroups/ControllerOperationGroupsCreate.vue";
import ControllerOperationGroupsUpdate from "@/components/Settings/ControllerOperationGroups/ControllerOperationGroupsUpdate.vue";

import CompanyOverview from "@/components/company-management/CompanyOverview.vue";
import AccountingVoucherList from "@/components/company-management/Accounting/AccountingTransaction/List.vue";  
import AccountingVoucherCreate from "@/components/company-management/Accounting/AccountingTransaction/Create.vue";  
import AccountingVoucherUpdate from "@/components/company-management/Accounting/AccountingTransaction/Update.vue";  
import ClosingOperationsList from "@/components/company-management/Accounting/ClosingOperations/List.vue";  
import OpeningOperationsList from "@/components/company-management/Accounting/OpeningOperations/List.vue";  
import ProfitAndLossOperationsList from "@/components/company-management/Accounting/ProfitAndLossOperations/List.vue";
import ComparativeProfitAndLossList from "@/components/company-management/Accounting/ProfitAndLossStatements/ComparativeProfitAndLossList.vue";  
import GeneralProfitAndLossList from "@/components/company-management/Accounting/ProfitAndLossStatements/GeneralProfitAndLossList.vue";  
import VATOperationsList from "@/components/company-management/Accounting/VATOperations/List.vue";
import BalanceSheetList from "@/components/company-management/Accounting/BalanceSheet/DXList.vue";
import ComparativeBalanceSheetList from "@/components/company-management/Accounting/ComparativeBalanceSheet/DXList.vue";

import DashboardFolders from "@/components/Settings/DashboardFolders/DashboardFolders.vue"; 
import CompanyDocuments from "@/components/Settings/DashboardFolders/CompanyDocuments.vue"; 

import PagesList from "@/components/Settings/Pages/PagesList.vue";
import PagesCreate from "@/components/Settings/Pages/PagesCreate.vue"; 
import PagesUpdate from "@/components/Settings/Pages/PagesUpdate.vue";
 
import Liabilities from "@/components/system-management/Liabilities/Liabilities.vue";
import MediaLibrary from "@/components/Settings/MediaLibrary.vue";
import MailSettings from "@/components/system-management/GeneralSystemParameters/MailSettings.vue";
import MailSettingsList from "@/components/system-management/GeneralSystemParameters/MailSettings/List.vue";
import MailSettingsCreate from "@/components/system-management/GeneralSystemParameters/MailSettings/Create.vue";
import MailSettingsUpdate from "@/components/system-management/GeneralSystemParameters/MailSettings/Update.vue";
import RateTypeList from "@/components/system-management/RateTypeList/List.vue";
import GeneralCompanySettings from "@/components/system-management/Accounts/Company/GeneralCompanySettings.vue";
import CompaniesHouseList from "@/components/controller-management/CompaniesHouseList.vue";

import MainBranchList from "@/components/system-management/PolicyListTypes/MainBranch/MainBranchList.vue";
import MainBranchCreate from "@/components/system-management/PolicyListTypes/MainBranch/MainBranchCreate.vue";
import MainBranchUpdate from "@/components/system-management/PolicyListTypes/MainBranch/MainBranchUpdate.vue";

import PolicyBranchList from "@/components/system-management/PolicyListTypes/PolicyBranch/PolicyBranchList.vue";
import PolicyBranchCreate from "@/components/system-management/PolicyListTypes/PolicyBranch/PolicyBranchCreate.vue";
import PolicyBranchUpdate from "@/components/system-management/PolicyListTypes/PolicyBranch/PolicyBranchUpdate.vue";

import PolicyGroupList from "@/components/system-management/PolicyListTypes/PolicyGroup/PolicyGroupList.vue";
import PolicyGroupCreate from "@/components/system-management/PolicyListTypes/PolicyGroup/PolicyGroupCreate.vue";
import PolicyGroupUpdate from "@/components/system-management/PolicyListTypes/PolicyGroup/PolicyGroupUpdate.vue";

import CoverageMainList from "@/components/system-management/PolicyListTypes/CoverageMain/CoverageMainList.vue";
import CoverageMainCreate from "@/components/system-management/PolicyListTypes/CoverageMain/CoverageMainCreate.vue";
import CoverageMainUpdate from "@/components/system-management/PolicyListTypes/CoverageMain/CoverageMainUpdate.vue";

import CoverageDetailList from "@/components/system-management/PolicyListTypes/CoverageDetail/CoverageDetailList.vue";
import CoverageDetailCreate from "@/components/system-management/PolicyListTypes/CoverageDetail/CoverageDetailCreate.vue";
import CoverageDetailUpdate from "@/components/system-management/PolicyListTypes/CoverageDetail/CoverageDetailUpdate.vue";

import FactorTypeDetailList from "@/components/system-management/PolicyListTypes/FactorTypeDetail/FactorTypeDetailList.vue";
import FactorTypeDetailCreate from "@/components/system-management/PolicyListTypes/FactorTypeDetail/FactorTypeDetailCreate.vue";
import FactorTypeDetailUpdate from "@/components/system-management/PolicyListTypes/FactorTypeDetail/FactorTypeDetailUpdate.vue";

import EndorsementDetailList from "@/components/system-management/PolicyListTypes/EndorsementDetail/EndorsementDetailList.vue";
import EndorsementDetailCreate from "@/components/system-management/PolicyListTypes/EndorsementDetail/EndorsementDetailCreate.vue";
import EndorsementDetailUpdate from "@/components/system-management/PolicyListTypes/EndorsementDetail/EndorsementDetailUpdate.vue";

import SourceCodeList from "@/components/system-management/PolicyListTypes/SourceCodeList/SourceCodeList.vue";
import SourceCodeCreate from "@/components/system-management/PolicyListTypes/SourceCodeList/SourceCodeCreate.vue";
import SourceCodeUpdate from "@/components/system-management/PolicyListTypes/SourceCodeList/SourceCodeUpdate.vue";

import SourceCode2List from "@/components/system-management/PolicyListTypes/SourceCode2List/SourceCode2List.vue";
import SourceCode2Create from "@/components/system-management/PolicyListTypes/SourceCode2List/SourceCode2Create.vue";
import SourceCode2Update from "@/components/system-management/PolicyListTypes/SourceCode2List/SourceCode2Update.vue";


import VehicleGroupList from "@/components/system-management/PolicyListTypes/VehicleGroup/VehicleGroupList.vue";
import VehicleGroupCreate from "@/components/system-management/PolicyListTypes/VehicleGroup/VehicleGroupCreate.vue";
import VehicleGroupUpdate from "@/components/system-management/PolicyListTypes/VehicleGroup/VehicleGroupUpdate.vue";

import VehicleTypeList from "@/components/system-management/PolicyListTypes/VehicleType/VehicleTypeList.vue";
import VehicleTypeCreate from "@/components/system-management/PolicyListTypes/VehicleType/VehicleTypeCreate.vue";
import VehicleTypeUpdate from "@/components/system-management/PolicyListTypes/VehicleType/VehicleTypeUpdate.vue";

import UsageTypeList from "@/components/system-management/PolicyListTypes/UsageType/UsageTypeList.vue";
import UsageTypeCreate from "@/components/system-management/PolicyListTypes/UsageType/UsageTypeCreate.vue";
import UsageTypeUpdate from "@/components/system-management/PolicyListTypes/UsageType/UsageTypeUpdate.vue";

import UsageDetailList from "@/components/system-management/PolicyListTypes/UsageDetail/UsageDetailList.vue";
import UsageDetailCreate from "@/components/system-management/PolicyListTypes/UsageDetail/UsageDetailCreate.vue";
import UsageDetailUpdate from "@/components/system-management/PolicyListTypes/UsageDetail/UsageDetailUpdate.vue";

import FuelTypeList from "@/components/system-management/PolicyListTypes/FuelType/FuelTypeList.vue";
import FuelTypeCreate from "@/components/system-management/PolicyListTypes/FuelType/FuelTypeCreate.vue";
import FuelTypeUpdate from "@/components/system-management/PolicyListTypes/FuelType/FuelTypeUpdate.vue";

// import MainVehicleBrandList from "@/components/system-management/PolicyListTypes/MainVehicleBrand/MainVehicleBrandList.vue";
// import MainVehicleBrandCreate from "@/components/system-management/PolicyListTypes/MainVehicleBrand/MainVehicleBrandCreate.vue";
// import MainVehicleBrandUpdate from "@/components/system-management/PolicyListTypes/MainVehicleBrand/MainVehicleBrandUpdate.vue";

import VehicleBrandList from "@/components/system-management/PolicyListTypes/VehicleBrand/VehicleBrandList.vue";
import VehicleBrandCreate from "@/components/system-management/PolicyListTypes/VehicleBrand/VehicleBrandCreate.vue";
import VehicleBrandUpdate from "@/components/system-management/PolicyListTypes/VehicleBrand/VehicleBrandUpdate.vue";

import VehicleModelList from "@/components/system-management/PolicyListTypes/VehicleModel/VehicleModelList.vue";
import VehicleModelCreate from "@/components/system-management/PolicyListTypes/VehicleModel/VehicleModelCreate.vue";
import VehicleModelUpdate from "@/components/system-management/PolicyListTypes/VehicleModel/VehicleModelUpdate.vue";

import PropertyConstructionTypeList from "@/components/system-management/PolicyListTypes/PropertyConstructionType/List.vue";
import PropertyConstructionTypeCreate from "@/components/system-management/PolicyListTypes/PropertyConstructionType/Create.vue";
import PropertyConstructionTypeUpdate from "@/components/system-management/PolicyListTypes/PropertyConstructionType/Update.vue";

import PropertyConstructionYearList from "@/components/system-management/PolicyListTypes/PropertyConstructionYear/List.vue";
import PropertyConstructionYearCreate from "@/components/system-management/PolicyListTypes/PropertyConstructionYear/Create.vue";
import PropertyConstructionYearUpdate from "@/components/system-management/PolicyListTypes/PropertyConstructionYear/Update.vue";

import PropertyTypeList from "@/components/system-management/PolicyListTypes/PropertyType/List.vue";
import PropertyTypeCreate from "@/components/system-management/PolicyListTypes/PropertyType/Create.vue";
import PropertyTypeUpdate from "@/components/system-management/PolicyListTypes/PropertyType/Update.vue";

import PropertyUsageDetailList from "@/components/system-management/PolicyListTypes/PropertyUsageDetail/List.vue";
import PropertyUsageDetailCreate from "@/components/system-management/PolicyListTypes/PropertyUsageDetail/Create.vue";
import PropertyUsageDetailUpdate from "@/components/system-management/PolicyListTypes/PropertyUsageDetail/Update.vue";

import PropertyUsageTypeList from "@/components/system-management/PolicyListTypes/PropertyUsageType/List.vue";
import PropertyUsageTypeCreate from "@/components/system-management/PolicyListTypes/PropertyUsageType/Create.vue";
import PropertyUsageTypeUpdate from "@/components/system-management/PolicyListTypes/PropertyUsageType/Update.vue";

import PersonPositionTypeList from "@/components/system-management/PolicyListTypes/PersonPositionType/List.vue";
import PersonPositionTypeCreate from "@/components/system-management/PolicyListTypes/PersonPositionType/Create.vue";
import PersonPositionTypeUpdate from "@/components/system-management/PolicyListTypes/PersonPositionType/Update.vue";

import ConsolidationReports from "@/components/system-management/SystemReports/ConsolidationReports.vue";
import DLLTemplatesList from "@/components/system-management/DLLTemplates/List.vue";
import DLLTemplatesCreate from "@/components/system-management/DLLTemplates/Create.vue";
import DLLTemplatesUpdate from "@/components/system-management/DLLTemplates/Update.vue";

import SecuritySettings from "@/components/system-management/GeneralSystemParameters/RateLimiting/SecuritySettings.vue";
import SecuritySettingsCreate from "@/components/system-management/GeneralSystemParameters/RateLimiting/SecuritySettingsCreate.vue";
import SecuritySettingsUpdate from "@/components/system-management/GeneralSystemParameters/RateLimiting/SecuritySettingsUpdate.vue";

import PageNotFound from "@/components/PageNotFound.vue";
import SystemLogsList from "@/components/Settings/SystemLogs/SystemLogsList.vue";
import MemberLogsList from "@/components/Settings/SystemLogs/MemberLogsList.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {category:'Home', requiresAuth: true },
        props: true
    },
    {
        path: "/CompanyOverview",
        name: "CompanyOverview",
        component: CompanyOverview,
        meta: {category:'CompanyOverview',  requiresAuth: true },
        props: true
    },
    {
        path: "/Login",
        name: "Login",
        component: Login
    },
    {
        path: "/Logout",
        name: "Logout",
        component: Logout,
        meta: {category:'Logout', requiresAuth: false, fullwidth: true },
        props: true
    },
    {
        path: "/ScreenLock",
        name: "ScreenLock",
        component: ScreenLock,
        meta: {category:'ScreenLock', requiresAuth: false, fullwidth: true },
        props: true
    },
    {
        path: "/ResetPassword",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {category:'ResetPassword', requiresAuth: false, fullwidth: true },
        props: true
    },
    {
        path: "/ForgotPassword",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {category:'ForgotPassword', requiresAuth: false, fullwidth: true },
        props: true
    },
    {
        path: "/VerifyAccount",
        name: "VerifyAccount",
        component: VerifyAccount,
        meta: {category:'VerifyAccount', requiresAuth: false, fullwidth: true },
        props: true
    },
    {
        path: "/SystemAccount",
        name: "SystemAccount",
        component: SystemAccount,
        meta: {category:'SystemAccount', requiresAuth: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/ControllerAccountList",
        name: "ControllerAccountList",
        component: ControllerAccountList,
        meta: {category:'ControllerAccount', requiresAuth: true, fullwidth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ControllerAccountCreate",
        name: "ControllerAccountCreate",
        component: ControllerAccountCreate,
        meta: {category:'ControllerAccount', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/Update",
        name: "ControllerAccountUpdate",
        component: ControllerAccountUpdate,
        meta: {category:'ControllerAccount', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CompanyAccountList",
        name: "CompanyAccountList",
        component: CompanyAccountList,
        meta: {category:'CompanyAccountList', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ControllerUsers",
        name: "ControllerUsers",
        component: ControllerUsers,
        meta: {category:'ControllerUsers', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CompanyTypesList",
        name: "CompanyTypesList",
        component: CompanyTypesList,
        meta: {category:'CompanyTypes', requiresAuth: true, fullwidth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/SystemUserList",
        name: "SystemUserList",
        component: SystemUserList,
        meta: {category:'SystemUserList', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/GeneralUserParameters",
        name: "GeneralUserParameters",
        component: GeneralUserParameters,
        meta: {category:'GeneralUserParameters', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/RequiredFieldDefaults",
        name: "RequiredFieldDefaults",
        component: RequiredFieldDefaults,
        meta: {category:'RequiredFieldDefaults', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/ReportDesign",
        name: "ReportDesign",
        component: ReportDesign,
        meta: {category:'ReportDesign', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/ControllerUserList",
        name: "ControllerUserList",
        component: ControllerUserList,
        meta: {category:'ControllerUser', requiresAuth: true, fullwidth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CompanyUserList",
        name: "CompanyUserList",
        component: CompanyUserList,
        meta: {category:'CompanyUser', requiresAuth: true, fullwidth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/DataImportTemplates",
        name: "DataImportTemplates",
        component: DataImportTemplates,
        meta: {category:'DataImportTemplates', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/CreateImportTemplate",
        name: "CreateImportTemplate",
        component: CreateImportTemplate,
        meta: {category:'CreateImportTemplate', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/UpdateImportTemplate",
        name: "UpdateImportTemplate",
        component: UpdateImportTemplate,
        meta: {category:'UpdateImportTemplate', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/EditImportTemplate",
        name: "EditImportTemplate",
        component: EditImportTemplate,
        meta: {category:'EditImportTemplate', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/DesignImportTemplate",
        name: "DesignImportTemplate",
        component: DesignImportTemplate,
        meta: {category:'DesignImportTemplate', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    // {
    //     path: "/CompanyAccountCreate",
    //     name: "CompanyAccountCreate",
    //     component: CompanyAccountCreate,
    //     meta: {category:'Company Account', requiresAuth: true, requiresAdmin: true },
    //     props: true
    // },
    {
        path: "/CompanyAccountUpdate",
        name: "CompanyAccountUpdate",
        component: CompanyAccountUpdate,
        meta: {category:'CompanyAccount', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/MailSettings",
        name: "MailSettings",
        component: MailSettings,
        meta: {requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/MailSettingsList",
        name: "MailSettingsList",
        component: MailSettingsList,
        meta: {requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/MailSettingsCreate",
        name: "MailSettingsCreate",
        component: MailSettingsCreate,
        meta: {requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/MailSettingsUpdate",
        name: "MailSettingsUpdate",
        component: MailSettingsUpdate,
        meta: {requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/SystemUserCreate",
        name: "SystemUserCreate",
        component: SystemUserCreate,
        meta: {category:'User', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/CompanyUserCreate",
        name: "CompanyUserCreate",
        component: CompanyUserCreate,
        meta: {category:'CompanyUser', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ControllerUserCreate",
        name: "ControllerUserCreate",
        component: ControllerUserCreate,
        meta: {category:'ControllerUser', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/SystemUserUpdate",
        name: "SystemUserUpdate",
        component: SystemUserUpdate,
        meta: {category:'User', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/CompanyUserUpdate",
        name: "CompanyUserUpdate",
        component: CompanyUserUpdate,
        meta: {category:'CompanyUser', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ControllerUserUpdate",
        name: "ControllerUserUpdate",
        component: ControllerUserUpdate,
        meta: {category:'ControllerUser', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/UserAdminProfile",
        name: "UserAdminProfile",
        component: UserAdminProfile,
        meta: {category:'UserAdminProfile', requiresAuth: true  },
        props: true
    },
    {
        path: "/CompanyTypesCreate",
        name: "CompanyTypesCreate",
        component: CompanyTypesCreate,
        meta: {category:'CompanyTypes', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CompanyTypesUpdate",
        name: "CompanyTypesUpdate",
        component: CompanyTypesUpdate,
        meta: {category:'CompanyTypes', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    // {
    //     path: "/DeleteParameters",
    //     name: "DeleteParameters",
    //     component: DeleteParameters,
    //     meta: {category:'DeleteParameters', requiresAuth: true, requiresAdmin: true  },
    //     props: true
    // },
    {
        path: "/ListingParameters",
        name: "ListingParameters",
        component: ListingParameters,
        meta: {category:'ListingParameters', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/SEOSettings",
        name: "SEOSettings",
        component: SEOSettings,
        meta: {category:'SEOSettings', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/ConsolidationSettings",
        name: "ConsolidationSettings",
        component: ConsolidationSettings,
        meta: {category:'ConsolidationSettings', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/CompanyBusinessTypeList",
        name: "CompanyBusinessTypeList",
        component: CompanyBusinessTypeList,
        meta: {category:'Company Business Type', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CompanyBusinessTypeCreate",
        name: "CompanyBusinessTypeCreate",
        component: CompanyBusinessTypeCreate,
        meta: {category:'Company Business Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CompanyBusinessTypeUpdate",
        name: "CompanyBusinessTypeUpdate",
        component: CompanyBusinessTypeUpdate,
        meta: {category:'Company Business Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CompanySectorList",
        name: "CompanySectorList",
        component: CompanySectorList,
        meta: {category:'Company Sector', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CompanySectorCreate",
        name: "CompanySectorCreate",
        component: CompanySectorCreate,
        meta: {category:'Company Sector', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CompanySectorUpdate",
        name: "CompanySectorUpdate",
        component: CompanySectorUpdate,
        meta: {category:'Company Sector', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/TaskGroupCategoriesList",
        name: "TaskGroupCategoriesList",
        component: TaskGroupCategoriesList,
        meta: {category:'Task Categories', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/TaskGroupCategoriesCreate",
        name: "TaskGroupCategoriesCreate",
        component: TaskGroupCategoriesCreate,
        meta: {category:'Task Categories', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/TaskGroupCategoriesUpdate",
        name: "TaskGroupCategoriesUpdate",
        component: TaskGroupCategoriesUpdate,
        meta: {category:'Task Categories', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/TaskGroupsList",
        name: "TaskGroupsList",
        component: TaskGroupsList,
        meta: {category:'Task Groups', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/TaskGroupsCreate",
        name: "TaskGroupsCreate",
        component: TaskGroupsCreate,
        meta: {category:'Task Groups', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/TaskGroupsUpdate",
        name: "TaskGroupsUpdate",
        component: TaskGroupsUpdate,
        meta: {category:'Task Groups', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/TaskTypesList",
        name: "TaskTypesList",
        component: TaskTypesList,
        meta: {category:'Task Types', requiresAuth: true, fullwidth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/TaskTypesCreate",
        name: "TaskTypesCreate",
        component: TaskTypesCreate,
        meta: { category:'Task Types', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/TaskTypesUpdate",
        name: "TaskTypesUpdate",
        component: TaskTypesUpdate,
        meta: {category:'Task Types', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/DefaultCompanyTasksList",
        name: "DefaultCompanyTasksList",
        component: DefaultCompanyTasksList,
        meta: {category:'DefaultCompanyTasksList',  requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/DefaultLiabilityTypesList",
        name: "DefaultLiabilityTypesList",
        component: DefaultLiabilityTypesList,
        meta: {category:'DefaultLiabilityTypesList',  requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/HolidaysList",
        name: "HolidaysList",
        component: HolidaysList,
        meta: {category:'Holidays', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/HolidaysCreate",
        name: "HolidaysCreate",
        component: HolidaysCreate,
        meta: {category:'Holidays', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/HolidaysUpdate",
        name: "HolidaysUpdate",
        component: HolidaysUpdate,
        meta: {category:'Holidays', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CompanyTypeDetailsList",
        name: "CompanyTypeDetailsList",
        component: CompanyTypeDetailsList,
        meta: {category:'CompanyType Detail', requiresAuth: true, fullwidth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CompanyTypeDetailsCreate",
        name: "CompanyTypeDetailsCreate",
        component: CompanyTypeDetailsCreate,
        meta: {category:'CompanyType Detail', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/CompanyTypeDetailsUpdate",
        name: "CompanyTypeDetailsUpdate",
        component: CompanyTypeDetailsUpdate,
        meta: {category:'CompanyType Detail', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/PlanList",
        name: "PlanList",
        component: PlanList,
        meta: {category:'Plan', requiresAuth: true, fullwidth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/PlanSettingCreate",
        name: "PlanSettingCreate",
        component: PlanSettingCreate,
        meta: {category:'Plan', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/PlanCreate",
        name: "PlanCreate",
        component: PlanCreate,
        meta: {category:'Plan', requiresAuth: true , requiresAdmin: true },
        props: true
    },
    {
        path: "/PlanUpdate",
        name: "PlanUpdate",
        component: PlanUpdate,
        meta: {category:'Plan', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/BankAccountTypeList",
        name: "BankAccountTypeList",
        component: BankAccountTypeList,
        meta: {category:'BankAccount Type', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
         props: true
    },
    {
        path: "/BankAccountTypeCreate",
        name: "BankAccountTypeCreate",
        component: BankAccountTypeCreate,
        meta: {category:'BankAccount Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/BankAccountTypeUpdate",
        name: "BankAccountTypeUpdate",
        component: BankAccountTypeUpdate,
        meta: {category:'BankAccount Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    }, 
    {
        path: "/BankList",
        name: "BankList",
        component: BankList,
        meta: {category:'Bank', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/BankCreate",
        name: "BankCreate",
        component: BankCreate,
        meta: {category:'Bank', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/BankUpdate",
        name: "BankUpdate",
        component: BankUpdate,
        meta: {category:'Bank', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/PersonnelPositionList",
        name: "PersonnelPositionList",
        component: PersonnelPositionList,
        meta: {category:'Personnel Position', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/PersonnelPositionCreate",
        name: "PersonnelPositionCreate",
        component: PersonnelPositionCreate,
        meta: {category:'Personnel Position', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/PersonnelPositionUpdate",
        name: "PersonnelPositionUpdate",
        component: PersonnelPositionUpdate,
        meta: {category:'Personnel Position', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ExpenseGroupList",
        name: "ExpenseGroupList",
        component: ExpenseGroupList,
        meta: {category:'Expense Group', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ExpenseGroupCreate",
        name: "ExpenseGroupCreate",
        component: ExpenseGroupCreate,
        meta: {category:'Expense Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ExpenseGroupUpdate",
        name: "ExpenseGroupUpdate",
        component: ExpenseGroupUpdate,
        meta: {category:'Expense Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/ExpenseTypeList",
        name: "ExpenseTypeList",
        component: ExpenseTypeList,
        meta: {category:'Expense Type', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/ExpenseTypeCreate",
        name: "ExpenseTypeCreate",
        component: ExpenseTypeCreate,
        meta: {category:'Expense Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/ExpenseTypeUpdate",
        name: "ExpenseTypeUpdate",
        component: ExpenseTypeUpdate,
        meta: {category:'Expense Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    }, 
    {
        path: "/StockGroupList",
        name: "StockGroupList",
        component: StockGroupList,
        meta: {category:'Stock Group', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/StockGroupCreate",
        name: "StockGroupCreate",
        component: StockGroupCreate,
        meta: {category:'Stock Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/StockGroupUpdate",
        name: "StockGroupUpdate",
        component: StockGroupUpdate,
        meta: {category:'Stock Group', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/ProductGroupList",
        name: "ProductGroupList",
        component: ProductGroupList,
        meta: {category:'Product Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ProductGroupCreate",
        name: "ProductGroupCreate",
        component: ProductGroupCreate,
        meta: {category:'Product Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ProductGroupUpdate",
        name: "ProductGroupUpdate",
        component: ProductGroupUpdate,
        meta: {category:'Product Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/ProductTypeList",
        name: "ProductTypeList",
        component: ProductTypeList,
        meta: {category:'Product Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ProductTypeCreate",
        name: "ProductTypeCreate",
        component: ProductTypeCreate,
        meta: {category:'Product Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ProductTypeUpdate",
        name: "ProductTypeUpdate",
        component: ProductTypeUpdate,
        meta: {category:'Product Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/AssetTypeList",
        name: "AssetTypeList",
        component: AssetTypeList,
        meta: {category:'Asset Type', requiresAuth: true, fullwidth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/AssetTypeCreate",
        name: "AssetTypeCreate",
        component: AssetTypeCreate,
        meta: {category:'Asset Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/AssetTypeUpdate",
        name: "AssetTypeUpdate",
        component: AssetTypeUpdate,
        meta: {category:'Asset Type', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/UnitTypeList",
        name: "UnitTypeList",
        component: UnitTypeList,
        meta: {category:'Unit Type', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/UnitTypeCreate",
        name: "UnitTypeCreate",
        component: UnitTypeCreate,
        meta: {category:'Unit Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/UnitTypeUpdate",
        name: "UnitTypeUpdate",
        component: UnitTypeUpdate,
        meta: {category:'Unit Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    }, 
    {
        path: "/UnitType2List",
        name: "UnitType2List",
        component: UnitType2List,
        meta: {category:'Unit Type2', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/UnitType2Create",
        name: "UnitType2Create",
        component: UnitType2Create,
        meta: {category:'Unit Type2', requiresAuth: true , requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/UnitType2Update",
        name: "UnitType2Update",
        component: UnitType2Update,
        meta: {category:'Unit Type2', requiresAuth: true , requiresAdmin: true, isSystemAdmin: true },
        props: true
    }, 
    {
        path: "/AccountingTemplatesCreate",
        name: "AccountingTemplatesCreate",
        component: AccountingTemplatesCreate,
        meta: {category:'Accounting Templates', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/AccountingTemplatesList",
        name: "AccountingTemplatesList",
        component: AccountingTemplatesList,
        meta: {category:'Accounting Templates', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/AccountingTemplatesUpdate",
        name: "AccountingTemplatesUpdate",
        component: AccountingTemplatesUpdate,
        meta: {category:'Accounting Templates', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/PaymentList",
        name: "PaymentList",
        component: PaymentList,
        meta: {category:'Payment', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/PaymentCreate",
        name: "PaymentCreate",
        component: PaymentCreate,
        meta: { category:'Payment',requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/PaymentUpdate",
        name: "PaymentUpdate",
        component: PaymentUpdate,
        meta: {category:'Payment', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/ShippingList",
        name: "ShippingList",
        component: ShippingList,
        meta: {category:'Shipping', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/ShippingCreate",
        name: "ShippingCreate",
        component: ShippingCreate,
        meta: {category:'Shipping', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/ShippingUpdate",
        name: "ShippingUpdate",
        component: ShippingUpdate,
        meta: {category:'Shipping', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/RelationTypeList",
        name: "RelationTypeList",
        component: RelationTypeList,
        meta: {category:'RelationType', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/RelationTypeCreate",
        name: "RelationTypeCreate",
        component: RelationTypeCreate,
        meta: {category:'RelationType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/RelationTypeUpdate",
        name: "RelationTypeUpdate",
        component: RelationTypeUpdate,
        meta: {category:'RelationType', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/PriceTypeList",
        name: "PriceTypeList",
        component: PriceTypeList,
        meta: {category:'Price Type', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/PriceTypeCreate",
        name: "PriceTypeCreate",
        component: PriceTypeCreate,
        meta: {category:'Price Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/PriceTypeUpdate",
        name: "PriceTypeUpdate",
        component: PriceTypeUpdate,
        meta: {category:'Price Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/CustomerTypeList",
        name: "CustomerTypeList",
        component: CustomerTypeList,
        meta: {category:'Customer Type', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/CustomerTypeCreate",
        name: "CustomerTypeCreate",
        component: CustomerTypeCreate,
        meta: {category:'Customer Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/CustomerTypeUpdate",
        name: "CustomerTypeUpdate",
        component: CustomerTypeUpdate,
        meta: {category:'Customer Type', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/VatRateList",
        name: "VatRateList",
        component: VatRateList,
        meta: {category:'Vat Rate', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/VatRateCreate",
        name: "VatRateCreate",
        component: VatRateCreate,
        meta: {category:'Vat Rate', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/VatRateUpdate",
        name: "VatRateUpdate",
        component: VatRateUpdate,
        meta: {category:'Vat Rate', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/UserAssignmentList",
        name: "UserAssignmentList",
        component: UserAssignmentList,
        meta: {category:'User Assignment', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/UserAssignmentCreate",
        name: "UserAssignmentCreate",
        component: UserAssignmentCreate,
        meta: {category:'User Assignment', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/UserAssignmentUpdate",
        name: "UserAssignmentUpdate",
        component: UserAssignmentUpdate,
        meta: {category:'User Assignment', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CurrencyList",
        name: "CurrencyList",
        component: CurrencyList,
        meta: {category:'Currency', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/CurrencyCreate",
        name: "CurrencyCreate",
        component: CurrencyCreate,
        meta: {category:'Currency', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/CurrencyUpdate",
        name: "CurrencyUpdate",
        component: CurrencyUpdate,
        meta: {category:'Currency', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/CountryList",
        name: "CountryList",
        component: CountryList,
        meta: {category:'Country', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    }, 
    {
        path: "/CountryCreate",
        name: "CountryCreate",
        component: CountryCreate,
        meta: {category:'Country', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    }, 
    {
        path: "/CountryUpdate",
        name: "CountryUpdate",
        component: CountryUpdate,
        meta: {category:'Country', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    }, 
    {
        path: "/Settings",
        name: "Settings",
        component: Settings,
        meta: {category:'Settings', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/SystemSettings",
        name: "SystemSettings",
        component: SystemSettings,
        meta: {category:'SystemSettings', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    }, 
    {
        path: "/GeneralSettings",
        name: "GeneralSettings",
        component: GeneralSettings,
        meta: {category:'GeneralSettings', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/ListTypes",
        name: "ListTypes",
        component: ListTypes,
        meta: {category:'ListTypes', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/PolicyListTypes",
        name: "PolicyListTypes",
        component: PolicyListTypes,
        meta: {category:'PolicyListTypes', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/AccountingPlans",
        name: "AccountingPlans",
        component: AccountingPlans,
        meta: {category:'AccountingPlans', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/CompanyManagement",
        name: "CompanyManagement",
        component: CompanyManagement,
        meta: {category:'CompanyManagement', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/TaskManagement",
        name: "TaskManagement",
        component: TaskManagement,
        meta: {category:'TaskManagement', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/ControllerSettings",
        name: "ControllerSettings",
        component: ControllerSettings,
        meta: {category:'ControllerSettings', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/CompanySettings",
        name: "CompanySettings",
        component: CompanySettings,
        meta: {category:'CompanySettings', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/OtherAccountGroupList",
        name: "OtherAccountGroupList",
        component: OtherAccountGroupList,
        meta: {category:'OterhAccount Group', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/OtherAccountGroupCreate",
        name: "OtherAccountGroupCreate",
        component: OtherAccountGroupCreate,
        meta: {category:'OterhAccount Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/OtherAccountGroupUpdate",
        name: "OtherAccountGroupUpdate",
        component: OtherAccountGroupUpdate,
        meta: {category:'OterhAccount Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/DescriptionTypeList",
        name: "DescriptionTypeList",
        component: DescriptionTypeList,
        meta: {category:'Description Type', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/DescriptionTypeCreate",
        name: "DescriptionTypeCreate",
        component: DescriptionTypeCreate,
        meta: {category:'Description Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/DescriptionTypeUpdate",
        name: "DescriptionTypeUpdate",
        component: DescriptionTypeUpdate,
        meta: {category:'Description Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/AccessAuthorization",
        name: "AccessAuthorization",
        component: AccessAuthorization,
        meta: {category:'AccessAuthorization', requiresAuth: true , requiresAdmin: true },
        props: true
    },
    {
        path: "/ControllerAuthorizationList",
        name: "ControllerAuthorizationList",
        component: ControllerAuthorizationList,
        meta: {category:'ControllerAuthorizationList', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },   
    {
        path: "/StockTrackingGroupList",
        name: "StockTrackingGroupList",
        component: StockTrackingGroupList,
        meta: {category:'StockTracking Group', requiresAuth: true, fullwidth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/StockTrackingGroupCreate",
        name: "StockTrackingGroupCreate",
        component: StockTrackingGroupCreate,
        meta: {category:'StockTracking Group', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true  },
        props: true
    },
    {
        path: "/StockTrackingGroupUpdate",
        name: "StockTrackingGroupUpdate",
        component: StockTrackingGroupUpdate,
        meta: {category:'StockTracking Group', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/PermissionDenied",
        name: "PermissionDenied",
        component: PermissionDenied,
        meta: {category:'PermissionDenied', requiresAuth: true  },
        props: true
    },
    {
        path: "/YourCompanies",
        name: "YourCompanies",
        component: YourCompanies,
        meta: {category:'YourCompanies', requiresAuth: true  },
        props: true
    },
    {
        path: "/CompaniesHouseList",
        name: "CompaniesHouseList",
        component: CompaniesHouseList,
        meta: {category:'CompaniesHouseList', requiresAuth: true, fullwidth: true  },
        props: true
    },
    {
        path: "/LiabilityGroupList",
        name: "LiabilityGroupList",
        component: LiabilityGroupList,
        meta: {category:'Liability Group', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/LiabilityGroupCreate",
        name: "LiabilityGroupCreate",
        component: LiabilityGroupCreate,
        meta: {category:'Liability Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/LiabilityGroupUpdate",
        name: "LiabilityGroupUpdate",
        component: LiabilityGroupUpdate,
        meta: {category:'Liability Group', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/LiabilityTypeList",
        name: "LiabilityTypeList",
        component: LiabilityTypeList,
        meta: {category:'Liability Type', requiresAuth: true, fullwidth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/LiabilityTypeCreate",
        name: "LiabilityTypeCreate",
        component: LiabilityTypeCreate,
        meta: {category:'Liability Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/LiabilityTypeUpdate",
        name: "LiabilityTypeUpdate",
        component: LiabilityTypeUpdate,
        meta: {category:'Liability Type', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/DocumentTypeList",
        name: "DocumentTypeList",
        component: DocumentTypeList,
        meta: {category:'Document Type', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/DocumentTypeCreate",
        name: "DocumentTypeCreate",
        component: DocumentTypeCreate,
        meta: {category:'Document Type', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/DocumentTypeUpdate",
        name: "DocumentTypeUpdate",
        component: DocumentTypeUpdate,
        meta: {category:'Document Type', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/DocumentLocationList",
        name: "DocumentLocationList",
        component: DocumentLocationList,
        meta: {category:'Document Location', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/DocumentLocationCreate",
        name: "DocumentLocationCreate",
        component: DocumentLocationCreate,
        meta: {category:'Document Location', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/DocumentLocationUpdate",
        name: "DocumentLocationUpdate",
        component: DocumentLocationUpdate,
        meta: {category:'Document Location', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/FixedPaymentValueUpdate",
        name: "FixedPaymentValueUpdate",
        component: FixedPaymentValueUpdate,
        meta: {category:'FixedPaymentValue', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/FixedPaymentValueCreate",
        name: "FixedPaymentValueCreate",
        component: FixedPaymentValueCreate,
        meta: {category:'FixedPaymentValue', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/FixedPaymentValueList",
        name: "FixedPaymentValueList",
        component: FixedPaymentValueList,
        meta: {category:'FixedPaymentValue', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/ControllerOperationGroupsUpdate",
        name: "ControllerOperationGroupsUpdate",
        component: ControllerOperationGroupsUpdate,
        meta: {category:'ControllerOperationGroups', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ControllerOperationGroupsCreate",
        name: "ControllerOperationGroupsCreate",
        component: ControllerOperationGroupsCreate,
        meta: {category:'ControllerOperationGroups', requiresAuth: true, requiresAdmin: true },
        props: true
    },
    {
        path: "/ControllerOperationGroupsList",
        name: "ControllerOperationGroupsList",
        component: ControllerOperationGroupsList,
        meta: {category:'ControllerOperationGroups', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/AccountingVoucherCreate",
        name: "AccountingVoucherCreate",
        component: AccountingVoucherCreate,
        meta: {category:'AccountingTransaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/AccountingVoucherList",
        name: "AccountingVoucherList",
        component: AccountingVoucherList,
        meta: {category:'AccountingTransaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/AccountingVoucherUpdate",
        name: "AccountingVoucherUpdate",
        component: AccountingVoucherUpdate,
        meta: {category:'AccountingTransaction', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ProfitAndLossOperationsList",
        name: "ProfitAndLossOperationsList",
        component: ProfitAndLossOperationsList,
        meta: {category:'ProfitAndLossOperations', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/OpeningOperationsList",
        name: "OpeningOperationsList",
        component: OpeningOperationsList,
        meta: {category:'OpeningOperations', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ClosingOperationsList",
        name: "ClosingOperationsList",
        component: ClosingOperationsList,
        meta: {category:'ClosingOperations', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ComparativeProfitAndLossList",
        name: "ComparativeProfitAndLossList",
        component: ComparativeProfitAndLossList,
        meta: {category:'ComparativeProfitAndLoss', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/GeneralProfitAndLossList",
        name: "GeneralProfitAndLossList",
        component: GeneralProfitAndLossList,
        meta: {category:'GeneralProfitAndLoss', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/VATOperationsList",
        name: "VATOperationsList",
        component: VATOperationsList,
        meta: {category:'VATOperations', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/BalanceSheetList",
        name: "BalanceSheetList",
        component: BalanceSheetList,
        meta: {category:'BalanceSheet', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/ComparativeBalanceSheetList",
        name: "ComparativeBalanceSheetList",
        component: ComparativeBalanceSheetList,
        meta: {category:'ComparativeBalanceSheet', requiresAuth: true, fullwidth: true },
        props: true
    },
    {
        path: "/DashboardFolders",
        name: "DashboardFolders",
        component: DashboardFolders,
        meta: {category:'DashboardFolders', requiresAuth: true, requiresAdmin: true },
        props: true
    }, 
    {
        path: "/Liabilities",
        name: "Liabilities",
        component: Liabilities,
        meta: {category:'Liabilities', requiresAuth: true },
        props: true
    },
    {
        path: "/PagesList",
        name: "PagesList",
        component: PagesList,
        meta: {category:'Pages', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/PagesCreate",
        name: "PagesCreate",
        component: PagesCreate,
        meta: {category:'Pages', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/PagesUpdate",
        name: "PagesUpdate",
        component: PagesUpdate,
        meta: {category:'Pages', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/CompanyDocuments",
        name: "CompanyDocuments",
        component: CompanyDocuments,
        meta: {category:'CompanyDocuments', requiresAuth: true },
        props: true
    },
    {
        path: "/MediaLibrary",
        name: "MediaLibrary",
        component: MediaLibrary,
        meta: {category:'MediaLibrary', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/GeneralCompanySettings",
        name: "GeneralCompanySettings",
        component: GeneralCompanySettings,
        meta: {category:'GeneralCompanySettings', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: PageNotFound,
        meta: {category:'PageNotFound', requiresAuth: true  },
        props: true
    },
    {
        path: "/RateTypeList",
        name: "RateTypeList",
        component: RateTypeList,
        meta: {category:'RateType', requiresAuth: true, requiresAdmin: true  },
        props: true
    },

    {
        path: "/MainBranchList",
        name: "MainBranchList",
        component: MainBranchList,
        meta: {category:'MainBranch', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/MainBranchCreate",
        name: "MainBranchCreate",
        component: MainBranchCreate,
        meta: {category:'MainBranch', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/MainBranchUpdate",
        name: "MainBranchUpdate",
        component: MainBranchUpdate,
        meta: {category:'MainBranch', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/PolicyBranchList",
        name: "PolicyBranchList",
        component: PolicyBranchList,
        meta: {category:'PolicyBranch', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/PolicyBranchCreate",
        name: "PolicyBranchCreate",
        component: PolicyBranchCreate,
        meta: {category:'PolicyBranch', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/PolicyBranchUpdate",
        name: "PolicyBranchUpdate",
        component: PolicyBranchUpdate,
        meta: {category:'PolicyBranch', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/PolicyGroupList",
        name: "PolicyGroupList",
        component: PolicyGroupList,
        meta: {category:'PolicyGroup', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/PolicyGroupCreate",
        name: "PolicyGroupCreate",
        component: PolicyGroupCreate,
        meta: {category:'PolicyGroup', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/PolicyGroupUpdate",
        name: "PolicyGroupUpdate",
        component: PolicyGroupUpdate,
        meta: {category:'PolicyGroup', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/CoverageMainList",
        name: "CoverageMainList",
        component: CoverageMainList,
        meta: {category:'CoverageMain', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/CoverageMainCreate",
        name: "CoverageMainCreate",
        component: CoverageMainCreate,
        meta: {category:'CoverageMain', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CoverageMainUpdate",
        name: "CoverageMainUpdate",
        component: CoverageMainUpdate,
        meta: {category:'CoverageMain', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/CoverageDetailList",
        name: "CoverageDetailList",
        component: CoverageDetailList,
        meta: {category:'CoverageDetail', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/CoverageDetailCreate",
        name: "CoverageDetailCreate",
        component: CoverageDetailCreate,
        meta: {category:'CoverageDetail', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/CoverageDetailUpdate",
        name: "CoverageDetailUpdate",
        component: CoverageDetailUpdate,
        meta: {category:'CoverageDetail', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/FactorTypeDetailList",
        name: "FactorTypeDetailList",
        component: FactorTypeDetailList,
        meta: {category:'FactorTypeDetailList', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/FactorTypeDetailCreate",
        name: "FactorTypeDetailCreate",
        component: FactorTypeDetailCreate,
        meta: {category:'FactorTypeDetailList', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/FactorTypeDetailUpdate",
        name: "FactorTypeDetailUpdate",
        component: FactorTypeDetailUpdate,
        meta: {category:'FactorTypeDetailList', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/EndorsementDetailList",
        name: "EndorsementDetailList",
        component: EndorsementDetailList,
        meta: {category:'EndorsementDetail', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/EndorsementDetailCreate",
        name: "EndorsementDetailCreate",
        component: EndorsementDetailCreate,
        meta: {category:'EndorsementDetail', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/EndorsementDetailUpdate",
        name: "EndorsementDetailUpdate",
        component: EndorsementDetailUpdate,
        meta: {category:'EndorsementDetail', requiresAuth: true, requiresAdmin: true  },
        props: true
    },     
    {
        path: "/SourceCodeList",
        name: "SourceCodeList",
        component: SourceCodeList,
        meta: {category:'SourceCode', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/SourceCodeCreate",
        name: "SourceCodeCreate",
        component: SourceCodeCreate,
        meta: {category:'SourceCode', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/SourceCodeUpdate",
        name: "SourceCodeUpdate",
        component: SourceCodeUpdate,
        meta: {category:'SourceCode', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/SourceCode2List",
        name: "SourceCode2List",
        component: SourceCode2List,
        meta: {category:'SourceCode2', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/SourceCode2Create",
        name: "SourceCode2Create",
        component: SourceCode2Create,
        meta: {category:'SourceCode2', requiresAuth: true, requiresAdmin: true  },
        props: true
    },
    {
        path: "/SourceCode2Update",
        name: "SourceCode2Update",
        component: SourceCode2Update,
        meta: {category:'SourceCode2', requiresAuth: true, requiresAdmin: true  },
        props: true
    }, 
    {
        path: "/VehicleGroupList",
        name: "VehicleGroupList",
        component: VehicleGroupList,
        meta: {category:'VehicleGroup', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/VehicleGroupCreate",
        name: "VehicleGroupCreate",
        component: VehicleGroupCreate,
        meta: {category:'VehicleGroup', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    },
    {
        path: "/VehicleGroupUpdate",
        name: "VehicleGroupUpdate",
        component: VehicleGroupUpdate,
        meta: {category:'VehicleGroup', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    }, 
    {
        path: "/VehicleTypeList",
        name: "VehicleTypeList",
        component: VehicleTypeList,
        meta: {category:'VehicleType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/VehicleTypeCreate",
        name: "VehicleTypeCreate",
        component: VehicleTypeCreate,
        meta: {category:'VehicleType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    },
    {
        path: "/VehicleTypeUpdate",
        name: "VehicleTypeUpdate",
        component: VehicleTypeUpdate,
        meta: {category:'VehicleType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    }, 
    {
        path: "/UsageTypeList",
        name: "UsageTypeList",
        component: UsageTypeList,
        meta: {category:'UsageType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/UsageTypeCreate",
        name: "UsageTypeCreate",
        component: UsageTypeCreate,
        meta: {category:'UsageType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    },
    {
        path: "/UsageTypeUpdate",
        name: "UsageTypeUpdate",
        component: UsageTypeUpdate,
        meta: {category:'UsageType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    }, 
    {
        path: "/UsageDetailList",
        name: "UsageDetailList",
        component: UsageDetailList,
        meta: {category:'UsageDetail', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/UsageDetailCreate",
        name: "UsageDetailCreate",
        component: UsageDetailCreate,
        meta: {category:'UsageDetail', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    },
    {
        path: "/UsageDetailUpdate",
        name: "UsageDetailUpdate",
        component: UsageDetailUpdate,
        meta: {category:'UsageDetail', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    }, 
    {
        path: "/FuelTypeList",
        name: "FuelTypeList",
        component: FuelTypeList,
        meta: {category:'FuelType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/FuelTypeCreate",
        name: "FuelTypeCreate",
        component: FuelTypeCreate,
        meta: {category:'FuelType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    },
    {
        path: "/FuelTypeUpdate",
        name: "FuelTypeUpdate",
        component: FuelTypeUpdate,
        meta: {category:'FuelType', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, },
        props: true
    }, 
    // {
    //     path: "/MainVehicleBrandList",
    //     name: "MainVehicleBrandList",
    //     component: MainVehicleBrandList,
    //     meta: {category:'MainVehicleBrand', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, fullwidth: true  },
    //     props: true
    // },
    // {
    //     path: "/MainVehicleBrandCreate",
    //     name: "MainVehicleBrandCreate",
    //     component: MainVehicleBrandCreate,
    //     meta: {category:'MainVehicleBrand', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
    //     props: true
    // },
    // {
    //     path: "/MainVehicleBrandUpdate",
    //     name: "MainVehicleBrandUpdate",
    //     component: MainVehicleBrandUpdate,
    //     meta: {category:'MainVehicleBrand', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
    //     props: true
    // }, 
    {
        path: "/VehicleBrandList",
        name: "VehicleBrandList",
        component: VehicleBrandList,
        meta: {category:'VehicleBrand', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/VehicleBrandCreate",
        name: "VehicleBrandCreate",
        component: VehicleBrandCreate,
        meta: {category:'VehicleBrand', requiresAuth: true, requiresAdmin: true, },
        props: true
    },
    {
        path: "/VehicleBrandUpdate",
        name: "VehicleBrandUpdate",
        component: VehicleBrandUpdate,
        meta: {category:'VehicleBrand', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/VehicleModelList",
        name: "VehicleModelList",
        component: VehicleModelList,
        meta: {category:'VehicleModel', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/VehicleModelCreate",
        name: "VehicleModelCreate",
        component: VehicleModelCreate,
        meta: {category:'VehicleModel', requiresAuth: true, requiresAdmin: true, },
        props: true
    },
    {
        path: "/VehicleModelUpdate",
        name: "VehicleModelUpdate",
        component: VehicleModelUpdate,
        meta: {category:'VehicleModel', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/PropertyConstructionTypeList",
        name: "PropertyConstructionTypeList",
        component: PropertyConstructionTypeList,
        meta: {category:'PropertyConstructionType', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/PropertyConstructionTypeCreate",
        name: "PropertyConstructionTypeCreate",
        component: PropertyConstructionTypeCreate,
        meta: {category:'PropertyConstructionType', requiresAuth: true, requiresAdmin: true, },
        props: true
    },
    {
        path: "/PropertyConstructionTypeUpdate",
        name: "PropertyConstructionTypeUpdate",
        component: PropertyConstructionTypeUpdate,
        meta: {category:'PropertyConstructionType', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/PropertyConstructionYearList",
        name: "PropertyConstructionYearList",
        component: PropertyConstructionYearList,
        meta: {category:'PropertyConstructionYear', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/PropertyConstructionYearCreate",
        name: "PropertyConstructionYearCreate",
        component: PropertyConstructionYearCreate,
        meta: {category:'PropertyConstructionYear', requiresAuth: true, requiresAdmin: true, },
        props: true
    },
    {
        path: "/PropertyConstructionYearUpdate",
        name: "PropertyConstructionYearUpdate",
        component: PropertyConstructionYearUpdate,
        meta: {category:'PropertyConstructionYear', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/PropertyTypeList",
        name: "PropertyTypeList",
        component: PropertyTypeList,
        meta: {category:'PropertyType', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/PropertyTypeCreate",
        name: "PropertyTypeCreate",
        component: PropertyTypeCreate,
        meta: {category:'PropertyType', requiresAuth: true, requiresAdmin: true, },
        props: true
    },
    {
        path: "/PropertyTypeUpdate",
        name: "PropertyTypeUpdate",
        component: PropertyTypeUpdate,
        meta: {category:'PropertyType', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/PropertyUsageDetailList",
        name: "PropertyUsageDetailList",
        component: PropertyUsageDetailList,
        meta: {category:'PropertyUsageDetail', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/PropertyUsageDetailCreate",
        name: "PropertyUsageDetailCreate",
        component: PropertyUsageDetailCreate,
        meta: {category:'PropertyUsageDetail', requiresAuth: true, requiresAdmin: true, },
        props: true
    },
    {
        path: "/PropertyUsageDetailUpdate",
        name: "PropertyUsageDetailUpdate",
        component: PropertyUsageDetailUpdate,
        meta: {category:'PropertyUsageDetail', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/PropertyUsageTypeList",
        name: "PropertyUsageTypeList",
        component: PropertyUsageTypeList,
        meta: {category:'PropertyUsageType', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/PropertyUsageTypeCreate",
        name: "PropertyUsageTypeCreate",
        component: PropertyUsageTypeCreate,
        meta: {category:'PropertyUsageType', requiresAuth: true, requiresAdmin: true, },
        props: true
    },
    {
        path: "/PropertyUsageTypeUpdate",
        name: "PropertyUsageTypeUpdate",
        component: PropertyUsageTypeUpdate,
        meta: {category:'PropertyUsageType', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/PersonPositionTypeList",
        name: "PersonPositionTypeList",
        component: PersonPositionTypeList,
        meta: {category:'PersonPositionType', requiresAuth: true, requiresAdmin: true, fullwidth: true  },
        props: true
    },
    {
        path: "/PersonPositionTypeCreate",
        name: "PersonPositionTypeCreate",
        component: PersonPositionTypeCreate,
        meta: {category:'PersonPositionType', requiresAuth: true, requiresAdmin: true, },
        props: true
    },
    {
        path: "/PersonPositionTypeUpdate",
        name: "PersonPositionTypeUpdate",
        component: PersonPositionTypeUpdate,
        meta: {category:'PersonPositionType', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/ConsolidationReports",
        name: "ConsolidationReports",
        component: ConsolidationReports,
        meta: {category:'ConsolidationReports', requiresAuth: true, requiresAdmin: true, isSystemAdmin: true, fullwidth: true },
        props: true
    }, 
    {
        path: "/DLLTemplatesList",
        name: "DLLTemplatesList",
        component: DLLTemplatesList,
        meta: {category:'DLLTemplates', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/DLLTemplatesCreate",
        name: "DLLTemplatesCreate",
        component: DLLTemplatesCreate,
        meta: {category:'DLLTemplates', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/DLLTemplatesUpdate",
        name: "DLLTemplatesUpdate",
        component: DLLTemplatesUpdate,
        meta: {category:'DLLTemplates', requiresAuth: true, requiresAdmin: true, },
        props: true
    }, 
    {
        path: "/SystemLogs",
        name: "SystemLogs",
        component: SystemLogsList,
        meta: {category:'SystemLogs', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/MemberLogs",
        name: "MemberLogs",
        component: MemberLogsList,
        meta: {category:'MemberLogs', requiresAuth: true, requiresAdmin: true, fullwidth: true },
        props: true
    },
    {
        path: "/SecuritySettings",
        name: "SecuritySettings",
        component: SecuritySettings,
        meta: {requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/SecuritySettingsCreate",
        name: "SecuritySettingsCreate",
        component: SecuritySettingsCreate,
        meta: {requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
    {
        path: "/SecuritySettingsUpdate",
        name: "SecuritySettingsUpdate",
        component: SecuritySettingsUpdate,
        meta: {requiresAuth: true, requiresAdmin: true, isSystemAdmin: true },
        props: true
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => { 
    if (to.meta.category != from.meta.category){
        sessionStorage.setItem('refreshList', false);
    }
 
    /**
     * Kurallar
     * 1. Admin yetkisi gerektiren ekranlarda Permission kontrolü yapılıyor.
     * 2. Route çizelgesinde Login gerektiren ekranlarda mutlaka Authentication kontrolü yapıyoruz.
     * 3. Deneme yanılmalara ve Route'un fail olma ihtimaline karşı kullanıcıyı her hatada /Login'e atmalıyız.
     */
    var GetAuthStorage =  ls.get('user_' + sessionStorage.getItem('sid'));
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        try{
            if (GetAuthStorage !== null && GetAuthStorage !== '' && GetAuthStorage !== undefined) {
                if (to.matched.some((record) => record.meta.isSystemAdmin)) { 
                    if(GetAuthStorage.account_type == 1 && GetAuthStorage.permission_type == 1)
                    {
                        next();
                        return; 
                    } 
                    else{
                        next("/PermissionDenied");
                    }  
                }
                // Ne zaman girebilir?
                if (to.matched.some((record) => record.meta.requiresAdmin)) { 
                    if(
                        (GetAuthStorage.account_type == 1 && GetAuthStorage.permission_type == 1) ||
                        (GetAuthStorage.account_type == 1 && GetAuthStorage.permission_type == 2) ||
                        (GetAuthStorage.account_type == 2 && GetAuthStorage.permission_type == 1)
                    ){
                        next();
                        return; 
                    } 
                    else{
                        next("/PermissionDenied");
                    }  
                }
                else{
                    next();
                    return; 
                }
            } else {
                sessionStorage.clear();
                router.push({ name: "Login" });
            }
            
        }catch(Err){
            sessionStorage.clear();
            router.push({ name: "Login" });
        }
    } else {
      next();
    }
  });

export default router;
<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Controller Account </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">Home</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerSettings' }">Controller Settings</router-link></li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerAccountList' }">Controller Account</router-link></li>
                    <li class="breadcrumb-item text-gray-600">Create</li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/ControllerAccountList">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                     <span class="ms-2 fs-7"> Return back to List</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="row">
                <div class="tab-content" id="myTabContent">
                    <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                        <div class="p-10"> 
                            <div class="row">
                                <div class="col-lg-6 d-flex flex-column flex-row-auto">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">
                                            <form id="kt_ecommerce_settings_general_form"
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            Controller Company Name                                                     
                                                        </label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                name="name" v-model="form.company_name">
                                                            <span class="input-group-text m-0" id="basic-addon2">
                                                                <i class="fas fa-search fs-4 me-3"></i> Search 
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Company Account ID    
                                                            </label> 
                                                            <input type="text" class="form-control"
                                                                name="companyVkn" v-model="form.company_vkn">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Licence ID</span>
                                                            </label> 
                                                            <input type="number" class="form-control"
                                                                name="licenceId" v-model="form.licence_id">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Country   
                                                            </label>
                                                            <Select2 v-model="form.company_country_code" :options="countryList" @select="onChangeCountry($event)" :settings="{ width: '100%' }"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            Region
                                                        </label>
                                                        <input class="form-control" v-model="form.company_region" placeholder="" name="Region" v-if="form.company_country_code !='GB'">
                                                        <Select2 v-model="form.company_region_select" :options="regionList" :settings="{width: '100%'}" v-if="form.company_country_code == 'GB'" />
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Currency   
                                                            </label>
                                                            <Select2 v-model="form.currency_code" :options="currencyList" :settings="{width: '100%'}"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex flex-column flex-row-auto">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">
                                            <form id="kt_ecommerce_settings_general_form"
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row mb-6 row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>Primary Person</span>
                                                        </label>
                                                        <input type="text" class="form-control" name="primaryPerson" v-model="form.primary_person">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Mobile Phone</span>
                                                            </label>
                                                            <div class="input-group">
                                                                <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                                                    {{form.company_country_code}} {{form.phone_country_code}}
                                                                </span>
                                                                <input type="text" class="form-control" name="mobile_phone" v-model="form.company_gsm">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Land Phone</span>
                                                            </label>
                                                            <div class="input-group">
                                                                <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                                                    {{form.company_country_code}} {{form.phone_country_code}}
                                                                </span>
                                                                <input type="text" class="form-control" name="landPhone" v-model="form.company_landphone">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row fv-plugins-icon-container">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Email</span>
                                                            </label>
                                                            <input type="email" class="form-control" name="email" v-model="form.company_email" :class="{'border border-danger': isValidEmail == false}">
                                                            <small class="text-danger" v-if="!isValidEmail && form.company_email !== null && form.company_email !== '' ">Email is not valid</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-5" for="onlineAccounting">
                                                            Online Accounting
                                                        </label>
                                                        <div class="form-check mt-3 mb-2">
                                                            <input class="form-check-input" type="checkbox" value="" id="onlineAccounting" v-model="form.online_accounting">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-5" for="onlineAccounting">
                                                            Company Online Accounting Default
                                                        </label>
                                                        <div class="form-check mt-3 mb-2">
                                                            <input class="form-check-input" type="checkbox" value="" id="companyOnlineAccountingDefault" v-model="form.company_online_accounting_default">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-5" for="isInsuranceModuleActive">
                                                            Consolidation?
                                                        </label>
                                                        <div class="form-check mt-3 mb-2">
                                                            <input class="form-check-input" type="checkbox" value="" id="isConsolidationActive" v-model="form.is_consolidation_active">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-5" for="isInsuranceModuleActive">
                                                            Insurance Module?
                                                        </label>
                                                        <div class="form-check mt-3 mb-2">
                                                            <input class="form-check-input" type="checkbox" value="" id="isInsuranceModuleActive" v-model="form.is_insurance_module_active">
                                                        </div> 
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">
                                            <form id="kt_ecommerce_settings_general_form"
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row mb-6 row">
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                Post Code
                                                            </label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" name="post_code" v-model="form.company_postcode">
                                                                <button type="button" class="btn btn-secondary" id="basic-addon2">
                                                                    <i class="fas fa-search fs-4 me-3"></i> Search 
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                Address List
                                                            </label>
                                                            <Select2 v-model="form.address_id" :options="addressList" :settings="{ width: '100%' }"/>  
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                Address
                                                            </label>
                                                            <textarea class="form-control" name="address" v-model="form.company_address"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                City
                                                            </label>
                                                            <input type="text" class="form-control" name="city" v-model="form.company_city">
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Address</span>
                                                            </label>
                                                            <textarea class="form-control" name="address" v-model="form.company_address"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>City</span>
                                                            </label>
                                                            <input class="form-control" placeholder="" name="city" v-model="form.company_city"> 
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Town</span>
                                                            </label><input class="form-control" placeholder="" name="town" v-model="form.company_town"> 
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>State</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="city" v-model="form.company_state">
                                                        </div>
                                                    </div> 
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Post Code</span>
                                                            </label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" name="post_code"
                                                                    v-model="form.company_postcode">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                Country 
                                                            </label>
                                                            <Select2 v-model="form.company_country_code" :options="countryList" />
                                                        </div>
                                                    </div> -->
                                                    <!-- <div class="col-lg-6" >
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Region</span>
                                                            </label>
                                                            <input class="form-control" v-if="form.company_country_code !='GB'" placeholder="" name="Region" v-model="form.company_region"> 
                                                            <Select2 v-model="form.company_region_select" :options="regionList" v-if="form.company_country_code =='GB'"/>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </form>
                                        </div>
                                    </div>                    
                                </div>
                                <div class="col-lg-12 mt-0">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-lg btn-success " @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            </svg>
                                            </span>
                                            <strong v-if="!this.isProgressing"> Save Controller Account </strong> 
                                                <span class="indicator-progress" v-if="this.isProgressing">
                                                    Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios' 

// import Swal from 'sweetalert2';

 
export default {
  name: "ControllerAccountCreate",
  data() {
    return {
      isProgressing: false,
      form: {
        address_id: '-1',
        company_name: null,
        company_vkn: null,
        primary_person: null,
        company_gsm: null,
        company_landphone: null,
        company_email: null, 
        company_address: null,
        company_city: null,
        company_town: null,
        company_state: null,
        company_region: null,
        company_region_select: '-1',
        company_postcode: null,
        company_country_code: '-1',
        licence_id: 0,
        currency_code: '-1',
        system_account_id: null,
        online_accounting: false,
        company_online_accounting_default: false,
        phone_country_code: null,
        is_insurance_module_active: 0,
        is_consolidation_active: 0
      },
      regionList: [],
      countryList: [],
      currencyList: [],
      addressList: [{ id: '-1', text: 'Select an option'}],
    };
  },
  computed: {
		isValidEmail() {
			return (this.form.company_email == null || this.form.company_email == '' || this.form.company_email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.company_email);
		}
  },
  methods: {
    getData() { 
        this.$Progress.start();
        this.$setSessionStorage('refreshList', true);
        const parameters = {
            session_id: this.$getUser().session_id,
            get_access_status: 0
        };  
        
        const requestOne =  axios.post('/api/Lists/Currencies', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Lists/GetCountryList', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Lists/GetRegionList', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            this.currencyList = responses[0].data;
            this.countryList = responses[1].data; 
            this.regionList = responses[2].data; 
            this.currencyList.unshift({id: '-1', text: 'Select an option'});
            this.countryList.unshift({id: '-1', text: 'Select an option'});
            this.regionList.unshift({id: '-1', text: 'ALL'});
            this.$Progress.finish();
        }))
        .catch(errors => { 
            console.error(errors); 
            this.$Progress.fail();
        });        
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;
      if (params.company_country_code == '-1') {params.company_country_code = null;}
      if (params.company_region_select == '-1') {params.company_region_select = null;}
      if (params.currency_code == '-1') {params.currency_code = null;}
      if(params.company_country_code == 'GB'){
        params.company_region = params.company_region_select;
      }
      params.session_id = this.$getUser().session_id;
      params.system_account_id = this.$getUser().system_account_id;
      params.online_accounting = params.online_accounting == true ? 1 : 0;
      params.company_online_accounting_default = params.company_online_accounting_default == true ? 1 : 0;
      params.is_insurance_module_active = params.is_insurance_module_active == true ? 1 : 0;
      params.is_consolidation_active = params.is_consolidation_active == true ? 1 : 0;
      axios.post('/api/Accounts/CreateControllerAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){ 
                this.$setSessionStorage("record_id", response.data);
                this.$swal("Success", "Data saved succesfully..!", 'success');    
                this.$setSessionStorage('refreshList', true);  
                this.$router.push({ name: 'ControllerAccountUpdate' });
            } else {
                if(this.form.company_country_code == null) {this.form.company_country_code = '-1';}
                if(this.form.currency_code == null) {this.form.currency_code = '-1';}
                if(this.form.company_region_select == null) {this.form.company_region_select = '-1';}
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$setSessionStorage('refreshList', true); 
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    },
    onChangeCountry(event) {
        this.form.company_region_select = '-1';
        this.form.company_region = null;
        if(event == null || event == '' || event == undefined) {
            this.countryList.forEach(element => {
                if(element.id == this.form.company_country_code) {
                    this.form.phone_country_code = element.phone_country_code;
                }
            });
        } else this.form.phone_country_code = event.phone_country_code;
    },
  },
  created() {
    this.getData();
  },
};
</script>
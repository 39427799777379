<template>
  <div v-cloak>
    <div v-if="$route.matched.some(({ name }) => (name === 'Login' || name === 'Logout' || name === 'ScreenLock' || name === 'ResetPassword' || name === 'ForgotPassword' || name === 'VerifyAccount')) == false">  
      <vue-progress-bar v-if="!isScreenLocked"></vue-progress-bar>
      <nav-header v-show="!isScreenLocked && userData !== null && userData !== '' && userData !== undefined"></nav-header> 
      <div id="kt_content_container" class="d-flex flex-column-fluid align-items-start mt-4" 
        :class="{ 'container-xxl': !$route.matched.some(m => m.meta.fullwidth ), 'px-7 py-2': $route.matched.some(m => m.meta.fullwidth ) }">
        <div class="content flex-row-fluid" id="kt_content"> 
          <div class="preloader vh-100" v-if="show">
              <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                  <span class="visually-hidden">Loading...</span>
              </div>
          </div>
          <router-view  v-show="!isScreenLocked" />
          <screen-lock v-if="isScreenLocked" :inactiveTime="screenLockTime"></screen-lock>
        </div>
      </div>
      <drawer v-if="!isScreenLocked && userData !== null && userData !== '' && userData !== undefined"></drawer>
      <nav-footer :class="{'d-none': isScreenLocked }" v-if="userData !== null && userData !== '' && userData !== undefined"></nav-footer>
    </div>

    <div class="d-flex flex-column flex-root" v-if="!isScreenLocked &&  $route.matched.some(({ name }) => (name === 'Login' || name === 'Logout' || name === 'ScreenLock' || name === 'ResetPassword' || name === 'ForgotPassword' || name === 'VerifyAccount'))">
       <router-view /> 
    </div>
  </div>
</template>   
<script>
import NavHeader from "./components/parts/NavHeader.vue";
// import Toolbar from "./components/parts/Toolbar.vue";
import Drawer from "./components/parts/Drawer.vue";
import NavFooter from "./components/parts/NavFooter.vue";
import ScreenLock from './components/ScreenLock.vue';

export default {
  name: "App",
  components: {
    NavHeader,
    //Toolbar,
    Drawer,
    NavFooter,
    ScreenLock,
  },
  data() {
    return { 
      userData: this.$getUser(),
      slides: [
        {
          title: "El Teide Volcano, Spain",
          content: "Photo by Max Rive",
          image: "assets/theme/media/stock/1600x800/img-1.jpg",
        },
        {
          title: "El Teide Volcano, Spain",
          content: "Photo by Max Rive",
          image: "assets/theme/media/stock/1600x800/img-2.jpg",
        },
      ],
      show: false,
      isScreenLocked: false,
      screenLockTime: this.$getUser().lock_screen_time
    };
  },
  mounted() {
    window.axios = require('axios');
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';      
    window.axios.interceptors.request.use( (config) => {
      if(this.$getUser().session_id !== undefined && this.$getUser().session_id !== null && this.$getUser().session_id !== '') {
        if(this.$getUser().is_locked == 1 && config.url.includes('ContinueToSession') == false && config.url.includes('LockSession') == false){
          return;
        }
      }
      // Bu kısma Axios isteklerinin preloadersız/silent bir şekilde çalışmasını istediğimiz hariç tutulan durumları yazıyoruz.
      var value = true;
      if(config.url.includes('Chatbi')){
        value = false;
      }
      window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
        detail: value
      }));
      return config
    }, function (error) {
      // create and dispatch the event
      window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
        detail: false
      }));
      return Promise.reject(error)
    })  
    window.axios.interceptors.response.use((value) => {
      // create and dispatch the event
      window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
        detail: false
      }));
      return value
    }, function (error) {
      // create and dispatch the event
      window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
        detail: false
      }));
      return Promise.reject(error)
    })
    window.addEventListener('isAxiosRequesting',  (event) => {
      this.show = event.detail;
    })    
    if(this.$getUser().session_id !== null && this.$getUser().session_id !== '' && this.$getUser().session_id !== undefined){     
      if(this.$getUser().is_locked == 1){
        this.isScreenLocked = true;
      }
    }
  }
};
</script>
<style>
.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

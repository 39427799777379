<template>
  <div>   
    <!-- Navigation and Actions -->
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update Company Account</h1>
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CompanySettings' }">Company Settings</router-link></li>  
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CompanyAccountList' }">Company Accounts</router-link></li>  
                <li class="breadcrumb-item text-gray-600">Update</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
          <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3" v-if="isControllerRedirect == 'true'" @click="onRedirectToController()">
              <span class="ms-2 fs-7"> Return back to Controller Account</span>
          </button>
          <router-link to="/CompanyAccountList" v-if="isControllerRedirect !== 'true'">
            <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
               <span class="ms-2 fs-7"> Return back to List</span>
            </button>
          </router-link> 
          &nbsp;
          
          <div class="dropdown">
            <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
            <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
            </svg></span>
                Actions
            </button>
            <!--begin::Menu-->
            <div class="dropdown-menu menu-rounded menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-5 fw-semibold w-250px" :class="{ 'show': this.isOpenActionsDropdown }">
                <div class="menu-item">
                    <div class="menu-content pb-2">
                        <span class="menu-section text-muted text-uppercase fs-7 fw-bold">Actions</span>
                    </div>
                </div>
                <div class="menu-item">
                    <a href="#" class="menu-link" @click="deleteUser(data.id)">
                        <span class="menu-title">Delete Company Account</span>
                    </a>
                </div>
                <div class="menu-item pt-5">
                    <div class="menu-content pb-2">
                        <span class="menu-section text-muted text-uppercase fs-7 fw-bold" v-if="userData.account_type == 1 || userData.account_type == 2 && userData.permission_type == 1">Company Data</span>
                    </div>
                </div>
                <div class="menu-item">
                    <a href="#" class="menu-link" v-if="userData.account_type == 1 || userData.account_type == 2  && userData.permission_type == 1" @click="deleteCompany()">
                        <span class="menu-title">Delete Company And Company's Data
                          <i class="bi bi-exclamation-triangle fs-2x text-warning me-4"></i>
                        </span>
                    </a>
                </div>
             </div>
          </div>  
        </div>
    </div>
    <!-- Content -->
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <!-- <RequiredField></RequiredField> -->
        <!-- Info Card -->
        <div class="card mb-5 mb-xl-10"> 
          <div class="card-body pt-9 pb-0">
            <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
              <div class="me-7 mb-4">
                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  <img v-if="form.logo_url == null || form.logo_url == '' || form.logo_url == undefined" src="assets/theme/media/avatars/blank.png" alt="image" class="img-responsive" style="max-height: 100px; max-width: 100px;"/>
                  <img v-if="form.logo_url !== null && form.logo_url !== '' && form.logo_url !== undefined" :src="form.logo_url" alt="image" class="img-responsive" style="max-height: 100px; max-width: 100px;"/>
                </div>
              </div>
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                      <a href="#" class=" text-gray-900 text-hover-primary fs-2 fw-bolder me-1">
                        {{ data.company_name }}
                      </a>
                      <a href="#">
                        <span class="svg-icon svg-icon-1 svg-icon-primary">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"></path>
                            <path class="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path>
                          </svg>
                        </span>
                      </a> 
                      &nbsp;&nbsp;&nbsp;
                      <a href="#" class=" text-gray-700 text-hover-success fs-2 fw-bolder me-1" v-if="form.current_period">
                        ({{ form.current_period }})
                      </a> 
                    </div>
                    <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                      <a href="#" class=" d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <span class="svg-icon svg-icon-4 me-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="currentColor"></path>
                          <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="currentColor"></path>
                          </svg>
                        </span>   
                        Company Account 
                      </a>
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"> 
                        <span class="svg-icon svg-icon-4 me-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="currentColor"></path>
                            <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="currentColor"></path>
                          </svg>
                        </span>   
                       <span v-if="data.company_region !== '-1' && data.company_region !== '' && data.company_region !== null">{{ data.company_region }}, </span> {{ data.company_country_code }}
                      </a>
                      <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"> 
                        <span class="svg-icon svg-icon-4 me-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                            <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                          </svg>
                        </span> 

                        {{ data.company_email }}
                      </a>
                    </div>  
                  </div> 
                  <!-- <div class="d-flex my-4"> 
                    <button type="button" class="btn btn-light-success border border-success btn-sm me-3" id="kt_activities_toggle_company" @click="this.openTasksTimeLine()">
                      <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M3 7.19995H10C10.6 7.19995 11 6.79995 11 6.19995V3.19995C11 2.59995 10.6 2.19995 10 2.19995H3C2.4 2.19995 2 2.59995 2 3.19995V6.19995C2 6.69995 2.4 7.19995 3 7.19995Z" fill="currentColor"/>
                      <path opacity="0.3" d="M10.1 22H3.10001C2.50001 22 2.10001 21.6 2.10001 21V10C2.10001 9.4 2.50001 9 3.10001 9H10.1C10.7 9 11.1 9.4 11.1 9V20C11.1 21.6 10.7 22 10.1 22ZM13.1 18V21C13.1 21.6 13.5 22 14.1 22H21.1C21.7 22 22.1 21.6 22.1 21V18C22.1 17.4 21.7 17 21.1 17H14.1C13.5 17 13.1 17.4 13.1 18ZM21.1 2H14.1C13.5 2 13.1 2.4 13.1 3V14C13.1 14.6 13.5 15 14.1 15H21.1C21.7 15 22.1 14.6 22.1 14V3C22.1 2.4 21.7 2 21.1 2Z" fill="currentColor"/>
                      </svg></span>
                      View Tasks Timeline
                    </button>
                  </div> -->
                </div>  
                <!-- <div class="d-flex flex-wrap flex-stack"> 
                  <div class="d-flex flex-column flex-grow-1 pe-8"> 
                    <div class="d-flex flex-wrap"> 
                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div class="d-flex align-items-center">
                          <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor"></rect>
                              <path
                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span> 
                          <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">
                            $4,500
                          </div>
                        </div> 
                        <div class="fw-bold fs-6 text-gray-400">Companies</div> 
                      </div> 
                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div class="d-flex align-items-center">
                          <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
                              <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"></path>
                            </svg>
                          </span> 
                          <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="75">
                            75
                          </div>
                        </div> 
                        <div class="fw-bold fs-6 text-gray-400">Users</div> 
                      </div> 
                  
                    </div> 
                  </div> 
                </div>  -->
              </div> 
            </div> 
            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder"> 
               <li class="nav-item mt-2">
                <a class="nav-link ms-0 me-10 py-5" @click="changeView('General')" :class="{ 'text-active-primary active': activeView == 'General' }" href="#">
                  General
                </a>
              </li>   
               <li class="nav-item mt-2">
                <a class="nav-link ms-0 me-10 py-5" @click="changeView('Users')" :class="{ 'text-active-primary active': activeView == 'Users' }" href="#">
                  User Management
                </a>
              </li>   
              <!-- <li class="nav-item mt-2">
                <a class="nav-link ms-0 me-10 py-5" @click="changeView('Tasks')" :class="{ 'text-active-primary active': activeView == 'Tasks' }" href="#">
                  Task Management
                </a>
              </li>  -->
              <li class="nav-item mt-2">
                <a class="nav-link ms-0 me-10 py-5" @click="changeView('Accounting'), this.activeAccountingView = 'Accounting'" :class="{ 'text-active-primary active': activeView == 'Accounting' }" href="#">
                  Accounting
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link ms-0 me-10 py-5" @click="changeView('Liabilities')" :class="{ 'text-active-primary active': activeView == 'Liabilities' }" href="#">
                  Liabilities
                </a>
              </li>
              <li class="nav-item mt-2">
                <a class="nav-link ms-0 me-10 py-5" @click="changeView('MailSettings')" :class="{ 'text-active-primary active': activeView == 'MailSettings' }" href="#">
                  Mail Settings
                </a>
              </li>              
              <li class="nav-item mt-2">
                <a class="nav-link ms-0 me-10 py-5" @click="changeView('Licence')" :class="{ 'text-active-primary active': activeView == 'Licence' }" href="#">
                  Licence
                </a>
              </li>              
            </ul> 
          </div>
        </div> 
        
        <RequiredField v-if="activeView !== 'Tasks' && activeView !== 'Users' && activeView !== 'Liabilities'"></RequiredField>

        <!-- General Tab -->  
        <div class="content flex-row-fluid mt-4" v-if="activeView == 'General'">
            <div class="row">
              <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                  <div class="row p-10">
                      <div class="col-lg-6  d-flex flex-column flex-row-auto">
                          <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                              <div class="card-body pb-0">
                                  <form id="kt_ecommerce_settings_general_form"
                                      class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                      <div class="fv-row mb-7 row">
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                  Controller Account
                                              </label>
                                              <Select2 v-model="form.controller_account_id" :options="controllerAccountList" @select="onSelectControllerAccount($event)" :settings="{width: '100%'}" :disabled="this.authUserAccountType !== 1"/>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                  Company/Account Name
                                              </label>
                                              <div class="input-group">
                                                  <input type="text" class="form-control" placeholder="" name="name" v-model="form.company_name"> 
                                                  <button type="button"
                                                      class="input-group-text m-0 text-hover-success cursor-pointer" 
                                                      id="basic-addon2" 
                                                      @click="onSearchCompanyByName()" 
                                                      data-bs-toggle="modal" 
                                                      data-bs-target="#kt_modal_search_company" 
                                                      data-kt-menu-trigger="click" 
                                                      data-kt-menu-placement="bottom-end"
                                                      :disabled="form.company_name == null || form.company_name == ''">
                                                      <i class="fas fa-search fs-4 me-3"></i> Search by Name
                                                  </button>
                                              </div>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                  Member Type
                                              </label>
                                              <Select2 v-model="form.company_member_type" :options="companyMemberTypeList" :settings="{width: '100%'}" @select="onChangeMemberType()"/>
                                          </div>
                                          <div class="col-lg-12" v-if="this.form.company_member_type == 1">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                  Company Number
                                              </label>
                                              <div class="input-group">
                                                  <input type="text" class="form-control" placeholder="" name="name" v-model="form.company_vkn"> 
                                                  <button type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                          :disabled="form.company_vkn == null || form.company_vkn == ''">
                                                      <i class="fas fa-search fs-4 me-3"></i> Search by No
                                                  </button>
                                              </div>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                Country
                                              </label>
                                              <Select2 v-model="form.company_country_code" :options="countryList" :settings="{width: '100%'}" @select="onChangeCountry($event)" disabled />
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  Region
                                              </label>
                                              <input class="form-control" v-model="form.company_region" placeholder="" name="Region" v-if="form.company_country_code !='GB'">
                                              <Select2 v-model="form.company_region_select" :options="regionList" :settings="{width: '100%'}" v-if="form.company_country_code =='GB'" />
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-6 d-flex flex-column flex-row-auto">
                          <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                              <div class="card-body pb-0">
                                  <form id="kt_ecommerce_settings_general_form"
                                      class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                      <div class="fv-row mb-7 row">
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3" :class="{'required': this.form.company_member_type == 1}">
                                                  Company Type(Capacity)
                                              </label>
                                              <Select2 v-model="form.company_type_id" :options="companyTypes" :settings="{width: '100%'}" @select="onChangeCompanyTypes($event)"/>
                                          </div>
                                          <div class="col-lg-6">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  Sector
                                              </label>
                                              <Select2 v-model="form.sector_id" :options="companySectors" :settings="{width: '100%'}" />
                                          </div>
                                          <div class="col-lg-6">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  Business Type
                                              </label>
                                              <Select2 v-model="form.business_type_id" :options="businessTypes" :settings="{width: '100%'}" />
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  Primary Person
                                              </label>
                                              <input type="text" class="form-control" placeholder="" name="name" v-model="form.primary_person"> 
                                          </div>
                                          <div class="col-lg-6">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  Mobile Phone
                                              </label>
                                              <div class="input-group">
                                                  <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                                      {{form.company_country_code}} {{form.phone_country_code}}
                                                  </span>
                                                  <input type="text" class="form-control" name="name" v-model="form.company_gsm"> 
                                              </div>
                                          </div>
                                          <div class="col-lg-6">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  Land Phone
                                              </label>
                                              <div class="input-group">
                                                <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                                    {{form.company_country_code}} {{form.phone_country_code}}
                                                </span>
                                                <input type="text" class="form-control" name="landPhone" v-model="form.company_landphone">
                                              </div>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  Email
                                              </label>
                                              <input type="text" class="form-control" name="name" v-model="form.company_email" :class="{'border border-danger': isValidEmail == false}"> 
                                              <small class="text-danger" v-if="!isValidEmail && form.company_email !== null && form.company_email !== '' ">Email is not valid</small>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-5" for="isActive">
                                                  Company Account Active/Passive?
                                              </label>
                                              <div class="form-check mt-3 mb-2">
                                                <input class="form-check-input" type="checkbox" value="" id="isActive" v-model="form.is_active">
                                                <small class="text-danger">If this option is checked as passive, the company account cannot be used actively.</small> 
                                              </div> 
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 d-flex flex-column flex-row-auto">
                          <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                              <div class="card-body pb-0">
                                  <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                      <div class="fv-row mb-7 row">
                                          <div class="col-lg-4">
                                            <div class="form-check mt-3 mb-2">
                                              <input class="form-check-input" type="checkbox" value="" id="isQuickMode" v-model="form.is_quick_mode">
                                              <label class="fs-6 fw-bold form-label" for="isQuickMode">
                                                Quick Mode Active/Passive?
                                              </label>
                                            </div> 
                                          </div>
                                          <div class="col-lg-4">
                                            <div class="form-check mt-3 mb-2">
                                              <input class="form-check-input" type="checkbox" value="" id="onlineAccounting" v-model="form.online_accounting" :disabled="form.is_quick_mode == true">
                                              <label class="fs-6 fw-bold form-label" for="onlineAccounting">
                                                Online Accounting Active/Passive?
                                              </label>
                                            </div> 
                                          </div>
                                          <div class="col-lg-4">
                                            <div class="form-check mt-3 mb-2">
                                              <input class="form-check-input" type="checkbox" value="" id="isManualIntegrationActive" v-model="form.is_manual_integration_active" :disabled="form.is_quick_mode == true">
                                              <label class="fs-6 fw-bold form-label" for="isManualIntegrationActive">
                                                Manual Accounting Active/Passive?
                                              </label>
                                            </div> 
                                          </div>
                                          <div class="col-lg-4">
                                            <div class="form-check mt-3 mb-2">
                                              <input class="form-check-input" type="checkbox" value="" id="isConsolidationActive" v-model="form.is_consolidation_active">
                                              <label class="fs-6 fw-bold form-label" for="isConsolidationActive">
                                                Consolidation Active/Passive??
                                              </label>
                                            </div> 
                                          </div>
                                          <div class="col-lg-4">
                                            <div class="form-check mt-3 mb-2">
                                              <input class="form-check-input" type="checkbox" value="" id="isInsuranceModuleActive" v-model="form.is_insurance_module_active">
                                              <label class="fs-6 fw-bold form-label" for="isInsuranceModuleActive">
                                                Insurance Module Active/Passive??
                                              </label>
                                            </div> 
                                          </div>
                                          <div class="col-lg-4">
                                            <div class="form-check mt-3 mb-2">
                                              <input class="form-check-input" type="checkbox" value="" id="isManageUsersByGroups" v-model="form.is_manage_users_by_groups">
                                              <label class="fs-6 fw-bold form-label" for="isManageUsersByGroups">
                                                Manage User Accounts by User Groups?
                                              </label>
                                            </div> 
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                              <div class="card-body pb-0">
                                  <form id="kt_ecommerce_settings_general_form"
                                      class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                      <div class="fv-row mb-7 row">
                                          <div class="col-lg-6">
                                              <div class="fv-row">
                                                  <label class="fs-6 fw-bold form-label mt-3">
                                                    Post Code
                                                  </label>
                                                  <div class="input-group">
                                                      <input type="text" class="form-control" name="post_code" v-model="form.company_postcode">
                                                      <button type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                              id="basic-addon2" 
                                                              @click="this.onFindAddressByPostCode()" 
                                                              data-bs-toggle="modal" 
                                                              data-bs-target="#kt_modal_search_address" 
                                                              data-kt-menu-trigger="click" 
                                                              data-kt-menu-placement="bottom-end"
                                                              :disabled="this.form.company_postcode == null || this.form.company_postcode == ''">
                                                          <i class="fas fa-search fs-4 me-3"></i> Search by Post Code
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-lg-6">
                                              <div class="fv-row">
                                                  <label class="fs-6 fw-bold form-label mt-3">
                                                      Address Code
                                                  </label>
                                                  <input type="text" class="form-control" name="address_code" v-model="form.uprn">
                                              </div>
                                          </div>
                                          <div class="col-lg-12">
                                              <div class="fv-row">
                                                  <label class="fs-6 fw-bold form-label mt-3">
                                                    Address
                                                  </label>
                                                  <textarea class="form-control" name="address" v-model="form.company_address"></textarea>
                                              </div>
                                          </div>
                                          <div class="col-lg-6">
                                              <div class="fv-row">
                                                  <label class="fs-6 fw-bold form-label mt-3">
                                                    City
                                                  </label>
                                                  <input type="text" class="form-control" name="city" v-model="form.company_city">
                                              </div>
                                          </div>
                                          <div class="col-lg-6">
                                              <div class="fv-row">
                                                  <label class="fs-6 fw-bold form-label mt-3 required">District
                                                  </label>
                                                  <input type="text" class="form-control" name="city" v-model="form.company_district">
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="d-grid gap-2">
                              <button type="button" class="btn btn-primary" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                  <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                  <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                  </svg>
                                  </span> 
                                  <strong v-if="!this.isProgressing"> Update Company Account </strong> 
                                      <span class="indicator-progress" v-if="this.isProgressing">
                                          Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span> 
                              </button>
                          </div>                 
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <!-- Users Tab -->  
        <div class="content flex-row-fluid mt-4" v-if="activeView == 'Users'">
          <div class="card no-border">
              <div class="card-body">
                <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fw-bolder">
                    <li class="nav-item mt-2">
                        <a class="nav-link ms-0 me-10 py-5 active" data-bs-toggle="tab" href="#company_users">Company Users</a>
                    </li>
                    <li class="nav-item mt-2">
                        <a class="nav-link ms-0 me-10 py-5" data-bs-toggle="tab" href="#assigned_controller_users" @click="getOperationGroups()">Controller Operation Groups</a>
                    </li>
                    <li class="nav-item mt-2">
                        <a class="nav-link ms-0 me-10 py-5" data-bs-toggle="tab" href="#auth_controller_users" @click="this.getControllerUserList();">Controller Access Users</a>
                    </li>
                </ul>
                
                <div class="tab-content" id="tabContent">
                  <div class="tab-pane fade show active" id="company_users" role="tabpanel">    
                    <CompanyUserList :controller-id="this.form.controller_account_id" :phone-country-code="this.form.phone_country_code"
                      :company-country-code="this.form.company_country_code" />
                  </div>
                  <div class="tab-pane fade" id="assigned_controller_users">
                    <DxDataGrid :show-borders="true" key-expr="id" :data-source="operationGroupList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                        data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Controller-users')"> 
                      <DxFilterRow :visible="true"/>
                      <DxHeaderFilter :visible="true"/>
                      <DxGroupPanel :visible="true"/>      
                      <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                      <DxPaging :page-size="20"/>
                      <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                      <DxSearchPanel :visible="true"/>
                      <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                      <DxColumn data-field="is_added_to_company" caption="" :width="80" :allow-filtering="false" :allow-exporting="false" /> 
                      <DxColumn data-field="name" caption="Group Name"/> 
                      <DxColumn data-field="description" caption="Description" />

                      <template #dataRowTemplate="{ data: data }">
                          <td class="text-gray-800">
                              <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                  <input class="form-check-input" type="checkbox" v-model="data.data.is_added_to_company" @change="onSaveOperationGroup(data.data)"/>
                              </div>
                          </td> 
                          <td class="text-gray-800">
                              <span class="text-gray-800 text-hover-primary mb-1">
                                  {{ data.data.name }}
                              </span>
                          </td>
                          <td class="text-gray-800">
                              <span class="text-gray-800 text-hover-primary mb-1">
                                  {{ data.data.description }}
                              </span>
                          </td>
                      </template>
                    </DxDataGrid>
                  </div>
                  <div class="tab-pane fade" id="auth_controller_users">                    
                    <div class="card-body p-0 mt-4">
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div class="table-responsive min-h-300px">
                            <DxDataGrid :show-borders="true" key-expr="id" :data-source="controllerUserList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                    data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Controller-users')"> 
                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="20"/>
                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                                    <DxColumn data-field="access_granted" :width="80" caption="Access" :allow-filtering="false" /> 
                                    <DxColumn data-field="name_surname" caption="Name Surname"/> 
                                    <DxColumn data-field="user_mail" caption="Email"/>
                                    <DxColumn data-field="phone" caption="Phone"/> 
                                    <DxColumn data-field="user_assignment_name" caption="User Assignment"/> 
                                    <DxColumn data-field="user_description" caption="Description" />

                                    <template #dataRowTemplate="{ data: data }">
                                        <td class="text-gray-800">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                <input class="form-check-input" type="checkbox" v-model="data.data.access_granted" @change="onSaveAuthorization(data.data)"/>
                                            </div>
                                        </td> 
                                        <td class="text-gray-800">
                                            <span class="text-gray-800 fw-bolder text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                <span> {{ data.data.name_surname }} </span>
                                            </span>
                                        </td> 
                                        <td class="text-gray-800">
                                            <span class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                {{ data.data.user_mail }}
                                            </span>
                                        </td>
                                        <td class="text-gray-800">
                                            <span class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                {{ data.data.phone }}
                                            </span>
                                        </td>
                                        <td class="text-gray-800">
                                            <span class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                {{ data.data.user_assignment_name }}
                                            </span>
                                        </td>
                                        <td class="text-gray-800">
                                            <span class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                {{ data.data.user_description }}
                                            </span>
                                        </td>
                                    </template>
                            </DxDataGrid>   
                          </div>
                        </div> 
                    </div>
                  </div>
                </div>
              </div> 
          </div> 
        </div>        

        <!-- Tasks Tab -->
        <div class="card" v-if="activeView == 'Tasks'"> 
            <div class="card-body">
                  <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                      <li class="nav-item">
                          <a class="nav-link" @click="this.activeTaskMgrView = 'Tasks';" :class="{ 'active': this.activeTaskMgrView == 'Tasks' }" href="#">
                            <h4>  
                              Tasks
                            </h4>
                          </a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" @click="this.activeTaskMgrView = 'TaskTypes';" :class="{ 'active': this.activeTaskMgrView == 'TaskTypes' }" href="#">
                            <h4>  
                              Task Types 
                            </h4>
                          </a>
                      </li> 
                  </ul> 
                  <div class="tab-content" id="myTabContent">
                  <div class="card card-xxl-stretch tab-pane fade show active" id="kt_tab_pane_4" role="tabpanel" v-if="this.activeTaskMgrView == 'Tasks'">
                    <div class="row p-10">
                      <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                        <div class="card-body pb-0">
                      <div class="table-responsive">  
                        <div class="d-flex flex-wrap flex-stack p-3 bg-lighten mb-4 border" data-select2-id="select2-data-215-u2a5"> 
                            <div class="d-flex flex-wrap align-items-center my-1">
                              <h5 class="fw-bolder me-5 my-1">
                                {{ tasks.length }} <span class="text-gray-700"> Tasks Found </span>
                                <span class="text-gray-400 fs-6" v-if="this.filteredGroupItem !== null || this.filteredAccountingYearPeriodItem !== null">
                                  (Filtered: {{filteredTaskList.length}})
                                </span> 
                              </h5>
                            </div> 
                            <div class="d-flex flex-wrap my-1">  
                              <div class="d-flex my-0"> 
                                <label class="fw-bold fs-6 mt-2">
                                  Filter:
                                </label>
                                &nbsp;
                                  <select class="form-select form-select-sm mr-5" v-model="filterForm.taskGroupFilterSelection">
                                      <option v-for="item in taskGroups" :value="item" :key="item.id">
                                        {{ item.name }}
                                      </option>
                                  </select>   
                                  &nbsp;
                                    <select class="form-select form-select-sm mr-5" v-model="filterForm.accountYearFilterSelection">
                                      <option v-for="taskFilterAccountingYearItem in companyAccountingPeriodsAll" 
                                              :value="taskFilterAccountingYearItem" 
                                              :key="taskFilterAccountingYearItem.id">
                                          <span v-if="taskFilterAccountingYearItem.id != null">
                                            {{ previewPeriodDate(taskFilterAccountingYearItem.start_date) }} - 
                                            {{ previewPeriodDate(taskFilterAccountingYearItem.end_date) }} 
                                            ({{ taskFilterAccountingYearItem.month_count }} Months)
                                          </span>
                                          <span v-if="taskFilterAccountingYearItem.id == null"> 
                                            {{ taskFilterAccountingYearItem.name }}
                                          </span>
                                      </option>
                                  </select>
                              </div>  
                              &nbsp;
                              &nbsp;
                              <button class="btn btn-light-primary border border-primary btn-sm ml-5" @click="getCompanyTaskTypes(); applyTaskFilter();">
                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor"/>
                                </svg></span>
                                List Tasks
                              </button>
                              &nbsp;
                              <button class="btn btn-secondary btn-sm ml-5" @click="clearTaskFilter()"> 
                                  <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M8.38 22H21C21.2652 22 21.5196 21.8947 21.7071 21.7072C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 20 21 20H10L8.38 22Z" fill="currentColor"/>
                                    <path d="M15.622 15.6219L9.855 21.3879C9.66117 21.582 9.43101 21.7359 9.17766 21.8409C8.92431 21.946 8.65275 22 8.37849 22C8.10424 22 7.83268 21.946 7.57933 21.8409C7.32598 21.7359 7.09582 21.582 6.90199 21.3879L2.612 17.098C2.41797 16.9042 2.26404 16.674 2.15903 16.4207C2.05401 16.1673 1.99997 15.8957 1.99997 15.6215C1.99997 15.3472 2.05401 15.0757 2.15903 14.8224C2.26404 14.569 2.41797 14.3388 2.612 14.145L8.37801 8.37805L15.622 15.6219Z" fill="currentColor"/>
                                    <path opacity="0.3" d="M8.37801 8.37805L14.145 2.61206C14.3388 2.41803 14.569 2.26408 14.8223 2.15906C15.0757 2.05404 15.3473 2 15.6215 2C15.8958 2 16.1673 2.05404 16.4207 2.15906C16.674 2.26408 16.9042 2.41803 17.098 2.61206L21.388 6.90198C21.582 7.0958 21.736 7.326 21.841 7.57935C21.946 7.83269 22 8.10429 22 8.37854C22 8.65279 21.946 8.92426 21.841 9.17761C21.736 9.43096 21.582 9.66116 21.388 9.85498L15.622 15.6219L8.37801 8.37805Z" fill="currentColor"/>
                                    </svg>
                                  </span>
                                  Clear
                              </button>
                            </div> 
                          </div>
                            

                        <table id="kt_widget_table_3" class="table table-row-dashed align-middle fs-7 gy-6 my-0 pb-3 dataTable no-footer table-striped table-hover mt-4 table-rounded border" data-kt-table-widget-3="all">
                          <thead>
                            <tr class="fw-bold fs-6 text-gray-400 border-bottom border-gray-200">
                              <th class="text-center">#</th>
                              <th><small>Task</small></th>
                              <th><small>Time</small></th>
                              <th><small>Group Category</small></th>
                              <th><small>Priority Period</small></th>
                              <th><small>Status</small></th>
                              <th><small>Dates</small></th>
                              <th class="text-center"><small>Actions</small></th>
                            </tr>
                          </thead>
                          <tbody> 
                              <tr class="odd" v-for="(taskItem, index) in filteredTaskList" v-bind:key="taskItem">
                                      <td class="text-center"><small>{{ ((this.page - 1) * this.page_size) + index + 1 }} </small></td>  
                                      <td class="min-w-175px">
                                          <div class="position-relative ps-6 pe-3 py-2">
                                              <div class="position-absolute start-0 top-0 w-4px h-100 rounded-2"
                                                    :style="{ 'background-color': taskItem.color }"></div>
                                              <div class="mb-1 text-dark text-hover-primary fw-bolder">{{ taskItem.name }}</div> 
                                                <div class="d-flex gap-2 mb-2">
                                                  <span class="badge badge-primary badge-circle badge-sm" :style="{ 'background-color': taskItem.color }">
                                                      <i :class="taskItem.icon + ' text-white'"></i>
                                                  </span>
                                                    <small> {{ taskItem.code }} </small>
                                                </div>  
                                          </div>
                                      </td> 
                                        <td> 
                                          <div class="d-flex gap-2 mb-2">
                                              <div class="fs-7 fw-bolder"> <i class="fa fa-flag"></i> Task Date: <span class="text-primary"> {{ previewWeeklyDate(taskItem.task_start_date) }} </span></div>
                                          </div> 
                                          <div class="d-flex gap-2 mb-2">
                                              <div class="fs-7 fw-bolder"> <i class="fa fa-clock"></i> Reminder: <span class="text-info"> {{ previewWeeklyDate(taskItem.task_reminder_date) }} </span></div>
                                          </div> 
                                      </td>
                                        <td class="min-w-125px">  
                                          <div class="fs-7 fw-bolder text-muted text-gray-600">
                                            {{ taskItem.task_group_category_name }} <br>
                                            {{ taskItem.task_group_name }}
                                          </div>
                                      </td>  
                                      <td>
                                          <div class="d-flex gap-2 mb-2">
                                            <span class="badge badge-secondary" v-if="taskItem.period_type == 1">Free</span>
                                            <span class="badge badge-secondary" v-if="taskItem.period_type == 2">Daily</span>
                                            <span class="badge badge-secondary" v-if="taskItem.period_type == 3">Weekly</span> 
                                            <span class="badge badge-secondary" v-if="taskItem.period_type == 4">Monthly</span> 
                                            <span class="badge badge-secondary" v-if="taskItem.period_type == 5">Annual</span> 
                                            <span class="badge badge-secondary" v-if="taskItem.period_type == 6">Periodic</span>  
                                          </div>
                                          <div class="d-flex gap-2 mb-2"> 
                                            <span class="badge badge-primary" v-if="taskItem.priority == 1">Normal</span>
                                            <span class="badge badge-success" v-if="taskItem.priority == 2">Low</span>
                                            <span class="badge badge-warning" v-if="taskItem.priority == 3">High</span> 
                                          </div>
                                      </td> 
                                      <td> 
                                        <div class="mb-2 fw-bolder">
                                          <span class="badge badge-light-primary" v-if="taskItem.status == 0">
                                            <span class="svg-icon svg-icon-1 svg-icon-primary"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M13.341 22H11.341C10.741 22 10.341 21.6 10.341 21V18C10.341 17.4 10.741 17 11.341 17H13.341C13.941 17 14.341 17.4 14.341 18V21C14.341 21.6 13.941 22 13.341 22ZM18.5409 10.7L21.141 9.19997C21.641 8.89997 21.7409 8.29997 21.5409 7.79997L20.5409 6.09997C20.2409 5.59997 19.641 5.49997 19.141 5.69997L16.5409 7.19997C16.0409 7.49997 15.941 8.09997 16.141 8.59997L17.141 10.3C17.441 10.8 18.0409 11 18.5409 10.7ZM8.14096 7.29997L5.54095 5.79997C5.04095 5.49997 4.44096 5.69997 4.14096 6.19997L3.14096 7.89997C2.84096 8.39997 3.04095 8.99997 3.54095 9.29997L6.14096 10.8C6.64096 11.1 7.24095 10.9 7.54095 10.4L8.54095 8.69997C8.74095 8.19997 8.64096 7.49997 8.14096 7.29997Z" fill="currentColor"/>
                                            <path d="M13.3409 7H11.3409C10.7409 7 10.3409 6.6 10.3409 6V3C10.3409 2.4 10.7409 2 11.3409 2H13.3409C13.9409 2 14.3409 2.4 14.3409 3V6C14.3409 6.6 13.9409 7 13.3409 7ZM5.54094 18.2L8.14095 16.7C8.64095 16.4 8.74094 15.8 8.54094 15.3L7.54094 13.6C7.24094 13.1 6.64095 13 6.14095 13.2L3.54094 14.7C3.04094 15 2.94095 15.6 3.14095 16.1L4.14095 17.8C4.44095 18.3 5.04094 18.5 5.54094 18.2ZM21.1409 14.8L18.5409 13.3C18.0409 13 17.4409 13.2 17.1409 13.7L16.1409 15.4C15.8409 15.9 16.0409 16.5 16.5409 16.8L19.1409 18.3C19.6409 18.6 20.2409 18.4 20.5409 17.9L21.5409 16.2C21.7409 15.7 21.6409 15 21.1409 14.8Z" fill="currentColor"/>
                                            </svg></span> &nbsp;
                                              <span class="m-0">Pending</span>
                                          </span> 
                                          <span class="badge badge-light-warning" v-if="taskItem.status == 1">
                                            <span class="svg-icon svg-icon-1 svg-icon-warning"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M18.0002 22H6.00019C5.20019 22 4.8002 21.1 5.2002 20.4L12.0002 12L18.8002 20.4C19.3002 21.1 18.8002 22 18.0002 22Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M18.8002 3.6L12.0002 12L5.20019 3.6C4.70019 3 5.20018 2 6.00018 2H18.0002C18.8002 2 19.3002 2.9 18.8002 3.6Z" fill="currentColor"/>
                                            </svg></span> &nbsp;
                                            <span class="m-0">Delayed</span>
                                          </span> 
                                          <span class="badge badge-light-danger" v-if="taskItem.status == 2">
                                            <span class="svg-icon svg-icon-1 svg-icon-danger"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"/>
                                            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"/>
                                            </svg></span> &nbsp;
                                            <span class="m-0">Cancelled</span>
                                          </span> 
                                          <span class="badge badge-light-success" v-if="taskItem.status == 3">
                                            <span class="svg-icon svg-icon-1 svg-icon-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                            </svg></span> &nbsp;
                                            <span class="m-0">Completed</span>
                                          </span> 
                                        </div> 
                                      </td>
                                      <td class="min-w-150px"> 
                                          <div class="mb-2 fw-bolder">
                                            <small> 
                                              Default Date: {{ previewDate(taskItem.original_start_date) }}
                                            </small>
                                          </div>   
                                          <div class="fs-7 text-muted"><small>Created at: {{ previewDate(taskItem.task_created_at) }}</small></div>
                                      </td>   
                                      <td class="text-center">
                                          <button class="btn btn-sm btn-danger btn-icon" @click="deleteTask(taskItem.task_id)"> 
                                            <span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"/>
                                            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"/>
                                            </svg>
                                            </span>
                                          </button>
                                      </td>
                              </tr> 
                            </tbody>  
                          </table> 
                            <div class="d-flex w-90px align-items-start justify-content-start justify-content-md-start mb-3 mt-5">
                                  <select v-model="this.page_size" class="form-select form-select-sm form-select-solid" @change="this.page = 1">
                                      <option>10</option>
                                      <option>20</option>
                                      <option>50</option>
                                      <option>100</option>
                                      <option>200</option>
                                  </select>
                              </div>
                              <pagination v-model="page" :records="tasks.length" :per-page="this.page_size" @paginate="filteredTaskList"/>  
                          <button class="btn btn-sm btn-light btn-active-warning mt-5 mb-5 nav justify-content-end pull-right" @click="deleteAllTasks()">
                            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                            <rect x="6.0104" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg></span>
                            Remove All Tasks
                          </button>
                      </div> 
                      </div> 
                      </div> 
                    </div>
                  </div>
                  <div class="card card-xxl-stretch tab-pane fade show active" id="kt_tab_pane_5" role="tabpanel" v-if="this.activeTaskMgrView == 'TaskTypes'">
                    <div class="row p-10">
                      <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                        <div class="card-body pb-0">
                          <div class="progress mb-4 mt-4" v-if="this.isTaskManagerRunning">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                              Generating Tasks...
                            </div>
                          </div> 
                        <div class="d-flex flex-wrap flex-stack p-3 bg-lighten mb-4 border" data-select2-id="select2-data-215-u2a5">  
                          <div class="d-flex flex-wrap align-items-center my-1">
                            <h5 class="fw-bolder me-5 my-1">
                              {{ companyRelatedTaskTypes.length }} <span class="text-gray-700"> Task Types Found </span>
                              <span class="text-gray-400 fs-6" v-if="this.ttFilteredGroupItem !== null">
                                (Filtered: {{ ttFilteredTaskList.length }})
                              </span> 
                            </h5>
                          </div> 
                          <div class="d-flex flex-wrap my-1">  
                            <div class="d-flex my-0"> 
                              <label class="fw-bold fs-6 mt-2">
                                Filter:
                              </label>
                              &nbsp;
                                <select class="form-select form-select-sm mr-5" v-model="ttFilterForm.ttTaskGroupFilterSelection">
                                    <option v-for="item in taskGroups" :value="item" :key="item.id">
                                      {{ item.name }}
                                    </option>
                                </select>    
                            </div>  
                            &nbsp;
                            &nbsp;
                            <button class="btn btn-light-primary border border-primary btn-sm ml-5" @click="getCompanyTaskTypes(); applyTaskTypeFilter();">
                              <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor"/>
                              </svg></span>
                              List Task Types
                            </button>
                            &nbsp;
                            <button class="btn btn-secondary btn-sm ml-5" @click="clearTaskTypeFilter()"> 
                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path opacity="0.3" d="M8.38 22H21C21.2652 22 21.5196 21.8947 21.7071 21.7072C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 20 21 20H10L8.38 22Z" fill="currentColor"/>
                                  <path d="M15.622 15.6219L9.855 21.3879C9.66117 21.582 9.43101 21.7359 9.17766 21.8409C8.92431 21.946 8.65275 22 8.37849 22C8.10424 22 7.83268 21.946 7.57933 21.8409C7.32598 21.7359 7.09582 21.582 6.90199 21.3879L2.612 17.098C2.41797 16.9042 2.26404 16.674 2.15903 16.4207C2.05401 16.1673 1.99997 15.8957 1.99997 15.6215C1.99997 15.3472 2.05401 15.0757 2.15903 14.8224C2.26404 14.569 2.41797 14.3388 2.612 14.145L8.37801 8.37805L15.622 15.6219Z" fill="currentColor"/>
                                  <path opacity="0.3" d="M8.37801 8.37805L14.145 2.61206C14.3388 2.41803 14.569 2.26408 14.8223 2.15906C15.0757 2.05404 15.3473 2 15.6215 2C15.8958 2 16.1673 2.05404 16.4207 2.15906C16.674 2.26408 16.9042 2.41803 17.098 2.61206L21.388 6.90198C21.582 7.0958 21.736 7.326 21.841 7.57935C21.946 7.83269 22 8.10429 22 8.37854C22 8.65279 21.946 8.92426 21.841 9.17761C21.736 9.43096 21.582 9.66116 21.388 9.85498L15.622 15.6219L8.37801 8.37805Z" fill="currentColor"/>
                                  </svg>
                                </span>
                                Clear
                            </button>
                          </div> 
                        </div> 
                            <div class="nav justify-content-between border bg-lighten p-3"> 
                              <button type="button" class="btn btn-sm btn-light btn-active-success" data-bs-toggle="modal" data-bs-target="#addtasktypemodal" @click="getTaskTypes()">
                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                        </svg></span>
                                  Add a new Task Type
                              </button>
                              <div class="dropdown">
                                 <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdownForType = !this.isOpenActionsDropdownForType;">
                                      <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                                      <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                                      </svg></span>
                                      Actions
                                  </button>
                                 <ul class="dropdown-menu" :class="{ 'show': this.isOpenActionsDropdownForType }">  
                                      <li><a class="dropdown-item" href="#" @click="removeTaskTypesFromCompany()">
                                        <h5 class="mt-2">
                                          <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                                          <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                                          <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                                          </svg></span>
                                          Remove Task Type
                                        </h5>
                                        </a>
                                      </li> 
                                  </ul>
                              </div> 
                            </div>
                             <div class="table-responsive mh-300 mt-4">
                                  <table class="table table-rounded border gy-5 gs-5 table-row-dashed table-hover table-striped">
                                  <thead>
                                    <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"> 
                                        <th class="sorting" tabindex="0" aria-controls="kt_customers_table" style="width: 1% !important;">#</th> 
                                        <th class="sorting" tabindex="0" aria-controls="kt_customers_table" style="width: 1% !important;">#</th> 
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Code</th>
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Name</th>
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Category<br>Group</th>
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Period Type</th>
                                        <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Priority</th> 
                                        <th class="text-end">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody class="fw-bold text-gray-600">  
                                      <tr v-for="(taskTypeItem, index) in ttFilteredTaskList" v-bind:key="taskTypeItem"> 
                                             <td>
                                                  <div class="form-check form-check-custom form-check-solid">
                                                      <input class="form-check-input" type="checkbox" v-model="taskTypeItem.isSelected"/> 
                                                  </div>
                                              </td>
                                            <td>
                                                {{ index +1 }} 
                                            </td>
                                            <td class="text-gray-600">
                                                <span class="badge badge-primary badge-circle badge-sm" :style="{ 'background-color': taskTypeItem.color }">
                                                    <i :class="taskTypeItem.icon + ' text-white'"></i>
                                                </span>  &nbsp;
                                                <small> {{ taskTypeItem.code }} </small>
                                                <small class="text-gray-400" v-if="taskTypeItem.parent_task_type_code !== '' && taskTypeItem.parent_task_type_code !== null"> 
                                                <br> 
                                                &nbsp; &nbsp; &nbsp; <i class="fas fa-arrow-up mt-2"></i> {{ taskTypeItem.parent_task_type_code }} 
                                                </small>
                                            </td> 
                                            <td class="text-gray-800">
                                                {{ taskTypeItem.name }}
                                            </td> 
                                            <td class="text-gray-600">
                                              <small> {{ taskTypeItem.task_group_category_name }}  </small> <br>
                                              <small> {{ taskTypeItem.task_group_name }} </small>
                                            </td> 
                                             <td>
                                                <span class="badge badge-secondary" v-if="taskTypeItem.period_type == 1">Free Period</span>
                                                <span class="badge badge-secondary" v-if="taskTypeItem.period_type == 2">Daily Period</span>
                                                <span class="badge badge-secondary" v-if="taskTypeItem.period_type == 3">Weekly Period</span> 
                                                <span class="badge badge-secondary" v-if="taskTypeItem.period_type == 4">Monthly Period</span> 
                                                <span class="badge badge-secondary" v-if="taskTypeItem.period_type == 5">Annual Period</span> 
                                                <span class="badge badge-secondary" v-if="taskTypeItem.period_type == 6">Periodic Period</span> 
                                                <br>
                                                <span class="badge badge-primary mt-3" v-if="taskTypeItem.period_type == 1"><small>{{ previewDate(taskTypeItem.period_start) }} - {{ previewDate(taskTypeItem.period_end) }}</small></span>
                                                <span class="badge badge-primary mt-3" v-if="taskTypeItem.period_type == 2"><small>{{ previewHours(taskTypeItem.period_daily_start_time) }} - {{ previewHours(taskTypeItem.period_daily_end_time) }}</small></span>
                                                <span class="badge badge-primary mt-3" v-if="taskTypeItem.period_type == 3">
                                                  <small>Every 
                                                    <span v-if="taskTypeItem.period_weekly_date == 1">Monday,</span>
                                                    <span v-if="taskTypeItem.period_weekly_date == 2">Tuesday,</span>
                                                    <span v-if="taskTypeItem.period_weekly_date == 3">Wednesday,</span>
                                                    <span v-if="taskTypeItem.period_weekly_date == 4">Thursday,</span>
                                                    <span v-if="taskTypeItem.period_weekly_date == 5">Friday,</span>
                                                    <span v-if="taskTypeItem.period_weekly_date == 6">Satuday,</span>
                                                    <span v-if="taskTypeItem.period_weekly_date == 7">Sunday,</span> 
                                                  {{ previewHours(taskTypeItem.period_daily_start_time) }} - {{ previewHours(taskTypeItem.period_daily_end_time) }}
                                                  </small>
                                                </span> 
                                                <span class="badge badge-primary mt-3" v-if="taskTypeItem.period_type == 4"><small>Every Month's {{ taskTypeItem.period_monthly_date }}</small></span> 
                                                <span class="badge badge-primary mt-3" v-if="taskTypeItem.period_type == 5"><small>{{ previewAnnualDate(taskTypeItem.period_annual_date) }}</small></span> 
                                            </td>  
                                             <td>
                                                <span class="badge badge-primary" v-if="taskTypeItem.priority == 1">Normal</span>
                                                <span class="badge badge-success" v-if="taskTypeItem.priority == 2">Low</span>
                                                <span class="badge badge-danger" v-if="taskTypeItem.priority == 3">High</span> 
                                            </td>   
                                            <td>
                                                <button class="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#createtaskmodal" @click="setActiveTask(taskTypeItem)">
                                                  <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                  <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                                                  <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                                                  </svg></span>
                                                    Generate Tasks
                                                </button>  
                                            </td>
                                      </tr> 
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="7"> {{ this.companyRelatedTaskTypes.length }} records </td> 
                                    </tr>
                                  </tfoot>
                                  </table>
                          </div>
                        </div>
                      </div> 
                    </div>
                  </div> 
                  </div> 
            </div> 
        </div>
        <!-- Accounting Tab -->
        <div class="card" v-if="activeView == 'Accounting'">
          <div class="card-body">
            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" target="_self" @click="changeAccountingView('Accounting')" :class="{ 'text-active-primary active': activeAccountingView == 'Accounting' }">
                    <h4>  Accounting </h4>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" target="_self" @click="changeAccountingView('AccountingPeriods')" :class="{ 'text-active-primary active': activeAccountingView == 'AccountingPeriods' }">
                      <h4> Accounting Periods </h4>
                    </a>
                </li> 
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" target="_self" @click="changeAccountingView('AccountingPlans')" :class="{ 'text-active-primary active': activeAccountingView == 'AccountingPlans' }">
                      <h4> Accounting Plans </h4>
                    </a>
                </li> 
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" target="_self" @click="changeAccountingView('VAT')" :class="{ 'text-active-primary active': activeAccountingView == 'VAT' }">
                      <h4> VAT</h4>
                    </a>
                </li> 
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" target="_self" @click="changeAccountingView('Payroll')" :class="{ 'text-active-primary active': activeAccountingView == 'Payroll' }">
                      <h4> Payroll</h4>
                    </a>
                </li>
            </ul> 
            <div class="tab-content">
              <div class="card card-xxl-stretch tab-pane fade show active" v-if="activeAccountingView == 'Accounting'" role="tabpanel">
                <div class="row p-10">
                    <div class=" card card-body bg-lighten border border-gray-300">
                        <div class="d-flex justify-content-end mb-7" v-if="this.form.companyTaxType !== '' && this.form.companyTaxType !== null && this.form.companyTaxType !== undefined">
                          <div class="text-gray-700 fs-7 fw-bold text-end"> Tax Type:
                            <span class="badge badge-light-primary"> {{this.form.companyTaxType}} </span>
                          </div>  
                        </div> 
                        <div class="row mb-7">
                          <div class="col-lg-4"> 
                              <label class="fs-6 fw-bold form-label mb-2">Incorporated In
                                <small v-if="form.incorporated_at == null" class="text-danger ms-3"> Please enter a valid date </small>
                              </label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.incorporated_at" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                          </div>
                          <div class="col-lg-4"> 
                              <label class="fs-6 fw-bold form-label mb-2">Accounting Start Period
                                <small v-if="form.accounting_start_period == null" class="text-danger ms-3"> Please enter a valid date </small>
                              </label>
                              <Datepicker v-model="form.accounting_start_period" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                          </div>
                          <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mb-2">Accounting End Period
                                <small v-if="form.accounting_end_period == null" class="text-danger ms-3"> Please enter a valid date </small>
                              </label>
                              <Datepicker v-model="form.accounting_end_period" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                          </div>
                        </div>                          
                        <div class="row mb-7"> 
                          <div class="col-lg-4"> 
                              <label class="fs-6 fw-bold form-label mb-2 ">Accounting Fee Selection</label>
                              <br>
                              <div class="form-check form-check-inline mt-5">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="monthly" v-model="form.accountingFeeSelection">
                                <label class="form-check-label" for="inlineRadio1">Monthly</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="yearly" v-model="form.accountingFeeSelection">
                                <label class="form-check-label" for="inlineRadio2">Yearly</label>
                              </div>
                          </div>
                          <div class="col-lg-4" v-if="form.accountingFeeSelection == 'monthly'">
                              <label class="fs-6 fw-bold form-label mb-2 ">Accounting Fee (Monthly)</label>
                              <CurrencyInput type="text" step="any" class="form-control text-end" v-model="form.accounting_fee_monthly" @focus="$event.target.select()"/>
                          </div>
                          <div class="col-lg-4" v-if="form.accountingFeeSelection == 'yearly'">
                              <label class="fs-6 fw-bold form-label mb-2 ">Accounting Fee (Yearly)</label>
                              <CurrencyInput type="text" step="any" class="form-control text-end" v-model="form.accounting_fee_yearly" @focus="$event.target.select()"/>
                          </div>  
                          <div class="col-lg-4" v-if="form.accountingFeeSelection == 'monthly'"> 
                              <label class="fs-6 fw-bold form-label mb-2 ">Accounting Fee Monthly Date
                                <small v-if="form.accounting_fee_monthly_date == null" class="text-danger ms-3"> Please enter a valid date </small>
                              </label>
                              <Datepicker v-model="form.accounting_fee_monthly_date" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>
                          </div>
                          <div class="col-lg-4" v-if="form.accountingFeeSelection == 'yearly'">
                              <label class="fs-6 fw-bold form-label mb-2 ">Accounting Fee Yearly Date
                                <small v-if="form.accounting_fee_yearly_date == null" class="text-danger ms-3"> Please enter a valid date </small>
                              </label>
                              <Datepicker v-model="form.accounting_fee_yearly_date" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                          </div>  
                        </div>  
                    </div>
                    <div class=" card card-body bg-lighten border border-gray-300 mt-5">
                        <div class="row mb-7">
                          <div class="col-lg-4"> 
                              <label class="fs-6 fw-bold form-label mb-2">Last Confirmation Statement</label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_confirmation_statement" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                          </div>
                          <div class="col-lg-4"> 
                              <label class="fs-6 fw-bold form-label mb-2">Next Confirmation Statement</label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.next_confirmation_statement" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker> 
                          </div>
                          <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mb-2">Next Confirmation Due Date</label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.next_confirmation_due_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                          </div>
                        </div><div class="row mb-7">
                          <div class="col-lg-4"> 
                              <label class="fs-6 fw-bold form-label mb-2">Last Account Date</label>
                              <div class="input-group">
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_account_date" inputClassName="form-control form-control-lg" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker> 
                                <!-- <button class="btn btn-sm btn-secondary" @click="onSearchCompanyByNo()">Update</button> -->
                                <button type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                        :disabled="form.company_vkn == null || form.company_vkn == ''">
                                    <i class="fas fa-search fs-4 me-3"></i> Update
                                </button>
                              </div>
                              <small v-if="this.form.data_update_date !== null && this.form.data_update_date !== ''">Last Update: {{previewDate(this.form.data_update_date)}} </small>
                          </div>
                          <div class="col-lg-4"> 
                              <label class="fs-6 fw-bold form-label mb-2">Next Account Date</label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.next_account_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker> 
                          </div>
                          <div class="col-lg-4">
                              <label class="fs-6 fw-bold form-label mb-2">Next Account Due Date</label>
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.next_account_due_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker> 
                          </div>
                        </div>
                    </div>
                    <div class=" d-grid gap-2">
                        <button type="submit" href="#" class="btn btn-success mt-5" id="kt_invoice_submit_button" @click="saveData()">
                              <span class="svg-icon svg-icon-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor"></path>
                                <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor"></path>
                                </svg>
                              </span>
                              Save
                        </button>
                      </div>
                </div>
              </div>
              <div class="tab-pane fade show active" v-if="activeAccountingView == 'AccountingPeriods'" role="tabpanel">
                <div class="card"> 
                  <div class="card-header px-3">
                      <div class="card-title"> 
                        <button type="button" class="btn btn-light-primary border border-primary "
                          ref="btnAddItem"
                          data-bs-toggle="modal" 
                          data-bs-target="#kt_modal_add_year" 
                          @click="openAccountingPeriodModal('addItem')"
                          data-kt-menu-trigger="click" 
                          data-kt-menu-placement="bottom-end">

                          <span class="svg-icon svg-icon-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                  viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                      transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                      fill="currentColor">
                                  </rect>
                              </svg>
                          </span> 
                          Add Accounting Periods
                        </button>
                      </div>
                      <div class="card-toolbar">
                        <div class="d-flex justify-content-end">
                          <div class="text-gray-600 fw-bold my-10 text-end"> Online Accounting:                                       
                            <span v-if="form.online_accounting == true" class="badge badge-light-success"> Active </span>
                            <span v-if="form.online_accounting == false" class="badge badge-light-danger"> Passive </span>
                          </div>
                        </div>
                          
                        <div class="d-flex justify-content-end ms-5">
                          <button type="button" class="btn btn-light-primary border border-primary" @click="getRefreshAccountingYears()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <strong v-if="!this.isListProgressing"> List Accounting Periods </strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                          </button>
                        </div> 
                      </div>
                  </div>
                  <div class="card-body p-3">
                    <DxDataGrid :show-borders="true" key-expr="id" :data-source="filteredCompanyAccountingPeriods" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                      data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Accounting-period')"> 
                      <DxFilterRow :visible="true"/>
                      <DxHeaderFilter :visible="true"/>
                      <DxGroupPanel :visible="true"/>      
                      <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                      <DxPaging :page-size="20"/>
                      <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                      <DxSearchPanel :visible="true"/>
                      <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                      <DxColumn data-field="period_year_short" caption="Period"/> 
                      <DxColumn data-field="start_date" caption="Start Date" alignment="right" :allow-filtering="false"/> 
                      <DxColumn data-field="end_date" caption="End Date" alignment="right" :allow-filtering="false"/>
                      <DxColumn data-field="month_count" caption="Total Months" alignment="right" :allow-filtering="false"/> 
                      <DxColumn data-field="is_active" caption="Status" :allow-filtering="false" alignment="center" />
                      <DxColumn data-field="is_online_accounting_name" caption="Online Accounting" :allow-filtering="false" alignment="left"/>
                      <DxColumn data-field="is_vat_active_name" caption="Is VAT Active?" :allow-filtering="false" alignment="left"/>
                      <DxColumn data-field="cost_of_sales_method_name" caption="Cost Of Sales Method" :allow-filtering="false" alignment= "left"/>
                      <!-- <DxColumn data-field="is_automatic_task" caption="Is Automatic Task?" :allow-filtering="false" alignment= "left"/> -->
                      <DxColumn data-field="plan_template_name" caption="Plan Template" :allow-filtering="false" alignment= "left"/>

                      <template #dataRowTemplate="{ data: data }">
                          <td class="text-gray-800">
                              <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                                  <span> {{ data.data.period_year_short }} </span>
                              </a>
                          </td> 
                          <td class="text-gray-800 text-end">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                {{ previewPeriodDate(data.data.start_date) }}
                              </a>
                          </td>
                          <td class="text-gray-800 text-end">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                {{ previewPeriodDate(data.data.end_date) }}
                              </a>
                          </td>
                          <td class="text-gray-800 text-end">
                              <a href="#" class="text-gray-800 text-end text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                  {{ data.data.month_count }}
                              </a>
                          </td>
                          <td class="text-gray-800 text-center">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                  <span v-if="data.data.is_active == 1" class="badge badge-light-success"> Active </span>
                                  <span v-if="data.data.is_active == 0" class="badge badge-light-danger"> Passive </span>
                              </a>
                          </td>
                          <td class="text-gray-800">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                  <span :class="data.data.is_online_accounting == 1 ? 'badge badge-light-success' : 'badge badge-light-danger'">
                                    {{ data.data.is_online_accounting_name }}
                                  </span>
                              </a>
                          </td>
                          <td class="text-gray-800">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                  <span :class="data.data.is_vat_active == 1 ? 'badge badge-light-success' : 'badge badge-light-danger'">
                                    {{ data.data.is_vat_active_name }}
                                  </span>
                              </a>
                          </td>
                          <td class="text-gray-800">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                  <span> {{ data.data.cost_of_sales_method_name }} </span>
                              </a>
                          </td>
                          <!-- <td class="text-gray-800">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                  <span class="badge badge-light-danger" v-if="data.data.is_automatic_task == 0"> No  </span>
                                  <span class="badge badge-light-success" v-if="data.data.is_automatic_task == 1"> Yes  </span>
                              </a>
                          </td> -->
                          <td class="text-gray-800">
                              <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" 
                                  data-kt-menu-placement="bottom-end"  @click="openAccountingPeriodModal('editItem', data.data)">
                                  {{data.data.plan_template_name}}
                              </a>
                          </td>
                      </template>
                    </DxDataGrid>
                  </div> 
                </div>
              </div>
              <div class="tab-pane fade show active" v-if="activeAccountingView == 'AccountingPlans'" role="tabpanel">
                <CompanyPlans />
              </div>
              <div class="card card-xxl-stretch tab-pane fade show active" v-if="activeAccountingView == 'VAT'" role="tabpanel">
                <div class="row p-10">
                  <div class=" card card-body bg-lighten border border-gray-300">                            
                    <div class="row mb-7"> 
                      <div class="col-lg-4 mb-3">
                          <label class="fs-6 fw-bold form-label">Is VAT Account Active?</label> 
                          <br>
                          <div class="form-check form-check-inline mt-3">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="false"
                                  v-model="form.is_vat_active">
                              <label class="form-check-label" for="inlineRadio1">No</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="true"
                              v-model="form.is_vat_active">
                              <label class="form-check-label" for="inlineRadio2">Yes</label>
                          </div>
                      </div>
                      <div class="col-lg-4 mb-3">
                        <label class="fs-6 fw-bold form-label mt-3">VAT Periods</label> 
                        <Select2 v-model="form.vat_period" :options="vatPeriodList" :settings="{width: '100%'}"/>
                      </div>
                    </div>
                    <div class="row mb-7"> 
                      <div class="col-lg-4">
                        <label class="fs-6 fw-bold form-label mt-3 required">
                          VAT Number
                        </label>
                        <input type="text" class="form-control" placeholder="" name="name" v-model="form.company_vat_number"> 
                      </div>
                      <div class="col-lg-4"> 
                          <label class="fs-6 fw-bold form-label mt-3 ">VAT Start Period
                            <small v-if="form.vat_start_period == null" class="text-danger ms-3"> Please enter a valid date </small>
                          </label>
                          <Datepicker v-model="form.vat_start_period" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                      </div>
                      <div class="col-lg-4">
                          <label class="fs-6 fw-bold form-label mt-3 ">VAT End Period
                            <small v-if="form.vat_end_period == null" class="text-danger ms-3"> Please enter a valid date </small>
                          </label>
                          <Datepicker v-model="form.vat_end_period" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                      </div>
                    </div>
                </div>
                <div class=" d-grid gap-2">
                    <button type="submit" href="#" class="btn btn-success mt-5" id="kt_invoice_submit_button" @click="saveData()">
                          <span class="svg-icon svg-icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor"></path>
                            </svg>
                          </span>
                          Save
                    </button>
                  </div>
                </div>
              </div>
              <div class="card card-xxl-stretch tab-pane fade show active" v-if="activeAccountingView == 'Payroll'" role="tabpanel">
                <div class="row p-10">
                  <div class=" card card-body bg-lighten border border-gray-300">                             
                    <div class="row">
                      <div class="col-lg-4"> 
                          <label class="fs-6 fw-bold form-label required">
                            Account Office Reference
                          </label>
                          <input type="text" class="form-control" placeholder="" name="name" v-model="form.account_office_reference"> 
                      </div>
                      <div class="col-lg-4">
                        <label class="fs-6 fw-bold form-label">Payroll Periods</label> 
                        <Select2 v-model="form.payroll_period" :options="payrollPeriodList" :settings="{width: '100%'}"/>
                      </div>
                    </div> 
                  </div>
                  <div class=" d-grid gap-2">
                    <button type="submit" href="#" class="btn btn-success mt-5" id="kt_invoice_submit_button" @click="saveData()">
                          <span class="svg-icon svg-icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor"></path>
                            </svg>
                          </span>
                          Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Liabilities Tab -->
        <div class="card" v-if="activeView == 'Liabilities'">         
          <div class="row p-10">
            <div class="card-header">
              <div class="card-title">
                <button type="button" class="btn btn-sm btn btn-light btn-active-success me-3" :disabled="this.filteredCompanyAccountingPeriods.length == 0"
                  data-bs-toggle="modal" data-bs-target="#add_manual_liability_modal" @click="openLiabilityModal('addItem')">
                    <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                fill="currentColor">
                            </rect>
                        </svg>
                    </span>
                    Add Item
                </button>
                <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3"
                  @click="deleteAllLiabilityItems()" :disabled="this.companyLiabilities.length == 0">
                    <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                        </svg>
                    </span>
                    Remove All (!)
                </button>                              
              </div>
              <div class="card-toolbar">
                <button type="button" class="btn btn-light-info border border-info me-3" data-bs-toggle="modal"
                  data-bs-target="#generateLiabilityModal" @click="this.generateLiabilityModalForm.accounting_period_id = this.form.current_period_id !== '' ? this.form.current_period_id : this.filteredCompanyAccountingPeriods[0].id"
                  :disabled="this.filteredCompanyAccountingPeriods.length == 0">
                    <span class="svg-icon svg-icon-muted svg-icon-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                        <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                      </svg>
                    </span>
                    Generate Liabilities
                </button>
              </div>
            </div>
            <div class="card-body">              
              <div class="card-header p-0">
                <div class="card-title">                
                  <div class="col-lg-12 mw-250px">
                      <label class="fs-6 fw-bold form-label mt-3">Company's Accounting Periods:</label>                      
                      <Select2 v-model="liabilityFilterForm.period_id" :options="companyAccountingPeriodsFilter" @select="getLiabilities()"/>
                  </div>
                  <div class="col-lg-12 mw-250px ms-3">
                      <label class="fs-6 fw-bold form-label mt-3">Liability Group</label> 
                      <Select2 v-model="liabilityFilterForm.liability_group_id" :options="liabilityGroupList" @select="getLiabilities()"/>
                  </div>
                </div>
                <div class="card-toolbar">
                  <button type="button" class="btn btn-light-primary border border-primary me-3" @click="getLiabilities()">
                      <span class="svg-icon svg-icon-muted svg-icon-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                          <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                        </svg>
                      </span>
                      List Liabilities
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="table-responsive">
                        <DxDataGrid :show-borders="true" 
                                key-expr="id" ref="dxLiabilities"
                                :data-source="companyLiabilities" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Controller-users')"
                                @row-updating="onUpdateRow"
                                @row-removing="onDeleteRow"
                                @editing-start="setDecimalValueForEdit">

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="50"/>
                                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                                <DxEditing :allow-updating="true" :allow-deleting="true" mode="row"/>

                                <!-- <DxColumn data-field="isSelected" caption="#" :width="50" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> -->
                                <DxColumn data-field="period_year_short" caption="Period" :width="75" :allowEditing="false" cell-template="period_year_short-cell-template"/> 
                                <DxColumn data-field="liability_type_name" caption="Liability Type" :width="200" :allowEditing="false" cell-template="liability_type_name-cell-template" header-cell-template="liability_type_name-header"/> 
                                <DxColumn data-field="liability_group_name" caption="Liability Group" :width="180" :allowEditing="false" cell-template="liability_group_name-cell-template" header-cell-template="liability_group_name-header"/> 
                                <DxColumn data-field="value_criteria_name" caption="Value Criteria" :width="150" :allowEditing="false" cell-template="value_criteria_name-cell-template" header-cell-template="value_criteria_name-header"/>
                                <DxColumn data-field="at" caption="Starting Date" alignment="right" data-type="date" format="dd.MM.yyyy" cell-template="at-cell-template" header-cell-template="created_date-header"/>
                                <DxColumn data-field="value_date" caption="Value Date" alignment="right" data-type="date" format="dd.MM.yyyy" cell-template="value_date-cell-template" header-cell-template="value_date-header"/>
                                <DxColumn data-field="amount" caption="Amount" :width="80" :editor-options="columnEditorOptions" :format="decimalFormat" data-type="number" cell-template="amount-cell-template" alignment="right">
                                  <DxFormat type="fixedPoint" :precision="2"/>
                                </DxColumn>
                                <DxColumn data-field="description" caption="Description" :width="120" cell-template="description-cell-template"/>
                                <DxColumn data-field="status" caption="Status" :allowEditing="false" cell-template="status-cell-template"/>

                                <!-- Header Templates -->
                                <template #liability_type_name-header>
                                  <div>Liability</div> <div>Type</div>
                                </template>
                                <template #liability_group_name-header>
                                  <div>Liability</div> <div>Group</div>
                                </template>
                                <template #value_criteria_name-header>
                                  <div>Value</div> <div>Criteria</div>
                                </template>
                                <template #created_date-header>
                                  <div>Starting</div> <div>Date</div>
                                </template>
                                <template #value_date-header>
                                  <div>Value</div> <div>Date</div>
                                </template>

                                <!-- Cell Templates -->
                                <!-- <template #is-selected-cell-template="{ data }"> 
                                    <span>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.code !== null && data.data.code !== ''">
                                          <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                        </div>
                                    </span>
                                </template> -->
                                <template #period_year_short-cell-template="{ data }">
                                    <span class="text-gray-900 mb-1">
                                        {{ data.data.period_year_short }}
                                    </span>
                                </template>
                                <template #liability_type_name-cell-template="{ data }">
                                    <span class="text-gray-900 fw-bold mb-1">
                                        {{ data.data.liability_type_name }}
                                    </span>
                                </template>
                                <template #liability_group_name-cell-template="{ data }">
                                    <span class="text-gray-900 fw-bold mb-1">
                                        {{ data.data.liability_group_name }}
                                    </span>
                                </template>
                                <template #value_criteria_name-cell-template="{ data }">
                                    <span class="text-gray-900 fw-bold mb-1">
                                        {{ data.data.value_criteria_name }}
                                    </span>
                                </template>
                                <template #at-cell-template="{ data }">
                                    <span class="text-gray-900 fw-bold mb-1">
                                        {{ previewPeriodDate(data.data.at) }}
                                    </span>
                                </template>
                                <template #value_date-cell-template="{ data }">
                                    <span class="text-gray-900 fw-bold mb-1">
                                        {{ previewPeriodDate(data.data.value_date) }}
                                    </span>
                                </template>
                                <template #amount-cell-template="{ data }">
                                    <span class="text-gray-900 fw-bold mb-1">
                                        {{ data.data.amount }}
                                    </span>
                                </template>
                                <template #description-cell-template="{ data }">
                                    <span class="text-gray-900 fw-bold mb-1">                                          
                                      {{data.data.description}}
                                    </span>
                                </template>
                                <template #status-cell-template="{ data }">
                                    <span class="text-gray-900 fw-bold mb-1">
                                      <span class="badge badge-sm" :class="'badge-' + liabilityStatusColor(data.data.status)">{{liabilityStatusName(data.data.status)}}</span>
                                    </span>
                                </template>
                        </DxDataGrid>
                      </div>
                  </div>      
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mail Settings Tab -->
        <MailSettings v-if="activeView == 'MailSettings'" ref="MailSettings" :mail-type="3" :account-id="form.id"></MailSettings>
        <!-- <div class="card" >         
          <div class="row">
            <div class="p-10">
              <div class="col-lg-12"> 
                <div class="d-flex flex-row row">
                  <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3 required">
                              Sender Mail
                            </label>
                            <input type="text" class="form-control" name="name" v-model="mailSettingsForm.sender" :class="{'border border-danger': isValidEmails == false}">
                            <small class="text-danger" v-if="!isValidEmails && mailSettingsForm.sender !== null && mailSettingsForm.sender !== '' ">Email is not valid</small> 
                          </div>
                          <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3 required">
                              Mail Server (Smtp)
                            </label>
                            <input type="text" class="form-control" name="Smtp" v-model="mailSettingsForm.smtp"> 
                          </div>
                          <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3 required">
                              Port
                            </label>
                            <input type="number" class="form-control" name="port" v-model="mailSettingsForm.port">
                          </div>
                          <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3 required">
                              SSL
                            </label>
                            <div class="form-check mt-3 mb-2">
                                <input class="form-check-input" type="checkbox" value="" id="isSSL" v-model="mailSettingsForm.ssl">
                            </div> 
                          </div>
                        </div> 
                      </div>
                    </div> 
                  </div>
                  <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3 required">
                              Sender Username
                            </label>
                            <input type="text" class="form-control" name="name" v-model="mailSettingsForm.username"> 
                          </div>
                          <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3 required">
                              Sender  Password
                            </label>
                            <div class="position-relative">
                            <input type="password" class="form-control" name="name" v-model="mailSettingsForm.password"> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-5"> 
                <div class="d-grid gap-2"> 
                    <button type="button" class="btn btn-lg btn-primary " @click="this.saveMailSettings()" data-kt-indicator="on" :disabled="isValidEmails !== true">
                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                        </svg>
                        </span>
                          <strong v-if="!this.isProgressing"> Save Mail Settings </strong> 
                            <span class="indicator-progress" v-if="this.isProgressing">
                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                    </button>
                </div> 
              </div>
            </div>
          </div>
        </div> -->
        <!-- Licence Tab -->
        <div class="card" v-if="activeView == 'Licence'"> 
          <div class="card-body">
            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
              <li class="nav-item">
                <a class="nav-link cursor-pointer" target="_self" @click="activeLicenceView = 'RemainingLicenceUses', getRemainingLicenceUses()" :class="{ 'text-active-primary active': activeLicenceView == 'RemainingLicenceUses' }">
                  <h4>Remaining License Uses</h4>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link cursor-pointer" target="_self" @click="activeLicenceView = 'PaymentPlan', getLicencePaymentPlan()" :class="{ 'text-active-primary active': activeLicenceView == 'PaymentPlan' }">
                  <h4>Payment Plan</h4>
                </a>
              </li>
            </ul>            
            <div class="tab-content">
              <div class="tab-pane fade show active" v-if="activeLicenceView == 'PaymentPlan'" role="tabpanel" >
                <div class="card"> 
                  <div class="card-header border-0 p-2 pt-0">
                    <div class="card-title">
                    </div>
                    <div class="card-toolbar">
                      <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                          <button type="button" class="btn btn-light-primary border border-primary" @click="getLicencePaymentPlan()" data-kt-indicator="on">
                              <span class="svg-icon svg-icon-muted">
                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                  <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                  <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                              </span>
                              List Payment Plan
                          </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-2">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="table-responsive min-h-300px">
                          <DxDataGrid :show-borders="true" 
                                  key-expr="id" 
                                  :data-source="licencePaymentPlanList" 
                                  :allow-column-reordering="true"  
                                  :allow-column-resizing="true" 
                                  :column-auto-width="true" 
                                  :row-alternatin-enabled="true"
                                  :hover-state-enabled="true" 
                                  :show-row-lines="true" 
                                  @exporting="this.$onExporting($event , 'Controller-users')">

                                  <DxFilterRow :visible="true"/>
                                  <DxHeaderFilter :visible="true"/>
                                  <DxGroupPanel :visible="true"/>      
                                  <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                  <DxPaging :page-size="100"/>
                                  <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                  <DxSearchPanel :visible="true"/>
                                  <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                                  <DxColumn data-field="description" caption="Plan Name" header-cell-template="description-header" cell-template="description-cell-template"/>
                                  <DxColumn data-field="payment_type_name" caption="Payment Type" header-cell-template="payment_type-header" cell-template="payment_type-cell-template"/>
                                  <DxColumn data-field="payment_method" caption="Payment Method" header-cell-template="payment_method-header" cell-template="payment_method-cell-template"/>
                                  <DxColumn data-field="monthly_price" caption="Price" alignment="right" cell-template="monthly_price-cell-template"/>
                                  <DxColumn data-field="value_date" caption="Value Date" data-type="date" format="dd.MM.yyyy" header-cell-template="value_date-header" cell-template="value_date-template"/> 
                                  <DxColumn data-field="status" caption="Status" alignment="center" cell-template="status-cell-template"/>
                                  <DxColumn data-field="" caption="Actions" alignment="center" cell-template="actions-cell-template"/>

                                  <!-- Header Cell Templates -->
                                  <template #description-header>
                                      <div> Plan </div> <div> Name </div>
                                  </template> 
                                  <template #value_date-header>
                                      <div> Value </div> <div> Date </div>
                                  </template>
                                  <template #payment_method-header>
                                      <div> Payment </div> <div> Method </div>
                                  </template>
                                  <template #payment_type-header>
                                      <div> Payment </div> <div> Type </div>
                                  </template>

                                  <!-- Cell Templates -->
                                  <template #description-cell-template="{ data }">
                                    <span class="fw-bold p-1 align-middle text-gray-900" >
                                      {{ data.data.description }} <span v-if="data.data.user_count > 0">(+{{ data.data.user_count }} User)</span>
                                    </span>
                                  </template>
                                  <template #payment_type-cell-template="{ data }">
                                    <span class="fw-bold p-1 align-middle text-gray-900" >
                                      {{ data.data.payment_type_name }}
                                    </span>
                                  </template> 
                                  <template #monthly_price-cell-template="{ data }">
                                    <span class="fw-bold p-1 align-middle text-gray-900" >
                                      {{ data.data.monthly_price }}
                                    </span>
                                  </template> 
                                  <template #value_date-template="{ data }">
                                    <span class="fw-bolder p-1 align-middle text-gray-900" >
                                      {{ previewPeriodDate(data.data.value_date) }}
                                    </span>
                                  </template>
                                  <template #status-cell-template="{ data }">
                                    <span class="fw-bolder p-1 align-middle" :class="{'text-warning': data.data.status == 0, 'text-success': data.data.status == 1, 'text-danger': data.data.status == 2}">                                      
                                      {{ data.data.status_name }}
                                    </span>
                                  </template> 
                                  <template #payment_method-cell-template="{ data }">
                                    <span class="fw-bold p-1 align-middle text-gray-900" >
                                      {{ data.data.payment_method_name }}
                                    </span>
                                  </template> 
                                  <template #actions-cell-template="{ data }">
                                    <span class="text-center">
                                      <button v-if="data.data.payment_method == 2 && data.data.status == 0" class="btn btn-xs btn-primary" @click="onSetLicenceAsPaid(data.data.id)">Set as Paid</button>
                                      <span v-else>-</span>
                                    </span>
                                  </template> 
                          </DxDataGrid>  
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show active" v-if="activeLicenceView == 'RemainingLicenceUses'" role="tabpanel" >
                <div class="card">
                  <div class="card-body p-2">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-row-bordered gy-3">
                            <tbody>
                              <tr>
                                <td class="fw-bolder">Remaining Days</td>
                                <td>                                    
                                    <div class="row">
                                      <div class="col-lg-5 fv-row fv-plugins-icon-container">
                                        <b>{{licenceForm.period_day}} days left</b>
                                      </div>
                                      <div class="col-lg-7 fv-row fv-plugins-icon-container">
                                        <input type="number" class="form-control form-control-sm" @focus="$event.target.select()" name="add_period_day" v-model="licenceForm.add_period_day">
                                      </div>
                                    </div>
                                </td>
                                <td>
                                  <div class="alert bg-lighten d-flex flex-column flex-sm-row p-3 m-0">
                                      <i class="bi bi-info-circle fs-3 me-4 mb-5 mb-sm-0"></i>
                                      <div class="d-flex flex-column">
                                          <span class="fw-semibold">The entered day value to be added to the remaining days. </span>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder">User Count </td>
                                <td>
                                  <div class="row">
                                    <div class="col-lg-5 fv-row fv-plugins-icon-container">
                                      <b>{{ licenceForm.user_count }} Users</b>
                                    </div>
                                    <div class="col-lg-7 fv-row fv-plugins-icon-container">
                                      <input type="number" class="form-control form-control-sm" @focus="$event.target.select()" name="add_user_count" v-model="licenceForm.add_user_count">
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="alert bg-lighten d-flex flex-column flex-sm-row p-3 m-0">
                                      <i class="bi bi-info-circle fs-3 me-4 mb-5 mb-sm-0"></i>
                                      <div class="d-flex flex-column">
                                          <span class="fw-semibold">The entered day value to be added to the current number of users. </span>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder">Is Company Inquiry Active? / Query Limit</td>
                                <td>
                                  <div class="row">
                                    <div class="col-lg-5 fv-row fv-plugins-icon-container">
                                        <input class="form-check-input mt-3" type="checkbox" id="isCompanyInquiryActive" v-model="licenceForm.is_company_inquiry_active">
                                    </div>
                                    <div class="col-lg-7 fv-row fv-plugins-icon-container">
                                        <input type="number" class="form-control form-control-sm" @focus="$event.target.select()" name="company_inquiry_rate_limit" v-model="licenceForm.company_inquiry_rate_limit">
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="alert bg-lighten d-flex flex-column flex-sm-row p-3 m-0">
                                      <i class="bi bi-info-circle fs-3 me-4 mb-5 mb-sm-0"></i>
                                      <div class="d-flex flex-column">
                                          <span class="fw-semibold">You can change the Company Inquiry status and limit. </span>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder">Is Address Inquiry Active? / Query Limit</td>
                                <td>
                                  <div class="row">
                                    <div class="col-lg-5 fv-row fv-plugins-icon-container">
                                        <input class="form-check-input mt-3" type="checkbox" id="isAddressInquiryActive" v-model="licenceForm.is_address_inquiry_active">
                                    </div>
                                    <div class="col-lg-7 fv-row fv-plugins-icon-container">
                                        <input type="number" class="form-control form-control-sm" @focus="$event.target.select()" name="address_inquiry_rate_limit" v-model="licenceForm.address_inquiry_rate_limit">
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="alert bg-lighten d-flex flex-column flex-sm-row p-3 m-0">
                                      <i class="bi bi-info-circle fs-3 me-4 mb-5 mb-sm-0"></i>
                                      <div class="d-flex flex-column">
                                          <span class="fw-semibold">You can change the Address Inquiry status and limit. </span>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder">Is Documents Included? / Limit</td>
                                <td>
                                  <div class="row">
                                    <div class="col-lg-5 fv-row fv-plugins-icon-container">
                                        <input class="form-check-input mt-3" type="checkbox" id="isDocumentsIncluded" v-model="licenceForm.is_documents_included">
                                    </div>
                                    <div class="col-lg-7 fv-row fv-plugins-icon-container">
                                        <input type="number" class="form-control form-control-sm" @focus="$event.target.select()" name="document_limit" v-model="licenceForm.document_limit">
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="alert bg-lighten d-flex flex-column flex-sm-row p-3 m-0">
                                      <i class="bi bi-info-circle fs-3 me-4 mb-5 mb-sm-0"></i>
                                      <div class="d-flex flex-column">
                                          <span class="fw-semibold">You can change the Document Module status and limit. </span>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder">Is Liabilities Included?</td>
                                <td> <input class="form-check-input mt-3" type="checkbox" id="isLiabilitiesIncluded" v-model="licenceForm.is_liabilities_included">
                                </td>
                                <td>
                                  <div class="alert bg-lighten d-flex flex-column flex-sm-row p-3 m-0">
                                      <i class="bi bi-info-circle fs-3 me-4 mb-5 mb-sm-0"></i>
                                      <div class="d-flex flex-column">
                                          <span class="fw-semibold">You can change the Liabilities Module status. </span>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="fw-bolder">Is Quick Mode?</td>
                                <td> 
                                  <input class="form-check-input mt-3" type="checkbox" id="isQuickMode" v-model="licenceForm.is_quick_mode" disabled>
                                </td>
                                <td>
                                  <div class="alert bg-lighten d-flex flex-column flex-sm-row p-3 m-0">
                                      <i class="bi bi-info-circle fs-3 me-4 mb-5 mb-sm-0"></i>
                                      <div class="d-flex flex-column">
                                          <span class="fw-semibold">Licence Quick Mode status. To activate or deactivate this mode, you can change the <b>Is Quick Mode?</b> selection in the General tab.</span>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-lg-12">                        
                        <div class=" d-grid gap-2">
                          <button type="submit" href="#" class="btn btn-primary mt-5" @click="onUpdateLicenceUses()">
                            <span class="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor"></path>
                              <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor"></path>
                              </svg>
                            </span>
                            Update Licence
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div> 
    <!-- Modal: Add Task Type To Company -->
    <div class="modal fade" tabindex="-1" id="addtasktypemodal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Task Types</h5> 
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div> 
              <div class="modal-body">
                 <div class="d-flex align-items-center position-relative my-1">
                      <span class="svg-icon svg-icon-1 position-absolute ms-6">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                              <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                          </svg>
                      </span>
                      
                      <input type="text" data-kt-customer-table-filter="search" class="form-control w-250px ps-15 form-control-sm" placeholder="Search Types" v-model="searchbar">
                  </div>
                   <div class="table-responsive min-h-300px">
                      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-hover">
                      <thead>
                          <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                              <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label="" style="width: 29.25px;">
                                  <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                      <input class="form-check-input" type="checkbox" data-kt-check="true" value="1">
                                  </div>
                              </th> 
                              <th class="sorting" tabindex="0" style="width: 1% !important;">#</th>
                              <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Code</th>
                              <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Name</th>
                              <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Category/Group</th>
                              <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Priority</th> 
                              <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_customers_table">Period Type</th> 
                        </tr>
                      </thead>
                     <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, index) in taskTypeSearchList" v-bind:key="item">
                            <td>
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="0" v-model="item.isSelected">
                                </div>
                            </td>
                            <td class="text-gray-600">
                                <small> {{ index +1 }}  </small>
                            </td> 
                            <td class="text-gray-600">
                                <span class="badge badge-primary badge-circle badge-sm" :style="{ 'background-color': item.color }">
                                    <i :class="item.icon + ' text-white'"></i>
                                </span>   &nbsp;
                                <small> {{ item.code }} </small>
                            </td> 
                            <td class="text-gray-800">
                                {{ item.amount }}
                            </td> 
                            <td class="text-gray-600">
                                <small> {{ item.task_group_category_name}} </small> <br>
                                <small> {{ item.task_group_name }} </small>
                            </td>
                            <td>
                                <span class="badge badge-primary" v-if="item.priority == 1">Normal</span>
                                <span class="badge badge-success" v-if="item.priority == 2">Low</span>
                                <span class="badge badge-danger" v-if="item.priority == 3">High</span> 
                            </td>  
                            <td>
                                <span class="badge badge-secondary" v-if="item.period_type == 1">Free Period</span>
                                <span class="badge badge-secondary" v-if="item.period_type == 2">Daily Period</span>
                                <span class="badge badge-secondary" v-if="item.period_type == 3">Weekly Period</span> 
                                <span class="badge badge-secondary" v-if="item.period_type == 4">Monthly Period</span> 
                                <span class="badge badge-secondary" v-if="item.period_type == 5">Annual Period</span> 
                                <span class="badge badge-secondary" v-if="item.period_type == 6">Periodic Period</span>
                            </td>   
                        </tr>
                        </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="10"> {{ this.taskTypes.length }} records </td> 
                        </tr>
                      </tfoot>
                      </table>
                  </div>
              </div> 
              <div class="modal-footer"> 
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="addTaskTypes()">Save</button>
              </div>
          </div>
      </div>
    </div>
    <!-- Modal: Generate Task -->
    <div class="modal fade" tabindex="-1" id="createtaskmodal">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Accounting Periods</h5> 
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>

              <div class="modal-body">
                  <div class="fv-row mb-15">
                      <label class="fs-6 fw-bold form-label mb-2">Company's Accounting Periods:</label> 
                      <select class="form-control" v-model="selectedAccountingPeriod">
                          <option v-for="periodModalItem in companyAccountingPeriods" :value="periodModalItem" :key="periodModalItem.id"> 
                              <span v-if="periodModalItem.id != null">
                                  {{ previewPeriodDate(periodModalItem.start_date) }} - 
                                  {{ previewPeriodDate(periodModalItem.end_date) }} 
                                  ({{ periodModalItem.month_count }} Months)
                                </span>
                                <span v-if="periodModalItem.id == null"> 
                                    Select an option
                                </span>
                          </option>
                      </select>
                  </div>
              </div> 
              <div class="modal-footer">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="generateAllTasks(selectedAccountingPeriod)">Select</button>
              </div>
          </div>
      </div>
    </div> 
    <!-- Timeline -->
    <div id="kt_activities" 
      class="bg-body drawer drawer-end drawer-on animated fadeInLeft" 
      v-if="isTimelineDrawerOpen" 
      style="border-left: 3px dashed; border-color: #E4E6EF; box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 20px 20px rgba(0,0,0,0.33);">

      <div class="card rounded-0">
        <div class="card-header" id="kt_activities_header">
            <h3 class="card-title fw-bolder text-dark">
              {{ data.company_name }}'s Tasks
            </h3>
            <div class="card-toolbar">
                <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close" @click="this.closeTasksTimeLine()">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="currentColor" />
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                fill="currentColor" />
                        </svg>
                    </span>
                </button>
            </div>
        </div>

        <div class="card-body position-relative" id="kt_activities_body">
            <div id="kt_activities_scroll" class="position-relative scroll-y me-n5 pe-5" data-kt-scroll="true"
                data-kt-scroll-height="auto" data-kt-scroll-wrappers="#kt_activities_body"
                data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer" data-kt-scroll-offset="5px"> 
                <div class="timeline">
                    <div class="timeline-item" v-for="taskItem in tasks" v-bind:key="taskItem">
                        <div class="timeline-line w-40px"></div>

                        <div class="timeline-icon symbol symbol-circle symbol-40px me-4">
                            <div class="symbol-label" :style="{ 'background-color': taskItem.color }">
                                <span class="svg-icon svg-icon-2 svg-icon-gray-500">
                                    <i :class="taskItem.icon + ' text-white'"></i>
                                </span>
                            </div>
                        </div>

                        <div class="timeline-content mb-10 mt-n1">
                            <div class="pe-3 mb-5 pb-1 shadow-sm"> 
                                <div class="fs-5 fw-bold mb-2">
                                    {{ taskItem.name }} - <small> {{ taskItem.code }} </small> 
                                </div>
                                <div class="fs-5 mb-2" v-if="taskItem.message !== ''">
                                  <div class="alert" :class="{ 'bg-light-primary': taskItem.priority == 1, 'bg-light-warning': taskItem.priority == 2, 'bg-light-danger': taskItem.priority == 3 }">
                                      <p>
                                          {{ taskItem.message }}
                                      </p>
                                  </div>
                                </div> 
                                <i class="fas fa-folder-open"></i> {{ taskItem.task_group_category_name }} 
                                / {{ taskItem.task_group_name }}
                                <br>
                                <span class="badge badge-secondary mt-4" v-if="taskItem.period_type == 1">Free</span>
                                <span class="badge badge-secondary mt-4" v-if="taskItem.period_type == 2">Daily</span>
                                <span class="badge badge-secondary mt-4" v-if="taskItem.period_type == 3">Weekly</span> 
                                <span class="badge badge-secondary mt-4" v-if="taskItem.period_type == 4">Monthly</span> 
                                <span class="badge badge-secondary mt-4" v-if="taskItem.period_type == 5">Annual</span> 
                                <span class="badge badge-secondary mt-4" v-if="taskItem.period_type == 6">Periodic</span> 
                                &nbsp;
                                <span class="badge badge-primary mt-4" v-if="taskItem.priority == 1">Normal</span>
                                <span class="badge badge-success mt-4" v-if="taskItem.priority == 2">Low</span>
                                <span class="badge badge-warning mt-4" v-if="taskItem.priority == 3">High</span> 
                                <br>
                                <div class="d-flex align-items-center mt-4 fs-6">
                                    <div class="text-muted me-2 fs-7">
                                        <div class="d-flex gap-2 mb-2">
                                              <div class="fs-7 fw-bolder"> <i class="fa fa-flag"></i> Starts at: <span class="text-dark"> {{ previewWeeklyDate(taskItem.task_start_date) }} </span></div>
                                          </div> 
                                          <div class="d-flex gap-2 mb-2">
                                              <div class="fs-7 fw-bolder"> <i class="fa fa-clock"></i> Reminder: <span class="text-dark"> {{ previewWeeklyDate(taskItem.task_reminder_date) }} </span></div>
                                          </div> 
                                    </div> 
                                </div> 
                                  <div class="d-flex justify-content-end border border-dashed border-gray-300 rounded px-7 py-3 mb-5" v-if="taskItem.is_have_value">
                                    <h4 class="mt-3" v-if="!taskItem.amount_parameter > 0">
                                        {{ taskItem.amount_value }},00 GBP
                                    </h4> 
                                    <h4 class="mt-3" v-if="taskItem.amount_parameter == 1 && taskItem.period_type == 4">
                                        {{ form.accounting_fee_monthly }} GBP
                                    </h4>
                                    <h4 class="mt-3" v-if="taskItem.amount_parameter == 1 && taskItem.period_type == 5">
                                        {{ form.accounting_fee_yearly }} GBP
                                    </h4>
                                    &nbsp;  &nbsp;
                                    <a href="#" class="btn btn-outline btn-outline-dashed btn-outline-success btn-active-light-success btn-sm"> 
                                      +
                                    </a> 
                                </div>
                            </div> 
                        </div>
                    </div>  
                </div>
            </div>
        </div>

        <div class="card-footer py-5 text-center" id="kt_activities_footer">
            <a href="" class="btn btn-bg-body text-primary">
            View All Tasks 
                <span class="svg-icon svg-icon-3 svg-icon-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                            fill="currentColor" />
                        <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="currentColor" />
                    </svg>
                </span>
            </a>
        </div>
      </div>
    </div>
    <!-- ADD ACCOUNTING YEAR MODAL -->
    <div class="modal fade" id="kt_modal_add_year" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                <div class="modal-header" id="kt_modal_add_user_header">
                    <h2 class="modal-title fw-bolder col-md-11">
                        <span v-if="this.modalType == 'addItem'"> Add </span>
                        <span v-if="this.modalType == 'editItem'"> {{accountingYearModalForm.period_year_short}} </span> Accounting Periods
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary">
                        <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i>  
                    </div>  
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closePeriodModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y pt-0" :class="{'overlay overlay-block': this.isAccountingYearSaving == true}">
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card card-xxl-stretch mb-6">
                                            <div class="card-body p-4">
                                                <div class="row mb-2" v-if="this.modalType == 'addItem'">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                          Year
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Datepicker :textInputOptions="{ format: 'yyyy' }" v-maska="'####'" v-model="accountingYearModalForm.year" yearPicker autoApply :clearable="false"></Datepicker>  
                                                    </div>
                                                </div>
                                                <div class="row mb-2" v-if="this.modalType == 'addItem' && this.filteredCompanyAccountingPeriods.length > 0">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        Accounting Plan Option
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                      <Select2 v-model="accountingYearModalForm.account_plan_option"
                                                        :options="planOptionsList" :settings="{ width: '100%' }"/>
                                                    </div>
                                                </div>
                                                <div class="row mb-2" v-if="accountingYearModalForm.account_plan_option == 2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        Accounting Plan Templates
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                      <Select2 v-model="accountingYearModalForm.plan_template_id" :options="planTemplateList" :settings="{ width: '100%' }"/>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                          ‘Cost Of Sales’ Method
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                      <Select2 v-model="accountingYearModalForm.cost_of_sales_method" :options="costOfSalesMethodList" 
                                                        :disabled="accountingYearModalForm.cost_of_sales_method_status == true"  :settings="{ width: '100%' }"/> 
                                                        <small class="ps-1 text-gray-600" v-if="accountingYearModalForm.cost_of_sales_method_status == true"> Accounting period has transactions and cannot be changed..! </small>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                          Automatic Task
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                      <Select2 v-model="accountingYearModalForm.is_automatic_task" :options="automaticTaskOptionList" :settings="{ width: '100%' }"/> 
                                                    </div>
                                                </div> -->
                                                <div class="row mb-2" v-if="this.modalType == 'editItem'">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                          Active
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                      <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="accountingYearModalForm.is_active">
                                                    </div>
                                                </div>
                                                <div class="row mb-2" v-if="this.modalType == 'editItem'">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                          Online Accounting
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                      <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="accountingYearModalForm.is_online_accounting">
                                                    </div>
                                                </div>
                                                <div class="row mb-2" v-if="this.modalType == 'editItem'">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        Is VAT Active?
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                      <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="accountingYearModalForm.is_vat_active">
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'" 
                                            @click="this.addAccountingPeriod()">
                                            <strong>Save</strong> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'" 
                                            @click="this.updateAccountingPeriod()">
                                            <strong>Update</strong> 
                                        </button>
                                        <button type="button" class="btn btn-danger float-start btn-lg" v-if="this.modalType == 'editItem'" 
                                            @click="this.removeAccountingPeriod(this.accountingYearModalForm.id)">
                                            <strong>Delete</strong> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isAccountingYearSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: Liability -->
    <div class="modal fade" aria-hidden="true" style="display: none;" id="add_manual_liability_modal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-lg">
          <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-bolder col-md-11">
                    <span class="fw-bolder"> Add Liability </span>
                </h3>
                <div class="btn btn-icon btn-sm btn-active-icon-primary">
                    <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i>   
                </div>     
                <div class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeManualLiabilityModal">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
              </div>
              <div class="modal-body" :class="{'overlay overlay-block': this.isLiabilitySaving == true}">
                <div class="row">
                  
                  <div class="col-lg-4">
                      <label class="fs-6 fw-bold form-label mt-3">Company's Accounting Periods:</label>                      
                      <Select2 v-model="manuelLiabilityForm.period_id" :options="filteredCompanyAccountingPeriods" :settings="{ width: '100%', dropdownParent: '#add_manual_liability_modal .modal-content' }"/>
                  </div>
                  <div class="col-lg-4">
                      <label class="fs-6 fw-bold form-label mt-3">Liability Type</label> 
                      <Select2 v-model="manuelLiabilityForm.liability_id" :options="liabilityTypeList" @select="onChangeLiabilityType($event)" :settings="{ width: '100%', dropdownParent: '#add_manual_liability_modal .modal-content' }"/>
                  </div>
                  <div class="col-lg-4">
                      <label class="fs-6 fw-bold form-label mt-4"> Period Month </label>
                      <CurrencyInput type="text" class="form-control" name="name" :precision="0" @focus="$event.target.select()"  v-model="manuelLiabilityForm.period_month"/>

                  </div>
                  <div class="col-lg-4">
                      <label class="fs-6 fw-bold form-label mt-3">Starting Date</label> 
                      <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="manuelLiabilityForm.created_date" 
                        inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                  </div>
                  
                  <div class="col-lg-4">
                      <label class="fs-6 fw-bold form-label mt-3">Value Date</label> 
                      <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="manuelLiabilityForm.value_date"
                        inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                  </div>
                  <div class="col-lg-4" v-if="this.manuelLiabilityForm.amount_type == 1 || this.manuelLiabilityForm.amount_type == 2">
                      <label class="fs-6 fw-bold form-label mt-3">Amount</label>
                      <CurrencyInput type="text" class="form-control text-end" name="amount_local" v-model="manuelLiabilityForm.amount" @focus="$event.target.select()"/>
                  </div>
                  <div class="col-lg-6" v-if="this.manuelLiabilityForm.amount_type == 1 || this.manuelLiabilityForm.amount_type == 2">
                      <label class="fw-bold form-label mt-3">
                          <span>Amount Type</span>
                      </label>
                      <Select2 v-model="manuelLiabilityForm.amount_type" :options="amountTypeList" :settings="{ width: '100%', dropdownParent: '#add_manual_liability_modal .modal-content' }"/>
                  </div>
                  <div class="col-lg-12">
                      <label class="fs-6 fw-bold form-label mt-3">Description</label> 
                      <textarea rows="3" class="form-control" name="address" v-model="manuelLiabilityForm.description"></textarea>
                  </div>
                  <div class="col-lg-12">
                      <div class="mt-3 alert alert-primary d-flex align-items-center p-3 mb-0">
                          <span class="svg-icon svg-icon-primary svg-icon-1 me-2">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                  <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                  <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                              </svg>
                          </span>
                          <div class="d-flex flex-column pe-0 pe-sm-10">
                              <span> Periodic <b> {{manuelLiabilityForm.period_month}} </b> liabilities will be created. </span>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="col-lg-12">
                    <div class="card card-flush mt-3" id="kt_contacts_list">
                        <div class="d-grid gap-2"> 
                            <button type="button" class="btn btn-success btn-lg" @click="this.addManuelLiability()">
                              <span class="indicator-label">Add Liability</span> 
                            </button>
                        </div>
                    </div>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isLiabilitySaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>  
              </div>
          </div>
      </div>
    </div> 

    <!-- Modal: Generate Liability -->
    <div class="modal fade" aria-hidden="true" style="display: none;" id="generateLiabilityModal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Generate Liabilities</h5> 
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeLiabilityGenerateModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>

              <div class="modal-body">
                  <div class="fv-row">
                      <label class="fs-6 fw-bold form-label mt-3">Company's Accounting Periods:</label>                      
                      <Select2 v-model="generateLiabilityModalForm.accounting_period_id" :options="filteredCompanyAccountingPeriods" :settings="{width: '100%'}"/>
                  </div>
                  <div class="fv-row">
                      <label class="fs-6 fw-bold form-label mt-3">Liability Group</label> 
                      <Select2 v-model="generateLiabilityModalForm.liability_group_id" :options="liabilityGroupList" :settings="{width: '100%'}"/>
                  </div>
              </div> 
              <div class="modal-footer">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-success" @click="generateLiabilities()">Generate</button>
              </div>
          </div>
      </div>
    </div> 
    
    <!-- SEARCH COMPANY BY NAME RESULT -->
    <div class="modal fade" id="kt_modal_search_company" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <SearchCompanyByName ref="SearchCompanyByName" :account-name="this.form.company_name"></SearchCompanyByName>
    </div>

    <!-- SEARCH ADDRESS BY POSTCODE RESULT -->
    <div class="modal fade" id="kt_modal_search_address" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <FindAddressByPostCode ref="FindAddressByPostCode" :post-code="this.form.company_postcode"></FindAddressByPostCode>
    </div>
  </div>
</template> 
<script>
import axios from 'axios';
  

import moment from 'moment';
// import Swal from 'sweetalert2';
import CompanyPlans from '../../CompanyAccountingPlans/PlanList.vue';
import CompanyUserList from '../../Users/CompanyUserList.vue';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling, DxEditing, DxFormat } from 'devextreme-vue/data-grid';
import SearchCompanyByName from '../Modals/SearchCompanyByName.vue';
import FindAddressByPostCode from '../Modals/FindAddressByPostCode.vue';
import MailSettings from '../Modals/MailSettings.vue';
 
export default {
  name: "CompanyAccountUpdate", 
  components:{
    CompanyPlans, DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, CompanyUserList, SearchCompanyByName, FindAddressByPostCode, MailSettings, DxScrolling, DxEditing, DxFormat
  },
  data() {
    return {
      userData: this.$getUser(),
      isOpenActionsDropdown: false,
      isOpenPeriodActionsDropdown: false,
      isOpenActionsDropdownForType: false,
      isProgressing: false,
      lists: [],
      companyTypes: [],
      businessTypes: [],
      companySectors: [],
      data: {},
      activeView: "General", 
      activeAccountingView: 'Accounting',
      activeLicenceView: 'RemainingLicenceUses',
      taskTypes: [],
      addressList: [{id: '-1', text: 'Select an option'}],
      searchbar: '',
      modalType: 'addItem',
      costOfSalesMethodList: [
        { id: 1, text: 'First In First Out'},
        { id: 2, text: 'Moving Weighted Average Cost'}, /* hareketli ağırlıklı ortalama maliyet */
      ],
      isHide: true,
      form: { 
        id: null,
        company_type_id: '-1',
        company_member_type: 1,
        business_type_id: '-1',
        sector_id: '-1',
        address_id: '-1',
        company_country_code: '-1',
        company_region_select: '-1',
        company_name: null,
        primary_person: null,
        company_gsm: null,
        company_landphone: null,
        company_email: null,
        company_address: null,
        company_city: null,
        company_town: null,
        company_state: null,
        company_region: null,
        company_country_name: null,
        postcode: null,
        company_vkn: null,
        incorporated_at: new Date(),
        accounting_start_period: new Date(),
        accounting_end_period: new Date(),
        vat_start_period: new Date(),
        vat_end_period: new Date(),
        accounting_fee_monthly: 0.00,
        accounting_fee_yearly: 0.00,
        account_office_reference: null,
        company_vat_number: null,
        online_accounting: false,
        is_active: false,
        is_quick_mode: false,
        is_vat_active: 'false',
        is_automatic_task: false,
        vat_period: 1,
        companyTaxType: '',
        payroll_period: 1,
        accounting_fee_monthly_date: new Date(),
        accounting_fee_yearly_date: new Date(),
        accountingFeeSelection: 'monthly',
        accounting_fee_period: 1,
        phone_country_code: null,
        company_district: null,
        x_coordinate: 0,
        y_coordinate: 0,
        uprn: null,
        udprn: null,
        last_confirmation_statement: null,
        next_confirmation_statement: null,
        next_confirmation_due_date: null,
        last_account_date: null,
        next_account_date: null,
        next_account_due_date: null,
        official_company_type: null,
        data_update_date: null,
        is_manual_integration_active: false,
        logo_url: null,
        is_insurance_module_active: 0,
        is_consolidation_active: 0,
        is_manage_users_by_groups: 0
      },
      countryList: [],
      regionList: [],
      selectedTaskTypes: [],
      companyRelatedTaskTypes: [],
      accountingYearModalForm: {
        year: moment().year(),
        month: 12,
        cost_of_sales_method: 1,
        is_online_accounting: false,
        is_active: false,
        is_vat_active: false,
        cost_of_sales_method_status: false,
        plan_template_id: '-1',
        is_automatic_task: 0,
        account_plan_option: 1
      },
      companyAccountingPeriods: [],
      companyAccountingPeriodsAll: [],
      activeTaskType: null,
      tasks: [],
      taskGroups: [],
      filterForm: {
        taskGroupFilterSelection: {id: null, text: null, name: null},
        accountYearFilterSelection: {id: null, text: null, name: null}
      },
      filteredGroupItem: null,
      filteredAccountingYearPeriodItem: null,
      isTimelineDrawerOpen: false,
      ttFilterForm: {
        ttTaskGroupFilterSelection: null
      },
      ttFilteredGroupItem: null,
      activeTaskMgrView: 'Tasks',
      isTaskManagerRunning: false,
      page_size: 10,
      page: 1,
      controllerAccountList: [],
      authUserAccountType: null,
      controllerUserList: [],
      isAccountingYearSaving: false,
      planTemplateList: [],
      planOptionsList: [{id: 1, text: 'From the previous year'}, {id: 2, text: 'From the template'}],
      manuelLiabilityForm: {
        period_id: '-1',
        liability_id: '-1',
        created_date: moment(),
        value_date: moment(),
        amount: 0.00,
        description: null,
        value_criteria: 0,
        payment_day_option: 0,
        period_month: 1,
        amount_type: 0
      },
      generateLiabilityModalForm: {
        accounting_period_id: '-1',
        liability_group_id: '-1',
      },
      liabilityFilterForm: {
        period_id: '-1',
        liability_group_id: '-1',
      },
      liabilityTypeList: [],
      liabilityGroupList: [{id: '-1', text: 'ALL'}],
      automaticTaskOptionList: [
        {id: 0, text: 'No'},
        {id: 1, text: 'Yes'},
      ],
      companyLiabilities: [],
      vatPeriodList: [ {id: 1, text: 'Monthly'}, {id: 2, text: 'Periodic'}, {id: 3, text: 'Yearly'}],
      payrollPeriodList: [ {id: 1, text: 'Monthly'}, {id: 2, text: 'Periodic'}],
      monthDays: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      valueCriteriaList: [
        {id: 0, text: 'No Criteria'},
        {id: 1, text: 'Incorporated In (Day/Month)'}, 
        {id: 2, text: 'Accounting Start Period (Day/Month)'}, 
        {id: 3, text: 'Accounting End Period (Day/Month)'}, 
        {id: 4, text: 'Accounting Fee (Day/Month)'}, 
        {id: 5, text: 'VAT Start Period (Day/Month)'}, 
        {id: 6, text: 'VAT End Period (Day/Month)'}, 
        {id: 7, text: 'Payroll'}
      ],
      isLiabilitySaving: false,
      companyAccountingPeriodsFilter: [],
      operationGroupList: [],
      amountTypeList: [
        {id: 0, text: 'No Amount'},
        {id: 1, text: 'Payment'},
        {id: 2, text: 'Collection'}, 
      ],
      statusList: [
          {id: 0, text: 'Awaiting', color: 'secondary'},
          {id: 1, text: 'Pending', color: 'warning'},
          {id: 2, text: 'Calculated', color: 'info'},
          {id: 3, text: 'Paid', color: 'primary'},
          {id: 4, text: 'Not Paid', color: 'light-danger'},
          {id: 5, text: 'Completed', color: 'success'},
          {id: 6, text: 'Hidden', color: 'dark'},
          {id: 7, text: 'Canceled', color: 'danger'},
      ],
      mailSettingsForm: { 
        id: null,
        username: null,
        password: null,
        sender: null,
        smtp: null,
        port: 0,
        ssl: false
      },
      licencePaymentPlanList: [],      
      licenceForm: {
        add_user_count: 0,
        add_period_day: 0,
        user_count: 0,
        period_day: 0,
        is_documents_included: 0,
        is_liabilities_included: 0,
        isLiabilitiesIncluded: false,
        isDocumentsIncluded: false,
        document_limit: 0,
        isCompanyInquiryActive: false,
        isAddressInquiryActive: false,
        is_company_inquiry_active: 0,
        company_inquiry_rate_limit: 0,
        is_address_inquiry_active: 0,
        address_inquiry_rate_limit: 0,
        is_quick_mode: 0
      },
			companyMemberTypeList: [
				{id: 1, text: 'Company'},
				{id: 2, text: 'Person'},
			],
      isControllerRedirect: this.$route.params.isRedirect,
      columnEditorOptions: { format: "#,##0.00", precision: 2 },
      decimalFormat: "#,##0.00",
    };
  },
  computed: {
    isValidEmail() {
			return (this.form.company_email == null || this.form.company_email == '' || this.form.company_email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.company_email);
		},
    isValidEmails() {
      return (this.mailSettingsForm.sender == null || this.mailSettingsForm.sender == '' || this.mailSettingsForm.sender == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.mailSettingsForm.sender);
		},
    taskTypeSearchList() {
      return this.taskTypes.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1 && !p.isAdded;
      });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    previewWeeklyDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm, ddd')
        }
      }
    },
    previewPeriodDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    previewLongDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('MMMM Do, YYYY')
        }
      }
    },   
    previewAnnualDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('MMM Do')
        }
      }
    }, 
    previewHours(){
       return (value)=>{
       if (value) {
          return moment(new Date(value)).format('HH:mm')
        }
      }
    },
    filteredTaskList() { 
      return this.tasks.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    ttFilteredTaskList() {
      var ttFilteredGroupItem = this.ttFilteredGroupItem;
      if(ttFilteredGroupItem !== null){ 
        return this.companyRelatedTaskTypes.filter(function(p) {  
          return ttFilteredGroupItem.indexOf(p.task_group_id) != -1;
        })
      }  
      return this.companyRelatedTaskTypes;
    },
    filteredCompanyAccountingPeriods(){
        return this.companyAccountingPeriods.filter(p => { 
            return p.id != '-1';
        });
    },
    liabilityStatusName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.statusList.find(element => {
            return element.id == value
          }).text;
        }
      }
    },
    liabilityStatusColor(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.statusList.find(element => {
            return element.id == value
          }).color;
        }
      }
    },
  },
  methods: {
    applyTaskFilter(){  
      this.filteredGroupItem = this.filterForm.taskGroupFilterSelection.id; 
      this.filteredAccountingYearPeriodItem = this.filterForm.accountYearFilterSelection.id;  
      //this.getTasks();
    },
    clearTaskFilter(){  
      this.filteredGroupItem = null; 
      this.filterForm.taskGroupFilterSelection = this.taskGroups[0];
      this.filteredAccountingYearPeriodItem = null;  
      this.filterForm.accountYearFilterSelection = this.companyAccountingPeriodsAll[0];
    },
    applyTaskTypeFilter(){  
      this.ttFilteredGroupItem = this.ttFilterForm.ttTaskGroupFilterSelection.id; 
    },
    clearTaskTypeFilter(){  
      this.ttFilteredGroupItem = null; 
      this.ttFilterForm.ttTaskGroupFilterSelection = this.taskGroups[0];
    },
    getData() {
        // this.$removeSessionStorage('data'+ '_CCPTM01'); 
        // this.$removeSessionStorage('data'+ '_CACTP01');
        this.$removeSessionStorage('active_period');
        this.lists = []; 
        this.searchbar = '';
        this.authUserAccountType = this.$getUser().account_type;
        this.$Progress.start();
        this.$setSessionStorage('refreshList', true);  
        const queryParameter = {
            id: this.$getSessionStorage("cp_record_id"),
            session_id: this.$getUser().session_id,
        };
        axios.post('/api/Accounts/GetAccountById', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {   
          response.data.controller_id = this.$unlock(response.data.controller_id);
          response.data.company_name = this.$unlock(response.data.company_name);
          response.data.company_vkn = this.$unlock(response.data.company_vkn);
          response.data.primary_person = this.$unlock(response.data.primary_person);
          response.data.company_gsm = this.$unlock(response.data.company_gsm);
          response.data.company_landphone = this.$unlock(response.data.company_landphone);
          response.data.company_email = this.$unlock(response.data.company_email);
          response.data.company_address = this.$unlock(response.data.company_address);
          response.data.company_city = this.$unlock(response.data.company_city);
          response.data.company_town = this.$unlock(response.data.company_town);
          response.data.company_state = this.$unlock(response.data.company_state);
          response.data.company_region = this.$unlock(response.data.company_region);
          response.data.company_postcode = this.$unlock(response.data.company_postcode);
          response.data.company_district = this.$unlock(response.data.company_district);
          response.data.company_country_code = this.$unlock(response.data.company_country_code);
          response.data.company_vat_number = this.$unlock(response.data.company_vat_number);
          response.data.phone_country_code = this.$unlock(response.data.phone_country_code);
          response.data.x_coordinate = this.$unlock(response.data.x_coordinate);
          response.data.y_coordinate = this.$unlock(response.data.y_coordinate);
          response.data.uprn = this.$unlock(response.data.uprn);
          response.data.udprn = this.$unlock(response.data.udprn);
          this.data = response.data;
          this.form = {
            id: response.data.id,  
            address_id: '-1',
            controller_account_id: response.data.controller_id,
            company_name: response.data.company_name,
            sector_id: (response.data.sector_id == '' || response.data.sector_id == null) ? '-1' : response.data.sector_id,
            business_type_id: (response.data.business_type_id == '' || response.data.business_type_id == null) ? '-1' : response.data.business_type_id,
            company_type_id: (response.data.company_type_id == '' || response.data.company_type_id == null) ? '-1' : response.data.company_type_id,
            company_member_type: response.data.company_member_type,
            company_vkn: (response.data.company_vkn !== null && response.data.company_vkn !== '') ? response.data.company_vkn : null,
            primary_person: response.data.primary_person,
            company_gsm: response.data.company_gsm,
            company_landphone: response.data.company_landphone,
            company_email: response.data.company_email,
            company_address: response.data.company_address,
            company_city: response.data.company_city,
            company_town: response.data.company_town,
            company_state: response.data.company_state,
            company_region: response.data.company_region,
            company_region_select: (response.data.company_region == '' || response.data.company_region == null) ? '-1' : response.data.company_region,
            company_postcode: response.data.company_postcode,
            company_country_code: response.data.company_country_code,
            activation_date: response.data.activation_date,
            incorporated_at: response.data.incorporated_at,
            accounting_start_period: response.data.accounting_start_period,
            accounting_end_period: response.data.accounting_end_period,
            vat_start_period: response.data.vat_start_period,
            vat_end_period: response.data.vat_end_period,
            accounting_fee_monthly: parseFloat(response.data.accounting_fee_monthly.replace(/,/g, '')),
            accounting_fee_yearly: parseFloat(response.data.accounting_fee_yearly.replace(/,/g, '')),
            accountingFeeSelection: 'monthly',
            account_office_reference: response.data.account_office_reference,
            company_vat_number: response.data.company_vat_number,
            current_period: response.data.current_period,
            current_period_id: response.data.current_period_id,
            is_active: response.data.is_active == 1 ? true : false,
            is_quick_mode: response.data.is_quick_mode == 1 ? true : false,
            is_manual_integration_active: response.data.is_manual_integration_active == 1 ? true : false,
            is_insurance_module_active: response.data.is_insurance_module_active == 1 ? true : false,
            is_manage_users_by_groups: response.data.is_manage_users_by_groups == 1 ? true : false,
            is_consolidation_active: response.data.is_consolidation_active == 1 ? true : false,
            online_accounting: response.data.online_accounting == 1 ? true : false,
            is_vat_active: response.data.is_vat_active == 1 ? 'true' : 'false',
            is_automatic_task: response.data.is_automatic_task == 1 ? true : false,
            vat_period: response.data.vat_period,
            payroll_period: response.data.payroll_period,
            accounting_fee_monthly_date: response.data.accounting_fee_monthly_date,
            accounting_fee_yearly_date: response.data.accounting_fee_yearly_date,
            accounting_fee_period: response.data.accounting_fee_period,
            phone_country_code: response.data.phone_country_code,
            company_district: response.data.company_district,                    
            x_coordinate: (response.data.x_coordinate == null || response.data.x_coordinate == '') ? 0 : Math.abs(parseFloat(response.data.x_coordinate.replace(',', '.'))),
            y_coordinate: (response.data.y_coordinate == null || response.data.y_coordinate == '') ? 0 : Math.abs(parseFloat(response.data.y_coordinate.replace(',', '.'))),
            uprn: response.data.uprn,
            udprn: response.data.udprn,
            last_confirmation_statement: response.data.last_confirmation_statement,
            next_confirmation_statement: response.data.next_confirmation_statement,
            next_confirmation_due_date: response.data.next_confirmation_due_date,
            last_account_date: response.data.last_account_date,
            next_account_date: response.data.next_account_date,
            next_account_due_date: response.data.next_account_due_date,
            official_company_type: response.data.official_company_type,
            data_update_date: response.data.data_update_date,
            logo_url: response.data.logo_url
          };

          if(this.form.accounting_fee_period == 1){ this.form.accountingFeeSelection = 'monthly'; }
          if(this.form.accounting_fee_period == 3){ this.form.accountingFeeSelection = 'yearly'; }

          this.companySectors = [];
          this.businessTypes = []; 
          this.taskTypes = [];
          
          this.controllerAccountList = [];

          
          const parameters = {
            session_id: this.$getUser().session_id,
            country_code: this.form.company_country_code,
            get_access_status: 0,
            list_type: 19, // Liability Group: 19
            member_type: this.form.company_member_type
          }
          
          const requestOne =  axios.post('/api/Accounts/ControllerAccount', parameters, {'Content-Type': 'application/json'});
          const requestTwo =  axios.post('/api/CompanyManagement/CompanyTypes', parameters, {'Content-Type': 'application/json'}); 
          const requestThree =  axios.post('/api/SystemParameters/CompanyBusinessType', parameters, {'Content-Type': 'application/json'}); 
          const requestFour =  axios.post('/api/SystemParameters/CompanySectors', parameters, {'Content-Type': 'application/json'}); 
          const requestFive =  axios.post('/api/Lists/GetLists', parameters, {'Content-Type': 'application/json'}); 
          const requestSix =  axios.post('/api/Lists/GetCountryList', parameters, {'Content-Type': 'application/json'}); 
          const requestSeven =  axios.post('/api/Lists/GetRegionList', parameters, {'Content-Type': 'application/json'}); 
          
          axios.all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix, requestSeven]).then(axios.spread((...responses) => {
            this.controllerAccountList = responses[0].data;            
            this.controllerAccountList.forEach(element => {
              element.company_name = this.$unlock(element.company_name);
              element.text = this.$unlock(element.text);
              element.company_vkn = this.$unlock(element.company_vkn);
              element.primary_person = this.$unlock(element.primary_person);
              element.company_gsm = this.$unlock(element.company_gsm);
              element.company_landphone = this.$unlock(element.company_landphone);
              element.company_email = this.$unlock(element.company_email);
              element.company_address = this.$unlock(element.company_address);
              element.company_city = this.$unlock(element.company_city);
              element.company_town = this.$unlock(element.company_town);
              element.company_state = this.$unlock(element.company_state);
              element.company_region = this.$unlock(element.company_region);
              element.company_postcode = this.$unlock(element.company_postcode);
              element.company_country_code = this.$unlock(element.company_country_code);
              element.currency_code = this.$unlock(element.currency_code);
              element.phone_country_code = this.$unlock(element.phone_country_code);
            });
            this.companyTypes = responses[1].data;
            this.businessTypes = responses[2].data;
            this.companySectors = responses[3].data;
            this.liabilityGroupList = responses[4].data; 
            this.countryList = responses[5].data; 
            this.regionList = responses[6].data; 
            this.countryList.unshift({ id: '-1', text: 'Select an option'});
            this.regionList.unshift({ id: '-1', text: 'Select an option'});
            this.companyTypes.unshift({ id: '-1', text: 'Select an option'});
            this.companySectors.unshift({ id: '-1', text: 'Select an option'});
            this.businessTypes.unshift({ id: '-1', text: 'Select an option'});
            this.companyTypes.forEach(type => {
              if(type.id == this.form.company_type_id) {
                if (type.tax_type == 1) {this.form.companyTaxType = 'Corporation Tax';}
                if (type.tax_type == 2) {this.form.companyTaxType = 'Income Tax';}
                if (type.tax_type == 3) {this.form.companyTaxType = 'None Tax';}
              }
            });
            this.liabilityGroupList.unshift({ id: '-1', text: 'ALL'});
            this.countryList.forEach(element => {
              if(element.id == this.form.company_country_code) {
                this.form.phone_country_code = element.phone_country_code;
              }
            });                  
            this.$Progress.finish();
          }))
          .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish();
          });
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });  
    },
    saveData(){
      this.isProgressing = true;
      if (this.form.accounting_fee_monthly == null || this.form.accounting_fee_monthly == '' || this.form.accounting_fee_monthly == undefined) { this.form.accounting_fee_monthly = 0; }
      if (this.form.accounting_fee_yearly == null || this.form.accounting_fee_yearly == '' || this.form.accounting_fee_yearly == undefined) { this.form.accounting_fee_yearly = 0; }
      const params = this.form;
      const that = this;      
      if(params.company_country_code == 'GB'){
        params.company_region = params.company_region_select;
      } 
      params.incorporated_at = params.incorporated_at !== null ? moment(params.incorporated_at).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      params.accounting_start_period = params.accounting_start_period !== null ? moment(params.accounting_start_period).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      params.accounting_end_period = params.accounting_end_period !== null ? moment(params.accounting_end_period).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      params.vat_start_period = params.vat_start_period !== null ? moment(params.vat_start_period).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      params.vat_end_period = params.vat_end_period !== null ? moment(params.vat_end_period).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      params.accounting_fee_yearly_date = params.accounting_fee_yearly_date !== null ? moment(params.accounting_fee_yearly_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      params.accounting_fee_monthly_date = params.accounting_fee_monthly_date !== null ? moment(params.accounting_fee_monthly_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;

      params.session_id = this.$getUser().session_id;
      this.form.online_accounting == true ? params.online_accounting = 1 : params.online_accounting = 0; 
      this.form.is_active == true ? params.is_active = 1 : params.is_active = 0; 
      this.form.is_vat_active == 'true' ? params.is_vat_active = 1 : params.is_vat_active = 0; 
      this.form.is_automatic_task == true ? params.is_automatic_task = 1 : params.is_automatic_task = 0; 
      this.form.is_manual_integration_active == true ? params.is_manual_integration_active = 1 : params.is_manual_integration_active = 0; 
      this.form.is_insurance_module_active == true ? params.is_insurance_module_active = 1 : params.is_insurance_module_active = 0; 
      this.form.is_manage_users_by_groups == true ? params.is_manage_users_by_groups = 1 : params.is_manage_users_by_groups = 0; 
      this.form.is_consolidation_active == true ? params.is_consolidation_active = 1 : params.is_consolidation_active = 0; 
      this.form.is_quick_mode == true ? params.is_quick_mode = 1 : params.is_quick_mode = 0; 
      if (params.company_type_id == '-1') { params.company_type_id = null; }
      if (params.business_type_id == '-1') { params.business_type_id = null; }
      if (params.sector_id == '-1') { params.sector_id = null; }
      if (params.company_vkn == '') { params.company_vkn = null; }

      if(params.accountingFeeSelection == 'monthly'){
        params.accounting_fee_period = 1;
        params.accounting_fee_yearly = 0.00;
        params.accounting_fee_yearly_date = null;
      }
      if(params.accountingFeeSelection == 'yearly'){
        params.accounting_fee_period = 3;
        params.accounting_fee_monthly = 0.00;
        params.accounting_fee_monthly_date = null;
      }
      
      if (this.$getUser().account_type == 1 && params.controller_account_id == null){
        this.$swal("Failed", "Controller account is required!", 'error');
        this.isProgressing = false;
        return;
      }
      axios.post('/api/Accounts/UpdateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error && response.data != null && response.data.length == 36){ 
                params.is_active == 1 ? this.form.is_active = true : this.form.is_active = false; 
                params.online_accounting == 1 ? this.form.online_accounting = true : this.form.online_accounting = false; 
                params.is_vat_active == 1 ? this.form.is_vat_active = 'true' : this.form.is_vat_active = 'false'; 
                params.is_automatic_task == 1 ? this.form.is_automatic_task = true : this.form.is_automatic_task = false; 
                params.is_manual_integration_active == 1 ? this.form.is_manual_integration_active = true : this.form.is_manual_integration_active = false; 
                params.is_insurance_module_active == 1 ? this.form.is_insurance_module_active = true : this.form.is_insurance_module_active = false; 
                params.is_manage_users_by_groups == 1 ? this.form.is_manage_users_by_groups = true : this.form.is_manage_users_by_groups = false; 
                params.is_consolidation_active == 1 ? this.form.is_consolidation_active = true : this.form.is_consolidation_active = false; 
                params.is_quick_mode == 1 ? this.form.is_quick_mode = true : this.form.is_quick_mode = false; 
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('refreshList', true);    
                this.getData();
            } else {              
              if(this.form.company_country_code == null) {this.form.company_country_code = '-1';}
              if(this.form.business_type_id == null) {this.form.business_type_id = '-1';}
              if(this.form.company_type_id == null) {this.form.company_type_id = '-1';}
              if(this.form.company_member_type == null) {this.form.company_member_type = '-1';}
              if(this.form.sector_id == null) {this.form.sector_id = '-1';}
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        }) .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },    
    onChangeCountry(event) {
        if(event == null || event == '' || event == undefined) {
            this.countryList.forEach(element => {
                if(element.id == this.form.company_country_code) {
                    this.form.phone_country_code = element.phone_country_code;
                }
            });
        } else this.form.phone_country_code = event.phone_country_code;
        this.form.company_region_select = '-1';
        this.form.company_region = null;
        this.companyTypes = [];
        const params = {
            session_id: this.$getUser().session_id,
            country_code: this.form.company_country_code,
            get_access_status: 0,
            member_type: this.form.company_member_type
        };
        axios.post('/api/CompanyManagement/CompanyTypes', params, {'Content-Type': 'application/json'}).then((response) => { 
            this.companyTypes = response.data;             
            this.companyTypes.unshift({ id: '-1', text: 'Select an option'});            
            this.form.company_type_id = '-1';
        });
    },
    changeView(view){ 
      this.activeView = view;
      if(view == 'Tasks' || view == 'Liabilities'){
        this.getAccountingYears();
      }
      if(view == 'Liabilities'){
        this.getLiabilities();
      }
      if(view == 'Licence'){
        this.activeLicenceView = 'RemainingLicenceUses';
        this.getRemainingLicenceUses();
      }
    },
    changeAccountingView(view){
      this.activeAccountingView = view;
    },  
    getTaskTypes(callback){
      this.taskTypes = []; 
      const params = {
        session_id: this.$getUser().session_id,
        get_access_status: 0
      };
      axios.post("/api/TaskTypes/GetTaskTypes", params, {'Content-Type': 'application/json'}).then((response) => {  
          this.taskTypes = response.data;
          this.taskTypes.forEach((value) => {
            this.companyRelatedTaskTypes.forEach((companyTaskValue) => {
              value['isSelected'] = false;
              if(companyTaskValue.task_type_id == value.id){
                 value["isAdded"] = true;
              }
            }); 
          }); 

          if(typeof callback == 'function'){
            callback();
          }

          this.$Progress.finish();
        })
        .catch(function (error) {
          console.log(error);
          this.$Progress.finish();
        });
    },
    addTaskTypes(){
        this.selectedTaskTypes = [];
        this.taskTypes.forEach((value) => {
          if(value.isSelected){
             this.selectedTaskTypes.push(value.id);
          }
        }); 
        
        const params = {
          company_account_id: this.$getSessionStorage("cp_record_id"),
          TaskTypes: this.selectedTaskTypes,
          session_id: this.$getUser().session_id
        };
        axios.post('/api/CompanyTaskTypes/CreateCompanyTaskType', params, {'Content-Type': 'application/json'})
          .then((response) => { 
              if(response.data != null && response.data.length == 36){ 
                  this.$swal("Success", "Data saved succesfully..!", 'success');     
                  this.getCompanyTaskTypes();
              }
          })
          .catch(function (error) {
              console.log(error);
              this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
          });  
    },
    getCompanyTaskTypes(){
      this.companyRelatedTaskTypes = []; 
      const queryParameter = {
        id: this.$getSessionStorage("cp_record_id"),
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/CompanyTaskTypes/GetCompanyTaskTypes', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {    
            this.companyRelatedTaskTypes = response.data; 
            this.getTasks();
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });  
    },
    deleteCompanyType(id) { 
      const queryParameter = {
        id: id,
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/CompanyTaskTypes/DeleteCompanyTaskType', queryParameter, {'Content-Type': 'application/json'})
      .then(() => { 
          
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    deleteTask(id) { 
      this.$swal.fire({
        title: 'Are you sure you want to delete this data?',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        icon: 'warning'
      }).then((result) => { 
        if (result.isConfirmed) {
          const queryParameter = {
              id: id
          };
          axios.get('/api/Tasks/DeleteTask/' + queryParameter.id)
              .then(() => { 
                  this.getTasks();
              })
              .catch(function (error) {
                  console.log(error);
              });
        }
      })  
    },
    deleteAllTasks(){
        this.$swal.fire({
        title: 'Are you sure you want to delete this data?',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        icon: 'warning'
      }).then((result) => { 
        if (result.isConfirmed) { 
          axios.get('/api/Tasks/DeleteAllTasks')
              .then(() => { 
                  this.getTasks();
              })
              .catch(function (error) {
                  console.log(error);
              });
        }
      })  
    },
    removeAccountingPeriod(id){
        this.$swal.fire({
        title: 'Are you sure you want to delete this Accounting Period with Accounting Plans?',           
        icon: 'warning',
        confirmButtonColor: '#f1416c', //'#E53935',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!'
      }).then((result) => { 
        if (result.isConfirmed) { 
          const params = {
              session_id: this.$getUser().session_id,
              id: id
          };
          axios.post("/api/Accounts/DeleteCompanyAccountingYear", params, {'Content-Type': 'application/json'})
              .then((response) => { 
                if(!response.data.error){
                  this.$swal("Deleted", "Data deleted successfully..!", 'warning'); 
                  this.$refs.closePeriodModal.click();
                  this.getRefreshAccountingYears();
                } 
              })
              .catch(function (error) {
                  console.log(error);
              });
        }
      })  
    },
    getRefreshAccountingYears(){
      this.companyAccountingPeriods = [];
      const queryParameter = {
        id:  this.$getSessionStorage("cp_record_id"),
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/Accounts/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {
          this.companyAccountingPeriods = response.data;
          this.companyAccountingPeriodsFilter = response.data;
          this.companyAccountingPeriodsFilter.unshift({ id: '-1', text: 'All Accounting Periods'});
        })
        .catch(function (error) {
            console.log(error);
        }); 
    },
    getAccountingYears(){
      this.companyAccountingPeriods = [];
      this.companyAccountingPeriodsFilter = [];
      this.companyAccountingPeriodsAll = [];
      const queryParameter = {
        id:  this.$getSessionStorage("cp_record_id"),
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/Accounts/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
      .then((response) => {
        response.data.forEach((value) => {
          this.companyAccountingPeriods.push(value);
        });
        response.data.forEach((value) => {
          this.companyAccountingPeriodsFilter.push(value);
        });
        this.companyAccountingPeriodsAll = response.data;
        this.companyAccountingPeriodsAll.unshift({ id: null, name: 'All Accounting Periods'});
        this.companyAccountingPeriodsFilter.unshift({ id: '-1', text: 'All Accounting Periods'});
        if(response.data.length > 0){
          this.filterForm.accountYearFilterSelection = this.companyAccountingPeriodsAll[0];
        }
        if(response.data.length > 1){
          this.selectedAccountingPeriod = this.companyAccountingPeriodsAll[1];
          this.manuelLiabilityForm.period_id = this.form.current_period_id !== '' ? this.form.current_period_id : this.companyAccountingPeriodsAll[1].id;
          // this.liabilityFilterForm.period_id = this.form.current_period_id !== '' ? this.form.current_period_id : this.companyAccountingPeriodsFilter[1].id;
          this.generateLiabilityModalForm.accounting_period_id = this.form.current_period_id !== '' ? this.form.current_period_id : this.companyAccountingPeriodsFilter[1].id;

          if (this.form.current_period_id !== '' && this.form.current_period_id !== null && this.form.current_period_id !== undefined) {                  
            this.companyAccountingPeriodsAll.forEach(element => {
              if (element.id == this.form.current_period_id && element.id !== '-1') {
                this.$setSessionStorage('active_period', element);
              }
            });
          }
        }
        this.getTaskGroups();                
      })
      .catch(function (error) {
        console.log(error);
      }); 
    },
    getTasks(){
      this.tasks = [];
      this.$Progress.start(); 
      const params = {
        session_id : this.$getUser().session_id,
        company_account_id : this.$getSessionStorage("cp_record_id"),
        task_group_id: this.filterForm.taskGroupFilterSelection.id,
        accounting_period_id: this.filterForm.accountYearFilterSelection.id
      };  
      axios.post('api/Tasks/GetTasksByFilter', params, {'Content-Type': 'application/json'})
      .then((response) => { 
            this.tasks = response.data; 
            this.$Progress.finish();
      })
      .catch(function (error) {
          console.log(error); 
          this.$Progress.fail();
      }); 
    },
    getTaskGroups(){
      const params = {
        session_id : this.$getUser().session_id,
        get_access_status: 0,
        main_task_type: 0
      };
      axios.post("/api/TaskGroups/GetTaskGroups", params, {'Content-Type': 'application/json'}).then((response) => { 
          this.taskGroups = response.data;
          this.taskGroups.unshift({ id: null, name: 'All Task Groups'});
          this.filterForm.taskGroupFilterSelection = this.taskGroups[0];
          this.ttFilterForm.ttTaskGroupFilterSelection = this.taskGroups[0];
        })
        .catch(function (error) {
          console.log(error); 
        });
    },
    saveMailSettings(){
      this.isProgressing = true;
      const obj = {
        "username": this.mailSettingsForm.username,
        "password": this.mailSettingsForm.password,
        "sender": this.mailSettingsForm.sender,
        "smtp": this.mailSettingsForm.smtp,
        "port": this.mailSettingsForm.port,
        "ssl": this.mailSettingsForm.ssl
      };
      const mailInfo = JSON.stringify(obj);
      const params = {
        id: this.mailSettingsForm.id,
        session_id:  this.$getUser().session_id,
        data : mailInfo,
        company_id: this.$getSessionStorage("cp_record_id")
      };
      const that = this;
      axios.post('/api/MailSettings/Create', params, {'Content-Type': 'application/json'})
      .then((response) => { 
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.$swal("Success", "Data saved succesfully..!", 'success');
          this.mailSettingsForm.id = response.data;
        }
      })
      .catch(function (error) {
          console.log(error);
          this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
      })
      .finally(function () { 
          setTimeout(() => {
              that.isProgressing = false;
          }, 500);
      });   
    },
    addAccountingPeriod() {
      this.isAccountingYearSaving = true;
      const that = this;
      if(this.accountingYearModalForm.year == null || !(this.accountingYearModalForm.month > 0)){
          this.$swal.fire({
            icon: 'error',
            title: 'An Error Occured !!',
            text: 'Year should be defined..!'
          });
      }
      else {  
      const params = {
        company_account_id: this.$getSessionStorage("cp_record_id"), 
        year: parseInt(this.accountingYearModalForm.year),
        session_id: this.$getUser().session_id,
        cost_of_sales_method: this.accountingYearModalForm.cost_of_sales_method,
        plan_template_id: this.accountingYearModalForm.plan_template_id,
        is_automatic_task: this.accountingYearModalForm.is_automatic_task,
        account_plan_option: this.accountingYearModalForm.account_plan_option
      };
      if (params.plan_template_id == '-1') { params.plan_template_id = null; }
      axios.post('/api/Accounts/CreateCompanyAccountingYears', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error && response.data != null && response.data.length == 36){
              this.isAccountingYearSaving = false;
              this.$refs.closePeriodModal.click();
            }
            this.getRefreshAccountingYears();
        })
        .catch(function (error) {
            console.log(error);
            this.isAccountingYearSaving = false;
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function(){            
            that.isAccountingYearSaving = false;
        });
      } 
    },
    updateAccountingPeriod() {
      this.isAccountingYearSaving = true;
      const that = this;
      const params = this.accountingYearModalForm;
      params.session_id = this.$getUser().session_id;
      params.is_online_accounting = params.is_online_accounting ? 1 : 0;
      params.is_vat_active = params.is_vat_active ? 1 : 0;
      params.is_active = params.is_active ? 1 : 0;
      if (params.plan_template_id == '-1') { params.plan_template_id = null; }
      axios.post('/api/Accounts/UpdateCompanyAccountingYears', params, {'Content-Type': 'application/json'})
      .then((response) => {
          if(!response.data.error && response.data != null && response.data.length == 36){ 
            this.$swal("Success", "Accounting Period saved succesfully..!", 'success');
            this.isAccountingYearSaving = false;
            this.$refs.closePeriodModal.click();
            this.getRefreshAccountingYears();
          }
      })
      .catch(function (error) {
          console.log(error);
          this.isAccountingYearSaving = false;
          this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
      })
      .finally(function(){
          that.isAccountingYearSaving = false;
      });
    },
    setActiveTask(value){ 
      this.activeTaskType = value; 
    },
    openAccountingPeriodModal(modalType, data) {
      this.modalType = modalType;   
      this.accountingYearModalForm = {
        year: moment().year(),
        month: 12,
        cost_of_sales_method: 1,
        is_online_accounting: false,
        is_active: false,
        is_vat_active: false,
        cost_of_sales_method_status: false,
        plan_template_id: '-1',
        is_automatic_task: 0,
        period_year_short: null,
        account_plan_option: 1
      }       
      if (modalType == 'editItem') {
        this.accountingYearModalForm = {
          id: data.id,
          year: data.year,
          month: data.month,
          cost_of_sales_method: data.cost_of_sales_method,
          is_online_accounting: data.is_online_accounting == 1 ? true : false,
          is_active: data.is_active == 1 ? true : false,
          is_vat_active: data.is_vat_active == 1 ? true : false,
          cost_of_sales_method_status: data.cost_of_sales_method_status,
          plan_template_id: data.plan_template_id == '' ? '-1' : data.plan_template_id,
          is_automatic_task: data.is_automatic_task,
          period_year_short: data.period_year_short
        }
      }
      if(this.filteredCompanyAccountingPeriods.length == 0) {this.accountingYearModalForm.account_plan_option = 2;}
      const params = {
        session_id: this.$getUser().session_id,
        get_access_status: 0
      };
      axios.post("/api/AccountingPlans/GetPlanTemplates", params, {'Content-Type': 'application/json'}).then((templateResponse) => {
          this.planTemplateList = templateResponse.data;
          this.planTemplateList.unshift({id: '-1', text: 'Select an option'});

          if (modalType == 'addItem') {
            this.companyTypes.forEach(types => {
              this.planTemplateList.forEach(planTemplates => {
                if (types.id == this.form.company_type_id && types.plan_id == planTemplates.id) {
                  this.accountingYearModalForm.plan_template_id = planTemplates.id;
                }
              });                
            });
          }
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    getDaysBetweenDates(start, end, day) {
        var result = []; 
        var targetDate = day - 1; 
        var current = new Date(start); 
        current.setDate(current.getDate() + (targetDate - current.getDay() + 7) % 7); 
        while (current < end) {
          result.push(new Date(+current));
          current.setDate(current.getDate() + 7);
        }
        return result;  
    },
    getSpecificDayofWeek(target, dayOfWeek){
      const dayINeed = dayOfWeek + 1;   
      return moment(target).isoWeekday(dayINeed);
    },
    generateAllTasks(accountingYear){
      const params = {
            task_type_id: this.activeTaskType.task_type_id,
            company_id: this.$getSessionStorage("cp_record_id"),
            accounting_period_id: accountingYear.id, 
            session_id: this.$getUser().session_id
        };
        axios.post('/api/Tasks/GenerateTasks', params, {'Content-Type': 'application/json'})
          .then((response) => { 
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                setTimeout(() => this.activeTaskType = null, 500);
                this.$swal("Success", "Data saved succesfully..!", 'success');   
                this.activeTaskType = null;
                this.$Progress.finish();
                this.isTaskManagerRunning = false;
                this.getTasks(); 
              } 
              else{
                this.$swal("Hata", response.data.error, 'warning');  
                this.$Progress.finish();
                this.isTaskManagerRunning = false;
                this.activeTaskType = null; 
              }
          })
          .catch(function (error) { 
              this.$swal("Uyarı", "Seçilen Task Tipi için bazı tasklar zaten oluşturulmuş..! Detaylar: " + error);  
              this.activeTaskType = null; 
          });   
    },
    runTasks(accountingYear){ 

        this.isTaskManagerRunning = true;

        this.$Progress.start();

        var taskCount = 0;
        // 1: Free, 2: Daily, 3: Weekly, 4: Monthly, 5: Annual, 6: Periodic
        var periodType = this.activeTaskType.period_type;
        var accountingYearStartDate = moment(accountingYear.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        var accountingYearEndDate = moment(accountingYear.end_date).format('YYYY-MM-DD[T]HH:mm:ss');

        if(periodType == 2){   
            taskCount = moment(accountingYearEndDate).diff(moment(accountingYearStartDate), 'days') + 1;
        } 
        if(periodType == 3){  
          taskCount = moment(accountingYearEndDate).diff(moment(accountingYearStartDate), 'weeks') + 1; 
        }
        if(periodType == 4){ 
          taskCount = moment(accountingYearEndDate).diff(moment(accountingYearStartDate), 'months') + 1;  
        }
        if(periodType == 5){ 
          taskCount = moment(accountingYearEndDate).diff(moment(accountingYearStartDate), 'years');
        }
        if(taskCount == 0){
          taskCount = 1;
        } 

        var taskCollection = [];

        for (var i = 0; i < taskCount; i++) {  
            var startDate = null;
            var endDate = null;  

            if(periodType == 2){
              startDate = moment(accountingYear.start_date).add(i, 'days').format('YYYY-MM-DD[T]HH:mm:ss');
              endDate = moment(accountingYear.start_date).add(i + 1, 'days').format('YYYY-MM-DD[T]HH:mm:ss');  
              var startDayDaily = moment(startDate).format('D'); 
              var startMonthDaily = moment(startDate).format('M'); 
              var startYearDaily = moment(startDate).format('Y'); 
              var endDayDaily = moment(endDate).format('D'); 
              var endMonthDaily = moment(endDate).format('M'); 
              var endYearDaily = moment(endDate).format('Y'); 

              var startHour = moment(this.activeTaskType.period_daily_start_time).format('HH');
              var startMinute = moment(this.activeTaskType.period_daily_start_time).format('mm');
              var endHour = moment(this.activeTaskType.period_daily_end_time).format('HH');
              var endMinute = moment(this.activeTaskType.period_daily_end_time).format('mm');

              startDate = moment([startYearDaily, startMonthDaily -1, startDayDaily, startHour, startMinute, 0]).format('YYYY-MM-DD[T]HH:mm:ss');
              endDate = moment([endYearDaily, endMonthDaily -1, endDayDaily, endHour, endMinute, 0]).format('YYYY-MM-DD[T]HH:mm:ss'); 
            }
            if(periodType == 3){
              startDate = moment(accountingYear.start_date).add(i, 'weeks').format('YYYY-MM-DD[T]HH:mm:ss');
              endDate = moment(accountingYear.start_date).add(i + 1, 'weeks').format('YYYY-MM-DD[T]HH:mm:ss');
              // Belirlenen zaman aralığındaki tüm 'Seçili Günler' için Weekly Tasklar oluşturulmalıdır.
              // Her hafta Cuma günü gibi. 
              startDate = this.getSpecificDayofWeek(moment(startDate), this.activeTaskType.period_weekly_date);
              endDate = this.getSpecificDayofWeek(moment(endDate), this.activeTaskType.period_weekly_date); 
            }
            if(periodType == 4){
              var periodMonthlyDay = this.activeTaskType.period_monthly_date;
              startDate = moment(accountingYear.start_date).add(i, 'months').format('YYYY-MM-DD[T]HH:mm:ss');
              endDate = moment(accountingYear.start_date).add(i + 1, 'months').format('YYYY-MM-DD[T]HH:mm:ss');  
              var startMonth = moment(startDate).format('M'); 
              var startYear = moment(startDate).format('Y'); 
              var endMonth = moment(endDate).format('M'); 
              var endYear = moment(endDate).format('Y'); 
              startDate = moment([startYear, startMonth -1, periodMonthlyDay, 0, 0, 0]).format('YYYY-MM-DD[T]HH:mm:ss');
              endDate = moment([endYear, endMonth -1, periodMonthlyDay, 0, 0, 0]).format('YYYY-MM-DD[T]HH:mm:ss');
            }
            if(periodType == 5){ 
              var annualDate = moment(this.activeTaskType.period_annual_date).format('YYYY-MM-DD[T]HH:mm:ss');
              startDate = moment(annualDate).add(i, 'years').format('YYYY-MM-DD[T]HH:mm:ss');
              endDate = moment(annualDate).add(i, 'years').format('YYYY-MM-DD[T]HH:mm:ss');  
              var startDayAnnual = moment(startDate).format('D'); 
              var startMonthAnnual = moment(startDate).format('M'); 
              var startYearAnnual = moment(startDate).format('Y'); 
              var endDayAnnual = moment(endDate).format('D'); 
              var endMonthAnnual = moment(endDate).format('M'); 
              var endYearAnnual = moment(endDate).format('Y'); 
              startDate = moment([startYearAnnual, startMonthAnnual -1, startDayAnnual, 0, 0, 0]).format('YYYY-MM-DD[T]HH:mm:ss');
              endDate = moment([endYearAnnual, endMonthAnnual -1, endDayAnnual, 0, 0, 0]).format('YYYY-MM-DD[T]HH:mm:ss');

              // Oluşturulan tarih Mali Dönem içerisinde mi diye kontrol edilir. 
              // Eğer değilse Yıl bir düşürülür. 
              // Ve bu yeni tarih yine mali dönem içerisinde var mı kontrol edilir.
              if(!moment(startDate).isBetween(accountingYearStartDate, accountingYearEndDate, 'days', true)){ 
                var betaDate = moment([startYearAnnual -1, startMonthAnnual -1, startDayAnnual, 0, 0, 0]).format('YYYY-MM-DD[T]HH:mm:ss');
                if(moment(betaDate).isBetween(accountingYearStartDate, accountingYearEndDate, 'days', true)){ 
                  startDate = betaDate;
                }
              }  
            }

            // Eğer bu tarih Mali Dönem içerisindeyse eklenir.  
            if(!(moment(startDate) >= moment(accountingYearStartDate))){
              this.$swal("Uyarı", "Seçilen Task Tipinin tarih aralığı mali döneminin kapsamı dışında kalıyor. Task oluşturulamadı..!"); 
              this.$Progress.finish(); 
              this.isTaskManagerRunning = false;
            }
            else{ 
                // Set Reminder Time
                var task_reminder_date = moment(startDate);
                if(this.activeTaskType.reminder_day !== null && this.activeTaskType.reminder_day > 0){
                    task_reminder_date = moment(task_reminder_date).subtract(this.activeTaskType.reminder_day, 'days').format('YYYY-MM-DD[T]HH:mm:ss');
                }
                if(this.activeTaskType.reminder_hours !== null && this.activeTaskType.reminder_hours > 0){
                    task_reminder_date = moment(task_reminder_date).subtract(this.activeTaskType.reminder_hours, 'hours').format('YYYY-MM-DD[T]HH:mm:ss');
                } 
                
                const params = {
                    task_type_id: this.activeTaskType.task_type_id,
                    company_account_id: this.$getSessionStorage("cp_record_id"),
                    accounting_period_year: accountingYear.id,
                    task_start_date: startDate,
                    task_completed_date: endDate,
                    session_id: this.$getUser().session_id,
                    task_reminder_date: task_reminder_date
                };

                taskCollection.push(params); 
            } 
        }     
      
        const collectionParams = {
          collection: taskCollection
        };
        
        axios.post('/api/Tasks/CreateTask', collectionParams, {'Content-Type': 'application/json'})
          .then(() => {  
              setTimeout(() => this.activeTaskType = null, 500);
              this.$swal("Success", "Data saved succesfully..!", 'success');   
              this.activeTaskType = null;
              this.getTasks(); 
              this.$Progress.finish();
              this.isTaskManagerRunning = false;
          })
          .catch(function (error) { 
              this.$swal("Uyarı", "Seçilen Task Tipi için bazı tasklar zaten oluşturulmuş..! Detaylar: " + error);  
              this.activeTaskType = null; 
          });   
    },
    openTasksTimeLine(){
      this.isTimelineDrawerOpen = true;
      this.getTasks();
    },
    closeTasksTimeLine(){
      this.isTimelineDrawerOpen = false;
    },
    deleteUser(id) { 
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            icon: 'warning',
            confirmButtonColor: '#f1416c', //'#E53935',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
              id: id,
              session_id: this.$getUser().session_id
            };
            axios.post('/api/Accounts/DeleteAccount', queryParameter, {'Content-Type': 'application/json'})
            .then((response) => { 
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                  this.$swal("Deleted", "Data deleted successfully..!", 'warning'); 
                  this.$setSessionStorage('refreshList', true);
                  this.$router.push({ name: 'CompanyAccountList' });
                } 
            })
            .catch(function (error) {
                console.log(error);
            });
          }
        })  
    },
    deleteCompany() { 
      this.$swal.fire({
          title: 'Are you sure you want to delete this all Company data and this company?',
          icon: 'warning',
          confirmButtonColor: '#f1416c', //'#E53935',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes, Delete Company!'
      }).then((result) => { 
        if (result.isConfirmed) {
          const params = {
            session_id: this.$getUser().session_id,
            company_id: this.form.id,
          };
          axios.post('/api/Accounts/DeleteCompanyAccount', params, {'Content-Type': 'application/json'})
          .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.$swal("Deleted", "Data deleted successfully..!", 'warning'); 
                this.$setSessionStorage('refreshList', true);
                this.$router.push({ name: 'CompanyAccountList' });
              } 
          })
          .catch(function (error) {
              console.log(error);
          });
        }
      })
    },
    removeTaskTypesFromCompany(){
      this.$swal.fire({
        title: 'Are you sure you want to delete this data?',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        icon: 'warning'
      }).then((result) => { 
        if (result.isConfirmed) {
            this.companyRelatedTaskTypes.forEach((type) => {
              if(type.isSelected){
                this.deleteCompanyType(type.id);
              }
            });  

            setTimeout(() => {
              this.getCompanyTaskTypes();
          }, 1000); 
        }
      })
    },
    getControllerUserList() {
      this.controllerUserList = [];
      const params = {
        session_id: this.$getUser().session_id,          
        company_id: this.$getSessionStorage("cp_record_id")
      }
      axios.post("/api/Accounts/ControllerUsersForCompany", params, {'Content-Type': 'application/json'})
      .then((response) => { 
        this.controllerUserList = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    onSaveAuthorization(item) {
      this.$swal.fire({
            title: item.access_granted == true ? 'Confirm to grant access to user?' : 'Confirm to remove access from user?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Yes'
        }).then((result) => { 
          if (result.isConfirmed) {
            const params = {
              session_id: this.$getUser().session_id,
              company_id: this.$getSessionStorage("cp_record_id"),
              user_id: item.id,
              add_remove: (item.access_granted == true ? 1 : 0)
            } 
            axios.post("/api/Accounts/SaveControllerUserAccess", params, {'Content-Type': 'application/json'}).then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') {
                this.getControllerUserList();
              }
            })
            .catch(function (error) {
              console.log(error); 
            });
          }
          else{
            item.access_granted = !item.access_granted;
          }
      });
    },
    openLiabilityModal() {
      this.isLiabilitySaving = false;
      this.manuelLiabilityForm.period_id = this.form.current_period_id !== '' ? this.form.current_period_id : this.filteredCompanyAccountingPeriods[0].id;
      this.manuelLiabilityForm.liability_id = '-1';
      this.manuelLiabilityForm.created_date = moment();
      this.manuelLiabilityForm.value_date = moment();
      this.manuelLiabilityForm.amount = 0.00;
      this.manuelLiabilityForm.description = null;
      this.manuelLiabilityForm.value_criteria = 0;
      this.manuelLiabilityForm.payment_day_option = 0;
      this.manuelLiabilityForm.period_month = 1;
      this.manuelLiabilityForm.amount_type = 0;

      const parameters = {
        session_id: this.$getUser().session_id,
        company_type_id: this.form.company_type_id
      };  
      axios.post('/api/Liabilities/GetManuelLiabilityTypes', parameters, {'Content-Type': 'application/json'})
      .then((response) => { 
        this.liabilityTypeList = response.data;
        this.liabilityTypeList.unshift({id: '-1', text: 'Select an option'});
      })
      .catch(function (error) {
        console.log(error); 
      });
    },
    addManuelLiability() {
        const parameters = {
            session_id: this.$getUser().session_id,
            period_id: this.manuelLiabilityForm.period_id,
            liability_id: this.manuelLiabilityForm.liability_id,
            at: moment(this.manuelLiabilityForm.created_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            value_date: moment(this.manuelLiabilityForm.value_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            amount: this.manuelLiabilityForm.amount_type == 0 ? 0 : this.manuelLiabilityForm.amount,
            description: this.manuelLiabilityForm.description,
            value_criteria: this.manuelLiabilityForm.value_criteria,
            payment_day_option: this.manuelLiabilityForm.payment_day_option,
            period_month: this.manuelLiabilityForm.period_month,
            amount_type: this.manuelLiabilityForm.amount_type
        };
        axios.post('/api/Liabilities/CreateManuelLiability', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
              this.$refs.closeManualLiabilityModal.click();
              this.liabilityFilterForm.period_id = this.manuelLiabilityForm.period_id;
              this.getLiabilities();
            }
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    onUpdateRow(e){
      const parameters = {
        id: e.oldData.id,
        session_id: this.$getUser().session_id,
        liability_id: e.oldData.liability_id,
        at: e.newData.at ? moment(e.newData.at).format('YYYY-MM-DD[T]HH:mm:ss') : moment(e.oldData.at).format('YYYY-MM-DD[T]HH:mm:ss'),
        value_date: e.newData.value_date ? moment(e.newData.value_date).format('YYYY-MM-DD[T]HH:mm:ss') : moment(e.oldData.value_date).format('YYYY-MM-DD[T]HH:mm:ss'),
        amount: e.newData.amount ? parseFloat(e.newData.amount.toString().split(',').join('')) : parseFloat(e.oldData.amount.toString().split(',').join('')),
        description: e.newData.description ? e.newData.description : e.oldData.description
      };
      axios.post('/api/Liabilities/UpdateCompanyLiability', parameters, { 'Content-Type': 'application/json' })
      .then((response) => {
        if (response.data.error == null || response.data.error == '' || response.data.error == undefined) {                
          const dataSource = this.$refs["dxLiabilities"].instance.getDataSource();
          var store = dataSource.store();
          store.update(response.data.id,  response.data)
          .done(function () {
            dataSource.reload();
          })
          .fail(function (error) { console.log(error) });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    onDeleteRow(e) {
      const params = {
        session_id: this.$getUser().session_id, 
        id: e.key,
        company_id: null
      };   
      axios.post('api/Liabilities/DeleteLiabilityItem', params, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.getLiabilities();
        } 
      });
    },
    getLiabilities(){
      const liabilityGroupParams = {
        session_id: this.$getUser().session_id,
        get_access_status: 1,
        list_type: 19 // Liability Group: 19
      };
      axios.post('/api/Lists/GetLists', liabilityGroupParams, {'Content-Type': 'application/json'})
      .then((response) => { 
          this.liabilityGroupList = response.data; 
          this.liabilityGroupList.unshift({ id: '-1', text: 'ALL'});
          
          this.companyLiabilities = [];
          this.$Progress.start(); 
          const params = {
            session_id : this.$getUser().session_id,
            company_id : this.$getSessionStorage("cp_record_id"),
            period_id: this.liabilityFilterForm.period_id,
            liability_group_id: this.liabilityFilterForm.liability_group_id
          };  
          axios.post('api/Liabilities/GetCompanyLiabilities', params, {'Content-Type': 'application/json'})
          .then((response) => { 
            this.companyLiabilities = response.data; 
            this.$Progress.finish();
          })
          .catch(function (error) {
              console.log(error); 
              this.$Progress.fail();
          }); 
      })        
      .catch(function (error) {
        console.log(error); 
        this.$Progress.finish();
      });
    },
    onChangeCompanyTypes() {
      this.form.companyTaxType = '';
      this.companyTypes.forEach(types => {
        if(types.id == this.form.company_type_id) {
          if (types.tax_type == 1) { this.form.companyTaxType = 'Corporation Tax';}
          if (types.tax_type == 2) { this.form.companyTaxType = 'Income Tax';}
          if (types.tax_type == 3) { this.form.companyTaxType = 'None Tax';}
        }
      });
    },
    onChangeLiabilityType(event) {      
      if (event.liability_id != '-1') {
          this.manuelLiabilityForm.period_month = event.period_month;
          this.manuelLiabilityForm.amount_type = event.amount_type;
          this.manuelLiabilityForm.amount = event.amount;  
      }
    },
    onSelectItem(item) { 
      this.companyLiabilities.forEach((value) => {  
        if (item.id !== value.id) { 
          value.isSelected = false;
        }
      });
    },
    deleteAllLiabilityItems() {
      if (this.liabilityFilterForm.period_id == '-1') {
        this.$swal("Failed", " Accounting Period must be selected..!", 'warning' );
        return;
      }

      this.$swal.fire({
        title: "Are you sure you want to remove all items?",
        confirmButtonColor: "#f1416c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "DELETE",
        icon: 'info'
      })
      .then((result) => {
        if (result.isConfirmed) {  
          this.$Progress.start(); 
          const params = {
            id: null,
            session_id: this.$getUser().session_id,
            period_id: this.liabilityFilterForm.period_id,
            liability_group_id: this.liabilityFilterForm.liability_group_id
          };   
          axios.post('api/Liabilities/DeleteAllLiabilityItems', params, {'Content-Type': 'application/json'})
          .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.companyLiabilities = [];
              this.$Progress.finish();
            } 
            this.$Progress.finish();
          })
          .catch(function () {
            this.$Progress.finish();
          }); 
        }
      }); 
    },
    generateLiabilities() {      
      if (this.generateLiabilityModalForm.accounting_period_id == '-1') {
        this.$swal("Failed", " Accounting Period must be selected..!", 'warning' );
        return;
      }
      this.$Progress.start(); 
      const params = {
        session_id: this.$getUser().session_id, 
        period_id: this.generateLiabilityModalForm.accounting_period_id,        
        liability_group_id: null
      };   
      axios.post('api/Liabilities/GenerateLiabilities', params, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.liabilityFilterForm.period_id = this.generateLiabilityModalForm.accounting_period_id;
          this.$refs.closeLiabilityGenerateModal.click();
          this.getLiabilities();
          this.$Progress.finish();
        } 
        this.$Progress.finish();
      })
      .catch(function () {
        this.$Progress.finish();
      });    
    },
    getOperationGroups() {
        this.$Progress.start();
        this.lists = [];
        this.isListProgressing = true;
        const params = {
          session_id: this.$getUser().session_id,
          company_id: this.$getSessionStorage("cp_record_id")
        };
        axios.post('/api/Accounts/GetCompanyOperationGroups', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.operationGroupList = response.data;
          this.$Progress.finish();
          setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
          console.log(error); 
          this.$Progress.finish();
          setTimeout(() => {
            this.isListProgressing = false;
          }, 500);
        });      
    },
    onSaveOperationGroup(item) {
      this.$swal.fire({
            title: item.is_added_to_company == true ? 'Are you sure to add this group to company?' : 'Are you sure to remove this group?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Yes'
        }).then((result) => { 
          if (result.isConfirmed) {            
            const params = {
              session_id: this.$getUser().session_id,
              company_id: this.$getSessionStorage("cp_record_id"),
              operation_group_id: item.id,
              add_remove: (item.is_added_to_company == true ? 1 : 0)
            } 
            axios.post("/api/Accounts/SaveCompanyOperationGroup", params, {'Content-Type': 'application/json'}).then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') {
                this.getOperationGroups();
              }
            })
            .catch(function (error) {
              console.log(error); 
            });
          }
          else{
            item.is_added_to_company = !item.is_added_to_company;
          }
      });
    },
    onSelectControllerAccount(event) {
        this.form.online_accounting = event.company_online_accounting_default == 1 ? true : false;
        if(event.company_country_code !== null && event.company_country_code !== '') {
          this.form.company_country_code = event.company_country_code;
          this.onChangeCountry();
        }
        if(event.company_region !== null && event.company_region !== '') {
          this.form.company_region = event.company_region;
          this.form.company_region_select = event.company_country_code == 'GB' ? event.company_region : '-1';
        } else {            
          this.form.company_region_select = '-1';
          this.form.company_region = null;
        }
    },
    getLicencePaymentPlan() {
        this.$Progress.start();
        const params = {
          session_id: this.$getUser().session_id,
          company_id: this.$getSessionStorage("cp_record_id"),
          
        };
        axios.post('/api/Accounts/GetPaymentPlan', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.licencePaymentPlanList = response.data;
          this.$Progress.finish();
        })
        .catch(function (error) {
          console.log(error); 
          this.$Progress.finish();
        });
    },
    getRemainingLicenceUses() {
      this.$Progress.start();
      const params = {
        session_id: this.$getUser().session_id,
        owner_id: this.$getSessionStorage("cp_record_id")
      };
      axios.post('/api/Accounts/CurrentLic', params, {'Content-Type': 'application/json'})
      .then((response) => {
        this.licenceForm = response.data;
        this.licenceForm.add_user_count = 0,
        this.licenceForm.add_period_day = 0,
        this.$Progress.finish();
      })
      .catch(function (error) {
        console.log(error); 
        this.$Progress.finish();
      });
    },
    onUpdateLicenceUses() {
      this.$swal.fire({
        title: 'If you click Update Licence button, this company\'s licence uses will be updated and all active sessions will be logged out. All users need to login to the system again.',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Yes'
      }).then((result) => { 
        if (result.isConfirmed) {
          const parameters = {
            session_id: this.$getUser().session_id,
            owner_id: this.$getSessionStorage("cp_record_id"),        
            user_count: this.licenceForm.add_user_count,
            period_day: this.licenceForm.add_period_day,
            is_documents_included: this.licenceForm.is_documents_included == true ? 1 : 0,
            is_liabilities_included: this.licenceForm.is_liabilities_included == true ? 1 : 0,
            document_limit: this.licenceForm.document_limit,
            is_company_inquiry_active: this.licenceForm.is_company_inquiry_active == true ? 1 : 0,
            is_address_inquiry_active: this.licenceForm.is_address_inquiry_active == true ? 1 : 0,
            company_inquiry_rate_limit: this.licenceForm.company_inquiry_rate_limit,
            address_inquiry_rate_limit: this.licenceForm.address_inquiry_rate_limit,
          };  
          axios.post('/api/Accounts/UpdateLicence', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.$swal("Saved", "Licence updated successfully..!", 'success'); 
              this.getRemainingLicenceUses();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      });
    },
    onSetLicenceAsPaid(paymentPlanId) {
      this.$swal.fire({
        title: 'Only confirm this transaction if this payment has been completed. After confirmation, the package content will be defined to the license and all active sessions will be logged out. Users can relogin to the system to activate the new plan.',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Yes'
      }).then((result) => { 
        if (result.isConfirmed) {
          const parameters = {
            session_id: this.$getUser().session_id,
            owner_id: this.$getSessionStorage("cp_record_id"),
            payment_plan_id: paymentPlanId
          };  
          axios.post('/api/Accounts/SetLicenceAsPaid', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.getLicencePaymentPlan();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      });
    },    
    onChangeMemberType() {
        if(this.form.company_member_type == 2) {
            this.form.company_vkn = null;
        }
        this.companyTypes = [];
        const params = {
            session_id: this.$getUser().session_id,
            country_code: this.form.company_country_code,
            get_access_status: 0,
            member_type: this.form.company_member_type
        };
        axios.post('/api/CompanyManagement/CompanyTypes', params, {'Content-Type': 'application/json'}).then((response) => { 
            this.companyTypes = response.data;             
            this.companyTypes.unshift({ id: '-1', text: 'Select an option'});            
            this.form.company_type_id = '-1';
        });
    },
    onSearchCompanyByNo(selectedCompanyNumber){
        if(selectedCompanyNumber) {
            this.form.company_vkn = selectedCompanyNumber;
        }
        const parameters = {
            CompanyNumber: this.form.company_vkn,
            SessionId: this.$getUser().session_id
        }
        axios.post('api/Accounts/SearchByNo', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.form.company_name = response.data.name;
            this.form.company_vkn = response.data.number;
            this.form.primary_person = response.data.contactName;
            this.form.company_postcode = response.data.postalCode;
            this.form.company_address = response.data.addressLine2 == null ? response.data.addressLine1 : response.data.addressLine1 + ' '+ response.data.addressLine2; 
            //this.form.country = response.data.country; Çıktı England şeklinde?
            this.form.company_city = response.data.locality;
            this.form.company_town = response.data.Town;
            this.form.company_state = response.data.State;
            this.form.company_region = response.data.Region;
            this.form.last_confirmation_statement = response.data.last_confirmation_statement;
            this.form.next_confirmation_statement = response.data.next_confirmation_statement;
            this.form.next_confirmation_due_date = response.data.next_confirmation_due_date;
            this.form.last_account_date = response.data.last_account_date;
            this.form.next_account_date = response.data.next_account_date;
            this.form.next_account_due_date = response.data.next_account_due_date;
            this.form.official_company_type = response.data.official_company_type;
            this.form.incorporated_at = response.data.corporatedAt;
            
            if(this.form.company_vkn !== null && this.form.company_vkn !== '' && this.form.company_vkn !== undefined) {
                this.form.company_member_type = 1;
            }
        })
    },
    onSearchCompanyByName(){
        this.$refs.SearchCompanyByName.getData();
    },
    onFindAddressByPostCode(){  
        this.$refs.FindAddressByPostCode.getData();
    },
    onSelectAddressSearchResult(item){
        this.form.company_address = item.ADDRESS;
        this.form.company_city = item.POST_TOWN;
        this.form.company_district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
        this.form.x_coordinate = item.X_COORDINATE;
        this.form.y_coordinate = item.Y_COORDINATE;
        this.form.uprn = item.UPRN;
        this.form.udprn = item.UDPRN;
        this.form.company_postcode = item.POSTCODE;
    },
    onRedirectToController() {
      this.$setSessionStorage("record_id", this.form.controller_account_id);
      this.$router.push({ name: 'ControllerAccountUpdate', params: { isRedirect: true } });
    },
    setDecimalValueForEdit(e){
      e.data.amount = e.data.amount.replaceAll(',','');
    },
  },
  created() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  },
  mounted(){ 
  }
}; 
</script>